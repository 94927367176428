import React from 'react';
import './QaCertified.css';
import {qaCertifiedTextOne, qaCertifiedTextThree, qaCertifiedTextTwo, qaCertifiedTitle} from "../../mocks";
import {smoothScroll} from "../../utils";

export const QaCertified = () => (
    <>
        <div className="qa-certified-container">
            <div className="qa-certified-logo">
                <img
                    src="/img/qa/resized/ISTQBlogo_small.webp"
                    srcSet="/img/qa/resized/ISTQBlogo_small.webp 300w, /img/qa/resized/ISTQBlogo_large.webp 768w, /img/qa/resized/ISTQBlogo_small.webp 1400w"
                    alt="ISTQBlogo"
                    loading="lazy"
                    width="100%"
                    height="100%"
                />
            </div>
            <div className="qa-certified-content">
                <h2 className="main-title">{qaCertifiedTitle}</h2>
                <p className="qa-certified-text">{qaCertifiedTextOne}</p>
                <h4 className="qa-certified-text">{qaCertifiedTextTwo}</h4>
                <p className="qa-certified-text">{qaCertifiedTextThree}</p>
                <button
                    onClick={() => smoothScroll("contact-title")}>
                    Contact us
                </button>
            </div>
        </div>
    </>
);
