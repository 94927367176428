import React from 'react';
import './JsdLogos.css';
import {jsdLogos, jsdLogos2} from '../../mocks';

export const JsdLogos = () => (
  <React.Fragment>
    <div className='jsd-logos-container-1'>
      {jsdLogos.map(item => (
        <div key={item.id}>
          <img
              src={item.path}
              alt="logo"
              srcSet={item.srcSet}
              className="jsd-logos-img-1"
              loading="lazy"
              width="100%"
              height="100%"
          />
        </div>
      ))}
    </div>
    <div className='jsd-logos-container-2'>
      {jsdLogos2.map(item => (
        <div className='jsd-logos-img-2-box' key={item.id}>
          <img
              src={item.path}
              alt="logo"
              className="jsd-logos-img-2"
              loading="lazy"
              width="100%"
              height="100%"
          />
        </div>
      ))}
    </div>
  </React.Fragment>
);
