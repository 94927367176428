import React from 'react';
import './CaseStudyFeedback.css';

export const CaseStudyFeedback = ({cssClass, img, name, position, text, author}) => (
        <div className='case-study-feedback-container'>
            {img && <img src={img} alt="Avatar" width="70" height="70" loading="lazy"/>}
            {name && <h5><strong>{name}</strong></h5>}
            {position && <h6 className='gray-text'><strong>{position}</strong></h6>}
            <p className={cssClass}>{text}</p>
            {author && <h5><strong>{author}</strong></h5>}
        </div>
);
