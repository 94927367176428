import React, {useEffect} from 'react';
import './DedicatedTeam.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {
    CaseStudiesGrid, Feedback,
    Hero,
    PageBody,
    Spacer,
    Wrapper,
    ScrollDownBtn, LogosList, WdsSlider
} from "../../components";
import {portfolioItem} from "../../utils";
import {ContactForm, MainCaption, SliderMaker} from "..";
import {breadcrumbStructuredData, dedicatedTechFaqStructuredData} from "../../utils/structuredData";
import {
    dedicatedMainCaptionText,
    dedicatedMainCaptionTitle,
    testimonialItems,
    homeClientsSliderHeader,
    dedicatedWhyChooseCards,
    nodeProjectText,
    dedicatedHiringTitle,
    dedicatedHiringText,
    dedicatedHiringContent,
    dedicatedHiringTextOne,
    dedicatedHiringTextTwo,
    dedicatedHiringTextThree,
    dedicatedHiringTextFour,
    customersLogos,
    dedicatedFaqTitle,
    dedicatedFaqList,
    technologiesLogos,
    homeLogosWorkWith,
    dedicatedProcessCards
} from "../../mocks";
import TechWhatOfferCards from "../../components/TechWhatOfferCards/TechWhatOfferCards";
import DedicatedProcess from "../../components/DedicatedProcess/DedicatedProcess";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import BlueLine from "../../components/BlueLine/BlueLine";
import TechWhatOfferPhoto from "../../components/TechWhatOfferPhoto/TechWhatOfferPhoto";
import FAQ from "../../components/FAQ/FAQ";

function DedicatedTeam() {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <React.Fragment>
            <HelmetSetter
                title="Dedicated Development Team Services | ELITEX"
                description="Unlock the full potential of dedicated development team services with ELITEX. Hire a dedicated software development team from a trusted development partner."
                ogImg="dedicatedTeam/back.jpg"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "Dedicated Development Teams",
                    item2: "https://elitex.systems/dedicated-software-development-team"
                })}
                faqStructuredData={dedicatedTechFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img dedicated">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={dedicatedMainCaptionTitle} text={dedicatedMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody>
                <div id="testimonial-slider">
                    <SliderMaker dark
                                 clientsSliderHeader={homeClientsSliderHeader}
                                 sliderItems={testimonialItems}
                                 SliderItem={Feedback} speed={15000}
                                 dots
                    />
                </div>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">Why Choose ELITEX’s <strong>Dedicated Development Team Services</strong>
                    </h2>
                    <TechWhatOfferCards cardsContent={dedicatedWhyChooseCards} noBlueLine/>
                    <Spacer type="big"/>
                    <h2 className="main-title">Proven Dedicated <strong>Software Development Team</strong> Process</h2>
                    <Spacer type="big"/>
                    <DedicatedProcess content={dedicatedProcessCards}/>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation text={nodeProjectText}/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{dedicatedHiringTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{dedicatedHiringText}</p>
                    <Spacer type="big"/>
                    <TechWhatOfferPhoto content={dedicatedHiringContent} bold/>
                    <Spacer type="big"/>
                    <p className="main-subtitle left">{dedicatedHiringTextOne}</p>
                    <p className="main-subtitle left">{dedicatedHiringTextTwo}</p>
                    <p className="main-subtitle left">{dedicatedHiringTextThree}</p>
                    <p className="main-subtitle left">{dedicatedHiringTextFour}</p>
                </Wrapper>
                <Spacer type="big"/>
                <h2 className="main-title">Check Out Our Case Studies for <strong>Dedicated Development Team
                    Model</strong></h2>
                <Spacer type="small"/>
                <CaseStudiesGrid list={[
                    portfolioItem('hotelReservations', false),
                    portfolioItem('smartrr', true),
                    portfolioItem('stm', false),
                    portfolioItem('felix', true)
                ]}/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">Our clients and partners</h2>
                    <LogosList list={customersLogos} redirect/>
                    <Spacer type="big"/>
                    <h2 className="main-title">Our Other Services</h2>
                </Wrapper>
                <Spacer type="small"/>
                <WdsSlider/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <FAQ title={dedicatedFaqTitle} content={dedicatedFaqList}/>
                    <Spacer type="big"/>
                    <h2 className="main-title" id="portfolio-grid">Technologies We Provide</h2>
                    <Spacer type="medium"/>
                    <div className="align-center">
                        <LogosList list={technologiesLogos}/>
                    </div>
                    <Spacer type="small"/>
                    <h2 className="main-title">We also work with</h2>
                    <div className="align-center">
                        <LogosList list={homeLogosWorkWith}/>
                    </div>
                    <Spacer type="big"/>
                </Wrapper>
                <div className="case-study-grey-2">
                    <ContactForm/>
                </div>
            </PageBody>
        </React.Fragment>
    );
}

export {DedicatedTeam};
