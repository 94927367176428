// Main Caption

const dedicatedMainCaptionTitle = "Dedicated Software Development Team Services";
const dedicatedMainCaptionText = "With almost a decade of experience working as a trusted development partner across various industries, ELITEX can extend your team with senior engineers in no time.";

// Why Choose

const dedicatedWhyChooseCards = [
    {
        id: 1,
        title: "High-tech expertise",
        textList: [
            {
                id: 1,
                text: "Our experienced team brings together top-tier professionals in a multitude of fields. We handpick experts who possess a deep understanding of the latest technologies, ensuring your project benefits from specialized knowledge that drives innovation and efficiency. ELITEX is a team of experts involving specialists with a diverse range of skills and vast technical expertise in front-end development, back-end development, UI/UX design, and quality assurance."
            }
        ],
        icon: "/img/dedicatedTeam/whyChoose/dedicated-high-tech.webp"
    },
    {
        id: 2,
        title: "Cost-effective solution",
        textList: [
            {
                id: 1,
                text: "Building an in-house team entails substantial overheads, from recruitment and onboarding to infrastructure and benefits. Opting for our dedicated development team services allows you to bypass these expenses while still accessing top talent. Collaboration with ELITEX allows you to benefit from a cost-effective solution that maximizes your project’s ROI without compromising quality."
            }
        ],
        icon: "/img/dedicatedTeam/whyChoose/dedicated-cost-efficiency.webp"
    },
    {
        id: 3,
        title: "Complete Transparency",
        textList: [
            {
                id: 1,
                text: "You will receive constant and regular updates on productivity, code quality, resources, and work done for each hour of work. At the same time, all major decisions regarding the product and its business model remain on your side. Smooth and honest collaboration combined with flexibility and zero bureaucracy is what makes ELITEX a perfect dedicated development team company in terms of transparency."
            }
        ],
        icon: "/img/dedicatedTeam/whyChoose/dedicated-complete-transparency.webp"
    },
    {
        id: 4,
        title: "Fast upscaling or downscaling",
        textList: [
            {
                id: 1,
                text: "Your project requirements can evolve, and our teams of dedicated software developers are designed to adapt to you. Whether you’re scaling up your efforts or pivoting in a new direction, our teams offer the flexibility to ramp up or down as needed. We always do what's best for the project; whether you want to change business priorities, focus on another product, or only want to develop an MVP, we can add or remove developers to the team at any time."
            }
        ],
        icon: "/img/dedicatedTeam/whyChoose/dedicated-fast-upscaling.webp"
    },
    {
        id: 5,
        title: "Long-term partnership",
        textList: [
            {
                id: 1,
                text: "ELITEX transcends the conventional service provider-client relationship, offering a transformative long-term partnership. Typical partnership duration term exceeds 2 years due to commitment to continuity, cost savings, and unmatched expertise allowing our software engineers to deliver solutions that evolve as your business does."
            }
        ],
        icon: "/img/dedicatedTeam/whyChoose/dedicated-long-term.svg"
    },
    {
        id: 6,
        title: "Cultural fit",
        textList: [
            {
                id: 1,
                text: "Choosing ELITEX as a dedicated development team services provider goes beyond technical prowess - it’s about finding a cultural fit that drives successful collaboration and success. Our team integrates seamlessly into your organizational fabric, adapting to your communication styles, work methodologies, and ethos. A shared vision complemented by sets of soft skills cultivates an environment where innovation flourishes and ideas flow freely."
            }
        ],
        icon: "/img/dedicatedTeam/whyChoose/dedicated-cultural-fit.svg"
    },
]

// Dedicated Development Process

const dedicatedProcessCards = [
    {
        id: 1,
        title: "Project goals and needs assessment",
        text: "At the beginning of every collaboration, our dedicated software development team will learn more about your business, long-term goals, financial expectations, and product requirements. We will assess your business idea and make all estimates beforehand so you can be sure of our transparency regarding development costs.",
        arrow: true
    },
    {
        id: 2,
        title: "Assigning a team of dedicated developers",
        text: "At this stage, we determine the exact team size and structure based on the project's complexity. We can get you up to speed with our dedicated team with certain skill sets in just 1-2 weeks after the first meeting. In less than a month, you will work with an entire team of software developers, project managers, QA specialists, UI/UX designers, and analysts whose expertise perfectly matches your business goals.",
        arrow: true
    },
    {
        id: 3,
        title: "Starting the project",
        text: "Our certified outsourcing developers will dive into the project, while managers will keep an eye out to foresee risks and find new opportunities. We will immediately start implementing technologies that fit the project and respond to your end-user needs. In most cases development process consists of several consequent steps, such as initial planning, infrastructure setup, design and architecture of the project, development, testing, and deployment.",
        arrow: true
    },
    {
        id: 4,
        title: "Progress updates",
        text: "Dedicated development team services require establishing effective communication channels for seamless collaboration. After establishing communication channels, you will receive detailed updates, visualized graphs, and productivity stats as part of each project stage, receiving valuable insights into the development process and retaining full control over each aspect of the project.",
        arrow: true
    },
    {
        id: 5,
        title: "Ongoing development and scaling of your product",
        text: "At this stage, dedicated teams work on implementing defined features and functionalities. Remote teams write code, conduct automated and manual testing as well as ensure continuous integrations and code review, allowing your in-house employees to focus on core tasks. ELITEX takes care of all administrative tasks, managing the team, hiring process, and onboarding new members to guarantee ongoing support for your product.",
        arrow: false
    },
];

// Hiring
const dedicatedHiringTitle = "Hiring a Dedicated Software Development Team Company";
const dedicatedHiringText = "Looking for a reliable partner to hire a dedicated development team of specialists can be a challenging task. By choosing ELITEX as a digital product development partner, you will get the following:";
const dedicatedHiringContent = [
    {
        id: 1,
        name: {
            firstStrong: true,
            strong: "Custom solutions",
            regular: "at affordable costs"
        },
        img: "/img/dedicatedTeam/whatDoWeOffer/dedicated-offer_1.webp"
    },
    {
        id: 2,
        name: {
            regular: "Top-notch",
            strong: "confidentiality and security"
        },
        img: "/img/dedicatedTeam/whatDoWeOffer/dedicated-offer_2.webp"
    },
    {
        id: 3,
        name: {
            firstStrong: true,
            strong: "Agile approach",
            regular: "and transparency"
        },
        img: "/img/dedicatedTeam/whatDoWeOffer/dedicated-offer_3.webp"
    },
    {
        id: 4,
        name: {
            regular: "Continuous communication and",
            strong: "transparent collaboration"
        },
        img: "/img/dedicatedTeam/whatDoWeOffer/dedicated-offer_4.webp"
    },
];
const dedicatedHiringTextOne = "A dedicated team model is a strategic decision that guarantees undivided attention to your project. The very essence of the ELITEX approach is focusing entirely on your project’s success, ensuring that specialists of our skilled teams are committed exclusively to your business requirements, as well as budget and time frames.";
const dedicatedHiringTextTwo = "ELITEX offers an additional advantage: the flexibility to handpick your team members by yourself, conduct interviews with dedicated developers suggested by us or choose to streamline the process by hiring dedicated programmers from our existing talent pool. Such an approach minimizes the complexities and time investment in the hiring process while ensuring you have the right professionals on board.";
const dedicatedHiringTextThree = "Our commitment to building your ideal dedicated development team is unwavering. We offer a composition of your team tailored precisely to your specific requirements, utilizing both our internal pool of talents and a rigorous recruitment process to ensure the perfect match for your project’s needs. By choosing us, you gain access not only to vast technical expertise but also to a cultural alignment that enables smooth communication and collaboration. Furthermore, our dedication extends far beyond development; we also offer dedicated development team services in the realm of UI/UX design, back-end development, DevOps, and quality assurance.";
const dedicatedHiringTextFour = "Elevate your projects with the assurance of unparalleled quality by partnering with ELITEX. Contact us today to embark on a journey of exceptional developers outsourcing dedicated team that redefines industry standards.";

// FAQ

const dedicatedFaqTitle = {
    main: "Dedicated Software Development Team",
    strong: "FAQs"
};
const dedicatedFaqList = [
    {
        id: 1,
        name: "What are dedicated development team services?",
        text: "Dedicated developers is an outsourcing model allowing you to hire an entire team of external software engineering specialists. Dedicated development team services provide you with a group of skilled professionals focused exclusively on your project."
    },
    {
        id: 2,
        name: "How does a dedicated team model differ from other outsourcing models?",
        text: "The dedicated team model stands apart from other outsourcing models by offering a committed team of experts exclusively assigned to your project. Unlike fixed-price or project-based outsourcing, where specific deliverables are defined, a dedicated team remains fully engaged through the development process, adapting to evolving requirements."
    },
    {
        id: 3,
        name: "How can I ensure the quality of dedicated development team services?",
        text: "Quality is assured through a strict selection of high-quality service providers, transparent communication, and ongoing monitoring of the team’s performance."
    },
    {
        id: 4,
        name: "What types of specialists can I find when I hire a dedicated software development team?",
        text: "You can find skilled specialists with various experience levels for almost every situation with this cooperation model. Apart from software engineers, UI/UX designers, and testers, you can find companies that can augment your team with DevOps engineers, business analysts, and other specialists."
    },
    {
        id: 5,
        name: "How can I hire dedicated development team?",
        text: "Hiring a committed team of professionals involves several stages. First of all, define the specific needs of your project. After that, choose a trusted dedicated software development team services provider, such as ELITEX, and work together on the project scope (also define team structure, time frames, budget limitations, and cooperation model with your in-house developers)."
    },
    {
        id: 6,
        name: "Is there a trial period or probation period for dedicated remote teams?",
        text: "Some providers may offer trial periods allowing you to evaluate the team’s fit and potential performance issues before fully committing."
    },
    {
        id: 7,
        name: "What level of control and transparency do I have over the development process?",
        text: "You retain direct control over the project and most of the development processes. Regular updates and open communication channels are must-have features to ensure full transparency between parties throughout the process."
    },
    {
        id: 8,
        name: "How does budgeting work with dedicated teams?",
        text: "Budgeting is typically structured on a monthly basis based on the hourly rates of team members, offering predictable costs and the flexibility to adjust team size as needed."
    },
    {
        id: 9,
        name: "How much does it cost to hire developers outsourcing dedicated team?",
        text: "Costs vary based on several principal factors. The final price will depend on the complexity of the project, team size, skillset, and location. Usually, this model of cooperation offers additional cost savings and flexibility compared to traditional hiring."
    },
    {
        id: 10,
        name: "What types of projects are dedicated teams good for?",
        text: "Dedicated teams excel in projects focused on enhancing and perfecting digital experiences, such as website development, mobile app creation, creating UI/UX design, etc."
    },
];
export {
    dedicatedMainCaptionTitle,
    dedicatedMainCaptionText,
    dedicatedWhyChooseCards,
    dedicatedProcessCards,
    dedicatedHiringTitle,
    dedicatedHiringText,
    dedicatedHiringContent,
    dedicatedHiringTextOne,
    dedicatedHiringTextTwo,
    dedicatedHiringTextThree,
    dedicatedHiringTextFour,
    dedicatedFaqTitle,
    dedicatedFaqList
}