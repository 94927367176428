export const trustedLinks = [
    {
        id: 1,
        name: 'clutch-icon',
        link: 'https://clutch.co/profile/elitex',
        image: '/img/trusted/resized/trusted_clutch-icon.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 3,
        name: 'guru-icon',
        link: 'https://www.guru.com/freelancers/elitex-javascript-development',
        image: '/img/trusted/resized/guru-icon.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 4,
        name: 'behemoth-software-icon',
        link: 'https://techbehemoths.com/company/elitex',
        image: '/img/trusted/webp/trusted_behemoth-software-icon.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 5,
        name: 'behemoth-web-icon',
        link: 'https://techbehemoths.com/company/elitex',
        image: '/img/trusted/webp/trusted_behemoth-web-icon.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 6,
        name: 'angular-web-icon',
        link: 'https://aciety.com/company/elitex',
        image: '/img/trusted/angular-badge.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 7,
        name: 'user-interface-web-icon',
        link: 'https://aciety.com/company/elitex',
        image: '/img/trusted/user-interface-badge.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 8,
        name: 'js-web-icon',
        link: 'https://www.goodfirms.co/company/elitex',
        image: '/img/trusted/trophy-badge.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 9,
        name: 'web-development-icon',
        link: 'https://www.goodfirms.co/company/elitex',
        image: '/img/trusted/top-website-development-companies.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 10,
        name: 'app-futura-icon',
        link: 'https://www.appfutura.com/companies/elitex',
        image: '/img/trusted/appfutura-badge.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 11,
        name: 'staff-augmentation-badge',
        link: 'https://clutch.co/profile/elitex#highlights',
        image: '/img/trusted/clutch-staff-augmentation-company.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 12,
        name: 'software-developers-badge',
        link: 'https://clutch.co/profile/elitex#highlights',
        image: '/img/trusted/clutch-software-developers.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 13,
        name: 'angular-developers-badge',
        link: 'https://clutch.co/profile/elitex#highlights',
        image: '/img/trusted/clutch-angular-developers.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 14,
        name: 'google-cloud-company-badge',
        link: 'https://clutch.co/profile/elitex#highlights',
        image: '/img/trusted/clutch-google-cloud-company.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 15,
        name: 'clutch-global-badge',
        link: 'https://clutch.co/profile/elitex#highlights',
        image: '/img/trusted/clutch-global.svg',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 16,
        name: 'top-react-badge',
        link: 'https://techbehemoths.com/company/elitex',
        image: '/img/trusted/react-company-award.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 17,
        name: 'top-web-badge',
        link: 'https://techbehemoths.com/company/elitex',
        image: '/img/trusted/web-development-award.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
    {
        id: 18,
        name: 'top-custom-software-badge',
        link: 'https://techbehemoths.com/company/elitex',
        image: '/img/trusted/custom-software-award.webp',
        explicitHeight: '100%',
        explicitWidth: '100%',
    },
];
