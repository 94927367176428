import React, {useEffect} from 'react';
import './PostCms.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, CaseStudyFeedback, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {postCmsSliders} from "../../mocks";
import {CaseStudyCustomSlider} from "../index";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const PostCms = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('postCMS');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Development of Custom Content Management System - Post CMS Case Study'
        description='Discover the power of custom web solutions with our latest case study. See how we engineered the innovative content management system for Il Sole 24 Ore.'
        ogImg='portfolio/postcms/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'PostCMS',
          item3: 'https://elitex.systems/case-studies/postcms'
        })}
      />
      <Hero className="hero-big banner-with-img postcms">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className="white-text h1">{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/postcms/webp/postcms_notebook.webp"
                  srcSet="/img/portfolio/postcms/resized/postcms_notebook_2_small.webp 600w,
                  /img/portfolio/postcms/resized/postcms_notebook_2_medium.webp 1100w,
                 /img/portfolio/postcms/webp/postcms_notebook.webp 1400w"
                  alt="notebook"
                  width="100%"
                  height="100%"
              />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <img
                src="/img/portfolio/postcms/resized/postcms_logo.webp"
                alt="logo"
                width="170"
                height="35"
            />
            <Spacer type='medium'/>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>Media</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>NodeJS, Sails.js, MongoDB, AngularJS, HTML5, CSS3, AWS, Nginx</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Full cycle web development</li>
              <li>Front-end development</li>
              <li>Back-end development</li>
              <li>Quality assurance</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/usa_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />The United States / Italy
            </p>
            <Spacer type='small'/>
            <a
              href='https://www.ilsole24ore.com/'
              className="btn btn-default btn-sm"
              target="_blank"
              rel="noopener noreferrer">View Site</a>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>PostCMS is a next-generation content management system engineered by ELITEX for one of the biggest Italian media corporations – <strong>Il Sole 24 Ore.</strong> While able to work with any kind of content,
              it was designed to be very fast and focused on search engines.</p>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>challenge</strong></h2>
            <p>While there were many content management systems (CMS) on the market, none of them perfectly met our customer's needs. Working in the newsroom and knowing the daily challenges of journalists and editors,
              the author of the project’s idea decided to build something completely different.</p>
            <p>The customer needed to develop a CMS based on six principles:</p>
            <p><strong>1. Speed</strong></p>
            <p>The product has to work seamlessly on modern devices, allowing editors and contributors to produce
              content without delays and distractions.</p>
            <p><strong>2. Simple UX</strong></p>
            <p>Light, easy to navigate, showing options only when they are needed, not cluttering the UI/UX
              unnecessarily.</p>
            <p><strong>3. Think SEO first</strong></p>
            <p>Search engine optimisation is a very important factor for websites. The CMS must present all required
              information to search engines so that pages can rank higher.</p>
            <p><strong>4. An efficient collaboration tool</strong></p>
            <p>They needed a robust and surefire solution for multiple people working on the same content remotely.</p>
            <p><strong>5. Desk functionality</strong></p>
            <p>For example, the ability to set statuses for the content and advanced permissions.</p>
            <p><strong>6. A perfect match for the existing software complex</strong></p>
            <p>The new system should work well and be easily integrated with existing software – Salsify frontend and
              ImageX media library.</p>
          </div>
        </Wrapper>

        <Spacer type='medium'/>
        <div className='case-study-grey-3'>
          <Wrapper>
            <CaseStudyCustomSlider slides={postCmsSliders}/>
          </Wrapper>
        </div>

        {/*sticky zone*/}
        <div className="case-study-relative">
          <div className='case-study-flex-order'>
            <Wrapper>
              <Spacer type='medium'/>
              <div className='case-study-60'>
                <h2 className='h4'>The <strong>solution</strong></h2>
                <p>From the ELITEX side, an R&D team of senior front-end and back-end JavaScript developers worked on this project, providing complete technical consultancy to the client. We also provided QA engineers to make sure the app worked as smoothly as possible.</p>
                <p>We used:</p>
                <ul className='case-study-ul case-study-list-bullet peerwith'>
                  <li>Back end: NodeJS and Sails.js</li>
                  <li>Front end: AngularJS</li>
                  <li>Database: MongoDB</li>
                  <li>Infrastructure: AWS</li>
                </ul>
                <p>As the project required a flexible approach, the team decided to work based on a <strong>scrum
                  methodology</strong>, having two-week-long sprints. New developments were continuously deployed to the cloud, so the result could be seen immediately.</p>
              </div>
              <Spacer type='medium'/>
            </Wrapper>
            <div className='case-study-grey'>
              <Wrapper>
                <div className='case-study-60'>
                  <CaseStudyFeedback
                    cssClass={'feedback-quotes-color'}
                    img='/img/clients/pierpaolo.jpg'
                    name='Pier Paolo Bozzano'
                    position='Head of Software Development | America24'
                    text='From concept to the final product, ELITEX has been a precious resource for our company and the mind behind our content management system. It is about more than just great knowledge in web programming; ELITEX guys take their role further. They offer their ideas and perspective and contribute to making things that were not there before, which is true.'/>
                </div>
              </Wrapper>
            </div>

            <div className='case-study-order-3'>
              <Wrapper>
                <Spacer type='medium'/>
                <div className='case-study-60'>
                  <h2 className='h4'>The <strong>result</strong></h2>
                  <p>After months of diligent work, the prototype was ready. The ELITEX team has engineered a new CMS with
                    a
                    number of advanced features, including:</p>
                  <ul className='case-study-ul case-study-list-bullet peerwith'>
                    <li><strong>Ability to work with any type of content</strong>- from articles to bank accounts. We
                      built an architecture that allows adding new types of content right in the admin UI.
                    </li>
                    <li><strong>Extended content management and collaboration tools</strong> to allow multiple editors
                      to
                      work on the same content effectively.
                    </li>
                    <li><strong>Strong integration with schema.org</strong> to maximise the proper understanding of the
                      content by search engines.
                    </li>
                    <li><strong>Clean and fast UI</strong> to focus on the content, not on the UI itself.</li>
                    <li><strong>User and role management</strong> to control access to the features of the system.</li>
                    <li><strong>Own templating system</strong> to define how the content is displayed.</li>
                  </ul>
                  <p>The CMS architecture allows it to run on the Amazon server and makes the system scalable, fully customized, and flexible to add new features. That’s why, in addition to its direct value, PostCMS also helps save the budget on the infrastructure and its maintenance.</p>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>

        <Spacer type='medium'/>
        <img
            src="/img/portfolio/postcms/resized/postcms_overview-img_large.webp"
            srcSet="/img/portfolio/postcms/resized/postcms_overview-img_small.webp 600w,
            /img/portfolio/postcms/resized/postcms_overview-img_medium.webp 1000w,
            /img/portfolio/postcms/resized/postcms_overview-img_large.webp 1400w"
            loading="lazy"
            alt="background-img"
            width="100%"
            height="100%"
        />
      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>
    </React.Fragment>
  )
};
