export const navigationList = [
  {
    name: 'Home',
    id: 1,
    link: '/'
  },
  {
    name: 'Services',
    id: 8,
    relative: true,
    category: 'services'
  },
  {
    name: 'Technologies',
    id: 9,
    relative: true,
    category: 'technologies'
  },
  {
    name: 'Case studies',
    id: 3,
    link: '/case-studies'
  },
  {
    name: 'Career',
    id: 4,
    link: '/career'
  },
  {
    name: 'Blog',
    id: 5,
    link: '/blog'
  },
  {
    name: 'About Us',
    id: 6,
    link: '/it-outsourcing-company-ukraine'
  },
  {
    name: 'Contact us',
    id: 7,
    link: '/contact'
  }
];
