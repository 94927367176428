import React from 'react';
import './CaseStudyCustomSlider.css';

export class CaseStudyCustomSlider extends React.Component {
  state = {
    activeIndex: 0,
    length: this.props.slides.length
  };

  goToPrevSlide = () => {
    let index = this.state.activeIndex;
    let length = this.state.length;
    if (index < 1) {
      index = length - 1;
    } else {
      index--;
    }
    this.setState({
      activeIndex: index
    });
  };

  goToNextSlide = () => {
    let index = this.state.activeIndex;
    let length = this.state.length;
    if (index === length - 1) {
      index = 0
    } else {
      index++;
    }
    this.setState({
      activeIndex: index
    });
  };

  render() {

    const {slides} = this.props;
    const {activeIndex, length} = this.state;

    return (
      <React.Fragment>
        <div className="CaseStudyCustomSlider-container-before"></div>
        <div className='CaseStudyCustomSlider-container'>
          <img
            src={slides[activeIndex !== 0 ? activeIndex - 1 : length - 1].img}
            alt="img"
            loading="lazy"
            className='CaseStudyCustomSlider-container-left-img'
            onClick={this.goToPrevSlide}/>

          {slides.map((item, index) => {
            return (
              <div key={index} className='CaseStudyCustomSlider-container-main-img'>
                <img
                  src={item.img}
                  srcSet={item.srcSet}
                  alt="img"
                  loading="lazy"
                  className={index === this.state.activeIndex ? 'active' : 'inactive'}/>
              </div>
            )
          })}

          <img
            src={slides[activeIndex !== length - 1 ? activeIndex + 1 : 0].img}
            alt="img"
            loading="lazy"
            className='CaseStudyCustomSlider-container-right-img'
            onClick={this.goToNextSlide}/>
        </div>

        <p className='CaseStudyCustomSlider-text'>
          <i className="fas fa-chevron-left" onClick={this.goToPrevSlide}></i>
          {' '}
          <span>{activeIndex + 1}</span>/{length}
          {' '}
          <i className="fas fa-chevron-right" onClick={this.goToNextSlide}></i>
        </p>
      </React.Fragment>
    );
  }
}
