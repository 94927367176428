export const testimonialItems = [
  {
    id: 1,
    name: 'Christopher Durand',
    position: 'Co-Founder | Bitsy Boost',
    message: 'ELITEX are fantastic! We needed ‘on-demand’ help to work in a fast, Agile fashion. Not only are they timely with their deliverables, but the quality is also elite. The team is always available (even during US hours, which was very important to us) and very forthcoming with communication, consistently providing updates and project tracking. We highly recommend ELITEX to any product or tech lead, and look forward to continuing our working relationship with them.',
    avatar: '/img/clients/resized/clients_bitsy.webp'
  },
  {
    id: 2,
    name: 'Henry Shao',
    position: 'CEO | Zippia',
    message: 'Elitex and Volodymyr have been professional and easy to communicate with.  They have been helping us to recruit and retain remote dedicated employees for our tech start-up company in the Bay Area.  The people we have hired generally enjoyed working at Elitex and so if you are looking to hire people in Ukraine, I would recommend them.',
    avatar: '/img/clients/resized/clients_henry_shao.webp'
  },
  {
    id: 3,
    name: 'Ivo Verbeek',
    position: 'Director | Peerwith',
    message: 'As an outsourced team, ELITEX serves as a cost-effective development option. They have proven to be a reliable vendor that is capable of finding, training, and implementing the appropriate resources to meet project requirements. Their experience with an array of technology is a definite strength.',
    avatar: '/img/clients/webp/clients_ivoverbeek.webp'
  },
  {
    id: 4,
    name: 'Pier Paolo Bozzano',
    position: 'Head of Software Development | America24',
    message: 'From concept to final product, ELITEX has been a precious resource for our company and the mind behind our content management system. It is not all about the great knowledge in web programming, ELITEX guys take their role further. They offer their ideas and perspective and contribute in making things that were not there before, which is the true.',
    avatar: '/img/clients/resized/clients_pierpaolo.webp'
  },
  {
    id: 5,
    name: 'Rob Woodall',
    position: 'VP Technology at Kallik',
    message: 'The collaboration with ELITEX was successful. Their team developed several unique software solutions, including a rule engine editor. They were responsive to inquiries from the client and consistently met deadlines. Customers can expect strong project management skills and creativity.',
    avatar: '/img/clients/resized/clients_rob.webp'
  },
  {
    id: 6,
    name: '',
    position: 'Director | Felix Security',
    message: 'The ELITEX team provided enhancements and updates for the platform, demonstrating their excellent technical skill and expertise. They communicated well, maintaining a smooth workflow and offering fantastic value for money.',
    avatar: ''
  }
];
