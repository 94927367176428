import React from 'react';
import { styles } from './GoogleMap.styles';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import './GoogleMap.css';

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
    }
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      showingInfoWindow: true,
      activeMarker: marker
    });
  };

  onMapClick = (props) => {
    if ( this.state.showingInfoWindow ) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    return (
      <div className="google-map-container">
        <Map
          item
          styles={styles}
          google={this.props.google}
          onClick={this.onMapClick}
          zoom={5}
          scrollwheel={false}
          scaleControl={false}
          disableDefaultUI={false}
          initialCenter={{ lat: 53, lng: 22 }}
        >
          <Marker
            icon={{
                url: '/img/logo/newLogos/logo-icon.svg',
              scaledSize: new window.google.maps.Size(23, 23)
            }}
            position={{lat: 49.844730, lng: 24.025164}}
            cursor="default"
          />
          <Marker
            icon={{
                url: '/img/logo/newLogos/logo-icon.svg',
              scaledSize: new window.google.maps.Size(23, 23)
            }}
            position={{lat: 51.522449, lng: -0.132630}}
            cursor="default"
          />
          <Marker
            icon={{
                url: '/img/logo/newLogos/logo-icon.svg',
              scaledSize: new window.google.maps.Size(23, 23)
            }}
            position={{lat: 52.345600, lng: 4.951350}}
            cursor="default"
          />
          <Marker
            icon={{
                url: '/img/logo/newLogos/logo-icon.svg',
              scaledSize: new window.google.maps.Size(23, 23)
            }}
            position={{lat: 55.916570, lng: -3.158370}}
            cursor="default"
          />
          <Marker
            icon={{
                url: '/img/logo/newLogos/logo-icon.svg',
              scaledSize: new window.google.maps.Size(23, 23)
            }}
            position={{ lat: 51.046640, lng: -114.067850 }}
            cursor="default"
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBf-EklJtCj1bTwHujw9HImSb4iDvy1HLs')
})(GoogleMap)
