  // MainCaption
  const uiMainCaptionTitle ="UI/UX Design and Development Services";
  const uiMainCaptionText = "UI and UX shape how people perceive your digital products. The user interface (UI) covers what users see on screen, while the concept of user experience (UX) focuses on the overall feel of using a product as intended. Both UI and UX designs are essential parts of web and software development, and no website or application can be built without functional design implemented at a certain development process stage. Good design helps users find what they need and enjoy the process, and companies that invest in UI/UX development services often see better results from their digital efforts.";

  // UI/UX We Offer

  const uiWhatOfferTitle = "UI/UX Development Services We Offer";
  const uiWhatOfferText = "As a trusted UI/UX design and development company with broad market experience, we offer a range of UI/UX design services that can eventually help businesses create functional and visually appealing products.";
  const uiWhatOfferCards =
       [
          {
              id:1,
              title: "UI/UX design and development from scratch",
              text: "Designing and developing from scratch involves building a digital product from the ground up, starting with a blank canvas. The process consists of building design systems combined with developing the front-end code to bring the design to life.",
              image: "/img/uid/whatDoWeOffer/ui-offer_1.webp"
          },
          {
              id:2,
              title: "Mobile app design",
              text: "Our list of mobile app development services includes mobile UX/UI designing, as well as ensuring responsive designs (adapting your website and web applications to specific mobile screen sizes).",
              image: "/img/uid/whatDoWeOffer/ui-offer_2.webp"
          },
          {
              id:3,
              title: "UI/UX design & development services for web",
              text: "ELITEX are a team of professional developers and designers that offer a full cycle of web development services, from building initial wireframes and prototypes to translating these designs into functional web experiences.",
              image: "/img/uid/whatDoWeOffer/ui-offer_3.webp"
          },
          {
              id:4,
              title: "Information architecture",
              text: "Our information architecture services are designed to facilitate organizing and structuring the product’s content and information. We endeavor to ensure that the product is easy to navigate and the content is easy to comprehend.",
              image: "/img/uid/whatDoWeOffer/ui-offer_4.webp"
          },
          {
              id:5,
              title: "UX and UI modernization",
              text: "Our development team can help update the design of existing digital products to keep them up with both the latest design trends and constantly evolving user expectations. Both UI and UX modernization can involve revamping the visual design, improving the product's navigation and usability, and integrating new technologies and features to enhance customer engagement.",
              image: "/img/uid/whatDoWeOffer/ui-offer_5.webp"
          },
          {
              id:6,
              title: "Cross-platform design solutions",
              text: "Our cross-platform design solutions are tailored to create user interfaces that work seamlessly across multiple platforms, devices, screen sizes, and operation systems. We offer crafting software solutions that work perfectly and scale seamlessly regardless of the platform.",
              image: "/img/uid/whatDoWeOffer/ui-offer_6.webp"
          },
      ];

  // UI/UX Dev Process
  const uiDevProcessTitle = "Our UI/UX Design and Development Process";

  const uiDevProcessText = "Although our approach to UI and UX design and development process may vary, the efficient design process typically involves several consistent stages.";

  const uiDevProcessContent = [
      {
          id: 1,
          header:"Research",
          text: "At the beginning, we conduct user research, gathering information about the product and analyzing the target audience. At the same time, the user information required from user research is combined with specific business requirements, allowing us to better understand product strategy.",
          arrow: true,
      },
      {
          id: 2,
          header:"Planning",
          text: "Then, we clearly define project requirements and choose suitable design strategies for the product. Based on this, we create user personas and develop a user flow.",
          arrow: true,
      },
      {
          id: 3,
          header:"Wireframing and prototyping",
          text: "We sketch designs and build working models. Our process at this stage involves creating basic layouts, developing detailed blueprints, and building testable prototypes. We start with simple sketches and move to fully functional models.",
          arrow: true,
      },
      {
          id: 4,
          header:"Testing",
          text: "Then, we conduct user testing to evaluate the effectiveness of the design solutions and make necessary adjustments.",
          arrow: true,
      },
      {
          id: 5,
          header:"Visual Design",
          text: "Then, we add the elements of visual designs, such as branding, color schemes, typography, and imagery.",
          arrow: true,
      },
      {
          id: 6,
          header:"Development",
          text: "When the designing part is ready, we transform the existing user interface and user experience into code and develop the user-ready digital product.",
          arrow: true,
      },
      {
          id: 7,
          header:"Launch, ongoing maintenance, and support",
          text: "When the development phase is done, we help you launch the product, gathering user feedback to refine the existing design solutions. Then, we continue to monitor and maintain the product, ensuring it meets user needs as well as your business goals.",
      },
  ];

  // UI/UX Why Choose Elitex

  const uiWhyChooseTitle = "Why Choose Elitex as a UI/UX Design And Development Company?";

  const uiWhyChooseSubtitle = "Choosing the right UI/UX design and development services provider can make all the difference in ensuring the creation of really catchy digital experiences. At ELITEX, we pride ourselves on our expertise in creating exceptional user journeys and designs that exceed our client’s expectations. However, here are a few particular reasons why we believe ELITEX is an ideal UI/UX development company for bringing your idea to life:";

  const uiWhyChooseText = "In case you’re looking for an ideal UI and UX development company to change your project, ELITEX have the skills, expertise, and commitment to excellence you need. Together, we will create exceptional designs.";

  const uiWhyChooseList = [
      {
          id: 1,
          text: "Our team comprises highly skilled design experts, developers, and project managers passionate about delivering innovative and user-centered designs. Over 90% of our team are hand-picked Senior and Middle-class designers and software engineers."
      },
      {
          id: 2,
          text: "We use advanced technologies and innovative solutions to create impressive, aesthetically pleasing, and highly functional designs."
      },
      {
          id: 3,
          text: "We adhere to the principles of zero bureaucracy and flexibility in order to ensure a smooth communication experience. Honesty, integrity, and transparency are key elements of our approach."
      },
      {
          id: 4,
          text: "We are delivering projects on time and within budget. We understand the importance of meeting deadlines and staying within budget constraints, and we use agile methodologies to ensure the delivery of high-quality designs efficiently and effectively."
      },
  ]

  // UI/UX FAQ

  const uiFaqTitle = {
      main: "Custom UI/UX Design and Development Services",
      strong: "FAQs"
  }
  const uiFaqContent = [
      {
          id: 1,
          name: "What is UI/UX design?",
          text: "UI and UX design refers to the process of creating digital interfaces that are user-friendly and aesthetically pleasing. Seamless user experience (UX) focuses on a user's overall experience when interacting with the product. In contrast, user interface (UI) design deals with the visual and interactive design elements of the product.",
      },
      {
          id: 2,
          name: "Why is UI and UX design important?",
          text: "In a word, you need UI and UX designs to create attractive digital products and provide a positive customer experience. Design is the first thing users see when using your product, and on the Internet, the book is often judged by its cover; that’s why decent quality UX and UI design are so important.",
      },
      {
          id: 3,
          name: "How long does a UI/UX app development company take to build a solid design?",
          text: "The time it takes for the company to build a robust design may vary slightly depending on the scope of the project and its complexity. Also, the type of collaboration (for instance, in-house development, outsourcing UI/UX development services, etc.) may impact the project’s time required. It usually takes several weeks to a few months to complete the design process, including all work components.",
      },
      {
          id: 4,
          name: "What is the best UI/UX design and development services near me?",
          text: "The choice of the best design services depends on your specific project goals and location. However, most of the design agencies now work remotely, offering high-quality services and cutting-edge technologies regardless of your location. Outsourcing has a wide range of benefits, providing access to a wider talent pool and cost-efficiency. In some cases, the best solution is to outsource UI/UX development services.",
      },
      {
          id: 5,
          name: "Do ELITEX work with startups?",
          text: "Sure, at ELITEX, we work with companies of all sizes. For startups, we offer flexible plans to fit different budgets and growth stages.",
      },
      {
          id: 6,
          name: "Can ELITEX improve an existing product?",
          text: "Yes, we can. We review your current products, conduct a short design audit, and suggest an improvement plan. Even the most basic design improvement often leads to better user satisfaction and, ultimately, higher conversion rates.",
      },
      {
          id: 7,
          name: "How can I measure the success of the UI and UX design project?",
          text: "At ELITEX, we track key metrics like user engagement, task completion rates, and customer feedback. We also match key design metrics with your business objectives, like increased sales or user growth, to make these metrics even more useful for your business growth.",
      },
  ];

  export {
      uiMainCaptionTitle,
      uiMainCaptionText,
      uiWhatOfferTitle,
      uiWhatOfferCards,
      uiWhatOfferText,
      uiDevProcessTitle,
      uiDevProcessText,
      uiDevProcessContent,
      uiWhyChooseTitle,
      uiWhyChooseSubtitle,
      uiWhyChooseText,
      uiWhyChooseList,
      uiFaqTitle,
      uiFaqContent
  }