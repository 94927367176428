import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import './TextExpandable.css';

export const TextExpandable = ({ mainText, hiddenText }) => {

  const [isExpanded, toggleHiddenTxt] = useState(false);
 
  return (
    <div className='text-expandable' style={ compStyle.container }>
      { mainText }
      { !isExpanded &&
        <button
          onClick={() => toggleHiddenTxt(!isExpanded)}
          style={ compStyle.actionBtn }
        >Learn more</button>
      }
        <Collapse isOpened={isExpanded}>{ hiddenText }</Collapse>
    </div>
  )
};


const compStyle = {
  container: {
    fontSize: '20px',
    margin: '20px 0'
  },
  actionBtn: {
    display: 'block',
    margin: '15px auto 0'
  }
};

