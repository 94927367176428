import React from 'react';

export const TrustedLink = ({name, link, image, scale, explicitWidth, explicitHeight}) => (
  <a href={link} target='_blank' rel="noopener noreferrer" >
      <img
          src={image}
          alt={name}
          className={scale ? `scale-${scale}` : ""}
          width={explicitWidth}
          height={explicitHeight}/>
  </a>
);
