import React from 'react';
import {Spacer, Wrapper} from '../';
import './VideoBg.css';

const VideoBg = () => {
  return (
    <React.Fragment>
      <div className='video-bg-wrapper'>
        <Wrapper type='big' className="fullwidth-content align-center">
          <Spacer type='big'/>
          <h4>Our locations</h4>
          <Spacer type='small'/>
          <h5>The head office of ELITEX is situated in the heart of Europe – the city of Lviv in Ukraine. We also have
            representatives in the US and across Europe. Lviv is an old and beautiful city with its own traditions and
            atmosphere. At the same time, it is one of the biggest IT centers in Eastern Europe, hosting 200+ IT
            companies and 21,000+ IT professionals. It is easily accessible via direct international flights from
            London, Berlin, Vienna, Warsaw, Copenhagen, Tel Aviv, and other cities.</h5>
          <Spacer type='big'/>
        </Wrapper>
      </div>
    </React.Fragment>
  );
};

export {VideoBg};
