export const workshopsMockData = {
    companyLogos: [
        {
            id: 1,
            logo: '/img/qaWorkshops/workshop-exon-mobil.svg'
        },
        {
            id: 2,
            logo: '/img/qaWorkshops/workshop-mary-kay.svg'
        },
        {
            id: 3,
            logo: '/img/qaWorkshops/workshop-cardinal.svg'
        },
        {
            id: 4,
            logo: '/img/qaWorkshops/workshop-smartrr.svg'
        },
        {
            id: 5,
            logo: '/img/qaWorkshops/workshop-diversey.svg'
        },
        {
            id: 6,
            logo: '/img/qaWorkshops/workshop-exterity.svg'
        },
    ],
    engineers: [
        {
            id: 1,
            title: "Hosted By:",
            name: "Pavlo Sukiasyan",
            summary: "10+ yrs of commercial experience testing software for E-commerce, E-Government, Automotive, Banking & Fintech.",
            photo: "/img/qaWorkshops/workshop-pavlo.png"
        },
        {
            id: 2,
            name: "Inna Perlovska",
            summary: "7+ yrs of commercial experience testing software for Telecoms, Talent Marketplaces, Nonprofits, Sports, and Leisure & Content Management industries.",
            photo: "/img/qaWorkshops/workshop-inna.png"
        },
    ],
    consulting: [
        {
            id: 1,
            text: "Incorporate **The Best QA** practices into your development process",
            icon: "/img/qaWorkshops/workshop-consulting-1.svg"
        },
        {
            id: 2,
            text: "**Assess & Improve** your existing QA processes",
            icon: "/img/qaWorkshops/workshop-consulting-2.svg"
        },
        {
            id: 3,
            text: "Build **A Custom** quality assurance strategy",
            icon: "/img/qaWorkshops/workshop-consulting-3.svg"
        },
    ],
    process: [
        {
            id: 1,
            text: "Signing an NDA"
        },
        {
            id: 2,
            text: "Workshop with our QA experts to dive into your product, processes, and needs"
        },
        {
            id: 3,
            text: "We do our homework and get back with personalized recommendations for your specific case"
        },
    ],
    services: [
        {
            id: 1,
            text: "Test planning & case development;"
        },
        {
            id: 2,
            text: "Manual testing & automation testing"
        },
        {
            id: 3,
            text: "Automation infrastructure solutions;"
        },
        {
            id: 4,
            text: "Quality metrics, reporting & documentation management;"
        },
        {
            id: 5,
            text: "Test environment management & release support;"
        },
    ]
};
