// MainCaption
const angularMainCaptionTitle = "Angular Development Services Company";
const angularMainCaptionText = "Developers from ELITEX use Angular to empower businesses of all sizes to achieve their goals with a robust software solution. The angular framework creates an interactive, user-friendly, lightweight, and highly-functional app. With solid expertise in Angular, our developers are the ideal choice to design the perfect project for your company based on your project requirements, timeframe, and budget.";

// What Do We Have to Offer
const angularWhatOfferTitle = {
    main: "Angular Development Services:",
    strong: "What Do We Have to Offer"
};
const angularWhatOfferText = "An experienced Angular development company, ELITEX, offers a wide range of Angular solutions to build dynamic, scalable, high-performance web applications. With the help of Angular, businesses can leverage the latest web development trends to create responsive and feature-rich web applications that meet their unique needs. Angular development services cover the complete development cycle and can help businesses optimize their existing applications and enhance user experience and performance."

const angularWhatOfferCards = [
    {
        id: 1,
        title: "Custom Angular development",
        textList: [
            {
                id: 1,
                text: "Our AngularJS developers can build custom web solutions using up-to-date technologies, developer tools, and techniques. Developers work closely with businesses to understand their needs and create custom solutions tailored to their unique requirements. This involves developing user-friendly interfaces, implementing advanced features, and integrating with third-party APIs and services."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_1.svg"
    },
    {
        id: 2,
        title: "App migration",
        textList: [
            {
                id: 1,
                text: "App migration moves an existing web application from an older framework to Angular. App migration involves rewriting the app codebase, re-implementing old features using Angular technology, and adjusting the architecture to meet the requirements of the new Angular framework. At ELITEX, the team of experienced developers ensures a smooth migration process and helps take advantage of Angular's benefits."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_3.svg"
    },
    {
        id: 3,
        title: "Angular consulting",
        textList: [
            {
                id: 1,
                text: "We provide consulting services to help businesses evaluate their needs, plan Angular projects, and make informed decisions about Angular development. Angular consulting involves a wide range of services to ensure adherence to best practices and identify areas for improvement. Code reviews, providing training and coaching, helping with the proper choice of third-party libraries and tools to integrate with the Angular application, evaluation of existing Angular applications, and other spheres that may require the assistance of qualified web development team members."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_4.svg"
    },
    {
        id: 4,
        title: "Maintenance and support",
        textList: [
            {
                id: 1,
                text: "Once the application is released, you’ll need ongoing maintenance and support to keep it running smoothly. An angular development team can provide bug fixes, performance optimization, continuous testing, and other maintenance services."
            },
            {
                id: 2,
                text: "Working on corporate projects requires dynamic and adaptable software development. We provide end-to-end Angular applications tailored to your needs and expectations. In addition to the entire development cycle, ELITEX provides Angular web design, UI development, QA, and software testing services. We also offer dedicated developers services if you require to supplement your current house team, regardless of your project’s complexity. "
            },
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_2.svg"
    },
];

// Angular Development Process
const angularProcessTitle = "What Does Custom Angular Development Cycle Look Like";
const angularProcessTextOne = "The Angular development methodology is a comprehensive process that involves several stages to ensure a successful application. It is a detailed and organized approach covering every application development aspect, from planning and design to deployment and maintenance.";
const angularProcessTextTwo = "Typically, the development process consists of the following:";
const angularProcessContent = [
    {
        id: 1,
        header: "Planning and analysis",
        text: "Gathering requirements, defining scope, and planning the project.",
        arrow: true
    },
    {
        id: 2,
        header: "Design",
        text: "Creating wireframes and mockups, defining UX/UI, and developing a design system.",
        arrow: true
    },
    {
        id: 3,
        header: "Development",
        text: "Writing code, building components, integrating APIs, and implementing certain features.",
        arrow: false
    },
    {
        id: 4,
        header: "Testing",
        text: "Conduct unit testing, debugging, integration testing, and end-to-end testing to ensure code quality and proper functionality.",
        arrow: true
    },
    {
        id: 5,
        header: "Deployment",
        text: "Launching the application on servers, configuring environments, and monitoring performance.",
        arrow: true
    },
    {
        id: 6,
        header: "Ongoing maintenance and updates",
        text: "Providing support, fixing bugs, and adding new features based on feedback.",
        arrow: false
    },
]

// Why Choose ELITEX
const angularWhyChooseTitle = {
    main:"as Your Angular Web Development Company",
    strong: "Why Choose ELITEX"
}
const angularWhyChooseText = {
    textOne: "At ELITEX, we have extensive experience in building high-performance and scalable applications that deliver exceptional results. Our team prioritizes quality, collaboration, and innovation in every project we undertake. We believe that effective communication and teamwork throughout the development process are crucial to success, and we make sure to incorporate feedback at every stage.",
    textTwo: "As an experienced Angular app development company, we have worked with every version of the framework, from the first Angular.js. The vast knowledge and expertise across various industries complement our extensive experience in Angular web app development. The perfect knowledge of English, transparency, honesty, and flexibility in development approaches are integral parts of the approach ELITEX can offer.",
    textThree: "At ELITEX, our team of qualified IT specialists sets us apart from mediocre Angular web development services providers. As your development partner, we are dedicated to providing outstanding proficiency and quality commitment to help your business thrive. Choose ELITEX, and experience the difference our expertise can make for your business."
}

// Reasons to Choose

const angularReasonsTitle = "Reasons to Choose Angular App Development Services for You";
const angularReasonsText = "Angular is an excellent choice for businesses looking for a new modern web application. It has all the tools you will need to build large-scale applications, and its architecture allows you to reuse code seamlessly. Angular ensures the end product’s excellent quality, assists in developing stable and high-performing apps, and makes the solutions lighter.";
const angularReasonsContent = [
    {
        id:1,
        title: "Component-based architecture",
        text: "The modular structure of Angular allows you to separate functionality into reusable pieces of code. This ensures the consistency of large software applications.",
        img: "/img/home/industries/resized/fintech.webp",
        tagName: "h3"
    },
    {
        id:2,
        title: "Cross-platform solution",
        text: "One of the main benefits of Angular development service is the framework's flexibility. Angular development capability covers web, desktop, and native mobile development. It enables engineers to create Angular development solutions that provide a wonderful experience for smartphone users.",
        img: "/img/home/industries/resized/shield.webp",
        tagName: "h3"
    },
    {
        id:3,
        title: "High app performance",
        text: "Angular is ideal for building feature-rich web applications and websites that can handle high traffic without slowing down. The apps run fast and ensure outstanding performance thanks to Angular's clean code.",
        img: "/img/home/industries/resized/journalist.webp",
        tagName: "h3"
    },
    {
        id:4,
        title: "Server-side rendering apps",
        text: "Although Angular applications are client-side, you can also have server-side rendering to your app. Our Angular mobile app development company efficiently improves the performance of your application using Angular Universal.",
        img: "/img/home/industries/resized/report.webp",
        tagName: "h3"
    },
    {
        id:5,
        title: "Modular dev structure",
        text: "Angular’s modular structure allows us to split development tasks between engineers while maintaining clean and well-organized code. It also enables the app to be scaled smoothly. Modular construction reduces app development time and lowers costs.",
        img: "/img/home/industries/resized/school.webp",
        tagName: "h3"
    },
    {
        id:6,
        title: "Faster coding process",
        text: "The Angular command-line interface speeds up application development by providing a set of helpful coding tools. Angular CLI can be enhanced using third-party libraries to handle complex software challenges.",
        img: "/img/home/industries/resized/dashboard.webp",
        tagName: "h3"
    },
];

// FAQ

const angularFaqTitle = {
    main: "Custom Angular Development Services",
    strong: "FAQs"
};
const angularFaqList = [
    {
        id: 1,
        name: "What is Angular, and what can be developed using Angular?",
        text: "Angular is a Google-developed open-source framework for building dynamic modern web apps. Angular development solutions can be used to create a wide range of applications such as web, mobile, desktop, progressive web, single page, and many more applications. Angular provides data binding, dependency injection, routing, and forms handling, which makes building apps more accessible."
    },
    {
        id: 2,
        name: "Why is Angular so popular?",
        text: "Building robust online and mobile applications is significantly easier with Angular. Angular includes excellent code reusability, data binding, templating, deep linking, and other fast development capabilities."
    },
    {
        id: 3,
        name: "How much time does it take to build a successful Angular project?",
        text: "The amount of time it takes to build a successful Angular project depends on various factors, including the complexity of the project, the angular development application services provider, the availability of resources, and the level of expertise of the developers."
    },
    {
        id: 4,
        name: "What are the main advantages of Angular development?",
        text: "The main advantages of Angular development include strong community support and resources, modular and scalable architecture, two-way data binding, dependency injection for efficient development, and built-in testing support."
    },
    {
        id: 5,
        name: "When is Angular the best choice for web development?",
        text: "Angular is perfect for developing large, complex, and data-intensive applications requiring modular and scalable architecture. Angular’s strict structure and conventions are a decent choice for projects that involve the collaboration of big developers' teams."
    },
];

export {
    angularMainCaptionTitle,
    angularMainCaptionText,
    angularWhatOfferTitle,
    angularWhatOfferText,
    angularWhatOfferCards,
    angularProcessTitle,
    angularProcessTextOne,
    angularProcessTextTwo,
    angularProcessContent,
    angularWhyChooseTitle,
    angularWhyChooseText,
    angularReasonsTitle,
    angularReasonsText,
    angularReasonsContent,
    angularFaqTitle,
    angularFaqList
}