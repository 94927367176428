import React, {useEffect} from 'react';
import {
  Hero,
  PageBody,
  Wrapper,
  Spacer,
  ColumnItemsList,
  CompanyValuesItem,
  VideoBg,
  WhyList,
  InstagramFeed
} from '../../components';
import {companyValues, aboutIsotopeItems, whyList} from '../../mocks';
import {ContactForm, SliderMaker} from '../';
import './About.css';
import {showLoader, hideLoader, delay} from '../../utils/loaderHandler.util';
import HelmetSetter from "../../components/Helmet/Helmet";
import {loadInstagram} from '../../utils/instagramLazyLoader.util';
import {testimonialItems} from '../../mocks';
import {Feedback} from '../../components';
import LazyLoad from "react-lazyload";

function About() {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    loadInstagram();
    return showLoader();
  }, []);

  return (
    <React.Fragment>
      <HelmetSetter
        title='IT and Software Development Outsourcing Company in Ukraine | ELITEX'
        description='ELITEX is a trusted software development outsourcing company headquartered in Ukraine with offices in UK & the EU. Hire our experts & dedicated teams to grow your business.'
        ogImg='about/team.jpg'/>
      <Hero className="hero-big banner-with-img about">
        <Wrapper id="page-title">
          <h1 className="h2 white-text">IT Company ELITEX</h1>
          <p className='white-text h4'>We are your partners and friends</p>
        </Wrapper>
      </Hero>
      <PageBody>
        <Wrapper>
          <Spacer type='big'/>
          <div className='about-column-container'>
            <h5 className="title-alt"><strong>A few words about us</strong></h5>
            <div className='about-column-content'>
              <h4>ELITEX is a boutique IT company based in Lviv, Ukraine. Our main expertise is custom software
                development using JavaScript and building dedicated development teams for businesses and
                startups.</h4>
              <Spacer type='small'/>
              <h4>Are you searching for a reliable partner with clear pricing and an excellent service? You’ve found
                it. We are ELITEX.</h4>
            </div>
          </div>
          <Spacer type='big'/>
        </Wrapper>
        <div className="fullwidth-section text-light align-center black-bg">
          <div className="fullwidth-content wrapper-big">
            <h4>ELITEX is different.</h4>
            <Spacer type='small'/>
            <h5>
              We are more than just an IT Outsourcing Partner. While thinking about the business, we still believe in a
              better world. We aim at building a real partnership with our clients, based on truth and honesty. ELITEX
              will not try to sell you unnecessary services. Instead, we will focus on making our collaboration last
              long and always be win-win. And our happy customers prove that. Become one of them.
            </h5>
          </div>
        </div>
        <Spacer type='big'/>
        <Wrapper>
          <ColumnItemsList list={companyValues} classBlock='elx-values' Component={CompanyValuesItem}/>
        </Wrapper>
        <Spacer type='small'/>
        <LazyLoad className='about-container'>
            {aboutIsotopeItems.map(item => (
                <img
                    src={item.image}
                    srcSet={item.srcSet && item.srcSet}
                    alt="about"
                    loading="lazy"
                    key={item.id}
                    className={`about-img ${item.doubleWidth ? 'about-img-100' : ''}`}
                    width="100%"
                    height="100%"
                />
            ))}
          </LazyLoad>
          <div id="testimonial-slider">
            <SliderMaker sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
          </div>
          <Hero className="text-light videobg-section elx-locations">
            <LazyLoad>
              <VideoBg/>
            </LazyLoad>
          </Hero>
        <Spacer type='big'/>
        <Wrapper>
          <WhyList list={whyList}/>
          <Spacer type='big'/>
          <InstagramFeed/>
          <Spacer type='big'/>
        </Wrapper>
        <ContactForm/>
      </PageBody>
    </React.Fragment>
  );
}

export {About};
