// Main Caption

const qaMainCaptionTitle = "Software Quality Assurance Services";
const qaMainCaptionText = "Explore how our QA services can elevate your software to new heights of excellence. Whether you’re a startup striving for a flawless launch or an established enterprise seeking to maintain high standards, our quality assurance and testing services provide the precision and expertise you need.";

// Who We Are

const qaWhoWeAreTitle = "Who We Are";
const qaWhoWeAreTextOne = "Being a software development and software quality assurance company, ELITEX is a team of passionate industry experts dedicated to ensuring the highest standards of performance, reliability, and user satisfaction for your digital products and services. With a lengthy track record of excellence and commitment to innovation, we are a trusted partner for businesses across various industries and technical domains.";
const qaWhoWeAreTextTwo = "With almost a decade of experience, we have honed our skills and refined our processes to become a cohesive team of highly dedicated and experienced software quality assurance professionals. Our journey in the field has equipped us with invaluable insights and expertise that allow us to ensure the top quality of your software products consistently."

// Our QA

const qaOurText = "Software QA services are integral components of a comprehensive quality management approach. QA involves systematically evaluating the product in order to identify and rectify defects, ensuring product quality, reliability, and functionality. These services play a pivotal role in the software development lifecycle, involving several testing processes and methodologies used to deliver superior user experiences.";

// We Offer

const qaWeOfferTitleOne = "We offer different approaches:";
const qaWeOfferContentOne = [
    {
        id: 1,
        name: "Manual testing",
        text: "Our expert testers conduct thorough, hands-on testing, meticulously executing test cases and exploring your software’s functionality, usability, performance, and other quality metrics. Manual testing is ideal for uncovering user experience issues, identifying subtle defects, and conducting exploratory tests to discover unexpected problems and look at the software from the end-user perspective.",
        image: "/img/qa/whatOffer/qa-manual-testing.svg"
    },
    {
        id: 2,
        name: "Automation testing",
        text: "We harness the power of specialized software tools and scripts to automate the execution of test cases and repetitive quality control-related tasks. This approach ensures consistency, repeatability, and quick feedback, making it indispensable for agile and CI/CD environments. It is the best solution for scenarios demanding rapid execution of numerous test cases across different configurations. Our team will create independent and reusable tests tailored to the specific needs of your software.",
        image: "/img/qa/whatOffer/qa-automation-testing.svg"
    }
];

const qaWeOfferTitleTwo = "We offer QA software testing services that cover every conceivable scenario, ensuring resilience in all circumstances:";
const qaWeOfferContentTwo = [
    {
        id: 1,
        name: "Web testing",
        text: "Our testing team carefully evaluates the functionality, performance, and user experience of your web applications. We leave no stone unturned in ensuring seamless performance across different browsers and devices, creating a user-friendly online presence that delights visitors.",
        image: "/img/qa/whatOffer/qa-web-testing.svg"
    },
    {
        id: 2,
        name: "Desktop and mobile app testing",
        text: "Our services cover desktop and mobile applications, ensuring top-notch quality regardless of the application type. We offer a highly qualified team of skilled QA specialists to examine every facet of the application, whether it comes to complex desktop applications or mobile apps. We have vast experience providing QA services for applications, platforms, OS, and devices across multiple industries, such as e-commerce, healthcare, education, etc.",
        image: "/img/qa/whatOffer/qa-desktop-and-mobile.svg"
    },
    {
        id: 3,
        name: "API testing",
        text: "API tests delve into the functionality of your application programming interfaces to ensure they operate smoothly and consistently. We thoroughly examine API endpoints, data exchange, and integration capabilities to maintain the reliability of data flow and communication between different software components.",
        image: "/img/qa/whatOffer/qa-api.svg"
    }
];

const qaWeOfferTitleThree = "We also offer various types of testing for every aspect of your product, regardless of the chosen testing strategy and software type:";
const qaWeOfferContentThree = [
    {
        id: 1,
        name: "Security testing",
        text: "We offer comprehensive security tests to identify and mitigate vulnerabilities, ensuring your software remains robust against cyber threats. A decent security level is essential for safeguarding sensitive data and maintaining user trust, particularly in domains where software solutions handle confidential information.",
        image: "/img/qa/whatOffer/qa-security-testing.svg"
    },
    {
        id: 2,
        name: "Functional testing",
        text: "Our QA specialists perform functional tests to validate that your software performs its intended functions flawlessly. It is an essential part of the QA testing services that help us guarantee that your application meets user requirements and provides a holistic experience, making ensuring functionality a cornerstone for software reliability.",
        image: "/img/qa/whatOffer/qa-functional.svg"
    },
    {
        id: 3,
        name: "Compatibility testing",
        text: "Our compatibility testing ensures your software functions consistently across various devices, browsers, and operating systems. This part of the process is essential for today’s multi-platform environment, providing a positive user experience across diverse user preferences and habits.",
        image: "/img/qa/whatOffer/qa-compatibility.svg"
    },
    {
        id: 4,
        name: "Regression testing",
        text: "We provide comprehensive regression testing services to validate that new updates or changes do not introduce defects or negatively impact existing functionalities. This service is vital for maintaining software reliability throughout its lifecycle, ensuring that enhancements or modifications do not compromise established functionality.",
        image: "/img/qa/whatOffer/qa-regression.svg"
    },
    {
        id: 5,
        name: "Compliance testing",
        text: "We provide compliance tests to ensure your software adheres to industry-specific regulatory standards. If your company or organization is subject to regulatory requirements, legal compliance can not only reduce the risk of fines or penalties but also protect your reputation.",
        image: "/img/qa/whatOffer/qa-compliance.svg"
    },
    {
        id: 6,
        name: "Performance testing",
        text: "We offer performance testing for any type of software in order to evaluate the product’s speed, scalability, and responsiveness. Evaluating performance may help a development team identify and address performance bottlenecks, optimize user experience, and ensure overall reliability, which is particularly important for high-traffic applications.",
        image: "/img/qa/whatOffer/qa-performance.svg"
    },
    {
        id: 7,
        name: "Integration testing",
        text: "We offer integration tests to validate that various software components perform perfectly and seamlessly when combined together. It is an indispensable solution for complex software ecosystems that allows you to guarantee the smooth interaction of integrated components, preventing compatibility issues.",
        image: "/img/qa/whatOffer/qa-integration.svg"
    },
    {
        id: 8,
        name: "UI testing",
        text: "UI tests focus on evaluating the user interface of your software to ensure it functions as intended. Our experts rigorously assess elements such as buttons, forms, navigation, and layout, identifying issues that may impact user satisfaction and the overall usability of your software and, consequently, guaranteeing a seamless and intuitive user experience.",
        image: "/img/qa/whatOffer/qa-ui-testing.svg"
    },
    {
        id: 9,
        name: "Usability testing",
        text: "At ELITEX, we also offer usability testing services that assess the user-friendliness and intuitiveness of your software. It allows you to gain valuable insights, including real user feedback, leading to user satisfaction optimization, wider product adoptions, and boosted customer retention.",
        image: "/img/qa/whatOffer/qa-usability.svg"
    }
];

const qaWeOfferTitleFour = "Moreover, we provide additional options allowing you to outsource software quality assurance services to elevate your software to new heights of excellence without significant costs and effort investments.";
const qaWeOfferContentFour = [
    {
        id: 1,
        name: "Dedicated QA team",
        text: "ELITEX offers you a dedicated team model, which provides you with a team of skilled professional testers and QA specialists entirely devoted to your project. We can build you a team that will handle end-to-end tests independently or one that will be fully integrated with your in-house staff working asynchronously with the main development team.",
        image: "/img/qa/whatOffer/qa-dedicated-team.svg"
    },
    {
        id: 2,
        name: "On-demand testing",
        text: "We also provide on-demand software testing services, offering flexible and scalable solutions precisely tailored to your business goals. By choosing this collaboration model, you gain the agility to adapt to changing project requirements and accelerate development cycles, still ensuring top-notch software quality on your terms.",
        image: "/img/qa/whatOffer/qa-on-demand.svg"
    },
    {
        id: 3,
        name: "Consulting team",
        text: "Being an experienced software quality assurance company, we offer you a dedicated consulting team service. Our team of seasoned professionals provides expert guidance and strategic insights to optimize your QA/QC and software development processes.",
        image: "/img/qa/whatOffer/qa-consulting.svg"
    }
];

//  Qa Certified

const qaCertifiedTitle = "We Are ISTQB Certified";
const qaCertifiedTextOne = "As a testament to our commitment to quality and expertise in software quality assurance testing services, we are proud to announce that we are ISTQB certified. The ISTQB is a software testing certification board that operates internationally. This certification attests to our team’s proficiency and adherence to international standards, ensuring that our clients receive the highest quality services";
const qaCertifiedTextTwo = "QA team available right now";
const qaCertifiedTextThree = "Our QA teams are available and ready to assist you right now. Whether you require immediate application assessment, have questions, or need urgent support from industry experts, ELITEX is here to ensure your product runs smoothly and effectively."

// Qa Benefits

const qaBenefitsContent = [
    {
        id: 1,
        name: "Enhanced product quality",
        text: "Our quality assurance services can help you significantly improve your software’s quality. By identifying and rectifying defects, we ensure your products are reliable, perform seamlessly, and meet client expectations.",
        img: "/img/qa/webp/qabenefits/qa_benefits_3.webp"
    },
    {
        id: 2,
        name: "Cost savings",
        text: "Investing in QA early during the development process can significantly reduce the overall software’s cost. Our engineers can help you develop a software quality control strategy and provide insights about your product work from the user’s perspective. By identifying and fixing issues early, you can avoid costly bug fixes and customer support after the release.",
        img: "/img/qa/webp/qabenefits/qa_benefits_2.webp"
    },
    {
        id: 3,
        name: "Reduced time-to-market",
        text: "Efficient QA processes, including automation and streamlined methodologies, accelerate the whole software development cycle. It means your software can reach the market faster, enabling you to gain a competitive edge and capitalize on opportunities more swiftly.",
        img: "/img/qa/webp/qabenefits/qa_benefits_4.webp"
    },
    {
        id: 4,
        name: "Prevent brand and reputation damage",
        text: "When your customers think of your brand, specific images, feelings, and expectations come into their minds. Our QA processes include robust quality control measures to identify and address vulnerabilities, as well as ensure adherence to industry regulations and enhance user experience.",
        img: "/img/qa/webp/qabenefits/qa_benefits_1.webp"
    },
]

// Qa Testing Services Company

const qaTestingCompanyTextOne = "As an experienced software testing services company, we value the partnership that goes beyond mere service provision. At ELITEX, we believe in collaborative relationships where your success is our success. That’s why we offer you to join forces to achieve your software quality goals and elevate your brand to new heights of excellence.";
const qaTestingCompanyTextTwo = "With a decade of experience in the software development industry, we have honed our expertise and refined our QA methodologies to deliver cutting-edge solutions that exceed your expectations. Our team of QA specialists is committed to conducting rigorous testing across various domains, including web, mobile, and desktop applications, offering a comprehensive suite of services, from functional, performance, and unit testing to security and usability assessments. Our approach is customizable solutions tailored to meet your specific project requirements, ensuring that your software not only meets but surpasses the highest industry and quality standards.";

// Qa Tools

const qaToolsContent = [
    {
        id: 1,
        name: "Performance Testing Tools",
        images: ["img/qa/tools/qa-jmeter.svg", "img/qa/tools/qa-visual-studio.png", "img/qa/tools/qa-k6.svg"]
    },
    {
        id: 2,
        name: "Cross-browser testing tools",
        images: ["img/qa/tools/qa-browser-stack.png", "img/qa/tools/qa-test-grid.png" ]
    },
    {
        id: 3,
        name: "CI/CD Tools",
        images: ["img/qa/tools/qa-azure-devops.png", "img/qa/tools/qa-jenkins.svg", "img/qa/tools/qa-gitlab.png"]
    },
    {
        id: 4,
        name: "Test Management tools",
        images: ["img/qa/tools/qa-jira.png", "img/qa/tools/qa-test-rail.png", "img/qa/tools/qa-zephyr.png", "img/qa/tools/qa-qase.png"]
    },
    {
        id: 5,
        name: "Api testing tools",
        images: ["img/qa/tools/qa-swagger.png", "img/qa/tools/qa-postman.png"]
    },
    {
        id: 6,
        name: "Automation testing tools",
        images: ["img/qa/tools/qa-selenium.svg", "img/qa/tools/qa-playwright.svg"]
    },
];

// Qa Testing Process

const qaProcessContent = [
    {
        id: 1,
        header: "Requirements gathering",
        text: "Before any work starts, our team determines what business performance you expect and the goals you want the product to achieve. This step helps us to understand the software’s intended functionality and behavior and, consequently, define clear and measurable test objectives based on this requirement analysis."
    },
    {
        id: 2,
        header: "Test planning",
        text: "At this stage, we create a detailed test plan that outlines the scope of the work, objectives, resources, schedules, and deliverables. We identify the test types and levels to be performed and establish communication with the development team."
    },
    {
        id: 3,
        header: "Designing and executing tests",
        text: "Based on client requirements, our specialists create a test suite that will help test the entire system. We also collect all the data needed to complete test scenarios. Once the environment for tests is up and running, we execute tests manually or with the help of automation tools."
    },
    {
        id: 4,
        header: "Test reporting",
        text: "Trust QA engineers at ELITEX in the test processes and observe the outcome. Our team will translate bug reports, technical terms, and metrics into the business language, and you will understand how every test results impact your product and brand reputation."
    },
];

// FAQ

const qaFaqTitle = {
    main: "QA",
    strong: "FAQs"
}
const qaFaqList = [
    {
        id: 1,
        name: "What is the typical duration of QA testing services?",
        text: "The duration of QA processes varies depending on the project’s complexity. We usually provide a detailed timeline during the project scoping stage."
    },
    {
        id: 2,
        name: "How quickly can you respond to urgent needs?",
        text: "We prioritize urgent needs and aim to respond promptly, often within hours, to ensure your project stays on track."
    },
    {
        id: 3,
        name: "What is the difference between manual and automated tests, and when should I use each approach?",
        text: "The manual strategy involves human testers executing tests, which is ideal for exploratory and other early stages. Automation is about using scripts for repetitive tasks, and it is efficient for regression tests for large-scale projects."
    },
    {
        id: 4,
        name: "What is the cost structure for your quality assurance software testing services?",
        text: "The pricing of our services is project-specific and based on factors like scope and complexity. We provide customized quotes to ensure fair and transparent costs."
    },
    {
        id: 5,
        name: "How can I choose a good software QA and testing services company?",
        text: "You should look for a QA services company with a strong track record, relevant expertise, industry recognition, and a commitment to aligning services without your specific needs and goals."
    },
];
export {
    qaMainCaptionTitle,
    qaMainCaptionText,
    qaWhoWeAreTextTwo,
    qaWhoWeAreTitle,
    qaWhoWeAreTextOne,
    qaOurText,
    qaWeOfferContentOne,
    qaWeOfferContentTwo,
    qaWeOfferContentThree,
    qaWeOfferContentFour,
    qaWeOfferTitleOne,
    qaWeOfferTitleThree,
    qaWeOfferTitleTwo,
    qaWeOfferTitleFour,
    qaCertifiedTitle,
    qaCertifiedTextOne,
    qaCertifiedTextTwo,
    qaCertifiedTextThree,
    qaBenefitsContent,
    qaTestingCompanyTextTwo,
    qaTestingCompanyTextOne,
    qaProcessContent,
    qaFaqList,
    qaFaqTitle,
    qaToolsContent
}