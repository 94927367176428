import React from 'react';
import './WhyList.css';

export const WhyList = ({ list }) => (
  <div className="column-section clearfix why-list">
    {list.map(elem => (
      <div key={elem.id} className="column one-third why-list-col">
        <h4>{elem.name}</h4>
        <ul>
          {elem.data.map((text, index) => <li key={index}>{text}</li>)}
        </ul>
      </div>
    ))}
  </div>
);
