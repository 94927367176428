export const csdWhatWeCanDo = [
  {
    id: 1,
    header: 'Custom Web Application',
    text: 'We are experts in web development and can create any kind of web application that could boost your business. Using mostly JavaScript tech stack we create fast and scalable web products.',
    img: '/img/csd/webp/whatWeDo/csd_1.webp',
  },
  {
    id: 2,
    header: 'User Experience and Design',
    text: 'UX helps users get around, predict, and complete the tasks. UI creates your unique look and feel. We know how to create designs that bring the highest ROI for your product.',
    img: '/img/csd/webp/whatWeDo/csd_2.webp',
    reverse: true
  },
  {
    id: 3,
    header: 'Custom Database Development',
    text: 'Designing a robust and efficient database is "must" for your project, especially, if it includes a whole lot of diverse data. We take into account the scale and workload to develop the best-fit for your product.',
    img: '/img/csd/webp/whatWeDo/csd_3.webp',
  },
  {
    id: 4,
    header: 'Big Data',
    text: 'We help organizations thrive in a data-driven world by creating scalable, secure and agile big data solutions customized to unique business objectives.',
    img: '/img/csd/webp/whatWeDo/csd_4.webp',
    reverse: true
  },
  {
    id: 5,
    header: 'IoT',
    text: 'We implement solutions that monitor data, automate the routine procedures, and predict how to optimize resources. We can create any kind of a "smart" solution.',
    img: '/img/csd/webp/whatWeDo/csd_5.webp',
  },
  {
    id: 6,
    header: 'MVP and PoC',
    text: 'We can help you with MVP development, Prototyping, and Proof of Concept because we know what your startup needs to succeed.',
    img: '/img/csd/webp/whatWeDo/csd_6.webp',
    reverse: true
  },
  {
    id: 7,
    header: 'Cloud Solutions',
    text: 'Our engineers build secure, scalable cloud infrastructures, data science applications in the cloud, and more.',
    img: '/img/csd/webp/whatWeDo/csd_7.webp',
  },
];
