import {portfolioItems} from "../mocks";

export const portfolioItem = (key, invert) =>{
    const caseElement =  portfolioItems.find(item => item.key === key);

    return {
        ...caseElement,
        invert
    }
}
