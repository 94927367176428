import React from 'react';
import './CookieWarning.css';
import {Link} from "react-router-dom";

export const CookieWarning = ({invert, cookieAccepted, cookieAcceptHandler}) => (
  <div className={`cookie-warning-container 
  ${invert ? 'cookie-warning-container-inverted' : ''}
  ${cookieAccepted ? 'cookie-accepted' : 'cookie-not-accepted'}`}>
    <div className='cookie-warning-img'>
      <img
          src="/img/icons/resized/cookies.webp"
          alt="cookies"
          loading="lazy"
          width='60'
          height='60'
      />
    </div>
    <div className='cookie-warning-txt'>
      <p>This website uses cookies for analytics, personalization and advertising.
        Check our <Link to='/privacy-policy'>Privacy Policy</Link> page to learn more or change your cookie settings.
        By continuing to browse, you agree to our use of cookies.</p>
    </div>
    <div className='cookie-warning-btn'>
      <button
        className='btn btn-default'
        onClick={cookieAcceptHandler}>Agree
      </button>
    </div>
  </div>
);
