import React, { useEffect } from 'react';
import {
  Hero,
  Wrapper,
  PageBody,
  Spacer,
  ScrollDownBtn,
  ContactAddresses
} from '../../components';
import { ContactForm } from '../';
import GoogleMap from '../GoogleMap/GoogleMap';
import { contactInfoList } from '../../mocks';
import { delay, hideLoader, showLoader } from "../../utils/loaderHandler.util";
import './Contact.css';
import HelmetSetter from "../../components/Helmet/Helmet";

function Contact() {
  useEffect(() => {
    setTimeout( hideLoader.bind(this), delay );
    return showLoader();
  }, []);

  return (
    <React.Fragment>
      <HelmetSetter
        title='Get a Quote in Custom Software and JavaScript Development | ELITEX'
        description='Looking to take your project to the next level with a dedicated JS developers team? Elitex is the right place. Contact the members of our team to get started!'
        ogImg='contact/contact.jpg' />
      <Hero className="hero-big banner-with-img contact">
        <Wrapper id="page-title">
          <h1 className="h2 white-text">We Would Love to Hear from You</h1>
          <p className="white-text h4">Drop us a line and let's start from there</p>
        </Wrapper>
        <ScrollDownBtn/>
      </Hero>
      <PageBody>
        <Spacer type="big"/>
        <Wrapper>
          <ContactAddresses list={contactInfoList} />
          <Spacer type="big"/>
        </Wrapper>
        <GoogleMap/>
        <Spacer type="big"/>
        <ContactForm/>
      </PageBody>
    </React.Fragment>
  );
}

export {Contact};
