import React from 'react';
import './HomeWhatDoWeOffer.css';
import {homeWeOfferList, homeWeOfferText, homeWeOfferTitle} from "../../mocks";
import BlueLine from "../BlueLine/BlueLine";
import {Spacer} from "../Spacer/Spacer";
import {Link} from "react-router-dom";

const HomeWhatDoWeOffer = () => {
    return (
        <div className="home-we-offer-container">
           <h2 className='h4 align-center'>{homeWeOfferTitle}</h2>
            <BlueLine/>
            <p className="home-we-offer-text">{homeWeOfferText}</p>
            <Spacer type="big"/>
            <div className="home-we-offer-cards-container">
                {
                    homeWeOfferList.map(({id, title, text, image, link}) => (
                            <div className="home-we-offer-card" key={id}>
                                <Link to={link}>
                                <div className="home-we-offer-card-hover">
                                    <div className="home-we-offer-card-header">
                                        <h3 className="home-we-offer-card-title">{title}</h3>
                                        <img
                                            src="/img/icons/arrows/white-right-arrow.svg"
                                            alt="arrow"
                                            width="45px"
                                            height="45px"
                                            className="home-we-offer-card-arrow white"
                                        />
                                        <img
                                            src="/img/icons/arrows/black-right-arrow.svg"
                                            alt="arrow"
                                            width="45px"
                                            height="45px"
                                            className="home-we-offer-card-arrow black"
                                        />
                                    </div>
                                    <span className="home-we-offer-card-text">{text}</span>
                                </div>
                                <img
                                    src={image}
                                    alt="team"
                                    width="100%"
                                    height="100%"
                                    className="home-we-offer-card-img"
                                />
                                </Link>
                            </div>
                    ))
                }
            </div>
        </div>
    );
};

export default HomeWhatDoWeOffer;