import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './CustomSoftwareDevelopment.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    PageBody,
    Spacer,
    WdsSlider,
    WdsTechExpertise,
    Wrapper,
    CsdWhatWeCanDo,
    TextExpandable, ScrollDownBtn
} from "../../components";
import {testimonialItems} from "../../mocks";
import {portfolioItem} from "../../utils";
import {ContactForm, MainCaption, SliderMaker} from "..";
import {breadcrumbStructuredData} from "../../utils/structuredData";

function CustomSoftwareDevelopment() {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <React.Fragment>
            <HelmetSetter
                title='Custom Software Development Services | Outsourcing Company ELITEX'
                description="At ELITEX, we specialize in custom software development and provide personalized web application development services to meet our client's unique requirements."
                ogImg='csd/webp/csd_back.webp'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Custom Software Development',
                    item2: 'https://elitex.systems/custom-software-development'
                })}
            />
            <Hero className="hero-big banner-with-img csd">
                <Wrapper className="wrapper-medium">
                    <MainCaption
                        title="Custom Software Development Services"
                        text="We build software that turns your idea into a product"
                        contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody>
                <Wrapper>
                    <Spacer type='big'/>
                    <span className='h4 align-center'>What Can We <strong>Do?</strong></span>
                    <CsdWhatWeCanDo/>
                </Wrapper>
                <Spacer type='big'/>
                <h3 className='h4 align-center'>Related <strong>Case Studies</strong></h3>
                <Spacer type='small'/>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('postCMS', true),
                    portfolioItem('imagex', false),
                    portfolioItem('peerwith', true)
                ]}/>
                <Wrapper>
                    <Spacer type='medium'/>
                    <span className='h4 align-center'>We work <strong>with</strong></span>
                    <Spacer type='medium'/>
                    <WdsTechExpertise titleTag="h2"/>
                </Wrapper>
                <Spacer type='big'/>
                <Wrapper>
                    <h2 className='h4 align-center'>CUSTOM <strong>software development</strong></h2>
                    <TextExpandable
                        mainText={
                            <p>As a custom software development company, we at <Link to='/'>ELITEX</Link> create
                                software and offer Web Application Development services specifically tailored for
                                customer needs, whether it is AI, IoT, Big Data, MVP and PoC, or cloud solutions.
                            </p>
                        }
                        hiddenText={<>
                            <p>With many custom software development projects completed, our dedicated software
                                developers teams will address your software needs. Our JavaScript experts will create
                                products tailored to the latest software development trends.
                            </p>
                            <p>Our Ukraine-based JavaScript company provides software development services, custom web
                                application development, and software development in the US, UK, Europe, and worldwide.
                            </p>
                            <p>Solving tech challenges for our customers is the core of the ELITEX approach when it
                                comes to custom software development services. We deliver the perfect mix of a software
                                QA and testing company together with a custom software development firm offering
                                flexibility, agility, and scalability.
                            </p>
                            <p>Our Nodejs, React and Angular engineers create products from scratch or fine-tune your
                                existing product as well as upgrade and improve it, focused on a tightly- defined set of
                                requirements. Besides custom application development, our dedicated development team
                                offers
                                high-quality software testing services.
                            </p>
                            <p>Entrust your production to a dedicated project team of skilled experts. If you’re looking
                                for a dedicated tech vendor far beyond your local capabilities, custom software
                                development
                                company ELITEX is the best solution.
                            </p>
                            <p><Link to='/contact'>Contact ELITEX</Link> to hire software developers for your project
                                and receive a custom development
                                solution tailored specially for you and gain a competitive edge.
                            </p>
                        </>}
                    ></TextExpandable>
                </Wrapper>
                <Spacer type='medium'/>
                <div id="testimonial-slider">
                    <SliderMaker sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Spacer type='big'/>
                <h4 className='align-center'>Other <strong>services</strong></h4>
                <Spacer type='small'/>
                <WdsSlider/>
                <Spacer type='medium'/>
                <ContactForm/>
            </PageBody>
        </React.Fragment>
    );
}

export {CustomSoftwareDevelopment};
