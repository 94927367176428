import React, { useEffect } from "react";
import "./ModelMaster.css";
import { delay, hideLoader, showLoader } from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import { Hero, Spacer, Wrapper } from "../../components";
import { ContactForm } from "..";
import { modelMasterSliders2 } from "../../mocks";
import { CaseStudyCustomSlider } from "../index";
import { portfolioItem } from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const ModelMaster = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem("model-master");

  return (
    <React.Fragment>
      <HelmetSetter
        title='ModelMaster'
        description='Learning and development platform'
        ogImg='portfolio/stm/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'ModelMaster',
          item3: 'https://elitex.systems/case-studies/model-master'
        })}
      />
      <Hero className='hero-big banner-with-img model-master'>
        <Wrapper id='page-title'>
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className='white-text h1'>{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src='/img/portfolio/model-master/webp/model-master_notebook.webp'
                  srcSet="/img/portfolio/model-master/resized/model-master_notebook_2_small.webp 600w,
                          /img/portfolio/model-master/resized/model-master_notebook_2_medium.webp 1100w,
                          /img/portfolio/model-master/webp/model-master_notebook.webp 1400w"
                  alt='notebook'
                  width='100%'
                  height='100%'
              />
            </div>
          </div>
        </Wrapper>
      </Hero>
      <Spacer type='medium' />
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <img
                className='case-study-logo'
                src='/img/portfolio/model-master/resized/model-master_logo.webp'
                alt='logo'
                width="170"
                height="42"
            />
            <Spacer type='medium' />
            <h3 className='h4'>
              <strong>Industry</strong>
            </h3>
            <p>Education</p>
            <Spacer type='mini' />
            <h3 className='h4'>
              <strong>Technology</strong>
            </h3>
            <p>
              React, Typescript, Enzyme, SCSS, ChartJS, Node js, Express, Jest, Sequelize ORM, MySQL, Passport,
              Stripe, Websockets, AWS
            </p>
            <Spacer type='mini' />
            <h3 className='h4'>
              <strong>Services</strong>
            </h3>
            <ul className='nolist'>
              <li>Full-cycle web development</li>
              <li>Back-end development</li>
              <li>Front-end development</li>
              <li>UI/UX design</li>
              <li>Project management</li>
            </ul>
            <Spacer type='mini' />
            <h3 className='h4'>
              <strong>Customer’s Location</strong>
            </h3>
            <p className='case-study-location'>
              <img
                  src='/img/portfolio/flags/usa_flag.webp'
                  alt='flag'
                  width="40"
                  height="40"
              />
              The United States
            </p>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>
              About the <strong>project</strong>
            </h2>
            <p>
              Our client is an educational startup from the USA that aims to build a state-of-the-art learning and
              development platform. It provides users the possibility to try the tasks as they learn about them while giving companies more granular visibility into their performance.
            </p>
            <Spacer type='medium' />
            <h2 className='h4'>
              The <strong>challenge</strong>
            </h2>
            <p>
              The client needed to create a custom-ready version of the product. Before contacting ELITEX, they managed to create a preliminary beta version that required a different outlook and refinement of experienced developers. The project needed an in-browser Excel emulator, course creation, management capabilities, and reporting functionality.
            </p>
            <Spacer type='medium' />
            <Wrapper>
              <img
                  src='/img/portfolio/model-master/resized/slider1/model-master1_large.webp'
                  srcSet="/img/portfolio/model-master/resized/slider1/model-master1_small.webp 600w,
                          /img/portfolio/model-master/resized/slider1/model-master1_large.webp 1400w"
                  alt='Model master site screenshot'
                  loading="lazy"
                  width="100%"
                  height="100%"
              />
            </Wrapper>
          </div>
        </Wrapper>
        {/*sticky zone*/}
        <div className='case-study-relative'>
          <div className='case-study-flex-order'>
            <Spacer type='medium' />
            <Wrapper>
              <div className='case-study-60'>
                <h2 className='h4'>
                  The <strong>solution</strong>
                </h2>
                <p>
                  We started our collaboration in May 2020. From the ELITEX side, a group of skilled engineers and a dedicated project manager were involved. The client was fairly flexible on the tech
                  stack, and our team used Node.js and React for the application development.
                </p>
                <p>
                  Our team designed and developed a revamp of the products' beta version. We were responsible for
                  the entire tech stack, from managing AWS to Front-end development.
                </p>
                <p>
                  We kept the customer updated about the development progress. Our project manager had bi-weekly
                  sprints with the customer, where guidelines and guidance on what the team was building were
                  provided. We carefully incorporated customer feedback based on those sessions, which resulted in
                  project success. The client was very happy with our communication.
                </p>
              </div>
            </Wrapper>
            <Spacer type='medium' />
            <Wrapper>
              <img
                  src='/img/portfolio/model-master/resized/slider1/model-master2_large.webp'
                  srcSet="/img/portfolio/model-master/resized/slider1/model-master2_small.webp 600w,
                          /img/portfolio/model-master/resized/slider1/model-master2_medium.webp 1100w,
                          /img/portfolio/model-master/resized/slider1/model-master2_large.webp 1400w"
                  alt='Model master site screenshot'
                  loading="lazy"
                  width="100%"
                  height="100%"
              />
            </Wrapper>
          </div>
        </div>

        <Spacer type='medium' />

        <div className='case-study-grey-3'>
          <Wrapper>
            <CaseStudyCustomSlider slides={modelMasterSliders2} />
          </Wrapper>
        </div>

        <div className='case-study-order-4'>
          <Wrapper>
            <div className='case-study-60'>
              <Spacer type='medium' />
              <h2 className='h4'>
                The <strong>result</strong>
              </h2>
              <p>
                We created a true partnership, where the team often suggested best practices, methods, and
                alternatives to the client's thoughts based on their deep experience.
              </p>
              <p>
                Since working together with the ELITEX team, our client began doing several demos and started to
                secure interest from their first set of customers.
              </p>
            </div>
          </Wrapper>
        </div>
        <Spacer type='big' />
      </div>
      <div className='case-study-grey-2'>
        <ContactForm />
      </div>
    </React.Fragment>
  );
};
