export const wdsTechExpertise = [
  {
    title: 'JavaScript / NodeJS stack',
    text: 'Modern, fast and powerful - JavaScript and NodeJS are among the most trendy technologies nowadays for both startups and business applications. Trusted by Google, PayPal, Uber and many others.',
    image: '/img/wds/resized/techExpertise/wds_mean.webp'
  },
  {
    title: 'Frontend / UI',
    text: 'The user interface is the first thing your user sees when they open your web app or a site. That\'s why having a fast, user-friendly and beautiful front-end is so important. Usually we build SPAs.',
    image: '/img/wds/resized/techExpertise/wds_ui.webp'
  },
  {
    title: 'Backend',
    text: 'While JavaScript is our favorite technology, we also work with some other powerful and well known tools.',
    image: '/img/wds/resized/techExpertise/wds_backend.webp'
  },
  {
    title: 'Databases, APIs, platforms etc.',
    text: 'SQL and NoSQL databases, different cloud platforms, variety of payment gateways etc.',
    image: '/img/wds/resized/techExpertise/wds_database.webp'
  }
];
