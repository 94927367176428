export const homeSliderItems = [
  {
    id: 1,
    image: "img/office/webp/office_1.webp",
    title: "Software Development Company and Consulting Services",
    text: "Software development has become a vital element of life, driving innovation and shaping how we interact with each other and the world. The tech industry's impact is felt across various industries and around the globe, entering each aspect of our lifestyle nowadays. Indeed, it’s hard to deny that software products have transformed our life from smartphone applications to complex systems empowering businesses and government.",
    srcSet: "img/office/resized/office_1_small.webp 600w, img/office/resized/office_1_large.webp 1280w"
  },
  // {
  //   id: 2,
  //   image: "img/office/webp/office_2.webp",
  //   text: "As an experienced custom software development company with substantial technical expertise, we understand the importance of developing advanced software solutions to drive growth, enhance productivity, and streamline operations. Our team of skilled developers, project managers, and designers are committed to delivering high-quality software that meets your unique business goals. At ELITEX, we understand that every project is unique, so we tailor our approach to meet the product's specific needs: we are committed to delivering software that exceeds all expectations.",
  //   srcSet: "img/office/resized/office_2_small.webp 600w, img/office/webp/office_2.webp 1280w"
  // },
  {
    id: 3,
    image: "img/office/webp/office_3.webp",
    title: "Software Development Company and Consulting Services",
    text: "As an experienced custom software development company with substantial technical expertise, we understand the importance of developing advanced software solutions to drive growth, enhance productivity, and streamline operations. Our team of skilled developers, project managers, and designers are committed to delivering high-quality software that meets your unique business goals. At ELITEX, we understand that every project is unique, so we tailor our approach to meet the product's specific needs: we are committed to delivering software that exceeds all expectations.",
    srcSet: "img/office/resized/office_3_small.webp 600w, img/office/resized/office_3_large.webp 1280w"
  }
];

export const jsdSliderItems = [
  {
    id: 1,
    image: "/img/jsd/jsd-main-large_1.webp",
    title: "JavaScript Development Services",
    text: "JavaScript has become vital to web development, powering modern websites and applications' interactive and dynamic elements. As a client-side scripting language, developers can create amazing user interfaces responding to real-time input. However, JS is more than just a front-end development language nowadays. Technologies such as JS-based Node slightly facilitate the full-stack development process as soon as both the front-end and the back-end development can be conducted using the same language.",
    srcSet: "/img/jsd/jsd-main-small_1.webp 600w, /img/jsd/jsd-main-large_1.webp 1280w"
  },
  {
    id: 2,
    image: "/img/jsd/jsd-main-large_2.webp",
    title: "JavaScript Development Services",
    text: "JavaScript is one of the most popular programming languages worldwide due to a vast array of JavaScript frameworks and libraries. A wide range of tools and resources are available to developers, from React and Angular for the front-end development to Express and Nest for the back-end. In fact, the vast majority of websites and apps you can meet on the Internet today were built using JavaScript. As a result, JavaScript services have become increasingly in demand, with businesses seeking to leverage the power and flexibility of this versatile language to build custom web applications.",
    srcSet: "/img/jsd/jsd-main-small_2.webp 600w, /img/jsd/jsd-main-large_2.webp 1280w"
  }
];
