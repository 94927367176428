import React, {Fragment, useEffect} from 'react';
import './AngularTech.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {angularTechFaqStructuredData, breadcrumbStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero, JsdLogos,
    LogosList,
    PageBody,
    QaWhatWeDo,
    ScrollDownBtn,
    Spacer, WdsSlider,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {
    angularFaqList,
    angularFaqTitle,
    angularMainCaptionText,
    angularMainCaptionTitle,
    angularProcessContent,
    angularProcessTextOne,
    angularProcessTextTwo,
    angularProcessTitle,
    angularReasonsContent,
    angularReasonsText,
    angularReasonsTitle,
    angularWhatOfferCards,
    angularWhatOfferText,
    angularWhatOfferTitle,
    angularWhyChooseText,
    angularWhyChooseTitle,
    homeClientsSliderHeader,
    customersLogos,
    testimonialItems
} from "../../mocks";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import TechWhatOfferCards from "../../components/TechWhatOfferCards/TechWhatOfferCards";
import BlueLine from "../../components/BlueLine/BlueLine";
import ProcessStages from "../../components/ProcessStages/ProcessStages";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import {portfolioItem} from "../../utils";
import Faq from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";

export const AngularTech = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <Fragment>
            <HelmetSetter
                title="Angular Development Services | ELITEX"
                description="Discover the benefits of angular application development services with ELITEX. Read more about the experience of an advanced angular development company."
                ogImg="angular/angular-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "Angular",
                    item2: "https://elitex.systems/angular-technology"
                })}
                faqStructuredData={angularTechFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img angular">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={angularMainCaptionTitle} text={angularMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-react">
                <div id="testimonial-slider">
                    <SliderMaker dark clientsSliderHeader={homeClientsSliderHeader} sliderItems={testimonialItems}
                                 SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <TechWhatOfferCards title={angularWhatOfferTitle} text={angularWhatOfferText}
                                        cardsContent={angularWhatOfferCards}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{angularProcessTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{angularProcessTextOne}</p>
                    <p className="main-subtitle">{angularProcessTextTwo}</p>
                    <Spacer type="big"/>
                    <ProcessStages content={angularProcessContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">
                        <strong>{angularWhyChooseTitle.strong}</strong> {angularWhyChooseTitle.main}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{angularWhyChooseText.textOne}</p>
                    <p className="main-subtitle">{angularWhyChooseText.textTwo}</p>
                    <p className="main-subtitle">{angularWhyChooseText.textThree}</p>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="h4 align-center"><strong>Our clients and partners</strong></h2>
                    <LogosList list={customersLogos} redirect/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{angularReasonsTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{angularReasonsText}</p>
                    <Spacer type="medium"/>
                    <QaWhatWeDo list={angularReasonsContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title" id="portfolio-grid">Explore Angular Practical Examples in <strong>Our Case Studies</strong></h2>
                    <Spacer type="small"/>
                </Wrapper>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('peerwith', true),
                    portfolioItem('salsify', false),
                    portfolioItem('watstock', true),
                ]}/>
                <Spacer type="big"/>
                <h2 className="main-title"><strong>What Do We Offer</strong> Beyond the Angular Development Services</h2>
                <Spacer type="small"/>
                <WdsSlider/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <Faq title={angularFaqTitle} content={angularFaqList}/>
                    <Spacer type="big"/>
                    <h2 className="h4 align-center">Other Technologies We Use</h2>
                    <Spacer type="medium"/>
                    <JsdLogos/>
                </Wrapper>
                <Spacer type="big"/>
                <div className="case-study-grey-2">
                    <ContactForm/>
                </div>
            </PageBody>
        </Fragment>
    );
};