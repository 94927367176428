import React from 'react';
import './QaWhatOfferCards.css';
import {Spacer} from "../Spacer/Spacer";

const QaWhatOfferCards = ({cards, title}) => {
    return (
        <>
            <p className="main-title"><strong>{title}</strong></p>
            <Spacer type="medium"/>
            <div className="qa-offer-container">
                {
                    cards.map(({id, name, text, image}) => (
                        <div className="qa-offer-card-wrapper" key={id}>
                            <img src={image} alt="qa offer icon" className="qa-offer-image"/>
                            <div className="qa-offer-card">
                                <h3 className="qa-offer-card-name">{name}</h3>
                                <p className="qa-offer-card-text">{text}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
};

export default QaWhatOfferCards;