import React from 'react';
import './WdsTechExpertise.css';
import { Heading } from '../Heading/heading';
import {wdsTechExpertise} from "../../mocks";
import LazyLoad from "react-lazyload";

export const WdsTechExpertise = ({titleTag}) => (
  <div className="wds-expertise-container">
    {wdsTechExpertise.map((item, index) => (
      <div className="wds-expertise-item" key={index}>
        <Heading tag={titleTag} className='h5'><strong>{item.title}</strong></Heading>
        <div className="blue-rectangle-2"></div>
        <p>{item.text}</p>
        <LazyLoad>
          <img
              src={item.image}
              alt={item.title}
              width="100%"
              height="100%"/>
        </LazyLoad>
      </div>
    ))}
  </div>
);
