export const aboutIsotopeItems = [ {
  id: 1,
  image: '/img/about/webp/about_1.webp',
  doubleWidth: true,
  srcSet: '/img/about/resized/about_1_small.webp 600w, /img/about/resized/about_1_medium.webp 1200w, /img/about/resized/about_1_large.webp 1400w,'
}, {
  id: 2,
  image: '/img/about/resized/about_2.webp',
  srcSet: '/img/about/resized/about_2.webp 1200w, /img/about/webp/about_2.webp 1400w',
  doubleWidth: false
}, {
  id: 3,
  image: '/img/about/resized/about_3.webp',
  doubleWidth: false
}, {
  id: 4,
  image: '/img/about/resized/about_4.webp',
  doubleWidth: false
}, {
  id: 5,
  image: '/img/about/resized/about_5.webp',
  doubleWidth: false
} ];
