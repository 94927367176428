import React, { useState, useEffect } from "react";
import { Transition } from "react-transition-group";

import "./CareerVacanciesItem.css";

const transitionStyles = {
  entering: {
    opacity: 0
  },
  entered: {
    opacity: 1
  },
  exiting: {
    opacity: 0.8
  },
  exited: {
    opacity: 0
  }
};

const delay = 50;

export const CareerVacanciesItem = props => {
  const {
    title = "Fullstack JS Developer (React+Node)",
    location = {
      city: "Lviv",
      state: "Remote"
    },
    link = "/not-found",
    empty = false,
    index
  } = props;

  const [mounted, setMounted] = useState(false);

  const goToURL = () => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Transition in={mounted} timeout={delay * index}>
      {(state) =>
        empty ? (
          <div className="vacancy-item" style={{ ...transitionStyles[state] }}>
            <span>Whoops...</span>
            <h4 className="font-bold">
              It looks like we have no new vacancies right now
              </h4>
          </div>
        ) : (
          <div className="vacancy-item" style={{ ...transitionStyles[state] }} onClick={goToURL}>
            <span>ongoing</span>
            <h4 className="font-bold">
              <span className="vacancy-item__title" title={title}>
                {title}
              </span>
            </h4>
            <p className="location-container">
              <i className="fas fa-map-marker-alt"/>
              {location.city}, {location.state}
            </p>
            <button className="btn btn-filled btn-apply upperc" onClick={goToURL}>
              Apply now!
            </button>
          </div>
        )
      }
    </Transition>
  );
};
