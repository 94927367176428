export const portfolioItems = [
  {
    id:1,
    key: "kallik",
    photoPath: "/img/portfolio/kallik/webp/kallik_notebook.webp",
    backPath: "/img/portfolio/kallik/webp/kallik_back.webp",
    srcSetPath: "/img/portfolio/kallik/resized/kallik_notebook_small.webp 600w, /img/portfolio/kallik/resized/kallik_notebook_large.webp 1280w ",
    invert: false,
    name: "Kallik",
    subName:
      "Labeling content management that allows accurate, print-ready artwork for packaging and labeling in no time",
    link: "kallik"
  },
  {
    id:2,
    key: "peerwith",
    photoPath: "/img/portfolio/peerwith/webp/peerwith_notebook.webp",
    backPath: "/img/portfolio/peerwith/webp/peerwith_back.webp",
    srcSetPath: "/img/portfolio/peerwith/resized/peerwith_notebook_small.webp 600w, /img/portfolio/peerwith/resized/peerwith_notebook_large.webp 1280w ",
    invert: true,
    name: "Peerwith",
    subName: "Researcher services marketplace aimed to connect experts",
    link: "peerwith"
  },
  {
    id:3,
    key: "hotelReservations",
    path: "/img/portfolio/property-management-and-hotel-reservation-system/",
    photoPath: "/img/portfolio/property-management-and-hotel-reservation-system/webp/property_notebook.webp",
    backPath: "/img/portfolio/property-management-and-hotel-reservation-system/webp/property_back.webp",
    srcSetPath: "/img/portfolio/property-management-and-hotel-reservation-system/resized/property_notebook_small.webp 600w, /img/portfolio/property-management-and-hotel-reservation-system/resized/property_notebook_large.webp 1280w ",
    invert: false,
    name: "Property Management and Hotel Reservation system",
    subName: "",
    link: "property-management-and-hotel-reservation-system"
  },
  {
    id:4,
    key: "exterity",
    photoPath: "/img/portfolio/exterity/webp/exterity_notebook.webp",
    backPath: "/img/portfolio/exterity/webp/exterity_back.webp",
    srcSetPath: "/img/portfolio/exterity/resized/exterity_notebook_small.webp 600w, /img/portfolio/exterity/resized/exterity_notebook_large.webp 1280w ",
    invert: true,
    name: "Exterity",
    subName: "Digital Signage and IPTV solution for a market leader",
    link: "exterity"
  },
  {
    id:5,
    key: "imagex",
    photoPath: "/img/portfolio/imagex/webp/imagex_notebook.webp",
    backPath: "/img/portfolio/imagex/webp/imagex_back.webp",
    srcSetPath: "/img/portfolio/imagex/resized/imagex_notebook_small.webp 600w, /img/portfolio/imagex/resized/imagex_notebook_large.webp 1280w ",
    name: "Imagex",
    subName: "Web digital library that is clear and easy to use and manage",
    link: "imagex"
  },
  {
    id:6,
    key: "postCMS",
    photoPath: "/img/portfolio/postcms/resized/postcms_notebook_small.webp",
    backPath: "/img/portfolio/postcms/webp/postcms_back.webp",
    srcSetPath: "/img/portfolio/postcms/resized/postcms_notebook_small.webp 600w, /img/portfolio/postcms/resized/postcms_notebook_large.webp 1280w ",
    invert: true,
    name: "PostCMS",
    subName: "PostCMS is a next-generation content management system",
    link: "postcms"
  },
  {
    id:7,
    key: "salsify",
    photoPath: "/img/portfolio/salsify/webp/salsify_notebook.webp",
    backPath: "/img/portfolio/salsify/webp/salsify_back.webp",
    srcSetPath: "/img/portfolio/salsify/resized/salsify_notebook_small.webp 600w, /img/portfolio/salsify/resized/salsify_notebook_large.webp 1280w ",
    invert: false,
    name: "Salsify",
    subName: "Mobile-first frontend system for displaying various content",
    link: "salsify"
  },
  {
    id:8,
    key: "watstock",
    photoPath: "/img/portfolio/watstock/webp/watstock_notebook.webp",
    backPath: "/img/portfolio/watstock/webp/watstock_back.webp",
    srcSetPath: "/img/portfolio/watstock/resized/watstock_notebook_small.webp 600w, /img/portfolio/watstock/resized/watstock_notebook_large.webp 1280w ",
    invert: true,
    name: "Watstock",
    subName: "FinTech startup predicting stock market",
    link: "watstock"
  },
  {
    id:9,
    key: "zippia",
    photoPath: "/img/portfolio/zippia/webp/zippia_notebook.webp",
    backPath: "/img/portfolio/zippia/webp/zippia_back.webp",
    srcSetPath: "/img/portfolio/zippia/resized/zippia_notebook_small.webp 600w, /img/portfolio/zippia/resized/zippia_notebook_large.webp 1280w ",
    invert: false,
    name: "Zippia",
    subName: "Job search site with state-of-the-art machine learning techniques",
    link: "zippia"
  },
  {
    id:10,
    key: "felix",
    photoPath: "/img/portfolio/felix/webp/felix_notebook.webp",
    backPath: "/img/portfolio/felix/webp/felix_back.webp",
    srcSetPath: "/img/portfolio/felix/resized/felix_notebook_small.webp 600w, /img/portfolio/felix/resized/felix_notebook_large.webp 1280w ",
    invert: true,
    name: "Felix",
    subName: "Next-Gen Anti-Virus to fit all purposes",
    link: "felix"
  },
  {
    id:11,
    key: "switchboard",
    photoPath: "/img/portfolio/oa-switchboard/webp/oa_notebook.webp",
    backPath: "/img/portfolio/oa-switchboard/webp/oa_back.webp",
    srcSetPath: "/img/portfolio/oa-switchboard/resized/oa_notebook_small.webp 600w, /img/portfolio/oa-switchboard/resized/oa_notebook_large.webp 1280w ",
    invert: false,
    name: "The OA Switchboard",
    subName: "An article-level reporting hub for Publishers, Libraries/Institutions, and Funders",
    link: "oa-switchboard"
  },
  {
    id:12,
    key: "stm",
    photoPath: "/img/portfolio/stm/webp/stm_notebook.webp",
    backPath: "/img/portfolio/stm/webp/stm_back.webp",
    srcSetPath: "/img/portfolio/stm/resized/stm_notebook_small.webp 600w, /img/portfolio/stm/resized/stm_notebook_large.webp 1280w ",
    invert: true,
    name: "STM Integrity Hub",
    subName: "A modular platform to safeguard research integrity",
    link: "stm"
  },
  {
    id:13,
    key: "model-master",
    photoPath: "/img/portfolio/model-master/webp/model-master_notebook.webp",
    backPath: "/img/portfolio/model-master/webp/model-master_back.webp",
    srcSetPath: "/img/portfolio/model-master/resized/model-master_notebook_small.webp 600w, /img/portfolio/model-master/resized/model-master_notebook_large.webp 1280w ",
    invert: false,
    fullWidth: false,
    name: "ModelMaster",
    subName: "Learning and development platform",
    link: "model-master"
  },
  {
    id:14,
    key: "smartrr",
    photoPath: "/img/portfolio/smartrr/smartrr-notebook-large.webp",
    backPath: "/img/portfolio/smartrr/smartrr-back.webp",
    srcSetPath: "/img/portfolio/smartrr/smartrr-notebook-small.webp 600w, /img/portfolio/smartrr/smartrr-notebook-large.webp 1280w ",
    invert: true,
    fullWidth: false,
    subName: "Subscription platform for DTC Shopify brands that transforms one-time buyers into brand champions",
    name: "Smartrr",
    link: "smartrr"
  },
];
