import React, {useEffect} from 'react';
import './Watstock.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {portfolioItem} from "../../utils";
import LazyLoad from "react-lazyload";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const Watstock = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('watstock');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Fintech Application Development - Watstock Case Study'
        description='Watstock™ is a FinTech startup with the idea to develop a trading platform that uses Artificial Intelligence, Deep Learning, and LSTM (to predict the stock market).'
        ogImg='portfolio/watstock/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'Watstock',
          item3: 'https://elitex.systems/case-studies/watstock'
        })}
      />
      <Hero className="hero-big banner-with-img watstock">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className="white-text h1">{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/watstock/resized/watstock_notebook_2_large.webp"
                  srcSet="/img/portfolio/watstock/resized/watstock_notebook_2_small.webp 600w,
                  /img/portfolio/watstock/resized/watstock_notebook_2_medium.webp 1100w,
                 /img/portfolio/watstock/resized/watstock_notebook_2_large.webp 1400w"
                  alt="notebook"
                  width="100%"
                  height="100%"
              />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <img
                src="/img/portfolio/watstock/resized/watstock_logo.webp"
                alt="logo"
                width="170"
                height="38"
            />
            <Spacer type='medium'/>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>Fintech</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>AngularJS, HTML/CSS</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Front-end development</li>
              <li>Managing customer’s remote team</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/singapore_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />Singapore
            </p>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>Watstock™ is a FinTech startup with the idea to develop a trading platform that uses Artificial Intelligence, Deep Learning, and LSTM (Long-Short Term Memory) to predict the stock market for up to 10 days with extremely high accuracy due to a proprietary Neural Network.</p>
            <p>ELITEX used its strong JavaScript and UI Development expertise to build a couple of websites and data
              visualization components for Watstock.</p>
            <p>Moreover, ELITEX has helped the customer to manage his remote mobile development team and improve the
              overall development process in the startup.</p>
          </div>
        </Wrapper>

        <Spacer type='medium' />
        <div className='watstock-grey'>
          <Wrapper>
            <div className="watstock-grid">
              <div className='watstock-column'>
                <LazyLoad>
                  <img
                      src="/img/portfolio/watstock/resized/watstock_1.webp"
                      alt="1"
                      width="100%"
                      height="100%"
                  />
                </LazyLoad>
                <LazyLoad>
                  <img
                      src="/img/portfolio/watstock/resized/watstock_2.webp"
                      alt="2"
                      loading="lazy"
                      width="100%"
                      height="100%"
                  />
                </LazyLoad>
              </div>
              <div className='watstock-column'>
                <LazyLoad>
                  <img
                      src="/img/portfolio/watstock/resized/watstock_3.webp"
                      alt="3"
                      loading="lazy"
                      width="100%"
                      height="100%"
                  />
                </LazyLoad>
              </div>
            </div>
          </Wrapper>
        </div>

      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>
    </React.Fragment>
  )
};
