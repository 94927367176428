import React, {useEffect} from 'react';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {Hero, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import HelmetSetter from "../../components/Helmet/Helmet";
import {portfolioItem} from "../../utils";
import './OASwitchboard.css';
import LazyLoad from "react-lazyload";
import {breadcrumbStructuredData} from "../../utils/structuredData";
import {oaVideoStructuredData} from "../../mocks/portfolioStructuredData";

export const OASwitchboard = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    const item = portfolioItem('switchboard');

    return (
        <React.Fragment>
            <HelmetSetter
                title='Case Study - The OA Switchboard initiative | ELITEX'
                description='An article-level reporting hub for Publishers, nstitutions and Funders to provide essential infrastructure to facilitate the fulfilment of open access strategies.'
                ogImg='portfolio/oa-switchboard/back.jpg'
                videoStructuredData={oaVideoStructuredData}
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Case Studies',
                    item2: 'https://elitex.systems/case-studies',
                    name3: 'The OA Switchboard',
                    item3: 'https://elitex.systems/case-studies/oa-switchboard'
                })}
            />
            <Hero className="hero-big banner-with-img oa">
                <Wrapper id="page-title">
                    <div className='case-study-hero-divider-container case-study-mt'>
                        <div className='case-study-hero-divider-txt'>
                            <h1 className="white-text h1">{item.name}</h1>
                            <p className='white-text h5'>{item.subName}</p>
                        </div>
                        <div className='case-study-hero-divider-img'>
                            <img
                                src="/img/portfolio/oa-switchboard/webp/oa_notebook.webp"
                                srcSet="/img/portfolio/oa-switchboard/resized/oa_notebook_2_small.webp 600w,
                          /img/portfolio/oa-switchboard/resized/oa_notebook_2_medium.webp 1100w,
                          /img/portfolio/oa-switchboard/webp/oa_notebook.webp 1400w"
                                alt="icon"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Wrapper>
            </Hero>
            <Spacer type='medium'/>
            <div className='case-study-page'>
                <Wrapper>
                    <div className='case-study-column column-mb'>
                        <h3 className='h4'><strong>Industry</strong></h3>
                        <p>Publishing</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Technology</strong></h3>
                        <p>React, NodeJS, AWS ( API gateway, Lambda, SQS, SNS and SES )</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Services</strong></h3>
                        <ul className="nolist">
                            <li>Front-end development</li>
                            <li>Back-end development</li>
                        </ul>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Customer’s Location</strong></h3>
                        <p className='case-study-location'>
                            <img
                                src="/img/portfolio/flags/netherlands_flag.webp"
                                alt="flag"
                                width="40"
                                height="40"
                            />The Netherlands
                        </p>
                    </div>

                    <div className='case-study-60'>
                        <h2 className='h4'>About the <strong>project</strong></h2>
                        <p>
                            <a href="https://www.oaswitchboard.org/">The OA Switchboard initiative</a> is a
                            not-for-profit collaboration between funders, institutions, and publishers to provide
                            essential infrastructure to facilitate the fulfillment of open access strategies across
                            business models, policies, and agreements. The OA Switchboard is the independent
                            intermediary,
                            connecting parties and systems, streamlining communication and the neutral exchange of Open
                            Access
                            related publication-level information, and ensuring a financial settlement.
                        </p>
                        <Spacer type='medium'/>
                        <Wrapper>
                            <div className='iframe-container'>
                                <div className='iframe-video-wrapper'>
                                    <iframe
                                        src="https://www.youtube.com/embed/uTBqxh8JyFg?controls=0&modestbranding=1&rel=0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen title='The OA Switchboard'>
                                    </iframe>
                                </div>
                            </div>
                        </Wrapper>
                        <Spacer type='medium'/>
                        <p>
                            ELITEX won the OA Switchboard RFI in early 2020 in a competition with 20 other applicants,
                            providing the best answer to the immediate requirements and showing the best experience in
                            the scholarly information or solutions space and evidence of competency in developing and
                            delivering open source solutions.
                        </p>
                        <p>
                            After being selected as the tech partner, ELITEX assembled a development team for the OA
                            Switchboard and immediately began work on the Minimum Viable Product (MVP). The MVP was
                            delivered in 6 iterations, a total of 13 weeks, within budget and timelines. After go-live
                            with the MVP, the next stage of the project commenced, which was the Pilot phase bringing
                            onboard publishers, institutions, and funders. Throughout
                            2020, the project (overseen by OASPA) ran to prepare for the
                            OA Switchboard to go live as an operational solution.
                        </p>
                        <p>
                            The OA Switchboard moved to the operational stage on 1 January 2021, now run from an
                            independent foundation (Stichting OA Switchboard) with ELITEX as their tech partner.
                        </p>
                        <Spacer type='medium'/>
                        <LazyLoad>
                            <img
                                src="/img/portfolio/oa-switchboard/resized/oa_infographic_1_large.webp"
                                srcSet="/img/portfolio/oa-switchboard/resized/oa_infographic_1_small.webp 600w,
                         /img/portfolio/oa-switchboard/resized/oa_infographic_1_large.webp 1100w"
                                alt="background"
                                width="100%"
                                height="100%"
                            />
                        </LazyLoad>
                        <Spacer type='medium'/>
                        <p>
                            The core of the OA Switchboard is a message hub built around AWS messaging services like
                            SQS, SNS, and SES.
                            This approach is highly scalable and secure. Access to the hub is implemented using an AWS
                            API gateway linked to Lambda functions that execute serverlessly. The entire core of the OA
                            Switchboard is deployed serverless.
                            That allows OA Switchboard to operate cost-effectively, scaling up and down on demand and
                            minimizing fixed costs.
                        </p>
                        <p>
                            The OA Switchboard stakeholders are expected to integrate with the Application Programming
                            Interface (API),
                            sending and receiving messages using a standard message structure. As an alternative, ELITEX
                            also delivered a User Interface (UI) that can be used to compose messages, read them, and
                            create or download reports.
                            Here is a 4-minute video that gives a good impression of the functionality in the UI:
                        </p>
                        <Spacer type='medium'/>
                        <Wrapper>
                            <div className='iframe-container'>
                                <div className='iframe-video-wrapper'>
                                    <iframe
                                        src="https://www.youtube.com/embed/Oo6_kzgBtIY?controls=0&modestbranding=1&rel=0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen title='The OA Switchboard'></iframe>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </Wrapper>

                {/*sticky zone*/}
                <div className="case-study-relative">
                    <div className='case-study-flex-order'>
                        <Wrapper>
                            <Spacer type='medium'/>
                            <div className='case-study-60'>
                                <p>
                                    The UI is developed on top of the Pubsweet open-source framework, supported by the
                                    CoKo foundation – an innovative player in the publishing industry. The UI dispatches
                                    standard messages using the standard OA Switchboard API.
                                    The open-source UI serves as a visual demonstration and a useful source of
                                    inspiration for all parties who want
                                    to connect to the API directly.
                                </p>
                            </div>
                        </Wrapper>
                        <div className="case-study-order-3">
                            <Wrapper>
                                <div className='case-study-60'>
                                    <Spacer type='medium'/>
                                    <LazyLoad>
                                        <img
                                            src="/img/portfolio/oa-switchboard/resized/oa_infographic_2_large.webp"
                                            srcSet="/img/portfolio/oa-switchboard/resized/oa_infographic_2_small.webp 600w,
                         /img/portfolio/oa-switchboard/resized/oa_infographic_2_large.webp 1100w"
                                            alt="background"
                                            width="100%"
                                            height="100%"
                                        />
                                    </LazyLoad>
                                    <Spacer type='medium'/>
                                    <p>
                                        Taking into consideration that some stakeholders might be reluctant to invest in
                                        API integration with the OA Switchboard early on, we helped them to bridge the
                                        gap and reduce the effort needed by introducing a number of generic connectors.
                                        These connectors allow publishers to start using the OA Switchboard without
                                        making
                                        any changes to their existing IT infrastructure. During the pilot phase, we
                                        created and implemented custom connectors for The Microbiology Society, The
                                        Royal Society, and the American Physiological Society, making them early
                                        adopters of the OA Switchboard. The whole project has been executed In less than
                                        a month.
                                    </p>
                                </div>
                            </Wrapper>
                        </div>
                    </div>
                </div>

                <Spacer type='small'/>
            </div>
            <div className='case-study-grey-2'>
                <ContactForm
                    hideCalendly
                    linkedinLink="https://www.linkedin.com/in/ivoverbeek/"
                    linkedinPhoto="/img/contactForm/ivo.jpeg"
                    linkedinPosition="Director ELITEX NL."
                    linkedinName="Ivo Verbeek"
                />
            </div>

        </React.Fragment>
    )
};
