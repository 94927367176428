export const QaRelatedArticlesMock = [
    {
        id: 1,
        img: '/img/qa/resized/articles/qa_articles_3.webp',
        blueHeader: 'OUTSOURCING, TECHNOLOGY',
        mainHeader: 'Top-10 IT staff augmentation companies',
        text: 'For businesses unable to employ diverse IT professionals in-house or do not need to do so, staff augmentation services are the ideal solution...',
        link: '/blog/top-10-it-staff-augmentation-companies/',
        date: 'August 17, 2023'
    },
    {
        id: 2,
        img: '/img/qa/resized/articles/qa_articles_1.webp',
        blueHeader: 'TECHNOLOGY',
        mainHeader: 'Digital banking trends propelling the industry in 2024',
        text: 'The global epidemic has caused havoc on nearly every sector and changed existing processes. This trend also affects the banking industry...',
        link: '/blog/digital-banking-trends-propelling-industry/',
        date: 'May 26, 2023'
    },
    {
        id: 3,
        img: '/img/qa/resized/articles/qa_articles_2.webp',
        blueHeader: 'JAVASCRIPT, TECHNOLOGY',
        mainHeader: 'Front-end and JavaScript trends in 2024',
        text: 'Trends in frontend development are not quick to identify. It may take several years for the technology to become the practice of developers...',
        link: '/blog/javascript-trends/',
        date: 'February 5, 2023'
    },
];
