import React from 'react';
import './TechAwards.css';
import {trustedLinks} from "../../mocks";
import {TrustedLinks} from "../TrustedLinks/TrustedLinks";
import {Spacer} from "../Spacer/Spacer";

const TechAwards = ({content}) => {
    return (
        <>
            <div className="tech-awards-box">
                <TrustedLinks trustedList={trustedLinks}/>
            </div>
            <Spacer type="small"/>
            {
                content.map(({id, text}) => (
                        <p key={id} className="tech-awards-text">{text}</p>
                    )
                )
            }
        </>
    );
};

export default TechAwards;