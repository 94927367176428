import React from 'react';

export const Textarea = ({
                             reference,
                             defaultValue,
                             placeholder = "Message",
                             required = false,
                             onFocus,
                             errorBorder,
                             errorMessage,
                             ...rest
                         }) => {
    let border = errorBorder ? 'invalid' : '';
    let errorMessageEmpty = errorMessage ? 'errors' : '';

    return (
        <div className="form-row">
      <textarea
          placeholder={placeholder}
          name='message'
          className={border}
          cols={150}
          rows={30}
          ref={reference}
          onFocus={onFocus}
          defaultValue={defaultValue}
          {...rest} />
            {required && <span className={errorMessageEmpty}>Message is required</span>}
        </div>
    )
};
