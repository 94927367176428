const jsdWhatOfferTitle = "JavaScript Development Services We Provide";
const jsdWhatOfferText = "Whether you’re a small business looking to enhance your digital presence or a large corporation needing custom solutions, we have much to offer. ELITEX is a leading provider of top-tier JavaScript software development services. We provide a wide range of services and flexibility to choose from several development models and concepts, including agile development, waterfall development, and hybrid development, based on your unique needs and business requirements."
const jsdWhatOfferCards = [
    {
        id: 1,
        title: "Custom web and mobile applications",
        text: "We work closely with our clients to meet specific project requirements and develop customized solutions that deliver value and achieve objectives. Whether you’re looking to build a new application from scratch or enhance your existing app, our team of developers is here to help.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_1.webp",
    },
    {
        id: 2,
        title: "Development of an intuitive user interface and personalized UX",
        text: "It is difficult to overestimate the importance of modern UI/UX solutions. Our experienced developers and designers work together to create visually appealing interfaces and engaging user experiences tailored to your business needs. We utilize the latest design trends and user interface patterns to ensure your applications are easy to use and provide a seamless user experience.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_2.webp",
    },
    {
        id: 3,
        title: "JavaScript-based app front-end creation",
        text: "One of the main specializations of our team is creating robust and scalable front-end applications using main JS frameworks. We possess deep expertise in JavaScript and other languages and technologies such as HTML, CSS, and AJAX in order to leverage its capabilities to develop high-performance software.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_3.webp",
    },
    {
        id: 4,
        title: "Mobile apps for Android and iOS",
        text: "Get tailored mobile apps for Android and iOS platforms built with advanced tools and technologies to provide an exceptional customer experience. Mobile development with JS frameworks such as React.JS allows us to achieve efficient, speedy, superior-quality customization and external integrations.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_4.webp",
    },
    {
        id: 5,
        title: "CMS Development",
        text: "Content management systems or CMS services involve designing, building, and managing websites and web applications using a CMS development platform, such as WP, Drupal, or Joomla. ELITEX team can help you create customized websites, plugins, theme customization, content management, website maintenance, and optimizations.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_5.webp",
    },
    {
        id: 6,
        title: "Processing, developing, and maintaining databases",
        text: "Databases are powerful tools that help companies and employees organize and track their data and activities actively. By outsourcing these tasks to our developers, you can focus on your core activities while ensuring your data is secure, reliable, and easily accessible.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_6.webp",
    },
    {
        id: 7,
        title: "Building and supporting cloud apps",
        text: "We provide a full range of cloud app services, including designing, developing, testing, deploying, and maintaining the software applications that run on cloud infrastructure, such as Amazon Web Services, Microsoft Azure, or Google Cloud Platforms.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_7.webp",
    },
    {
        id: 8,
        title: "Web apps of any scale and SaaS solutions creation",
        text: "We create software applications hosted and delivered remotely by a third-party provider to users over the Internet, usually on a subscription basis. Our portfolio includes various cases, ranging from small-scale applications for specific business needs to large-scale enterprise-level solutions that serve multiple customers.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_8.webp",
    },
    {
        id: 9,
        title: "Server-side apps empowered by Node.js",
        text: "Node.js provides a dast and event-driven platform for building real-time web applications and APIs. Using a powerful Node V8 JS engine together with the rich ecosystem of packages and modules, our devs can help create RESTful APIs, web servers, and other server-side solutions.",
        image: "/img/jsd/whatDoWeOffer/jsd-offer_9.webp",
    },
];

export {
    jsdWhatOfferTitle,
    jsdWhatOfferCards,
    jsdWhatOfferText
}