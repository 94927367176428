import React from 'react';
import './ProcessStages.css';

const ProcessStages = ({content}) => {
    return (
        <div className="process-stages-container">
            {content.map(item => (
                <div className="process-stages-item" key={item.id}>
                    <span className="process-stages-number">{item.id}</span>
                        <h3 className='h5'><strong>{item.header}</strong></h3>
                        <p className="process-stages-text">{item.text}</p>
                        {item.arrow ? <img className="process-stages-arrow" src="/img/icons/arrows/blue-right-arrow.svg" alt="arrow"/> : null}
                </div>
            ))}
        </div>
    );
};

export default ProcessStages;