export const showLoader = () => {
  const bodyClassList = document.body.classList;
  bodyClassList.remove('loaded');
  bodyClassList.remove('loading-end');
};

export const hideLoader = () => {
  const bodyClassList = document.body.classList;
  bodyClassList.add('loaded');
  bodyClassList.add('loading-end');
};

export const delay = 400;
