import React from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from 'react-router-dom';
import {localBusinessStructuredData, mainStructuredData} from '../../utils/structuredData';

const HelmetSetter = ({
                          title,
                          description,
                          ogImg,
                          match,
                          staticContext,
                          structuredData,
                          videoStructuredData,
                          faqStructuredData
                      }) => {
    const urlOrigin = staticContext ? staticContext.origin : window.location.origin;
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <link rel="canonical" href={`${urlOrigin}${match.path}`}/>
            <base href={urlOrigin}/>
            {/*Preview snippet*/}
            <meta property="og:title" content={title}/>
            <meta property="og:image" content={`/img/${ogImg}`}/>
            <meta property="og:description" content={description}/>
            <script async type="application/ld+json">
                {JSON.stringify(mainStructuredData)}
            </script>
            <script async type="application/ld+json">
                {JSON.stringify(localBusinessStructuredData)}
            </script>
            {
                structuredData && (
                    <script async type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )
            }
            {
                videoStructuredData && (
                    videoStructuredData.map(({id, data}) => (
                        <script key={id} async type="application/ld+json">
                            {JSON.stringify(data)}
                        </script>
                    ))
                )
            }
            {
                faqStructuredData && (
                    <script async type="application/ld+json">
                        {JSON.stringify(faqStructuredData)}
                    </script>
                )
            }
        </Helmet>
    )
};

export default withRouter(HelmetSetter);
