import React from 'react';
import './FrontEndInfo.css';
import {infoText} from "../../mocks/frontEndPage.mock";
import {Wrapper} from "../Wrapper/Wrapper";

const FrontEndInfo = () => {
    return (
        <div className='fe-info-container'>
            <Wrapper className="wrapper-medium">
                <span className='fe-info-text'>{infoText}</span>
            </Wrapper>
        </div>
    );
};

export default FrontEndInfo;