import React, {useEffect} from 'react';
import './Innroad.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const Innroad = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('hotelReservations');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Hotel Management Software and Custom CMS Development - Case Study'
        description='Our customer offers a Software-as-a-Service hotel software solution that seamlessly integrates property management, global distribution, bookings, and marketing.'
        ogImg='portfolio/property-management-and-hotel-reservation-system/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'Property Management and Hotel Reservation System',
          item3: 'https://elitex.systems/case-studies/property-management-and-hotel-reservation-system'
        })}
      />
      <Hero className="hero-big banner-with-img innroad">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container case-study-mt'>
            <div className='case-study-hero-divider-txt'>
              <h1 className='white-text h2'>{item.name}</h1>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/property-management-and-hotel-reservation-system/resized/property_notebook_2_large.webp"
                  srcSet='/img/portfolio/property-management-and-hotel-reservation-system/resized/property_notebook_2_small.webp 600w,
                  /img/portfolio/property-management-and-hotel-reservation-system/resized/property_notebook_2_medium.webp 1100w,
                  /img/portfolio/property-management-and-hotel-reservation-system/resized/property_notebook_2_large.webp 1400w'
                  alt="notebook"
                  width="100%"
                  height="100%"
              />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>Property Management, Hotel Reservation</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>React, Redux, NodeJS, .Net, HTML, CSS</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Team augmentation</li>
              <li>Product extension</li>
              <li>Frontend development</li>
              <li>Backend development</li>
              <li>Technical consultancy</li>
              <li>Quality assurance</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/usa_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />
              The United States
            </p>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>Our customer offers a Software-as-a-Service (SaaS) hotel software solution that seamlessly integrates property management, global distribution, bookings, and marketing. Its clients are independent hoteliers, small hotel chains, and hotel management companies.</p>
            <p>In this SaaS, they can find property management systems, booking engines, channel management, and reporting solutions. These features are all in a single location, which allows users to have greater control over managing hotels, distributing rooms online, and performing other daily tasks.</p>
            <p>The key features of the platform are:</p>
            <ul className='case-study-ul case-study-list-bullet peerwith'>
              <li>Property management system</li>
              <li>Commission-free booking engine</li>
              <li>Native GDS integration</li>
              <li>Revenue manager</li>
              <li>Credit card processing</li>
              <li>Data security</li>
              <li>Customizable reports</li>
            </ul>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>challenge</strong></h2>
            <p>Our client always:</p>
            <ul className='case-study-ul case-study-list-bullet peerwith'>
              <li>permanently improves the software quality</li>
              <li>continuously adds new features</li>
              <li>provides customization services.</li>
            </ul>
            <p>The company actively uses external services and remote engineers for core product development as well as side projects. This approach makes it possible to build new features and extend the platform much
              faster in reaction to market needs and clients’ enquiries.</p>
            <p>While having offices in the US and India plus a couple of software development vendors across the globe,
              the customer still needed a strong partner who could provide high-quality services and a team of top-level
              engineers.</p>
            <p>When the client contacted us, it was crucial for them to hire only senior software engineers who could
              work in one office.</p>
          </div>
        </Wrapper>

        <Spacer type='medium'/>
        <img
            src="/img/portfolio/property-management-and-hotel-reservation-system/webp/property_back2.webp"
            srcSet="/img/portfolio/property-management-and-hotel-reservation-system/resized/property_back2_small.webp 600w,
            /img/portfolio/property-management-and-hotel-reservation-system/webp/property_back2.webp
            "
            alt="background"
            loading="lazy"
            width="100%"
            height="100%"
        />

        {/*sticky zone*/}
        <div className="case-study-relative">
          <div className='case-study-flex-order'>
            <Wrapper>
              <Spacer type='medium'/>
              <div className='case-study-60'>
                <h2 className='h4'>The <strong>solution</strong></h2>
                <p>At first, with our team augmentation service, mostly senior front-end engineers reinforced the client’s expertise.</p>
                <p>Later on, after a successful phase of UI Development, the customer decided to extend the team
                  with <strong>backend and QA engineers</strong> to build a more autonomous team in Ukraine.</p>
                <p>At the start of our cooperation, the ELITEX team was managed by team leads on the client’s side. However,
                  with its expansion, it was agreed that managerial roles would be defined on the ELITEX side as well,
                  adding local team lead positions.</p>
                <p>The ELITEX team is involved in the following:</p>
                <ul className='case-study-ul case-study-list-bullet peerwith'>
                  <li>core product development</li>
                  <li>frontend and backend development</li>
                  <li>code clean-up</li>
                  <li>Refactoring</li>
                  <li>quality assurance (QA).</li>
                </ul>
                <p>As it was important to maintain smooth communication with the customer’s USA office, our
                  engineers <strong>shifted their work schedule</strong> to provide significant work time coverage with
                  the client’s timezone. That allowed Skype meetings, emails, and work chats within the same business day for both the Ukrainian and US sides.</p>
                <p>Our engineers also support the client’s development team from Asia, helping to handle the parts of work that require higher expertise and broader knowledge, providing the technical review, as well as consultancy.</p>
                <p>In the meantime, ELITEX is continuously looking for skilled Ukrainian engineers to extend the team.
                  Within the hiring process, ELITEX is responsible for choosing the most suitable applicants, interviewing them, and referencing the best candidates to the client, who makes the final decision.</p>
              </div>
            </Wrapper>
            <div className="case-study-order-3">
              <Wrapper>
                <div className='case-study-60'>
                  <Spacer type='medium'/>
                  <h2 className='h4'>The <strong>result</strong></h2>
                  <p>Working with ELITEX helped the customer to:</p>
                  <ul className='case-study-ul case-study-list-bullet peerwith'>
                    <li>Accelerate the development process;</li>
                    <li>Extend the engineering department’s capacity;</li>
                    <li>Significantly improve the code quality;</li>
                    <li>Augment its engineering team’s expertise, especially in the UI;</li>
                    <li>Build the right QA process.</li>
                  </ul>
                  <p>Elitex continues to provide services to the customer and help to achieve its business goals.</p>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>

        <Spacer type='small'/>

      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>
    </React.Fragment>
  )
};
