import React from 'react';
import './QaRelatedArticles.css';
import {QaRelatedArticlesMock} from '../../mocks';

export const QaRelatedArticles = () => (
  <div className='qa-articles-container'>
    {QaRelatedArticlesMock.map(item => (
      <a href={item.link} className='qa-articles-item' key={item.id}>
        <img
            src={item.img}
            alt="img"
            width="100%"
            height="100%"
        />
        <div className='qa-articles-item-content'>
          <p className='qa-articles-blue-header'><strong>{item.blueHeader}</strong></p>
          <p className='qa-articles-main-header'><strong>{item.mainHeader}</strong></p>
          <p className='qa-articles-item-text'>{item.text}</p>
          <div className='qa-articles-item-bottom'>
            <div>
              <span className='qa-articles-item-read-more'>Read More...</span>
            </div>
            <span className='qa-articles-item-date'>{item.date}</span>
          </div>
        </div>
      </a>
    ))}
  </div>
);
