import React from 'react';

export const PhoneField = ({
                               reference,
                               defaultValue,
                               placeholder = "Phone number",
                               required,
                               onFocus,
                               errorBorder,
                               errorMessage,
                               errorMessage2,
                               ...rest
                           }) => {
    let border = errorBorder ? 'invalid' : '';
    let errorMessageEmpty = errorMessage ? 'errors' : '';
    let errorMessageNotNumber = errorMessage2 ? 'errors' : '';

    return (
        <div className="form-row">
            <input
                placeholder={placeholder}
                name='phone'
                className={border}
                type="tel"
                ref={reference}
                onFocus={onFocus}
                defaultValue={defaultValue}
                {...rest} />
            {required && <span className={errorMessageEmpty}>Phone number is required</span>}
            <span className={errorMessageNotNumber}>Should be a valid phone number</span>
        </div>
    )
};