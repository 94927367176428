// MainCaption

const dotNetMainCaptionTitle = ".NET Development Company";
const dotNetMainCaptionText = "Unlock the full potential of your business with our bespoke .NET development solutions. Our team of skilled developers is ready to deliver top-notch solutions to modernize business processes and create sustainable, secure, and scalable applications that will empower your product.";

// We Offer

const dotNetWhatOfferTitle = "ELITEX .NET Development Services";
const dotNetWhatOfferText = "At ELTIEX, we leverage the power of .NET for developing enterprise-level web, desktop, and mobile applications. With almost a decade of experience in the domain, we offer a wide range of .NET development services for businesses and organizations with complex business goals and software requirements. Here is the list of services we offer:";
const dotNetWhatOfferCards = [
        {
            id: 1,
            title: "Custom .NET development",
            text: "Our experienced team specializes in crafting tailor-made software solutions that perfectly align with your project requirements. We offer you a wide range of custom .NET-based solutions that empower your organization to thrive.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_1.webp"
        },
        {
            id: 2,
            title: "Web application development",
            text: "ELITEX excels in .NET web development services, creating visually appealing and high-performing web applications with ASP.NET Core. Our web solutions are designed to provide seamless user experiences while meeting your specific functionality needs.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_2.webp"
        },
        {
            id: 3,
            title: "Mobile app development",
            text: "Whether you need cross-platform compatibility, native app development, or just a decent backend for your mobile application, our technical expertise in .NET technologies like Xamarin and Blazor ensures your mobile applications are feature-rich, secure, and responsive on all devices.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_3.webp"
        },
        {
            id: 4,
            title: "APIs and microservices development",
            text: "Our development team is well-versed in designing and developing APIs and microservices based on the .NET platform (Docker), enabling seamless data communication and modular architecture for your applications.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_4.webp"
        },
        {
            id: 5,
            title: "Desktop development",
            text: "We bring .NET’s power to the desktop environment, creating efficient and user-friendly desktop applications for enterprise requirements of any complexity. The list of desktop apps that can be built using .NET includes data management software, custom CRMs, ERPs, POS systems, accounting & financial software, healthcare solutions, media players, and other custom tools that cater to your project requirements.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_5.webp"
        },
        {
            id: 6,
            title: "Migration to cloud and cloud-native solutions",
            text: "ELITEX can help you embrace the benefits of the cloud with our extensive technical expertise in both creating native cloud and migrating existing applications to the cloud environment. Our dot net developers, together with the DevOps team, help you create cloud-based, cost-effective solutions that enhance scalability and reliability.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_6.webp"
        },
        {
            id: 7,
            title: "Custom third-party integrations",
            text: "We specialize in building custom integrations with third-party services, APIs, and tools to extend the functionality of your existing .NET applications, allowing seamless data exchange and enhanced capabilities.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_7.webp"
        },
        {
            id: 8,
            title: "Legacy systems modernization",
            text: "It is hard to overestimate the importance of modernizing outdated systems to improve performance and security. Our .NET experts can help transform your legacy software into agile, efficient, and future-ready solutions.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_8.webp"
        },
        {
            id: 9,
            title: ".NET development team",
            text: "We also offer a dedicated team option that allows you to scale your existing in-house resources quickly. Our dedicated .NET team comprises experienced professionals who are passionate about delivering excellence. With their deep expertise, we ensure that your projects are executed efficiently to the highest standards.",
            image: "/img/dotNet/whatDoWeOffer/dotNet-offer_9.webp"
        },
    ]

// Benefits

const dotNetBenefitsTitle = "Benefits of .NET Software Development";
const dotNetBenefitsTextOne = ".NET, a robust and versatile development platform (often referred to as a framework) from Microsoft, is widely recognized for its ability to streamline the custom development process. It is an ecosystem for building web, desktop, mobile, and cloud-based solutions. In fact, .NET is particularly well-suited for businesses seeking scalable and secure software in scenarios ranging from enterprise-level communication systems to e-commerce platforms, healthcare systems, and beyond. Its adaptability makes it a top choice for industries requiring compliance with stringent regulations, such as finance and healthcare.";
const dotNetBenefitsTextTwo = "Considering the platform’s advantages, your company can benefit significantly from partnering with a trusted .NET development company. By choosing .NET as a principal technology for application building in your technology stack, you choose cost-effectiveness, robust security features, scalability, and extensive language support, all of which enhance developer productivity. Let’s check in detail why choosing .NET is a good idea:";
const dotNetBenefitsCards = [
    {
        id: 1,
        title: "Language versatility",
        text: "The framework is language-independent, making it compatible with various programming languages, including VB.NET, C#, C++, and F#. Programmers can concentrate on building apps in their favorite language or languages while using .NET capability to make procedures built in one language available to other languages.",
        img: "/img/dotNet/benefits/dotNet-benefits_1.webp",
        tagName: "h3"
    },
    {
        id: 2,
        title: "Reusable code",
        text: "Multiple libraries of reusable code are available through the .NET. It means developers spend less time coding, resulting in lower expenses and a quicker turnaround for your projects. With capabilities like confidential components, no-impact apps, partially trusted code, side-by-side versioning, and managed code sharing, .NET makes deployment simpler.",
        img: "/img/dotNet/benefits/dotNet-benefits_2.webp",
        tagName: "h3"
    },
    {
        id: 3,
        title: "Speed of development",
        text: "Any .NET software development company can develop products really quickly. ASP.NET Core apps are compiled, which means the code is converted into object code before execution. Compiled code is fast and effective, resulting in excellent performance. The .NET platform accelerates basic setup creation for faster application connectivity.",
        img: "/img/dotNet/benefits/dotNet-benefits_3.webp",
        tagName: "h3"
    },
    {
        id: 4,
        title: "Interoperable Apps",
        text: ".NET is an ideal solution for the development of interoperable apps that offer a perfect user experience across different platforms. Interoperability helps maximize code reuse and makes the app’s development process highly efficient. Each code and feature produced in the .NET framework can be reused on various platforms.",
        img: "/img/dotNet/benefits/dotNet-benefits_4.webp",
        tagName: "h3"
    },
    {
        id: 5,
        title: "Cost-effective platform",
        text: "The platform .NET Core, which includes technologies like ASP.NET Core, is open-source and has a large contributor community. Senior .NET developers usually have access to a vast array of tools and resources, including development environments and troubleshooting advice. It ensures time-saving and cost-effectiveness.",
        img: "/img/dotNet/benefits/dotNet-benefits_5.webp",
        tagName: "h3"
    },
    {
        id: 6,
        title: "Compatible solution",
        text: "You can deploy .NET to Windows, Mac, Linux, or any other OS. Deployment is also made simple by containerization since, once it is operational on the development machine inside the container, there is no need to worry about environmental setup or deployment to move it to integration or production. The container is easily moveable.",
        img: "/img/dotNet/benefits/dotNet-benefits_6.webp",
        tagName: "h3"
    },
];

const dotNetProjectText = "Let’s Discuss Your Project";

// WhyChoose

const dotNetProcessText = "Choosing ELITEX as a partnering .NET application development company brings a multitude of compelling reasons to the table. Our commitment to excellence, years of experience, and proven track record in providing .NET development services make us the ideal choice for your next development project. Here is why you should consider ELITEX as your development partner:";
const dotNetProcessCards = [
    {
        id: 1,
        title: "Highly-skilled .NET developers",
        text: "Our team of experienced developers comprises the most qualified .NET talents in Europe, primarily senior developers. With a deep understanding of .NET intricacies, we possess the deep expertise needed to tackle even the most complex software challenges. Beyond the development team itself, ELITEX also offers a diverse array of professionals, such as QA specialists, UI/UX designers, project managers, and DevOps, all working collaboratively with developers to deliver high-quality solutions tailored to your exact requirements.",
        arrow: true
    },
    {
        id: 2,
        title: "Proven track record",
        text: "Our portfolio is a testament to our ability to deliver exceptional results. It showcases ELITEX’s successful projects spanning diverse industries and tailored to specific scenarios, demonstrating our prowess in tackling complex challenges. With us, you gain the assurance of a partner with a history of exceeding client expectations and delivering software that drives business growth and innovation.",
        arrow: true
    },
    {
        id: 3,
        title: "Comprehensive service offering",
        text: "Beyond traditional services, we offer a comprehensive suite of .NET development services for every scenario and stage of development. From strategic consulting and architecture design to legacy application modernization and ongoing support of existing software, we provide an end-to-end solution that ensures the success of your project.",
        arrow: true
    },
    {
        id: 4,
        title: "Long-term partnership and support",
        text: "Our commitment to your success extends far beyond the project completion period. ELITEX views clients as long-term partners and offers ongoing support and maintenance services to ensure your software's continued reliability and performance, with a typical partnership period exceeding two years. With us by your side, you’ll be confident that your software development endeavors are in capable hands that are not indifferent.",
        arrow: true
    },
    {
        id: 5,
        title: "Commitment to quality and security",
        text: "Quality and security have always been the top priority of our development philosophy. We implement rigorous testing, quality assurance processes, and regular security audits to ensure your software is secure, reliable, bug-free, and optimized for peak performance. Combining quality and security allows us to ensure that the product not only meets industry standards but also complies with regulatory requirements, such as HIPAA or GDPR, for instance.",
        arrow: false
    },
];

const dotNetMethodologyTitle = "Our .NET Software Development Methodology";
const dotNetMethodologyTextOne = "ELITEX offers various collaboration models depending on your project requirements. Whether you require a dedicated development team to work exclusively on your project, a fixed-scope engagement, a flexible, agile approach for the whole development cycle, or creating a brand-new product from scratch, we can accommodate your preferences. Our collaborative models are designed to give you the utmost control and transparency, ensuring you receive the level of engagement that best suits your project’s goals and dynamics.";
const dotNetMethodologyTextTwo = "You can expect a seamless development process when you choose ELITEX as a .NET development services provider. When it comes to creating a product from scratch, we usually begin by thoroughly understanding your project’s requirements. At this stage, we work closely with you to define objectives and design a tailored solution. Then, we choose the tools to bring your vision to life, including collaboration and project management tools. During the development lifecycle, we prioritize quality and security by adhering to the latest coding practices and incorporating feedback continuously. Upon completion, we ensure a smooth deployment and provide ongoing support, ensuring your product’s longevity and performance, all while prioritizing your unique needs and long-term success.";

// FAQ

const dotNetFaqList = [
    {
        id: 1,
        name: "How can .NET technology benefit my business?",
        text: ".NET can help your business by providing a versatile development platform for building secure, scalable, and cross-platform large applications that streamline operations and enhance customer experiences."
    },
    {
        id: 2,
        name: "What industries typically benefit from .NET development?",
        text: "Industries such as healthcare, finance, e-commerce, manufacturing, and logistics typically benefit from .NET development due to its robust security features, compliance capabilities, and scalability. Despite the particular industry, .NET is often referred to as an enterprise-scale applications development tool."
    },
    {
        id: 3,
        name: "How does a development process work in a good .NET web development company?",
        text: "In a good .NET company, the process typically involves requirements gathering, solution design, development itself, testing, deployment, and ongoing support, all tailored to your specific needs."
    },
    {
        id: 4,
        name: "How can I choose a good dot NET development outsourcing company?",
        text: "Consider factors like experience, expertise in particular industries, client reviews, and the ability to align with your project goals and requirements to choose a good dot NET development company."
    },
    {
        id: 5,
        name: "How do you handle software maintenance and updates in .NET projects?",
        text: "Software maintenance and updates in .NET projects are handled through regular monitoring, bug fixes, security patches, and feature enhancements, ensuring the software remains reliable and up-to-date."
    },
];
export {
    dotNetMainCaptionText,
    dotNetMainCaptionTitle,
    dotNetWhatOfferTitle,
    dotNetWhatOfferText,
    dotNetWhatOfferCards,
    dotNetBenefitsTitle,
    dotNetBenefitsCards,
    dotNetBenefitsTextOne,
    dotNetBenefitsTextTwo,
    dotNetProjectText,
    dotNetProcessText,
    dotNetProcessCards,
    dotNetMethodologyTitle,
    dotNetMethodologyTextTwo,
    dotNetMethodologyTextOne,
    dotNetFaqList
}