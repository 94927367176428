// Main

const aiMainCaptionTitle = "Artificial Intelligence Software Development Services";
const aiMainCaptionText = "Meet ELITEX, your trusted partner in AI development services that will make a difference for your project. We specialize in crafting custom AI solutions tailored to your unique business needs beyond all your expectations. ELITEX deliver practical, efficient, and reliable AI-driven applications to enhance your operations and achieve business goals. Choose ELITEX and check what AI can make for your business.";

// We Offer

const aiOfferTitle = "AI Development Services We Offer";
const aiOfferText = "ELITEX offers a wide range of innovative solutions in artificial intelligence for various industries and technological domains.";

const aiOfferCards = [
    {
        id: 1,
        title: "Custom AI software development solutions",
        text: "We design and build any custom AI solution that meets your specific business strategy and needs. Whether you are looking for a team to develop a groundbreaking AI application from scratch or enhance existing systems with AI capabilities, ELITEX are here to help. Our experienced software engineers ensure that AI solutions integrate seamlessly, while deep expertise in web and desktop development ensures visually appealing modern user interfaces in any solution we build.",
        image: "/img/ai/offer/ai-offer-1.webp"
    },
    {
        id: 2,
        title: "Machine learning models",
        text: "As a seasoned artificial intelligence software development company, ELITEX are experts in designing, developing, and deploying machine learning models that can accurately predict trends and automate decision-making in trading/market prediction areas. Whether you are looking for price-moving predictions based on market patterns or just looking for ways to automate your Excel routine tasks, we have what you need. We offer various models of predictive analytics tools that can handle data-related issues of any size and complexity.",
        image: "/img/ai/offer/ai-offer-2.webp"
    },
    {
        id: 3,
        title: "NLP/LLM development",
        text: "We offer a comprehensive list of solutions focused on developing and integrating sophisticated natural language processing and large language models (NLP and LLM technologies) into your business processes. ELITEX specialize in creating solutions that enable machines to understand and generate human language. Whether you need AI-driven solutions for advanced chatbots, sentiment analysis tools, content generation, or automated customer support, we have what to offer.",
        image: "/img/ai/offer/ai-offer-3.webp"
    },
    {
        id: 4,
        title: "OCR development",
        text: "Our optical character recognition (OCR) services leverage cutting-edge technology to convert images and scanned files into editable and searchable text. ELITEX offer the development of a full spectrum of text and image recognition tools, which can be an ideal choice for businesses looking to digitize paper-based records (for instance, detect sensitive elements, remove unacceptable wordings, make images and texts more accessible, remove colors, etc), automate data entry, and enhance document management process.",
        image: "/img/ai/offer/ai-offer-4.webp"
    },
    {
        id: 5,
        title: "Voice and facial recognition",
        text: "ELITEX provide robust AI software development services for voice and facial recognition systems tailored to enhance security and user interaction. Voice recognition solutions by ELITEX enable users to control devices and access information hands-free, improving accessibility and convenience. Similarly, our facial recognition technology offers secure identity verification, making it an excellent choice for security systems, personalized customer service, and user authentication.",
        image: "/img/ai/offer/ai-offer-5.webp"
    },
    {
        id: 6,
        title: "Ethical AI software development services",
        text: "At the same time, ELITEX are committed to developing AI solutions that are not only effective but also ethically sound. Our ethical AI service focuses on transparency, fairness, and accountability in AI development. Whether you are looking for secured yet advanced algorithms of biometric authentication, legal compliance, or just prevention of risks, we have solutions that will fit your specific project requirements yet ensure that AI solutions are secure, explainable, and equitable.",
        image: "/img/ai/offer/ai-offer-6.webp"
    },
    {
        id: 7,
        title: "Recommendation systems",
        text: "ELITEX design and implement recommendation engines that enhance customer experiences by personalizing content and product suggestions. We use sophisticated algorithms and some of our machine-learning techniques to analyze user behavior, preferences, and interactions to deliver highly accurate recommendations. Whether for e-commerce, media streaming, or content platforms, our systems can help you increase both user engagement and satisfaction by providing timely and relevant suggestions.",
        image: "/img/ai/offer/ai-offer-7.webp"
    },
    {
        id: 8,
        title: "AI-based fraud detection",
        text: "ELITEX offer support in developing advanced fraud detection systems using AI to help businesses safeguard their operations. The solutions we provide analyze transaction patterns, customer behavior, and other relevant data in real-time in order to identify and alert potential fraudulent activities. We know how AI can help minimize financial losses and enhance security for both sides, the company and their customers.",
        image: "/img/ai/offer/ai-offer-8.webp"
    },
    {
        id: 9,
        title: "AI consulting services",
        text: "ELITEX provide expert guidance on adopting and integrating AI technologies into your business operations. We offer strategic advice tailored to your specific industry and technology needs, from initial concept to full implementation. Our consultants are experienced in identifying opportunities for AI to add value, optimize existing AI systems, and ensure your team's overall readiness for AI adoption. We will help you navigate all the intricacies of AI technology to feel the difference AI can make.",
        image: "/img/ai/offer/ai-offer-9.webp"
    },
];


// ProjectConsultation

const aiProjectText = "Get Started on Your Project - Schedule a Consultation Today";

// Process

const aiProcessTitle = "Our AI Development Process";
const aiProcessText = "Developing an AI solution is a systematic process that requires thorough planning to ensure its success. Typically, the AI development services lifecycle looks like this:";

const aiProcessContent = [
    {
        id: 1,
        header: "Analysis and planning",
        text: "We start by understanding your business objectives and defining the scope of the AI solution you are looking for. This phase includes identifying the problem areas, determining desired outcomes, and planning the project timeline and resources required.",
        arrow: true
    },
    {
        id: 2,
        header: "Datasets creation",
        text: "Next, we collect or identify datasets that are relevant to AI for your project. This might involve gathering existing data within your organization or acquiring data from external sources, ensuring it’s both relevant and robust enough to train your models effectively.",
        arrow: true
    },
    {
        id: 3,
        header: "Preprocessing datasets",
        text: "Just before training begins, we preprocess the data to ensure the quality and consistency of all data samples. This involves cleaning the data, handling missing values, and normalizing it to prepare for the model training processes.",
        arrow: false
    },
    {
        id: 4,
        header: "Training models",
        text: "With the data ready, we develop and train models using suitable algorithms. During this stage, the models learn from the data, adjusting their parameters to improve the accuracy and efficiency of the overall AI system.",
        arrow: true
    },
    {
        id: 5,
        header: "Integration into your business model and infrastructure",
        text: "After the models are trained and validated, the next task is to make a smooth transition. We integrate these models into your existing business processes and infrastructure. This step ensures that the artificial intelligence we build operates seamlessly with your systems.",
        arrow: true
    },
    {
        id: 6,
        header: "Maintenance",
        text: "The final stage involves ongoing maintenance and updates to the AI systems to ensure they continue to perform optimally as your business and the external environment evolve. Regular monitoring, adding new data to the model, and system adjustments are what the maintenance looks like.",
        arrow: false
    },
];

// Industries

const aiIndustriesTitle = "Industries We Are Professional In";

const aiIndustriesContent = [
    {
        id: 1,
        title: "Fintech and e-commerce solutions",
        img: "/img/ai/industries/ai-fintech.svg",
        tagName: "h3"
    },
    {
        id: 2,
        title: "Telecom & Cybersecurity",
        img: "/img/ai/industries/ai-shield.svg",
        tagName: "h3"
    },
    {
        id: 3,
        title: "News & Media",
        img: "/img/ai/industries/ai-journalist.svg",
        tagName: "h3"
    },
    {
        id: 4,
        title: "Information Technology",
        img: "/img/ai/industries/ai-report.svg",
        tagName: "h3"
    },
    {
        id: 5,
        title: "Education",
        img: "/img/ai/industries/ai-school.svg",
        tagName: "h3"
    },
    {
        id: 6,
        title: "Dashboards & CRM",
        img: "/img/ai/industries/ai-dashboard.svg",
        tagName: "h3"
    },
    {
        id: 7,
        title: "Real Estate",
        img: "/img/ai/industries/ai-code.svg",
        tagName: "h3"
    }, {
        id: 8,
        title: "Content Management",
        img: "/img/ai/industries/ai-management.svg",
        tagName: "h3"
    },
];

// Why Choose

const aiWhyChooseSubtitle = "ELITEX is a software development company with a rich history and expertise crafting digital solutions across multiple industries and tech domains. Choosing ELITEX as your artificial intelligence development company offers you a partner renowned for its deep expertise in building custom solutions for any scenario. However, that’s not only about the technical expertise. Let’s check what partnering with ELITEX means in practice:";

const aiWhyChooseList = [
    {
        id: 1,
        text: "Honesty and transparent communication as principal values and drivers of the entire collaboration process;"
    },
    {
        id: 2,
        text: "Expertise based on a decade of experience in application development services and a portfolio involving dozens of successful projects (including Fortune 500 & Fortune Global 500 companies);"
    },
    {
        id: 3,
        text: "Zero tolerance for excessive bureaucracy; you have never experienced such a smooth partnership;"
    },
    {
        id: 4,
        text: "The expertise of the development team, 90% of which are middle and senior-level technical specialists with 86 eNPC score;"
    },
    {
        id: 5,
        text: "Long-term partnerships with a typical partnership cycle exceeding two years;"
    },
    {
        id: 6,
        text: "Strong focus on ROI of artificial intelligence solutions we build."
    },
];

// Models
const aiModelsTitle = "AI Models We Work With";
const aiModelsText = "As an experienced AI development company, ELITEX are equipped to work with a broad spectrum of AI models to meet diverse needs and challenges. Here is an overview of some key types of AI models that we specialize in:";

const aiModelsContent = [
    {
        id: 1,
        title: "Supervised learning models",
        text: "These models are trained using labeled data to predict outcomes based on past data. Linear regression, logistics regression, Naive Bayes, KNN, we can build AI solutions of any complexity for you.",
        img: "/img/ai/models/ai-model-1.svg",
        tagName: "h3"
    },
    {
        id: 2,
        title: "Support vector machines",
        text: "SVMs are effective for classification and regression challenges, especially in high-dimensional spaces, making them suitable for gene classification, image classification, and hand-written character recognition.",
        img: "/img/ai/models/ai-model-2.svg",
        tagName: "h3"
    },
    {
        id: 3,
        title: "Unsupervised learning models",
        text: "We build models used to identify patterns and relationships in data without pre-existing labels, these models are excellent for anomaly detection, market basket analysis, and customer clustering tasks.",
        img: "/img/ai/models/ai-model-3.svg",
        tagName: "h3"
    },
    {
        id: 4,
        title: "Deep learning models",
        text: "We leverage deep neural network systems that excel in tasks involving image and speech recognition and natural language processing. Deep learning models are powerful for handling large volumes of data with high levels of complexity.",
        img: "/img/ai/models/ai-model-4.svg",
        tagName: "h3"
    },
];

// Stack

const aiStackTitle = "Our Tech Stack";

const aiStackLogos = [
    {
        id: 1,
        alt: "Python",
        src: "/img/technologiesOthers/webp/python.webp",
        height: "70px",
        explicitHeight: "70",
        explicitWidth: "165",
    },
    {
        id: 2,
        alt: "Scikit learn",
        src: "/img/ai/stack/ai-scikit-learn.svg",
        height: "87px",
        explicitHeight: "87",
        explicitWidth: "160",
    },
    {
        id: 3,
        alt: "Tensor Flow",
        src: "/img/ai/stack/ai-tensor-flow.svg",
        height: "120px",
        explicitHeight: "120",
        explicitWidth: "180",
    }, {
        id: 4,
        alt: "Py Torch",
        src: "/img/ai/stack/ai-py-torch.svg",
        height: "90px",
        explicitHeight: "90",
        explicitWidth: "185",
    },

];

// FAQ

const aiFaqTitle = {
    main: "AI Software Development:",
    strong: "Frequently Asked Questions"
};

const aiFaqList = [
    {
        id: 1,
        name: "What is AI software development?",
        text: "In a nutshell, AI software development involves creating applications that enable machines to perform tasks that would typically require human intelligence. Sometimes, it involves speech recognition, computer vision, text generation, and even decision-making. The process of AI development involves building algorithms, machine learning, and other aspects that allow software developers and data specialists to build and train software systems."
    },
    {
        id: 2,
        name: "How can AI software benefit my business?",
        text: "AI software can automate some routine tasks, enhance and simplify decision-making with predictive analytics, and improve customer interactions with intelligent chatbots/personalized services. However, those are just several particular examples. Generally, implementing AI can lead to significant efficiency improvements, cost savings, and competitive advantages in various business operations."
    },
    {
        id: 3,
        name: "What industries can benefit from custom AI solutions?",
        text: "Nearly all industries can easily gain from custom AI solutions, including healthcare, finance, retail, manufacturing, logistics, and many more. AI can help automate tasks, provide insightful analytics, enhance customer service, and improve product quality and speed to market in these sectors. "
    },
    {
        id: 4,
        name: "How can I choose a good AI software development solutions company?",
        text: "Choosing a good artificial intelligence software company requires cheking track record and strong expertise of the service providers on the one hand, and clear understanding of your business requirements on the other one. "
    },
    {
        id: 5,
        name: "What do ELITEX offer in AI development?",
        text: "ELITEX offer comprehensive list of AI development services that include everything from consulting and concept development to integration of AI into existing infrastructure and building AI-based solutions from scratch. "
    },
    {
        id: 6,
        name: "What engagement models are ELITEX ready to offer in AI development?",
        text: "As an experienced AI software development company, ELITEX offer various engagement models to suit different needs and project scopes, including time and material contracts, staff augmentation models, and dedicated teams. Each model has its own pros and cons, and the choice will largely depend on your specific business objectives. "
    },
];

export {
    aiMainCaptionTitle,
    aiMainCaptionText,
    aiOfferText,
    aiOfferCards,
    aiOfferTitle,
    aiStackLogos,
    aiModelsText,
    aiModelsTitle,
    aiModelsContent,
    aiProcessText,
    aiIndustriesTitle,
    aiProcessTitle,
    aiStackTitle,
    aiProjectText,
    aiWhyChooseList,
    aiWhyChooseSubtitle,
    aiProcessContent,
    aiIndustriesContent,
    aiFaqList,
    aiFaqTitle
}