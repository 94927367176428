import React from 'react';
import './MobileDropDownTrigger.css';

export const MobileDropDownTrigger = ({clicked, link, triggerClass}) => {
  return (
    <div className={`elx-mobile-dropdown-trigger ${triggerClass}`} onClick={() => clicked(link)}>
      <svg viewBox="0 0 256 256"><path d="M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z"/></svg>
    </div>
  )
};
