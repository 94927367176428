export const homeWeOfferTitle = "What Do We Offer";

export const homeWeOfferText = "ELITEX is a software development services company that uses innovative technologies and the latest tech trends to deliver cutting-edge solutions to clients across multiple industries. Our experienced team works closely with clients to understand their unique business goals and develop customized software to meet their specific needs. With a focus on exceptional customer service and user-friendly cutting-edge software, ELITEX is a trusted partner for businesses looking to optimize their goals through advanced technology solutions."

export const homeWeOfferList = [
    {
        id: 1,
        title: "Custom software development",
        text: "Custom software service provides end-to-end solutions tailored to specific project requirements, from ideation to deployment and maintenance. We specialize in creating bespoke software solutions that help companies streamline their business processes and achieve their objectives. We cover the entire process of development: requirements gathering, as well as project vision, design, development, testing, support, and other stages required for the project's success.",
        image: "/img/home/whatDoWeOffer/home-offer_1.webp",
        link: "/custom-software-development"
    },
    {
        id: 2,
        title: "UI/UX development",
        text: "We provide visually appealing and intuitive user interfaces that enhance user experience and engagement. We create, rethink, and improve designs, focusing on user-centered solutions to make designs that meet our clients and their end-users unique needs.",
        image: "/img/home/whatDoWeOffer/home-offer_2.webp",
        link: "/ui-development"
    },
    {
        id: 3,
        title: "JavaScript development",
        text: "ELITEX has a vast experience in JavaScript development, providing robust and scalable digital solutions for web and mobile apps. Our team of developers leverages the power of JavaScript to create dynamic and interactive user interfaces, as well as efficient backend development.",
        image: "/img/home/whatDoWeOffer/home-offer_3.webp",
        link: "/javascript-development"
    },
    {
        id: 4,
        title: "Quality assurance",
        text: "Quality assurance (QA), along with quality control (QC), is an essential development process step. We provide rigorous testing to ensure the reliability of our software solution. Our team uses various testing methods and tools to eliminate bugs and vulnerabilities and ensure that the final product meets both functional and non-functional requirements and expectations.",
        image: "/img/home/whatDoWeOffer/home-offer_4.webp",
        link: "/software-qa-testing-services"
    },
    {
        id: 5,
        title: "Dedicated teams",
        text: "As an experienced outsourcing software development company, we can assemble a reliable dedicated team of development professionals who will seamlessly integrate with your existing team and work collaboratively to achieve your project goals.",
        image: "/img/home/whatDoWeOffer/home-offer_5.webp",
        link: "/dedicated-software-development-team"
    },
    {
        id: 6,
        title: "Web development",
        text: "ELITEX provides comprehensive solutions for those looking to establish a solid online presence. Our team has the expertise and experience necessary to deliver high-quality web applications. Focusing on digital experience and performance optimization, we build scalable and reliable websites and applications that help you achieve goals and reach the target audience.",
        image: "/img/home/whatDoWeOffer/home-offer_6.webp",
        link: "/web-development-service"
    },
]