import React from 'react';

export const ImageIndustry = ({image, name, srcSet}) => (
  <div className="elx-industries-grid-item">
    <img src={image} alt={name} srcSet={srcSet} loading="lazy" />
      <h4 className="elx-industries-grid-caption">
        <strong>{name}</strong>
      </h4>
  </div>
);
