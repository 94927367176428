export const careerPhotos = [
  {
    id: 1,
    path: '/img/career/office/resized/office_6_large.webp',
    srcSetPath: '/img/career/office/resized/office_6_small.webp 600w, /img/career/office/resized/office_6_medium.webp 1100w, /img/career/office/resized/office_6_large.webp 1400w'
  },
  {
    id: 2,
    path: '/img/career/office/resized/office_7_large.webp',
    srcSetPath: '/img/career/office/resized/office_7_small.webp 600w, /img/career/office/resized/office_7_medium.webp 1100w, /img/career/office/resized/office_7_large.webp 1500w,'
  },
  {
    id: 3,
    path: '/img/career/office/resized/office_8_large.webp',
    srcSetPath: '/img/career/office/resized/office_8_small.webp 600w, /img/career/office/resized/office_8_medium.webp 1100w, /img/career/office/resized/office_8_large.webp 1400w'
  },
  {
    id: 4,
    path: '/img/career/office/resized/office_5_large.webp',
    srcSetPath: '/img/career/office/resized/office_5_small.webp 600w, /img/career/office/resized/office_5_medium.webp 1100w, /img/career/office/resized/office_5_large.webp 1400w'
  },
  {
    id: 5,
    path: '/img/career/office/resized/office_9_large.webp',
    srcSetPath: '/img/career/office/resized/office_9_small.webp 600w, /img/career/office/resized/office_9_medium.webp 1100w, /img/career/office/resized/office_9_large.webp 1400w'
  },
];
