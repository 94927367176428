export const navigationSubMenu = {
    services: [
        {
            id: 1,
            link: '/front-end-development',
            name: 'Front-End Development'
        },
        {
            id: 2,
            link: '/ui-development',
            name: 'UI/UX Development'
        },
        {
            id: 3,
            link: '/dedicated-software-development-team',
            name: 'Dedicated Development Teams'
        },
        {
            id: 4,
            link: '/web-development-service',
            name: 'Custom Web Development'
        },
        {
            id: 5,
            link: '/web-app-development',
            name: 'Web Application Development'
        },
        {
            id: 6,
            link: '/custom-software-development',
            name: 'Custom Software Development'
        },
        {
            id: 7,
            link: '/software-qa-testing-services',
            name: 'Quality Assurance'
        },
        {
            id: 8,
            link: '/devops',
            name: 'DevOps'
        },
        {
            id: 9,
            link: '/artificial-intelligence',
            name: 'Artificial Intelligence'
        },
    ],
    technologies: [
        {
            id: 1,
            link: '/javascript-development',
            name: 'JavaScript Development'
        },
        {
            id: 2,
            link: '/react-technology',
            name: 'React',
        },
        {
            id: 3,
            link: '/node-js-technology',
            name: 'Node.js',
        },
        {
            id: 4,
            link: '/angular-technology',
            name: 'Angular',
        },
        {
            id: 5,
            link: '/python-technology',
            name: 'Python',
        },
        {
            id: 6,
            link: '/dotnet-technology',
            name: '.NET Development',
        }
    ],
    company: [
        {
            id: 1,
            link: '/it-outsourcing-company-ukraine',
            name: 'About us'
        },
        {
            id: 2,
            link: '/career',
            name: 'Career'
        }
    ]
};
