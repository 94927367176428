import React, {Fragment, useEffect} from 'react';
import "./Ai.css";
import HelmetSetter from "../../components/Helmet/Helmet";
import {aiFaqStructuredData, breadcrumbStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    LogosList,
    PageBody,
    QaWhatWeDo,
    ScrollDownBtn,
    Spacer,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import BlueLine from "../../components/BlueLine/BlueLine";
import {
    customersLogos,
    homeClientsSliderHeader,
    testimonialItems,
    aiFaqList, aiFaqTitle,
    aiIndustriesContent,
    aiIndustriesTitle,
    aiMainCaptionText,
    aiMainCaptionTitle,
    aiModelsContent,
    aiModelsText,
    aiModelsTitle,
    aiOfferCards,
    aiOfferText,
    aiOfferTitle,
    aiProcessContent,
    aiProcessText,
    aiProcessTitle,
    aiProjectText,
    aiStackLogos,
    aiStackTitle,
    aiWhyChooseList,
    aiWhyChooseSubtitle
} from "../../mocks";
import WhatDoWeOffer from "../../components/WhatDoWeOffer/WhatDoWeOffer";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import ProcessStages from "../../components/ProcessStages/ProcessStages";
import {portfolioItem} from "../../utils";
import UiWhyChooseElitex from "../../components/UiWhyChooseElitex/UiWhyChooseElitex";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import Faq from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";

export const Ai = () => {

    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <Fragment>
            <HelmetSetter
                title="AI Development Services | ELITEX"
                description="Meet ELITEX, an artificial intelligence software company beyond your expectations! Check out our AI development services that make a difference for businesses."
                ogImg="/ai/ai-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "DevOps",
                    item2: "https://elitex.systems/artificial-intelligence"
                })}
                faqStructuredData={aiFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img ai">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={aiMainCaptionTitle} text={aiMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-ai">
                <Wrapper type="medium">
                    <Spacer type='big'/>
                    <h2 className='main-title'><strong>Our clients and partners </strong></h2>
                    <LogosList list={customersLogos} redirect/>
                    <Spacer type='big'/>
                    <h2 className="main-title">{aiOfferTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{aiOfferText}</p>
                    <Spacer type="small"/>
                    <WhatDoWeOffer content={aiOfferCards}/>
                    <Spacer type='big'/>
                </Wrapper>
                <ProjectConsultation text={aiProjectText}/>
                <Wrapper type="medium" className="ai">
                    <Spacer type='big'/>
                    <h2 className="main-title">{aiProcessTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle left">{aiProcessText}</p>
                    <Spacer type="small"/>
                    <ProcessStages content={aiProcessContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{aiIndustriesTitle}</h2>
                    <Spacer type="small"/>
                    <QaWhatWeDo list={aiIndustriesContent}/>
                </Wrapper>
                <Spacer type="big"/>
                <h2 className="main-title" id="portfolio-grid">Check Out Our Case Studies</h2>
                <Spacer type="small"/>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('postCMS', true),
                    portfolioItem('imagex', false),
                    portfolioItem('peerwith', true),
                ]}/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title"><strong>Why Choose ELITEX </strong> as an AI Development Company?</h2>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{aiWhyChooseSubtitle}</p>
                    <Spacer type="small"/>
                    <UiWhyChooseElitex list={aiWhyChooseList}/>
                    <Spacer type="small"/>
                    <h2 className="main-title">{aiModelsTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{aiModelsText}</p>
                    <Spacer type="small"/>
                    <QaWhatWeDo list={aiModelsContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{aiStackTitle}</h2>
                    <Spacer type="small"/>
                    <LogosList list={aiStackLogos} tech/>
                    <Spacer type="big"/>
                </Wrapper>
                <div id="testimonial-slider">
                    <SliderMaker
                        clientsSliderHeader={homeClientsSliderHeader}
                        dark
                        sliderItems={testimonialItems}
                        SliderItem={Feedback}
                        speed={15000}
                        dots/>
                </div>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <Faq content={aiFaqList} title={aiFaqTitle}/>
                </Wrapper>
            </PageBody>
            <Spacer type="big"/>
            <div className="case-study-grey-2">
                <ContactForm/>
            </div>
        </Fragment>
    );
};