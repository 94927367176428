export const careerBenefits = [
  {
    id: 1,
    img: '/img/career/icons/benefit_1.svg',
    text: 'Modern working place (27″ iMac or MacBook)'
  },
  {
    id: 2,
    img: '/img/career/icons/benefit_2.svg',
    text: 'Flexible working hours and possibility of partly remote work'
  },
  {
    id: 3,
    img: '/img/career/icons/benefit_3.svg',
    text: 'Regular performance reviews'
  },
  {
    id: 4,
    img: '/img/career/icons/benefit_4.svg',
    text: '20 working days of paid vacation and 10 working days of paid sick-leaves'
  },
  {
    id: 5,
    img: '/img/career/icons/benefit_5.svg',
    text: 'Additional vacation days for special lifetime events'
  },
  {
    id: 6,
    img: '/img/career/icons/benefit_6.svg',
    text: 'Medical insurance and maternity leave policy'
  },
  {
    id: 7,
    img: '/img/career/icons/benefit_7.svg',
    text: 'Free English classes'
  },
  {
    id: 8,
    img: '/img/career/icons/benefit_8.svg',
    text: 'Compensation of educational and training programs'
  }
];
