import React from 'react';
import './CompanyValuesItem.css';

export const CompanyValuesItem = ({ name, description, icon }) => (
  <div>
    <h5>
      <i className={`${icon} values-icon`}></i>
      {name}
    </h5>
    <p>{description}</p>
  </div>
);
