// MainCaption
const pythonMainCaptionTitle = "Python Development Company";
const pythonMainCaptionText = "ELITEX creates reliable, fast, personalized solutions using its vast Python development expertise. We provide a wide range of high-quality solutions supported by various frameworks of Python in order to meet your project requirements.";

// What Do We Offer

const pythonWhatOfferTitle = "Python Development Services We Offer"
const pythonWhatOfferTextOne = "As a seasoned development firm, we cater to diverse industry sectors, crafting unique and robust web platforms that drive success. Our experienced developers leverage industry-leading frameworks and modules that ensure the adaptability, scalability, and high security of any complex web application. Whether you need data-driven solutions, scalable enterprise software, or secure databases, our end-to-end Python development services have what to offer.";
const pythonWhatOfferTextTwo = "Being one of the most popular programming languages with a vast ecosystem of libraries, Python offers a wide variety of solutions in many fields and industries. Its versatility extends to data science, machine learning, web services, task automation, image processing, and enterprise applications. Python is a safe and future-proved option for your next software development project.";

const pythonWhatOfferCards = [
    {
        id: 1,
        title: "Web application development",
        text: "We cover all areas of web application development, backend, and automation modules to empower your business with robust, responsive, and feature-rich websites and applications. We create user-friendly applications tailored to your business requirements by leveraging frameworks like Django and Flask. The list of our Python software development services involves a wide range of backend solutions that include building server-side components, handling data storage and retrieval, managing user authentication, and ensuring your app's overall functionality and performance.",
        image: "/img/python/whatDoOffer/pythonWhatDoOffer_1.webp"
    },
    {
        id: 2,
        title: "SaaS development",
        text: "Python-based SaaS is a cutting-edge solution that leverages Python’s power and versatility to deliver cloud-based software to businesses and users on a subscription basis. We offer a vast array of solutions, from data analytics and customer relationship management to project management and collaboration tools (both web and mobile apps). Our SaaS apps development provides scalability and rapid deployment, ensuring you can access feature-rich, cost-effective, and easily accessible software without the hassle of traditional installation and maintenance.",
        image: "/img/python/whatDoOffer/pythonWhatDoOffer_2.webp"
    },
    {
        id: 3,
        title: "Machine learning solutions",
        text: "Machine learning (ML) harnesses the transformative capabilities of ML algorithms and techniques to solve complex problems, make data-driven predictions, and automate decision-making processes. Our experienced team specializes in developing tailored software using robust Python frameworks and extensive libraries, such as PyTorch and TensorFlow. Whether you need predictive models for forecasting, a recommendation system for personalization, natural language processing for text analysis, or computer vision for image recognition, we have what to offer you.",
        image: "/img/python/whatDoOffer/pythonWhatDoOffer_3.webp"
    },
    {
        id: 4,
        title: "Custom software development",
        text: "As a Python app development company, we specialize in tailor-made solutions for every occasion. We create bespoke software that precisely aligns with your business processes, ensuring scalability and easiness in maintenance as needs evolve. Whether you need migration services, a specialized CMS/CRM system, or custom back-end development for your application tool to address a particular challenge, we’re here to turn your vision into a reality.",
        image: "/img/python/whatDoOffer/pythonWhatDoOffer_4.webp"
    },
];

// Benefits

const pythonBenefitsTitle = "Benefits of Python Development";
const pythonBenefitsText = "Python excels in several key areas, such as backend development, automation solutions, app development, machine learning, and Ai development. Moreover, it significantly enhances the development process and the overall productivity of software products. This versatile programming language offers a lot of advantages that extend well beyond the regular back-end development.";
const pythonBenefitsCards = [
    {
        id: 1,
        title: "Simple but powerful",
        text: "Python’s simple syntax rules make it easier for programmers to understand and write better code in less time. Its script formatting standards help developers avoid spending more time creating complex solutions. The language offers efficient code that is easy to read while the coding process still remains intuitive, fostering collaboration among developers.",
        img: "/img/python/benefits/python-benefits_1.svg",
        tagName: "h3"
    },
    {
        id: 2,
        title: "Fast time-to-market",
        text: "Python enables fast software development. Thanks to its simplicity and abundance of libraries, developers can interact and share code faster compared to other programming languages. Python code is simple to execute, debug, and test. All these factors combined are essential for building user-friendly apps.",
        img: "/img/python/benefits/python-benefits_2.svg",
        tagName: "h3"
    },
    {
        id: 3,
        title: "Versatile",
        text: "The list of Python development services is among the most diverse in the software industry. Due to its extensive set of tools and additional utilities, Python is suitable for app projects of all sizes and complexities. Besides traditional web app development, Python is an excellent language for data science, scientific computing, automation processes, and many other domains. Its wide-ranging solutions make it a valuable tool in various fields and industries.",
        img: "/img/python/benefits/python-benefits_3.svg",
        tagName: "h3"
    },
    {
        id: 4,
        title: "Secure",
        text: "Python is frequently chosen as the programming language for large-scale projects because of its built-in security features and a strong community that actively addresses security concerns. It’s a trusted language for developing secure applications backed by tech giants such as Facebook, Amazon, Google, and Microsoft.",
        img: "/img/python/benefits/python-benefits_4.svg",
        tagName: "h3"
    },
    {
        id: 5,
        title: "Large selection of frameworks",
        text: "Python comes with a large number of pre-built libraries and frameworks that are available for free. Also, due to its strong community, a rich ecosystem of third-party packages can be found. Such an ecosystem accelerates robust software development by providing pre-built modules for common tasks, reducing the need to reinvent the wheel.",
        img: "/img/python/benefits/python-benefits_5.svg",
        tagName: "h3"
    },
    {
        id: 6,
        title: "Cost-effective",
        text: "Python development services are renowned for their cost-effectiveness. It is an open-source language, meaning it’s freely available and doesn’t require licensing fees, reducing the overall project expenditure. Additionally, the language’s vast ecosystem of third-party libraries and frameworks, together with faster development cycles, translates into lower labor costs.",
        img: "/img/python/benefits/python-benefits_6.svg",
        tagName: "h3"
    },
    {
        id: 7,
        title: "Reliable",
        text: "Python has been praised for its durability and stability since its launch in 1991. Amazon, Google, Microsoft, YouTube, Instagram, NASA, and Dropbox trust this robust technology. With its cross-platform compatibility, community support, and wide industry adoption, Python apps will stay in trend for a while, with new versions released on a yearly basis.",
        img: "/img/python/benefits/python-benefits_7.svg",
        tagName: "h3"
    },
    {
        id: 8,
        title: "Integration capabilities",
        text: "The language excels in integration with most languages from the software development technology stack. Python seamlessly blends with other languages, enabling developers to unify it with existing systems. This versatility supports legacy system enhancement, third-party API integration, and cohesive software solution creation. Businesses can leverage Python with their core technologies to effortlessly maximize their investments.",
        img: "/img/python/benefits/python-benefits_8.svg",
        tagName: "h3"
    },
];

// Why Choose

const pythonWhyChooseTitle = {
    strong: "Why Choose ELITEX",
    main: "as a Python Software Development Company?"
};
const pythonWhyChooseTextOne = "When you choose ELITEX as your Python development company, you’re selecting a team of seasoned experts who excel in harnessing the power of Python. Our developers possess an in-depth understanding of the language, backed by a track record of building successful software solutions across various industries. With us, you benefit from a wealth of vast technical expertise, ensuring that your projects are executed with precision and efficiency.";
const pythonWhyChooseTextTwo = "What sets ELITEX apart is our commitment to customization. We don’t offer one-size-fits-all solutions; instead, we tailor our custom Python development services to align perfectly with your business objectives. We put a strong emphasis on back-end development, machine learning, multi-process architecture, and quality assurance. With us as your development partner, you can trust that you will receive feature-rich, robust, secure, and free-from-glitches products that will be delivered on time, on budget, and with a commitment to best development practices and software excellence.";
const pythonWhyChooseTextThree = "Additionally, ELITEX offers flexible pricing models and collaboration options, including dedicated teams to cater to your specific needs. Our transparent project progress tracking ensures you are always in the loop regarding the development stages and milestones, providing you with the visibility and control necessary for the project's success. With ELITEX, you not only access top-tier developers but also experience a partnership that prioritizes your objectives and delivers excellence at every turn.";

// Outsource

const pythonOutsourceTitle = "Outsource Python Development";
const pythonOutsourceTextOne = "Python software development outsourcing is often the most pragmatic approach in diverse scenarios. Tackling a complicated project requires vast technical expertise to ensure high product quality and avoid potential delays. By choosing to outsource Python development services, you can seamlessly augment your team with top-tier experts, ensuring the timely delivery of precisely the right product.";
const pythonOutsourceTextTwo = "Outsourcing Python development to Ukraine offers a cost-effective alternative, circumventing the overhead expenses associated with hiring in-house developers while upholding, if not surpassing, the app development standards. As a prominent Python development company based in Ukraine, ELITEX boasts a reservoir of highly skilled developers readily available to cater to the unique requirements of any project or organization.";

// FAQ

const pythonFaqTitle = {
    main: "Python Development Services",
    strong: "FAQs"
};
const pythonFaqList = [
    {
        id: 1,
        name: "What are the most popular Python web development services?",
        text: "The Python-based web services mostly refer to back-end development based on frameworks such as Django, Flask, FastAPI, and Pyramid."
    },
    {
        id: 2,
        name: "Why is Python so popular?",
        text: "Python’s popularity stems from its simplicity, readability, extensive library support, and versatility. It’s beginner-friendly and well-suited for a wide range of applications. Moreover, Python-driven software solutions are applicable to a vast array of industries and domains, making it one of the most popular programming languages."
    },
    {
        id: 3,
        name: "Is Python a suitable language for mobile app development?",
        text: "Yes, Python can be used for mobile development due to its frameworks, Kivy and BeeWare. However, Python is not a native development language like Swift (iOS) and Kotlin (Android), so its performance can be significantly lower in certain situations."
    },
    {
        id: 4,
        name: "How can I benefit from Python development outsourcing?",
        text: "Python outsourcing can save significant costs, provide access to a global talent pool, and speed up project delivery by leveraging experienced development teams to your project."
    },
    {
        id: 5,
        name: "How do I choose the right Python development partner for my project?",
        text: "Choosing a trusted development partner may be challenging, and the final choice will depend on numerous factors. For instance, if you are looking for a Python web development company, look for their expertise in your project domain (use portfolio, client testimonials on independent resources, and case studies). Also, pay attention to soft skills, such as clear communication and project management approaches."
    },
    {
        id: 6,
        name: "What is the cost structure for Python development projects, and which pricing models are preferred?",
        text: "The cost structure varies based on project complexity and the outsourcing provider. Common pricing models include hourly rates, fixed-price contracts, and dedicated development teams."
    },
];

// Technologies

const pythonTechnologiesLogos = [
    {
        id: 1,
        alt: "JavaScript",
        src: "img/technologies/webp/js.webp",
        height: "78px",
        explicitHeight: "78",
        explicitWidth: "78",
        link: "https://elitex.systems/javascript-development"
    },
    {
        id: 2,
        alt: 'NodeJS',
        src: 'img/technologies/webp/nodeJS.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '127.29',
        link: "https://elitex.systems/node-js-technology"
    },
    {
        id: 3,
        alt: 'Angular',
        src: 'img/technologies/webp/angular.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '160.64',
        link: "https://elitex.systems/angular-technology"
    },
    {
        id: 4,
        alt: 'python',
        src: 'img/technologiesOthers/webp/python.webp',
        height: '58px',
        explicitHeight: '58',
        explicitWidth: '136.6',
        link: "https://elitex.systems/python-technology"
    },
    {
        id: 5,
        alt: 'net',
        src: 'img/technologiesOthers/webp/net.webp',
        height: '58px',
        explicitHeight: '58',
        explicitWidth: '128.59',
        link: "https://elitex.systems/dotnet-technology"
    },
    {
        id: 6,
        alt: 'React',
        src: 'img/technologies/webp/react.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '151.81',
        link: "https://elitex.systems/react-technology"
    },
];

export {
    pythonMainCaptionTitle,
    pythonMainCaptionText,
    pythonWhatOfferTitle,
    pythonWhatOfferTextOne,
    pythonWhatOfferTextTwo,
    pythonWhatOfferCards,
    pythonBenefitsTitle,
    pythonBenefitsText,
    pythonBenefitsCards,
    pythonWhyChooseTitle,
    pythonWhyChooseTextOne,
    pythonWhyChooseTextTwo,
    pythonWhyChooseTextThree,
    pythonOutsourceTitle,
    pythonOutsourceTextTwo,
    pythonOutsourceTextOne,
    pythonFaqTitle,
    pythonFaqList,
    pythonTechnologiesLogos
}