import React, {Fragment} from 'react';
import './DedicatedProcess.css';

const DedicatedProcess = ({content}) => {
    return (
        <div className="ded-process-container">
            {content.map(({id, title, text, arrow}) => (
                <Fragment key={id}>
                    <div className="ded-process-card">
                        <h3 className="main-title"><strong>{title}</strong></h3>
                        <p className="main-subtitle">{text}</p>
                    </div>
                    {arrow && <img src="/img/icons/arrows/blue-right-arrow.svg" alt="arrow"/>}
                </Fragment>
            ))}
        </div>
    );
};

export default DedicatedProcess;