import React, { useEffect, useState } from 'react';
import { delay, hideLoader, showLoader } from '../../utils/loaderHandler.util';
import {
  Hero,
  Wrapper,
  Spacer,
  PageBody,
  CareerWhoWeAre,
  CareerPhoto,
  CareerRecruiters,
  CareerBenefits,
  CareerVacancies,
} from '../../components';
import { careerPhotos } from '../../mocks/careerPhotos.mock';
import HelmetSetter from '../../components/Helmet/Helmet';
import './Career.css';
import {ContactRecruitingForm} from "../ContactRecruitingForm/ContactRecruitingForm";
import {smoothScroll} from "../../utils";

function Career() {
  const [vacancies, setVacancies] = useState([]);
  const hasActiveVacancies = !!vacancies.length;

  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    getVacancies();
    return showLoader();
  }, []);

  const getVacancies = async () => {
    const response = await fetch("/rss");
    const result = await response.json();
    setVacancies(result);
  };

  return (
      <React.Fragment>
        <HelmetSetter
            title="Careers at Lviv IT Company Elitex: Join Our Awesome Team"
            description="ELITEX is a software development company based in Lviv, Ukraine. As we grow, we are always looking for talented software developers, QA engineers, and designers."
            ogImg="career/career.jpg"
        />
        <Hero className="hero-big banner-with-img career">
          <Wrapper id="page-title" className="page-title-career">
            <h1 className="h2 white-text">Join our awesome team!</h1>
            <p className="white-text h4">We are always looking for great talents. Are you one of them?</p>
            { hasActiveVacancies &&
                <button onClick={() => smoothScroll("career-slider")} className="btn btn-filled submit">
                  Check open positions {`(${vacancies.length})`}
                </button>
            }
          </Wrapper>
        </Hero>
        <PageBody className="career-container">
          <Wrapper>
            <CareerWhoWeAre vacancies={vacancies} />
          </Wrapper>
          <Wrapper>
            {hasActiveVacancies && <CareerVacancies vacancies={vacancies} />}
          </Wrapper>
          <Spacer type="medium" />
          <div id="we-care-about-people">
            <Wrapper>
              <h4 className="align-center">
                We care about <strong>our people</strong>
              </h4>
              <Spacer type="mini" />
              <CareerBenefits />
            </Wrapper>
          </div>
          <Spacer type="medium" />
          <CareerRecruiters />
          <Spacer type="medium" />
          <Spacer type="medium" />
          <h3 className="align-center">
            <strong>Our office</strong>
          </h3>
          <Spacer type="medium" />
            <CareerPhoto photosArr={careerPhotos} />
          <Spacer type="medium" />
          <div>
            <ContactRecruitingForm />
          </div>
        </PageBody>
      </React.Fragment>
  );
}

export { Career };
