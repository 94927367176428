import React from 'react';

export const LinkField = ({
                              reference,
                              defaultValue,
                              placeholder,
                              required,
                              onFocus,
                              errorBorder,
                              errorMessage,
                              ...rest
                          }) => {
    let border = errorBorder ? 'invalid' : '';
    let errorMessageNotUrl = errorMessage ? 'errors' : '';

    return (
        <div className="form-row">
            <input
                placeholder={placeholder}
                name='url'
                className={border}
                type="url"
                ref={reference}
                onFocus={onFocus}
                defaultValue={defaultValue}
                {...rest} />
            {required && <span className={errorMessageNotUrl}>Link is required</span>}
            <span className={errorMessageNotUrl}>Should be a valid URL</span>
        </div>
    )
};
