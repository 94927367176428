export const jsdLogos = [
  {
    id: 1,
    path: "/img/jsd/resized/logos/vue_large.webp",
    srcSet: "/img/jsd/resized/logos/vue_small.webp 600w, /img/jsd/resized/logos/vue_large.webp 1280w"
  },
  {
    id: 2,
    path: "/img/jsd/resized/logos/angular_large.webp",
    srcSet: "/img/jsd/resized/logos/angular_small.webp 600w, /img/jsd/resized/logos/angular_large.webp 1280w",
    height: "78px"
  },
  {
    id: 3,
    path: "/img/jsd/resized/logos/react_large.webp",
    srcSet: "/img/jsd/resized/logos/react_small.webp 600w, /img/jsd/resized/logos/react_large.webp 1280w",

  },
  {
    id: 4,
    path: "/img/jsd/resized/logos/redux_large.webp",
    srcSet: "/img/jsd/resized/logos/redux_small.webp 600w, /img/jsd/resized/logos/redux_large.webp 1280w",
  },
  {
    id: 5,
    path: "/img/jsd/resized/logos/node_large.webp",
    srcSet: "/img/jsd/resized/logos/node_small.webp 600w, /img/jsd/resized/logos/node_large.webp 1280w",
  }
];
