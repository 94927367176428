import React from 'react';
import './ProjectConsultation.css';
import {takeFirstStepText} from "../../mocks";
import {smoothScroll} from "../../utils";

const ProjectConsultation = ({text}) => {
    return (
        <div className="project-consultation">
            <h2 className="main-title">{text ? text : takeFirstStepText}</h2>
            <button className="btn-outline-blue" onClick={() => smoothScroll("contact-title")}>Contact Us</button>
        </div>
    );
};

export default ProjectConsultation;