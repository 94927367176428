import React from 'react';
import './TechnicalExpertiseItem.css';
import {Spacer} from '../';

export const TechnicalExpertiseItem = ({name, description, image, srcSetPath}) => (
  <React.Fragment>
    <div className="tech-expertise-container">
      <div className="tech-expertise-txt">
        <h3 className="h5"><strong>{name}</strong></h3>
        <p>{description}</p>
      </div>
      <div className="tech-expertise-img">
        <img
            src={image}
            srcSet={srcSetPath}
            alt={name}
            loading="lazy"
            width="100%"
            height="100%"
        />
      </div>
    </div>
    <Spacer type='small'/>
    <hr className="elx-hr"/>
  </React.Fragment>
);
