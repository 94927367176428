import React from 'react';
import {NavLink} from 'react-router-dom';
// import {navigationSubMenu} from '../../mocks';
import './Nav.css';

export const Nav = ({list}) => (
  <ul>
    {list.map(el => {
      if (el.link === '/blog') {
        return (
          <li key={el.id}>
            <a href="https://elitex.systems/blog/">Blog</a>
          </li>
        )
      }
      else if (el.link === '/web-app-development') {
        return (
          <React.Fragment key={el.id}>
            <li>
              <NavLink
                to={el.link}
                exact>{el.name}
              </NavLink>
            </li>
            {/*{navigationSubMenu.map(item => (*/}
            {/*  <li key={item.id}>*/}
            {/*    <NavLink*/}
            {/*      className='expertise-sublink'*/}
            {/*      to={item.link}*/}
            {/*      exact>{item.name}*/}
            {/*    </NavLink>*/}
            {/*  </li>*/}
            {/*))}*/}
          </React.Fragment>
        )
      }
      return (
        <li key={el.id}>
          <NavLink
            to={el.link}
            exact>{el.name}
          </NavLink>
        </li>
      )
    })}
  </ul>
);
