let intervalId;
const scrollStepInPx = 50;
const delayInMs = 16.66;


const smoothScroll = (elId) => {
   const element = document?.getElementById(elId);
  if(element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollToBottom = () => {
  window.scrollTo({ left: 0, top: document?.body?.scrollHeight, behavior: "smooth" });
};

const scrollStepTop = () => {
  if (window.scrollY === 0) {
    clearInterval(intervalId);
  } else {
    window.scroll(0, window.scrollY - scrollStepInPx);
  }
};

const scrollToTop = () => {
  intervalId = setInterval(scrollStepTop, delayInMs);
};

export const scrollMore = () => {
  setTimeout(() => window.scroll(0, window.scrollY - 70), 10)
};

export { scrollToBottom, scrollToTop, smoothScroll };
