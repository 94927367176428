import React from 'react';
import './ExpertiseWebAppOfferings.css';

export const ExpertiseWebAppOfferings = () => (
    <div className='expertise-focus-container'>
        <div className="expertise-focus-item">
            <ul>
                <li>Custom web and mobile applications</li>
                <li>Development of intuitive interface and personalized UX</li>
                <li>JavaScript-based app front-end creation</li>
                <li>Mobile apps for Android and iOS</li>
                <li>CMS Development</li>
                <li>Processing, developing, and maintaining databases</li>
                <li>Building and supporting cloud apps</li>
                <li>Web apps of any scale and SaaS solutions creation</li>
                <li>Server-side apps empowered by Node.js</li>
            </ul>
        </div>
    </div>
);
