import React, {Fragment} from 'react';
import './TechWhatOfferCards.css';
import BlueLine from "../BlueLine/BlueLine";
import {Spacer} from "../Spacer/Spacer";

const TechWhatOfferCards = ({title, text, noBlueLine, cardsContent}) => {
    return (
        <Fragment>
            {title && <h2 className="main-title">{title.main} <strong>{title.strong}</strong></h2>}
            {!noBlueLine && <BlueLine/>}
            {text && <p className="main-subtitle">{text}</p>}
            <Spacer type="big"/>
            <div className="tech-what-offer-cards">
                {
                    cardsContent.map(({id, title, textList, icon}) => (
                        <div key={id} className="tech-what-offer-card">
                            <div>
                                <div className="tech-what-offer-card-header">
                                    <img
                                        src={icon}
                                        alt="development service icon"
                                        width="50"
                                        height="50"
                                    />
                                    <h3 className="tech-what-offer-card-title">{title}</h3>
                                </div>
                                {textList.map(({id, text}) => (
                                    <p key={id} className="tech-what-offer-card-text">{text}</p>
                                ))}
                            </div>
                        </div>
                    ))
                }
            </div>
        </Fragment>
    );
};

export default TechWhatOfferCards;