import React from 'react';
import Slider from 'react-slick';
import './SliderMaker.css';

export const SliderMaker = ({dark, sliderItems, SliderItem, speed, dots, clientsSliderHeader}) => {
  const settings = {
    infinite: true,
    speed: 800,
    fade: false,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: speed,
    arrows: true,
    dots: dots
  };

  return (
    <div className={dark && 'dark-theme'}>
      <div className='testimonial-wrapper'>
        {clientsSliderHeader?.main ?
            <h2 className='main-title'>{clientsSliderHeader.main} <strong>{clientsSliderHeader.strong}</strong></h2> :
            clientsSliderHeader ?
                <h2 className='main-title'>{clientsSliderHeader}</h2> : ""
        }
        <Slider {...settings}>
          {sliderItems.map(elem => (
              <div key={elem.id}>
              <SliderItem info={elem}/>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
};
