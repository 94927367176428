export const technicalExpertiseList = [{
    name: 'JavaScript / NodeJS stack',
    id: 1,
    description: 'Modern, fast, and powerful - JavaScript and NodeJS are among the trendiest technologies to build applications for both startups and large organizations. Trusted by Google, PayPal, Uber, and many others.',
    image: '/img/stacks/resized/mean_large.webp',
    srcSetPath: '/img/stacks/resized/mean_small.webp 600w, /img/stacks/resized/mean_large.webp 1280w',
}, {
    name: 'Frontend / UI',
    id: 3,
    description: 'The user interface is the first thing your customers see when they browse your application or website. We are convinced that crafting an intuitive, user-friendly, and appealing front-end is essential for your online success.',
    image: '/img/stacks/resized/ui_large.webp',
    srcSetPath: '/img/stacks/resized/ui_small.webp 600w, /img/stacks/resized/ui_large.webp 1280w',
}, {
    name: 'Python, Java, C++ and .NET',
    id: 2,
    description: 'While JavaScript is one of our favorite technologies, we leverage other powerful frameworks to build custom software. We use Python, Java, C++, and .NET technologies for the backend of performing web applications.',
    image: '/img/stacks/resized/backend_large.webp',
    srcSetPath: '/img/stacks/resized/backend_small.webp 600w, /img/stacks/resized/backend_large.webp 1280w',
}, {
    name: 'Databases, APIs, platforms, etc.',
    id: 4,
    description: 'Our development team leverages bespoke project SQL and NoSQL databases, cloud platforms, payment gateways, and more.',
    image: '/img/stacks/resized/database_large.webp',
    srcSetPath: '/img/stacks/resized/database_small.webp 600w, /img/stacks/resized/database_large.webp 1280w',
}];
