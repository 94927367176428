import React, { useEffect } from 'react';
import { Status } from '../../containers';
import { Hero, Wrapper } from '../../components';
import { showLoader, hideLoader, delay } from '../../utils/loaderHandler.util';

const pageStyle = {
  minHeight: 'calc(100vh - 340px)'
};

function ErrorPage404(props) {
  useEffect(() => {
    document.title = 'Page not found 404 | ELITEX';

    setTimeout( hideLoader.bind(this), delay );
    return showLoader();
  }, []);

  return (
    <Status code={404}>
      <React.Fragment>
        <Hero className="hero-auto" style={pageStyle}>
          <Wrapper id="page-title">
            <h1 className="h1">404</h1>
            <h4 className="title-alt">That’s an error.</h4>
            <h6 className="title-alt">The requested URL
              <strong> {props.location.pathname} </strong>
              was not found on this server. <br/>That’s all we know.</h6>
          </Wrapper>
        </Hero>
      </React.Fragment>
    </Status>
  );
}

export {ErrorPage404};
