const  homeClientsSliderHeader = {
    main: "What Our Clients Say About",
    strong: "ELITEX"
};

const uiClientsSliderHeader = "Our Clients Say"

export {
    homeClientsSliderHeader,
    uiClientsSliderHeader
}