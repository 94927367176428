export const checkCurrentAddress = (currentLink) => links.some(link => link === currentLink);

const links = [
  '/',
  '/it-outsourcing-company-ukraine',
  '/web-app-development',
  '/software-qa-testing-services',
  '/software-qa-testing-services/qa-workshops',
  '/javascript-development',
  '/react-technology',
  '/angular-technology',
  '/node-js-technology',
  '/python-technology',
  '/dotnet-technology',
  '/dedicated-software-development-team',
  '/web-development-service',
  '/ui-development',
  '/custom-software-development',
  '/front-end-development',
  '/contact',
  '/career',
  '/case-studies',
  '/case-studies/kallik',
  '/case-studies/peerwith',
  '/case-studies/property-management-and-hotel-reservation-system',
  '/case-studies/imagex',
  '/case-studies/oa-switchboard',
  '/case-studies/postcms',
  '/case-studies/salsify',
  '/case-studies/exterity',
  '/case-studies/zippia',
  '/case-studies/watstock',
  '/case-studies/stm',
  '/case-studies/felix',
  '/case-studies/model-master',
  '/case-studies/smartrr',
  '/devops',
  '/artificial-intelligence'
];
