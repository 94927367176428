import React from 'react';
import './CaseStudyEmail.css';
import {EmailField} from '../../components';
import {isFieldNotEmpty, isEmailCorrect} from '../../utils/formValidator.utils';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CaseStudyEmail extends React.Component {
  email = React.createRef();
  form = React.createRef();

  state = {
    form: {
      email: {
        isValue: false,
        isEmail: false,
        empty: false,
        notEmail: false
      },
    },
    emailValue: ''
  };

  checkFields = () => {
    let form = {...this.state.form};
    form.email.isValue = isFieldNotEmpty(this.email.current.value);
    form.email.isEmail = isEmailCorrect(this.email.current.value);
    this.setState({form: form});
    return form.email.isValue && form.email.isEmail
  };

  indicateFields = () => {
    let form = {...this.state.form};
    form.email.empty = !isFieldNotEmpty(this.email.current.value);
    form.email.notEmail = !isEmailCorrect(this.email.current.value);
    this.setState({form: form});
  };

  resetForm = () => this.email.current.value = '';

  updateForm = () => this.setState({emailValue: this.email.current.value});

  onSubmitHandler = () => this.checkFields() ? this.submitForm() : this.indicateFields();

  onFocusHandler = (element) => {
    let form = {...this.state.form};
    if (element === 'email') form[element].notEmail = false;
    form[element].empty = false;
    this.setState({form: form});
  };

  submitForm = () => {
    fetch('/send-casestudy-email', {
      method: 'POST',
      body: new FormData(this.form.current)
    });
    toast('Thanks! We received your information.');
    this.resetForm();
  };

  render() {
    return (
      <div className='portfolio-email'>
        <span className='h4'>Check <strong>our portfolio</strong></span>
        <p>Would you like to look at other cases?</p>
        <p>We will send our deck.</p>
        <form onChange={this.updateForm} ref={this.form}>
          <EmailField
            reference={this.email}
            defaultValue={this.state.emailValue}
            onFocus={this.onFocusHandler.bind(this, 'email')}
            errorBorder={this.state.form.email.empty}
            errorMessage={this.state.form.email.empty}
            errorMessage2={this.state.form.email.notEmail && !this.state.form.email.empty}/>
          <div className="form-row-submit">
            <input
              type="button"
              className="btn btn-default submit portfolio-email-submit"
              value="Send"
              onClick={this.onSubmitHandler}/>
          </div>
        </form>
      </div>
    )
  }
}

export {CaseStudyEmail};
