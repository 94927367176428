export const companyValues = [{
  id: 1,
  name: 'Our values',
  data: [
    {
      name: 'Professionalism',
      icon: 'far fa-lightbulb',
      description: 'We are professionals in what we do. You can be sure that your project is in good hands.'
    },
    {
      name: 'Honesty',
      icon: 'far fa-thumbs-up',
      description: 'We build our cooperation based on truth and honesty. You can always trust us.'
    },
    {
      name: 'Responsibility',
      icon: 'far fa-calendar-check',
      description: 'We take responsibility for our tasks. We do our best to meet your goals.'
    }
  ]
}];
