import React from 'react';
import {Link} from "react-router-dom";
import './Logo.css';
import LazyLoad from "react-lazyload";

export const Logo = ({onClose}) => (
    <div className='elx-logo'>
        <Link to={'/'} onClick={onClose}>
            <LazyLoad>
                <img
                    src="/img/logo/newLogos/logo-black.svg"
                    alt="Logo"
                    className="dark"
                    width="180"
                    height="60"
                />
                <img
                    src="/img/logo/newLogos/logo-white.svg"
                    alt="Logo"
                    className="white"
                    width="180"
                    height="60"
                />
            </LazyLoad>
        </Link>
    </div>
);
