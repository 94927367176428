import React from 'react';
import './CareerBenefits.css';
import {careerBenefits} from '../../mocks';
import LazyLoad from "react-lazyload";

export const CareerBenefits = () => (
  <div className='career-benefits-container'>
    {careerBenefits.map(item => (
      <LazyLoad className="career-benefits-item" key={item.id}>
        <img
            src={item.img}
            alt="img"
            loading="lazy"
            width="50"
            height="50"
        />
        <p>{item.text}</p>
      </LazyLoad>
    ))}
  </div>
);
