import React, {useEffect} from 'react';
import './Imagex.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, CaseStudyFeedback, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {portfolioItem} from "../../utils";
import LazyLoad from "react-lazyload";
import {breadcrumbStructuredData} from "../../utils/structuredData";


export const Imagex = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('imagex');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Case Study - Digital media library | ELITEX'
        description='Imagex is a web service that performs as a digital library that is clear and easy to use and manage.'
        ogImg='portfolio/imagex/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'Imagex',
          item3: 'https://elitex.systems/case-studies/imagex'
        })}
      />
      <Hero className="hero-big banner-with-img imagex">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className="white-text h1">{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/imagex/webp/imagex_notebook.webp"
                  srcSet="/img/portfolio/imagex/resized/imagex_notebook_2_small.webp 600w,
                  /img/portfolio/imagex/resized/imagex_notebook_2_medium.webp 1100w,
                 /img/portfolio/imagex/webp/imagex_notebook.webp 1400w"
                  alt="notebook"
                  width="100%"
                  height="100%"
                  />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <img
                src="/img/portfolio/imagex/resized/imagex_logo.webp"
                alt="logo"
                width="170"
                height="37"
            />
            <Spacer type='medium'/>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>Media</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>NodeJS, Sails.js, MongoDB, AngularJS, Twitter Bootstrap, HTML5, CSS3, AWS</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Full cycle web development</li>
              <li>Front-end development</li>
              <li>Back-end development</li>
              <li>Project management</li>
              <li>Technical consultancy</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/usa_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />NY, United States/Italy
            </p>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>Our client, one of the largest media corporations in Italy – <strong>Il Sole 24 Ore</strong> – owns and
              manages multiple websites. It was inconvenient for editors to search, manage and use different types of media content separately on each website when they worked with multiple sites.</p>
            <p><strong>ImageX</strong> is a digital web library that is clear and easy to use and manage. With ImageX, the user can keep images, videos, audio, and live streams in one place, even if they have multiple websites.</p>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>challenge</strong></h2>
            <p>The customer required a system to store media files of all types, which at the same time could be easily
              and rapidly integrated with customers’ websites or function autonomously.</p>
            <p>Imagine you have an image that could be used by editors on multiple sites, in multiple designs, and seen
              on various devices – from mobiles to large desktop monitors. How and where would you store it properly so
              that it could meet all those needs? Our engineers found a solution.</p>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>solution</strong></h2>
            <p>Together with Il Sole 24 Ore, we agreed to build an R&D team of highly skilled front-end, back-end, and quality assurance engineers that would work under the project manager’s supervision.</p>
            <p>As the system had to be swift and reliable, our engineers advised using the following technologies:</p>
            <ul className='case-study-ul case-study-list-bullet peerwith'>
              <li>Node.js</li>
              <li>Express</li>
              <li>MongoDB</li>
              <li>Elasticsearch</li>
            </ul>
          </div>
        </Wrapper>

        <Spacer type='medium'/>
        {/*<div className='case-study-grey-3'>*/}
        {/*  <Wrapper>*/}
        {/*    <CaseStudyCustomSlider slides={imagexSliders}/>*/}
        {/*  </Wrapper>*/}
        {/*</div>*/}
        <LazyLoad>
          <img
              src="/img/portfolio/imagex/resized/imagex_overview_large.webp"
              srcSet="/img/portfolio/imagex/resized/imagex_overview_small.webp 600w,
                  /img/portfolio/imagex/resized/imagex_overview_medium.webp 1100w,
                 /img/portfolio/imagex/resized/imagex_overview_large.webp 1400w"
              alt="overview"
              width="100%"
              height="100%"
          />
        </LazyLoad>
        {/*sticky zone*/}
        <div className="case-study-relative">
          <div className='case-study-flex-order'>
            <Wrapper>
              <Spacer type='medium'/>
              <div className='case-study-60'>
                <p>This approach also ensured the web service architecture’s requirements were met, making the system scalable, maintainable, and cost-effective.</p>
                <p><strong>How can you use one image in multiple designs and dimensions on multiple sites?</strong> We
                  created a solution.</p>
                <p>1. The system automatically crops and resizes the image to multiple versions and uploads them to the
                  content delivery network.</p>
                <p>2. Editors choose the image and select the required aspect ratio (horizontal, vertical, square, etc.)</p>
                <p>3. ImageX does all the rest automatically, providing the correct image version based on the user’s screen size.</p>
              </div>
              <Spacer type='medium'/>
            </Wrapper>
            <div className='case-study-grey'>
              <Wrapper>
                <div className='case-study-60'>
                  <CaseStudyFeedback
                    cssClass={'feedback-quotes-color'}
                    img='/img/clients/pierpaolo.jpg'
                    name='Pier Paolo Bozzano'
                    position='Head of Software Development | America24'
                    text='From concept to the final product, ELITEX has been a precious resource for our company and the mind behind our content management system. It is about more than just great knowledge in web programming; ELITEX guys take their role further. They offer their ideas and perspective and contribute to making things that were not there before, which is true.'/>
                </div>
              </Wrapper>
            </div>

            <Wrapper>
              <Spacer type='medium'/>
              <div className='case-study-60'>
                <p><strong>We also developed more awesome features:</strong></p>
                <ul className='case-study-ul case-study-list-bullet peerwith'>
                  <li>Users are not required to tag the image when uploading it (the system detects what is shown in the picture and sets the tags automatically)</li>
                  <li>Users can make any corrections manually (crop or tag)</li>
                  <li>Users can also work with video content (upload from a desktop or YouTube/Vimeo)</li>
                  <li>We created a powerful search system. Users can easily find an image of a BMW that has the tags “Germany” and “car” but doesn’t have the tag “BMW.”</li>
                  <li>ImageX can be used as an autonomous system or integrated with third-party websites (using a JS
                    plugin)
                  </li>
                </ul>
                <p>Thanks to a smart queue-based approach, there is no need to wait until the upload process is finished and all the files are processed. The media content, ready for publishing to the library, will appear for review right on the upload page.</p>
              </div>
            </Wrapper>

            <div className="case-study-order-3">
              <Wrapper>
                <div className='case-study-60'>
                  <Spacer type='medium'/>
                  <h2 className='h4'>The <strong>result</strong></h2>
                  <p>As a result, the ImageX system eliminates the issue of storing countless media files of different
                    sizes and types. This new digital media library saves time and relieves the headache of editors and other users, making it possible to manage and share files between different offices and access them from any corner of the world at any time.</p>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>

        <Spacer type='medium'/>
        <LazyLoad>
          <img
              src="/img/portfolio/imagex/resized/imagex_overview_2_large.webp"
              srcSet="/img/portfolio/imagex/resized/imagex_overview_2_small.webp 600w,
                  /img/portfolio/imagex/resized/imagex_overview_2_medium.webp 1100w,
                 /img/portfolio/imagex/resized/imagex_overview_2_large.webp 1400w"
              alt="overview"
              width="100%"
              height="100%"
          />
        </LazyLoad>
      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>
    </React.Fragment>
  )
};
