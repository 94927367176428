import React, {useEffect} from 'react';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {
  Hero,
  Wrapper,
  Spacer,
  PageBody,
  QaWhatWeDo,
  Feedback,
  CaseStudiesGrid,
  WdsTechExpertise,
  WdsWorkflow,
  WdsSlider, ScrollDownBtn
} from "../../components";
import './WebDevelopmentService.css';
import {ContactForm, MainCaption, SliderMaker} from "..";
import {testimonialItems, wdsWhatWeCan} from "../../mocks";
import HelmetSetter from "../../components/Helmet/Helmet";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

function WebDevelopmentService() {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  return (
    <React.Fragment>
      <HelmetSetter
        title='Custom Web Development Services | App Development Company ELITEX'
        description='Get custom web solutions tailored to your business needs with ELITEX. At ELITEX, we use top-notch technologies to bring your vision to life.'
        ogImg='wds/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Custom Web Development',
          item2: 'https://elitex.systems/web-development-service'
        })}
      />
      <Hero className="hero-big banner-with-img wds">
        <Wrapper className="wrapper-medium">
          <MainCaption
              title="Custom Web Development Services"
              text="We create efficient and responsive web solutions"
              contactUsBtn
          />
          <ScrollDownBtn/>
        </Wrapper>
        <Wrapper id="page-title">
          <h1 className="h2 white-text"></h1>
          <p className='white-text h4'></p>
        </Wrapper>
      </Hero>
      <PageBody>
        <Wrapper>
          <Spacer type='medium'/>
          <span className='h4 align-center'>What Can We <strong>Do?</strong></span>
          <Spacer type='mini'/>
          <QaWhatWeDo list={wdsWhatWeCan}/>
        </Wrapper>
        <Spacer type='big'/>
        <h4 className='align-center'>Related <strong>Case Studies</strong></h4>
        <Spacer type='small'/>
        <CaseStudiesGrid list={[
          portfolioItem('model-master', false),
          portfolioItem('switchboard', true),
          portfolioItem('salsify', false),
          portfolioItem('felix',true)
        ]}/>
        <Wrapper>
          <Spacer type='medium'/>
          <span className='h4 align-center'>Technical <strong>Expertise</strong></span>
          <Spacer type='medium' />
          <WdsTechExpertise titleTag="h3"/>
        </Wrapper>
        <Spacer type='big'/>
        <div id="testimonial-slider">
        <SliderMaker dark sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
        </div>
        <Spacer type='medium'/>
        <Wrapper>
          <h4 className='align-center'>Our <strong>workflow</strong></h4>
          <Spacer type='medium'/>
          <WdsWorkflow/>
        </Wrapper>
        <Spacer type='big'/>
        <h4 className='align-center'>Other <strong>services</strong></h4>
        <Spacer type='small'/>
        <WdsSlider />
        <Spacer type='medium'/>
        <ContactForm/>
      </PageBody>
    </React.Fragment>
  );
}

export {WebDevelopmentService};
