import React, {Fragment} from 'react';
import './TechnologiesUse.css';
import {technologiesUseText, technologiesUseTitle} from "../../mocks";
import {LogosList} from "../LogosList/LogosList";
import {technologiesLogos} from "../../mocks";

const TechnologiesUse = () => {
    return (
        <Fragment>
            <h2 className='h4 align-center'>{technologiesUseTitle}</h2>
            <p className="technologies-use-text">{technologiesUseText}</p>
            <div className="technologies-use-img-box">
                <LogosList list={technologiesLogos}/>
            </div>
        </Fragment>
    );
};

export default TechnologiesUse;