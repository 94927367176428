import React from 'react';

export const FileField = ({ onChange, fileName, reference, errorMessage, label = 'Attach your file' }) => {
  let message = errorMessage ? 'errors' : '';

  return (
    <React.Fragment>
      <div className="form-row">
        <label htmlFor="file-upload" className="custom-file-upload">
          <i className="fas fa-paperclip"></i> {label}
        </label>
        <input
          type="file"
          name="myFile"
          id="file-upload"
          onChange={onChange}
          ref={reference} />
        <span className={message}>CV is required</span>
      </div>
      <span>{fileName}</span>
    </React.Fragment>
  )
};

