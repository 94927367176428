export const imageIndustriesList = [
  {
    id: 1,
    name: "News & Media",
    image: "/img/industries/resized/news_large.webp",
    srcSet: "/img/industries/resized/news_small.webp 600w, /img/industries/resized/news_large.webp 1080w, /img/industries/resized/news_large.webp 1450w"
  },
  {
    id: 2,
    name: "FinTech",
    image: "/img/industries/resized/fintech_large.webp",
    srcSet: "/img/industries/resized/fintech_small.webp 600w, /img/industries/resized/fintech_large.webp 1080w, /img/industries/resized/fintech_large.webp 1450w"
  },
  {
    id: 3,
    name: "Dashboards & CRM",
    image: "/img/industries/resized/crm_large.webp",
    srcSet: "/img/industries/resized/crm_small.webp 600w, /img/industries/resized/crm_large.webp 1080w, /img/industries/resized/crm_large.webp 1450w"
  },
  {
    id: 4,
    name: "Education",
    image: "/img/industries/resized/education_large.webp",
    srcSet: "/img/industries/resized/education_small.webp 600w, /img/industries/resized/education_large.webp 1080w, /img/industries/resized/education_large.webp 1450w"
  },
  {
    id: 5,
    name: "Telecom & Security",
    image: "/img/industries/resized/telecom_large.webp",
    srcSet: "/img/industries/resized/telecom_small.webp, /img/industries/resized/telecom_large.webp 1080w, /img/industries/resized/telecom_large.webp 1450w"
  },
  {
    id: 6,
    name: "Information Technology",
    image: "/img/industries/resized/hightech_large.webp",
    srcSet: "/img/industries/resized/hightech_small.webp 600w, /img/industries/resized/hightech_large.webp 1080w, /img/industries/resized/hightech_large.webp 1450w"
  },
  {
    id: 7,
    name: "Real Estate",
    image: "/img/industries/resized/realestate_large.webp",
    srcSet: "/img/industries/resized/realestate_small.webp 600w, /img/industries/resized/realestate_large.webp 1080w, /img/industries/resized/realestate_large.webp 1450w"
  },
  {
    id: 8,
    name: "Content Management",
    image: "/img/industries/resized/contentmanagement_large.webp",
    srcSet: "/img/industries/resized/contentmanagement_small.webp 600w, /img/industries/resized/contentmanagement_large.webp 1080w, /img/industries/resized/contentmanagement_large.webp 1450w"
  }
];
