export const homeLogosWorkWith = [
  {
    id: 1,
    alt: 'python',
    src: 'img/technologiesOthers/webp/python.webp',
    height: '58px',
    explicitHeight: '58',
    explicitWidth: '136.6',
    link:"https://elitex.systems/python-technology"
  },
  {
    id: 2,
    alt: 'net',
    src: 'img/technologiesOthers/webp/net.webp',
    height: '58px',
    explicitHeight: '58',
    explicitWidth: '128.59',
    link: "https://elitex.systems/dotnet-technology"
  }
];
