import React, {useEffect} from 'react';
import {
    Hero,
    PageBody,
    Spacer,
    TechnicalExpertiseItem,
    Wrapper,
    ImageIndustries,
    TrustedLinks,
    ScrollDownBtn
} from '../../components';
import {ContactForm, MainCaption, SliderMaker} from '../';
import {
    technicalExpertiseList,
    imageIndustriesList,
    trustedLinks,
} from '../../mocks';
import './Expertise.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {scrollMore, smoothScroll} from "../../utils";
import HelmetSetter from "../../components/Helmet/Helmet";
import {testimonialItems} from '../../mocks';
import {Feedback, TextExpandable} from '../../components';
import {
    ExpertiseWebAppOfferings,
} from "../../components/ExpertiseWebAppOfferings/ExpertiseWebAppOfferings";
import {breadcrumbStructuredData} from "../../utils/structuredData";

function Expertise() {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <React.Fragment>
            <HelmetSetter
                title='Experts in Web and Mobile App Development Services | ELITEX'
                description='Take a new look at your business with ELITEX web and mobile app development. We stand at the forefront of technology to deliver solutions that drive success.'
                ogImg='expertise/expertise.jpg'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Web Application Development',
                    item2: 'https://elitex.systems/web-app-development'
                })}
            />
            <Hero className="hero-big banner-with-img expertise">
                <Wrapper className="wrapper-medium">
                    <MainCaption
                        title="We Are Web App Development Experts"
                        text="Our developers have experience in the creation of web applications and
                        custom software for Fortune 500 & Fortune Global 500 companies."
                        contactUsBtn
                    />
                    <ScrollDownBtn/>
                </Wrapper>
                <ScrollDownBtn/>
            </Hero>
            <PageBody>
                <Wrapper>
                    <Spacer type='big'/>
                    <h2 className="h3 align-center">ELITEX Web App Development Company</h2>
                    <p className="align-center" style={{fontSize: "20px"}}>
                        Our web app developers can produce bespoke
                        applications and platforms to perfectly meet your
                        business requirements. Working with ELITEX, you will get a quality, high-performance, and secure
                        app, a well-designed product with highly maintainable code.
                    </p>
                    <Spacer type='big'/>
                    <h2 className="h3 align-center">Technical Expertise in Web App Development</h2>
                    <Spacer type='medium'/>
                    {technicalExpertiseList.map(elem => <TechnicalExpertiseItem key={elem.id} {...elem}/>)}
                    <Spacer type='mini'/>
                    <Wrapper type='big' className="align-center">
                        <h5 className="title-alt">Looking for a different tool?
                            <button
                                onClick={() => smoothScroll("contact-title")}
                                className="btn-text">
                                      Ask us
                            </button>
                            , we also work with a number of other technologies.
                        </h5>
                    </Wrapper>
                    <h2 className="h3 align-center mt-20">Trusted by</h2>
                    <TrustedLinks trustedList={trustedLinks}/>
                    <Spacer type='medium'/>
                    <h2 className="h3 align-center">Industries in Which We Developed Bespoke Web Applications</h2>
                    <Spacer type='medium'/>
                </Wrapper>
                <ImageIndustries list={imageIndustriesList}/>
                <Spacer type='small'/>
                <Wrapper type='big' className='align-center'>
                    <h5 className="title-alt">Need a solution for another industry?
                        <button
                            onClick={() => smoothScroll("contact-title")}
                            className="btn-text">
                            Ask us
                        </button>
                        , we can probably cover your needs as well.</h5>
                </Wrapper>
                <Spacer type='big'/>
                <Wrapper>
                    <h2 className="h3 align-center">Web Application Development Services Using Top Technologies</h2>
                    <TextExpandable
                        mainText={<>
                            <p>
                                ELITEX is an app development company that primarily focuses on creating functional,
                                reliable, and scalable web apps. We create unique web applications using the best
                                technology stacks, aligning them with your business goals. Our ultimate objective is to
                                produce high-quality web apps that efficiently use resources and help you achieve
                                maximum growth.
                            </p>
                        </>}
                        hiddenText={<>
                            <p>
                                By crafting unique web apps using cutting-edge technology and tools, ELITEX enhances the
                                digital transformation of expanding businesses. Companies trust us with the engineering
                                of powerful, effective, and simple-to-use web apps, online portals, SaaS solutions, and
                                other corporate and customer-centric software. Our web architects have experience in
                                creating all of the most recent architecture patterns, including microservices and PWA
                            </p>
                            <h2 className='h4 align-center mt-20'>Full-stack App Development Services</h2>
                            <p>
                                To deliver our clients the most robust web application development solutions, ELITEX
                                provides a broad choice of technologies. The tools and technologies mentioned above,
                                along with many more, are just a few examples of our deep experience in web application
                                development. We ensure a seamless user experience through the whole development process,
                                including UI/UX design, front-end development, code quality, and app debugging.
                            </p>
                            <p>
                                ELITEX engineers employ React.js and Angular for front-end solutions. We have experience
                                handling challenging frontend-related projects as a custom app development company. For
                                backend development, our engineers embrace Node.js. It is flexible for creating
                                scalable, high-load web applications and serverless apps. Our app development team is
                                skilled at incorporating real-time functionality, as well as third-party APIs and
                                solutions into web applications.
                            </p>
                            <h3 className='h4 align-center mt-20'>Bespoke web app development offerings</h3>
                            <ExpertiseWebAppOfferings/>
                            <Spacer type='medium'/>
                            <p>
                                Our web development services focus on producing not only visually appealing apps but
                                also
                                solid solutions with extensive features, outstanding usability, scalability, and
                                universal compatibility to increase sales. ELITEX offers top-notch custom application
                                development services by grasping the particular objectives and challenges your company
                                faces.
                            </p>
                            <p>
                                We ensure design, development, testing, support, and maintenance as part of our web
                                application development services. Additionally, you can get assistance from our skilled
                                web app developers with technology consultancy, updating old architectures, and
                                migration from legacy platforms.
                            </p>
                        </>}
                    />
                </Wrapper>
                <Spacer type='small'/>
                <Wrapper type='small'>
                    <h4 className="h3 align-center">Join our <b>happy customers</b> around the world!</h4>
                </Wrapper>
                <Spacer type='big'/>
                <Wrapper type='big' className='elx-customers-map-wrapper'>
                    <picture>
                        <source
                            type="image/webp"
                            srcSet="/img/customers/webp/map_small.webp 600w, /img/customers/webp/map_medium.webp 1111w, /img/customers/webp/map_large.webp 1400w"
                            width="100%"
                            height="100%"
                        />
                        <source
                            type="image/jpg"
                            srcSet="/img/customers/webp/map_small.webp 600w, /img/customers/webp/map_medium.webp 1111w, /img/customers/webp/map_large.webp 1400w"
                            width="100%"
                            height="100%"
                        />
                        <img
                            src="/img/customers/map.png"
                            srcSet="/img/customers/webp/map_small.webp 600w, /img/customers/webp/map_medium.webp 1111w, /img/customers/webp/map_large.webp 1400w"
                            alt="Customers Map"
                            width="100%"
                            height="100%"
                            loading="lazy"
                        />
                    </picture>
                </Wrapper>
                <Spacer type='medium'/>
                <Wrapper type='small'>
                    <p className="h4 align-center">The United States, the United Kingdom, the Netherlands, Germany,
                        Israel, Singapore, Australia - these
                        are just a part of the locations our customers operate in.</p>
                </Wrapper>
                <Spacer type='big'/>
                <div id="testimonial-slider">
                    <SliderMaker dark sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Spacer type='big'/>
                <ContactForm/>
            </PageBody>
        </React.Fragment>
    );
}

export {Expertise};
