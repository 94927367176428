import React, {Fragment} from 'react';
import './FeWhyChooseElitex.css';
import {
    whyChooseTextFive,
    whyChooseTextFour,
    whyChooseTextOne,
    whyChooseTextThree,
    whyChooseTextTwo,
    whyChooseTitle
} from "../../mocks";
import {smoothScroll} from "../../utils";

const FeWhyChooseElitex = () => {
    return (
            <Fragment>
                <h2 className='h4 align-center'>{whyChooseTitle}</h2>
                <p className="fe-development-text why-choose">{whyChooseTextOne}</p>
                <p className="fe-development-text why-choose">{whyChooseTextTwo}</p>
                <p className="fe-development-text why-choose">{whyChooseTextThree}</p>
                <p className="fe-development-text why-choose">{whyChooseTextFour}</p>
                <p className="fe-development-text why-choose">{whyChooseTextFive}</p>
                <button className='fe-development-contact-btn' onClick={smoothScroll.bind(this, 'contact-title')}>
                    Contact Us
                </button>
            </Fragment>
    );
};

export default FeWhyChooseElitex;