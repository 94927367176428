import React from 'react';
import './HomeWhyChooseElitex.css';
import {homeWhyChooseTextOne, homeWhyChooseTextTwo, homeWhyChooseTitle} from "../../mocks/homeWhyChoose.mock";
import {Spacer} from "../Spacer/Spacer";

const HomeWhyChooseElitex = () => {
    return (
        <div>
            <h2 className='h4 align-center'>{homeWhyChooseTitle}</h2>
            <Spacer type="medium"/>
            <p className="home-why-choose-text">{homeWhyChooseTextOne}</p>
            <p className="home-why-choose-text bottom">{homeWhyChooseTextTwo}</p>
        </div>
    );
};

export default HomeWhyChooseElitex;