import {videoStructuredData} from "../utils/structuredData";

const kallikVideoStructuredData = [{
    id:1,
    data: videoStructuredData({
        name: 'Rob Woodall - Elitex & Kallik Experience',
        thumbnailUrl: 'https://img.youtube.com/vi/RVRne623cAs/0.jpg',
        uploadDate: '2020-10-3T14:00:00+00:00'
    })
}];
const exterityVideoStructuredData = [{
    id:1,
    data: videoStructuredData({
        name: 'Exterity IP Video & Digital Signage Solutions',
        thumbnailUrl: 'https://img.youtube.com/vi/8I-Ab8Yaqqw/0.jpg',
        uploadDate: '2019-10-27T14:00:00+00:00'})
}];
const stmVideoStructuredData = [{
    id:1,
    data: videoStructuredData({
        name: 'STM Collaboration Hub for Research Integrity Webinar',
        thumbnailUrl: 'https://img.youtube.com/vi/6yA3phww3m8/maxresdefault.jpg',
        uploadDate: '2022-10-3T14:00:00+00:00'
    })
}];
const oaVideoStructuredData = [
    {
        id: 1,
        data: videoStructuredData({
            name: 'OA Switchboard - a safe space for publication metadata',
            thumbnailUrl: 'https://img.youtube.com/vi/uTBqxh8JyFg/maxresdefault.jpg',
            uploadDate: '2022-05-17T14:00:00+00:00'})
    },
    {
        id: 2,
        data:  videoStructuredData({
            name: 'iteration #6 video 1',
            thumbnailUrl: 'https://img.youtube.com/vi/Oo6_kzgBtIY/0.jpg',
            uploadDate: '2020-08-10T14:00:00+00:00'})
    }
];

export {
    kallikVideoStructuredData,
    exterityVideoStructuredData,
    stmVideoStructuredData,
    oaVideoStructuredData
}
