import React, { useEffect, useState } from "react";

import { Wrapper } from "../Wrapper/Wrapper";
import { CareerVacanciesItem } from "../CareerVacanciesItem/CareerVacanciesItem";

import "./CareerVacancies.css";

const DEFAULT_CATEGORY = { label: "All", key: null };

export const CareerVacancies = ({ vacancies }) => {
  const [vacancyCategories, setVacancyCategories] = useState([]);
  const [filteredVacancies, setFilteredVacancies] = useState(vacancies);
  const [activeTab, setActiveTab] = useState("");

  const getFormattedCategory = category => {
    switch (category) {
      case "HR":
        return "HR";
      case "SUPPORT":
        return "Support";
      case "FULL_STACK":
        return "Full stack";
      case "FRONTEND":
        return "Frontend";
      case "BACKEND":
        return "Backend";
      case "DEV_OPS":
        return "DevOps";
      case "MANAGER":
        return "Manager";
      case "COPYWRITER":
      case "COPY_WRITER":
        return "Copywriter";
      default:
        return category.replace("_", " ").toLowerCase();
    }
  };

  // set vacancy categories
  useEffect(() => {
    const availableCategories = vacancies
      .filter(el => el.category)
      .map(el => el.category)
      .filter((el, index, self) => {
        // filter out duplicates
        //gets all the indexes of duplicate categories except for the first item`s index
        const uniqueIndex = self.map((item, i) => {
          if (item.key === el.key && self.findIndex(c => c.key === el.key) !== i) {
            return i;
          }
          return -1;
        });
        return el.label && !uniqueIndex.includes(index);
      });
    setVacancyCategories([DEFAULT_CATEGORY, ...availableCategories]);
    setActiveTab(DEFAULT_CATEGORY);
  }, [vacancies]);

  // filter visible vacancies list
  useEffect(() => {
    const visibleVacancies = vacancies.filter(el => {
      return el.category && (activeTab.key === null || activeTab.key === el.category.key);
    });
    setFilteredVacancies(visibleVacancies);
  }, [activeTab, vacancies]);

  return (
    <div id='career-slider'>
      <Wrapper>
        <h4 className='align-center'>
          <strong>Vacancies</strong>
        </h4>

        <div className='vacancy-tab-container'>
          {[...vacancyCategories].map(tab => (
            <p
              key={tab.key}
              className={`category-name ${activeTab === tab ? "selected" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {getFormattedCategory(tab.label)}
            </p>
          ))}
        </div>
        <div className='vacancy-items-container'>
          {filteredVacancies.length ? (
            filteredVacancies.map((item, index) => <CareerVacanciesItem key={item.id} index={index} {...item} />)
          ) : (
            <CareerVacanciesItem empty index={1} />
          )}
        </div>
      </Wrapper>
    </div>
  );
};
