import React, {useEffect} from 'react';
import './Zippia.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, CaseStudyFeedback, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {CaseStudyCustomSlider} from "../index";
import {zippiaSliders} from "../../mocks";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const Zippia = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('zippia');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Software development for US based job startup | ELITEX'
        description='Software development outsourcing partnership with Zippia -  job site with state-of-art machine learning techniques'
        ogImg='portfolio/zippia/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'Zippia',
          item3: 'https://elitex.systems/case-studies/zippia'
        })}
      />
      <Hero className="hero-big banner-with-img zippia">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className="white-text h1">{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/zippia/resized/zippia_notebook_2_large.webp"
                  srcSet="/img/portfolio/zippia/resized/zippia_notebook_2_small.webp 600w,
                  /img/portfolio/zippia/resized/zippia_notebook_2_medium.webp 1100w,
                 /img/portfolio/zippia/resized/zippia_notebook_2_large.webp 1400w"
                  alt="notebook"
                  width="100%"
                  height="100%"
              />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <img
                src="/img/portfolio/zippia/resized/zippia_logo.webp"
                alt="logo"
                width="170"
                height="41"
            />
            <Spacer type='medium'/>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>HR tech</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>React, AngularJS, Node.js, Python</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Full-stack web development</li>
              <li>Back-end development</li>
              <li>Front-end development</li>
              <li>UI/UX design</li>
              <li>Team augmentation</li>
              <li>Product extension</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/usa_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />
              California, United States
            </p>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>Zippia provides online recruitment services. The company offers placements in engineering, executive
              management, restaurants, healthcare, sales, construction, and education. </p>
            <p>With the use of AI and ML they visualise a potential career map and provide all the information that
              users might need. Among other things, you can discover:</p>
            <ul className='case-study-ul case-study-list-bullet peerwith'>
              <li>The career paths that pay the highest</li>
              <li>The skills you need to get hired and promoted</li>
              <li>How long do people typically hold each job</li>
              <li>The companies that hire the most</li>
              <li>How your path changes with a particular company or degree</li>
            </ul>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>challenge</strong></h2>
            <p>When Zippia decided to approach ELITEX, they needed help <strong>migrating from AngularJS</strong> to React, as site load speed is essential for their business.</p>
            <p>Moreover, Zippia works with a large amount of data, so they needed help in processing it. Finally, the
              customer required UI/UX design services to improve their users’ experience while continuing to design new
              components and pages.</p>
            <p>Zippia was looking for specialists who could:</p>
            <ul className='case-study-ul case-study-list-bullet peerwith'>
              <li>work together at the same office;</li>
              <li>easily integrate and efficiently collaborate with a multicultural team.</li>
            </ul>
          </div>
        </Wrapper>

        <Spacer type='medium'/>
        <div className='case-study-grey-3'>
          <Wrapper>
            <CaseStudyCustomSlider slides={zippiaSliders}/>
          </Wrapper>
        </div>

        {/*sticky zone*/}
        <div className="case-study-relative">
          <div className='case-study-flex-order'>
            <Wrapper>
              <Spacer type='medium'/>
              <div className='case-study-60'>
                <h2 className='h4'>The <strong>solution</strong></h2>
                <p>We started by involving our full-stack JavaScript engineers, who closely collaborated with the customer’s developers and helped them migrate to React.</p>
                <p>Since our cooperation was successful, Zippia has started sharing increased responsibilities with our
                  team. As a result, the remote team expanded and now includes the following:</p>
                <ul className='case-study-ul case-study-list-bullet peerwith'>
                  <li>Full-stack JavaScript engineers;</li>
                  <li>Data engineer;</li>
                  <li>UI/UX designer.</li>
                </ul>
              </div>
              <Spacer type='medium'/>
            </Wrapper>
            <div className='case-study-grey'>
              <Wrapper>
                <div className='case-study-60'>
                  <CaseStudyFeedback
                    cssClass={'feedback-quotes-color'}
                    img='/img/clients/henry_shao.jpg'
                    name='Henry Shao'
                    position='CEO | Zippia'
                    text='Elitex and Volodymyr have been professional and easy to communicate with. They have been helping us to recruit and retain remote dedicated employees for our tech start-up company in the Bay Area. The people we have hired generally enjoyed working at Elitex, so if you are looking to hire people in Ukraine, I would recommend them.'/>
                </div>
              </Wrapper>
            </div>

            <div className="case-study-order-3">
              <Wrapper>
                <div className='case-study-60'>
                  <Spacer type='medium'/>
                  <h2 className='h4'>The <strong>result</strong></h2>
                  <p>After a long period of cooperation, we built a strong team that continues helping Zippia create a unique job service.</p>
                  <p>A team at ELITEX:</p>
                  <ul className='case-study-ul case-study-list-bullet peerwith'>
                    <li>Closely work with customer’s product managers to discuss new features and provide own
                      suggestions;
                    </li>
                    <li>Releases new features every month;</li>
                    <li>Conducts business trips to Bay Area (California) to clearly understand the client’s needs and
                      better integrate with the in-house team;
                    </li>
                    <li>Continuously works hard to help Zippia achieve its goals.</li>
                  </ul>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>
        <Spacer type='small'/>
      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>
    </React.Fragment>
  )
};
