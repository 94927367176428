export const jsdWhyWeWork = [
    {
        id: 1,
        title: "A wide variety of frameworks and libraries",
        text: "JavaScript offers one of the most robust framework ecosystems. JavaScript frameworks enable fast market entry, high scalability, and efficient application maintenance. Many frameworks and libraries allow JS to be one of the most versatile programming languages.",
        img: "/img/jsd/why/framework.svg",
        tagName: "h3"
    },
    {
        id: 2,
        title: "Versatility and high performance",
        text: "JavaScript helps to run all the components smoothly and thus increases application performance. Our developers use JS with all the latest UI technologies to run your app on almost any device or platform.",
        img: "/img/jsd/why/gear.svg",
        tagName: "h3"
    },
    {
        id: 3,
        title: "Efficient integration process",
        text: "JavaScript is easy to integrate since all modern browsers support it. Multiple add-ons and plugins are available to continuously speed up integration and support JavaScript features.",
        img: "/img/jsd/why/speed.svg",
        tagName: "h3"
    },
    {
        id: 4,
        title: "Large JavaScript developers' community",
        text: "JavaScript is leveraged to empower the front-end and the back-end of millions of websites and web apps. Thus, new maintenance tools are constantly released, while a vast JS developers community shares their knowledge on dedicated platforms.",
        img: "/img/jsd/why/speak.svg",
        tagName: "h3"
    }
];
