import React from 'react';

import { Spacer } from '../Spacer/Spacer';
import { careerRecruitersList } from '../../mocks/careerRecruitersList.mock';

import './CareerRecruiters.css';
import LazyLoad from "react-lazyload";

export const CareerRecruiters = () => {
  return (
    <React.Fragment>
      <h3 className="align-center">Meet our recruiters</h3>
      <Spacer type="small" />
      <div className="recruiters">
        {careerRecruitersList.map((recruiter) => (
          <LazyLoad className="align-center recruiter" key={recruiter.id}>
            <img
              src={recruiter.img}
              alt={recruiter.name}
              className="recruiters-avatar"
              width="185"
              height="185"
            />
            <Spacer type="mini" />
            <h5>
              <strong>{recruiter.name}</strong>
            </h5>
            <div className="recruiters-icons">
              <a
                href={recruiter.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                    src="/img/career/resized/linkedin.webp"
                    alt="linkedin"
                    width="30"
                    height="30"
                />
              </a>
              <a href={recruiter.mail}>
                <img
                    src="/img/career/resized/email.webp"
                    alt="email"
                    width="30"
                    height="30"
                />
              </a>
            </div>
          </LazyLoad>
        ))}
      </div>
    </React.Fragment>
  );
};
