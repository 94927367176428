export const jsdLogos2 = [
  {
    id: 5,
    path: "/img/jsd/resized/logos/ts_large.webp",
    srcSet: "/img/jsd/resized/logos/ts_small.webp 600w, /img/jsd/resized/logos/ts_large.webp 1280w"
  },
  {
    id: 7,
    path: "/img/jsd/resized/logos/js_large.webp",
    srcSet: "/img/jsd/resized/logos/js_small.webp 600w, /img/jsd/logos/js_large.webp 1280w"
  },
  {
    id: 8,
    path: "/img/jsd/resized/logos/firebase_large.webp",
    srcSet: "/img/jsd/resized/logos/firebase_small.webp 600w, /img/jsd/resized/logos/firebase_large.webp 1280w"
  },
];
