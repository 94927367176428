import React from 'react';
import './Footer.css';
import {Nav, Spacer} from '../../components';
import {navigationFooter} from '../../mocks';
import {Link, NavLink} from 'react-router-dom';
import {clickEmailEvent, clickSkypeEvent} from "../../utils/gtmEvents.utils";

export class Footer extends React.Component {
  render() {
    return (
      <footer id='footer' className='text-light'>
        <div className='footer-column'>
          <img id='light-logo2'
               className='light-logo-width'
               src="/img/logo/newLogos/logo-white.svg"
               alt='Logo Light'
               width='170'
               height='65'
          />
          <div className='spacer spacer-small'/>
          <div className='socialmedia-widget'>
            <a href="https://www.linkedin.com/company/elitex/" rel="noopener noreferrer nofollow" target="_blank"  aria-label="Linkedin">
              <svg viewBox="0 0 512 512" className='footer-icon linkedin-icon'>
                <path
                  d="M79.4 42.9c-20.3 3.3-35.7 16.3-40.9 34.5-1.3 4.5-1.6 8.5-1.3 16.7.4 10 .7 11.3 4.2 18.4C50.2 130.3 67 140 89 140c15.6 0 28.2-4.6 38.2-13.9 10.2-9.4 14.8-20.1 14.8-34.4 0-14.4-4.4-25.2-13.9-34.8-9.2-9.2-20.1-13.6-35.1-14.4-4.7-.2-10.8 0-13.6.4zM349.8 173.1c-14.3 2.1-26.4 6.5-36.7 13.3-8.4 5.6-21.2 18.2-26.6 26l-4 5.8-.3-19.6-.2-19.6h-93.5v283H282v-81.4c0-50.5.4-84.4 1-89.3 1.5-10.7 6.3-20.5 14.7-29.4 11.6-12.3 26.5-17.4 43.6-15.1 15.7 2.2 25.6 9.5 32.2 23.5 7.3 15.4 6.9 9.8 7.5 105.2l.5 86 46.8.3 46.7.2v-90.7c0-98.2-.2-101-5.5-121.3-2.9-10.7-7.7-22.5-12.7-30.7-11.6-19.3-32.4-35.5-54.3-42.3-14.6-4.4-37.4-6.2-52.7-3.9zM42 320.5V462h94V179H42v141.5z"/>
              </svg>
            </a>
            <a href="https://www.facebook.com/elitexsystems/" rel="noopener noreferrer nofollow" target="_blank" aria-label="Facebook">
              <svg viewBox="0 0 880 711" className='footer-icon facebook-icon'>
                <path
                  d="M493 42.5c-57.2 8.3-95 38.4-111.5 88.9-7.2 22.1-8.5 36.1-8.5 93.7V271l-48.2.2-48.3.3v56c0 44.1.3 56 1.3 56.1.6.1 22.4.2 48.2.3l47 .1v287h116V384h47.5c44.2 0 47.5-.1 48.3-1.8.5-.9 1.5-6.2 2.1-11.7 1.9-15.2 7.9-62.2 10.1-78.2 1.1-7.9 2-15.9 2-17.8V271H488.9l.3-44.8c.4-42.7.5-45 2.6-51.2 4.5-13.7 11.7-20.8 25.7-25.7l8-2.7 39-.6 38.9-.5.1-50v-50l-5-.7c-22.5-3.2-89.3-4.7-105.5-2.3z"/>
              </svg>
            </a>
            <a href="https://www.instagram.com/elitexsystems/" rel="noopener noreferrer nofollow" target="_blank" aria-label="Instagram">
              <svg viewBox="0 0 54 54" className='footer-icon instagram-icon'>
                <path
                  d="M48.1 26.3c0 4.3 0 7.2-.1 8.8-.2 3.9-1.3 6.9-3.5 9s-5.1 3.3-9 3.5c-1.6.1-4.6.1-8.8.1-4.3 0-7.2 0-8.8-.1-3.9-.2-6.9-1.3-9-3.5-2.1-2.1-3.3-5.1-3.5-9-.1-1.6-.1-4.6-.1-8.8s0-7.2.1-8.8c.2-3.9 1.3-6.9 3.5-9C11 6.4 14 5.2 17.9 5c1.6-.1 4.6-.1 8.8-.1 4.3 0 7.2 0 8.8.1 3.9.2 6.9 1.3 9 3.5s3.3 5.1 3.5 9c0 1.6.1 4.5.1 8.8zM28.8 8.7h-7.1c-.7 0-1.6 0-2.7.1-1.1 0-2.1.1-2.9.3-.8.1-1.5.3-2 .5-.9.4-1.7.9-2.5 1.6-.7.7-1.2 1.5-1.6 2.5-.2.5-.4 1.2-.5 2s-.2 1.7-.3 2.9c0 1.1-.1 2-.1 2.7v10c0 .7 0 1.6.1 2.7 0 1.1.1 2.1.3 2.9s.3 1.5.5 2c.4.9.9 1.7 1.6 2.5.7.7 1.5 1.2 2.5 1.6.5.2 1.2.4 2 .5.8.1 1.7.2 2.9.3s2 .1 2.7.1h10c.7 0 1.6 0 2.7-.1 1.1 0 2.1-.1 2.9-.3.8-.1 1.5-.3 2-.5.9-.4 1.7-.9 2.5-1.6.7-.7 1.2-1.5 1.6-2.5.2-.5.4-1.2.5-2 .1-.8.2-1.7.3-2.9 0-1.1.1-2 .1-2.7v-2.9-2.1-2.1-2.9c0-.7 0-1.6-.1-2.7 0-1.1-.1-2.1-.3-2.9-.1-.8-.3-1.5-.5-2-.4-.9-.9-1.7-1.6-2.5-.7-.7-1.5-1.2-2.5-1.6-.5-.2-1.2-.4-2-.5-.8-.1-1.7-.2-2.9-.3-1.1 0-2-.1-2.7-.1h-2.9zm5.6 9.8c2.1 2.1 3.2 4.7 3.2 7.8s-1.1 5.6-3.2 7.8c-2.1 2.1-4.7 3.2-7.8 3.2-3.1 0-5.6-1.1-7.8-3.2-2.1-2.1-3.2-4.7-3.2-7.8s1.1-5.6 3.2-7.8c2.1-2.1 4.7-3.2 7.8-3.2 3.1 0 5.7 1 7.8 3.2zm-2.7 12.8c1.4-1.4 2.1-3.1 2.1-5s-.7-3.7-2.1-5.1c-1.4-1.4-3.1-2.1-5.1-2.1-2 0-3.7.7-5.1 2.1s-2.1 3.1-2.1 5.1.7 3.7 2.1 5c1.4 1.4 3.1 2.1 5.1 2.1 2 0 3.7-.7 5.1-2.1zM39.9 13c.5.5.8 1.1.8 1.8s-.3 1.3-.8 1.8-1.1.8-1.8.8-1.3-.3-1.8-.8-.8-1.1-.8-1.8.3-1.3.8-1.8 1.1-.8 1.8-.8 1.3.3 1.8.8z"/>
              </svg>
            </a>
            <a href="https://www.youtube.com/channel/UCp2_ONsT8PN0ecatHP2yftw/" rel="noopener noreferrer nofollow" target="_blank" aria-label="Youtube">
              <svg width="28" height="28" viewBox="0 0 24 24" className='footer-icon youtube-icon'>
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/>
              </svg>
            </a>
            <a href="https://github.com/WeAreElitex/" rel="noopener noreferrer nofollow" target="_blank" aria-label="GitHub">
              <svg viewBox="0 0 768 793" className='footer-icon github-icon'>
                <path
                  d="M356 13C217.6 21 100.3 96.6 40.4 216.2 19.9 257.1 8.5 296.1 2.4 346c-2.5 20.4-2.5 67.6-.1 88 2.5 20.4 5.2 35.2 9.2 51.6 27.4 111.5 101 204.1 202.4 254.8 25.3 12.7 46.5 20.6 55.2 20.6 9.3 0 14.2-2.8 18-10.2 1.8-3.5 1.9-6.5 1.9-53.8v-50.2l-2.7.6c-16.8 3.4-49 4-64.3 1.2-30.7-5.5-52.5-19.8-61.1-40.2-5.4-12.9-14.4-30.4-19.7-38.4-6.5-9.8-17.2-20.9-22.9-23.8-9-4.6-18.3-14.4-18.3-19.3 0-3.7 1.8-4.9 9.4-6.2 5.6-.9 7.5-.8 15.2 1.1 23.3 5.7 39.9 18.3 53.5 40.5 17.5 28.4 41.7 42.1 71.4 40.4 10.9-.7 22.1-3.1 33-7.2l7-2.6 2.2-10.1c2.7-12.2 9.9-27.5 17.1-36.4l5-6-18.1-2.3c-31.6-3.9-56-9.4-74.4-17-44.7-18.4-69.1-52.6-77.8-108.6-2.3-14.6-3.2-52.2-1.5-65 2.9-23.4 11.4-41.8 28.5-61.8l6.6-7.8-1.5-4.7c-6.8-20.6-7.9-47-3-71.2 2.2-10.8 7.2-26.6 9-28.4 2.4-2.5 15.3-2.1 26.5.8 17.3 4.4 46.1 18.3 70.7 34.2l9.6 6.2 4.5-1.4c8.4-2.5 27.4-6.3 43.1-8.6 13.7-2 19.4-2.3 48.5-2.3 26.5 0 35.7.4 46.5 1.8 12.8 1.8 35.6 6.2 46.5 9l4.9 1.3 12.3-7.9c14.2-9 41.2-23.2 52.8-27.6 12.6-4.8 22.9-7.1 31.9-7.1 4.5 0 8.6.4 9.2.8.7.4 2.5 5 4.2 10.3 9.4 29.3 10.6 58.3 3.6 85.1-2.9 11.1-3.4 9.3 5.7 19.9 13.9 16 23.2 32.8 27.6 49.6 4.8 18.5 4.3 56-1.2 83.9-11.4 58.8-43.4 92-103.4 107.5-13.5 3.5-34 7.1-54.2 9.5l-14.7 1.7 5.5 6.5c6.5 7.7 13.4 21.5 16.2 32.6 4 15.2 4.4 26.3 3.9 97-.5 73-.5 73.6 4.9 78.8 7.8 7.4 15.7 7.6 34.1.7C643.8 710 732.9 603.8 760 471c9.7-47.6 10.4-102 1.9-149-18-99.5-75.9-187.2-160.9-243.4-22.8-15.1-37.7-23.3-61-33.3C483.4 20.9 418.2 9.4 356 13z"/>
                <path
                  d="M133.6 534.6c-.7 1.7 3.5 6.4 5.8 6.4 4.6 0 6.5-4.9 2.7-7-3-1.5-7.8-1.2-8.5.6zM150.2 547.2c-1.8 1.8-1.5 3.3 1.4 6.7 3.8 4.5 9.4 3.8 9.4-1.1 0-4.6-7.8-8.6-10.8-5.6zM165.1 568.2c-2.3 6.2 6.1 14.4 10.4 10.2 3.6-3.7-1.1-12.4-6.7-12.4-2.1 0-3.1.6-3.7 2.2zM184.2 589.2c-2.4 2.4-1.4 6.4 2.3 9.7 3.8 3.3 5.5 3.7 8.6 2.1 4.8-2.6-.6-13-6.8-13-1.6 0-3.4.5-4.1 1.2zM210.8 606.1c-2.6 1.5-2.3 6.3.5 8.2 3.9 2.5 8.5 3.1 11.2 1.3 3.2-2.1 3.2-5 0-8.1-2.6-2.7-8.4-3.4-11.7-1.4zM272.5 608.5c-3.2 3.1-3.2 6 0 8.1 4.6 3 13.5-.4 13.5-5.1 0-5.4-9.1-7.5-13.5-3zM243.5 611.2c-4 2.3-4.5 4.8-1.5 7.8 4.1 4.1 14.9 1.4 15-3.8 0-.6-.9-2.1-2-3.2-2.2-2.2-8.2-2.7-11.5-.8z"/>
              </svg>
            </a>
            <a href="https://clutch.co/profile/elitex" rel="noopener noreferrer nofollow" target="_blank" aria-label="Clutch">
              <svg viewBox="0 0 70.2 79.3" className='footer-icon clutch-icon'>
                <path
                  d="M54.8 56.2a22.35 22.35 0 01-15.1 5.6c-12.8 0-22.2-9.4-22.2-22.3s9.1-21.9 22.2-21.9a22.9 22.9 0 0115.2 5.5l2.8 2.4 12.4-12.4-3.1-2.9A40.13 40.13 0 0039.7 0C16.7 0 0 16.6 0 39.4s17.1 39.9 39.7 39.9a40.14 40.14 0 0027.5-10.4l3-2.8-12.6-12.4z"/>
                <circle cx="39.1" cy="39.6" r="13.3" fill="#5e5e5e" className='clutch-icon-inside'/>
              </svg>
            </a>
          </div>
          <Spacer type="mini"/>
          <div className="elx-terms-and-conditions">
            <NavLink to="/privacy-policy" activeClassName="active-nav">Privacy policy</NavLink>
            <NavLink to="/terms-and-conditions" activeClassName="active-nav">Terms and conditions</NavLink>
          </div>
        </div>
        <div className='footer-column'>
          <div className='widget'>
            <span className='widget-title title-alt'>Site Map</span>
            <Nav list={navigationFooter}/>
          </div>
        </div>
        <div className='footer-column'>
          <div className='widget'>
            <span className='widget-title title-alt'>UK</span>
            <p>3 Gower Street<br/>London, WC1E 6HA<br/>+44 (208) 144 9984</p>
            <p>39/5 Granton Crescent<br/>Edinburgh, EH5 1BN<br/>+44 (208) 144 9984</p>
            <p>
              <a
                href='mailto:sales@elitex.systems'
                onClick={clickEmailEvent}>sales@elitex.systems</a><br/>
              <a
                href="skype:live:elitex_18?chat"
                rel="noopener noreferrer nofollow"
                target='_blank'
                onClick={clickSkypeEvent}>Connect with us on Skype</a>
            </p>
          </div>
        </div>
        <div className='footer-column'>
          <div className='widget'>
            <span className='widget-title title-alt'>Canada</span>
            <p>700 2 St SW<br/>Calgary, AB T2P 2W2<br/>+1 416 219 47 90</p>
          </div>
        </div>
        <div className='footer-column'>
          <div className='widget'>
            <span className='widget-title title-alt'>The Netherlands</span>
            <p>Stade de Colombes 33<br/>Amsterdam, 1098 VS<br/>+31 (0)23 205 0048</p>
          </div>
        </div>
        <div className='footer-column'>
          <div className='widget'>
            <span className='widget-title title-alt'>Ukraine</span>
            <p>Horodotska Str. 2<br/>Lviv, 79007<br/>+38 (096) 585 0620</p>
            <p>Check <Link to='/contact'>Contacts</Link> page<br />for more locations.</p>
          </div>
        </div>
      </footer>
    );
  }
}
