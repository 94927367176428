import React from 'react';
import './TechWhatOfferPhoto.css';

const TechWhatOfferPhoto = ({content, bold}) => {
    return (
        <>
            <div className="tech-photo-cards">
                {content.map(({id, name, text, img}) => (
                    <div className="tech-photo-card-container" key={id}>
                        <div className="tech-photo-card-content text">
                            {bold ? name.firstStrong ?
                                    <p className="tech-photo-card-title">
                                        <strong>{name.strong} </strong>
                                        {name.regular}
                                    </p> :
                                    <p className="tech-photo-card-title">
                                        {name.regular}
                                        <strong> {name.strong}</strong>
                                    </p>
                                : <h3 className="tech-photo-card-title">{name}</h3>}
                            {text && <p className="tech-photo-card-text">{text}</p>}
                        </div>
                        <div className="tech-photo-card-content">
                            <img
                                src={img}
                                alt="offer"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default TechWhatOfferPhoto;