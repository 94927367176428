import React from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import { NameField, EmailField, Textarea, Spacer, LinkField, PhoneField, FileField } from "../../components";
import LazyReCaptcha from "../../components/ReCaptcha/LazyReCaptcha";
import {
  isFieldNotEmpty,
  isEmailCorrect,
  isURL,
  isPhoneNumber,
  checkRecruitingForm
} from "../../utils/formValidator.utils";
import { formSubmitEvent } from "../../utils/gtmEvents.utils";

import "react-toastify/dist/ReactToastify.css";
import "./ContactRecruitingForm.css";

const DEFAULT_STATE = {
  form: {
    name: {
      empty: false,
      value: ""
    },
    email: {
      isEmail: false,
      empty: false,
      notEmail: false,
      value: ""
    },
    phone: {
      isPhone: false,
      empty: false,
      notPhoneNumber: false,
      value: ""
    },
    linkedin: {
      empty: false,
      value: ""
    },
    message: {
      empty: false,
      value: ""
    },
    file: {
      isValue: false,
      empty: false,
    },
  },
  fileName: "",
  fileValue: "",

  reCaptcha: {
    isEmpty: false,
    isValid: false,
    isVisible: false
  }
};

class ContactRecruitingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
  }

  name = React.createRef();
  email = React.createRef();
  phone = React.createRef();
  linkedin = React.createRef();
  message = React.createRef();
  file = React.createRef();
  form = React.createRef();

  checkFields = () => {
    const form = { ...this.state.form };
    form.name.isValue = isFieldNotEmpty(form.name.value);
    form.email.isValue = isFieldNotEmpty(form.email.value);
    form.email.isEmail = isEmailCorrect(form.email.value);
    form.phone.isPhone = !!form.phone.value ? isPhoneNumber(form.phone.value) : false;
    form.linkedin.isURL = !!form.linkedin.value ? isURL(form.linkedin.value) : false;
    form.file.isValue = this.file.current.files.length > 0;
    this.setState({
      ...this.state,
      form
    });
    return checkRecruitingForm(form);
  };

  indicateFields = () => {
    const form = { ...this.state.form };
    form.name.empty = !isFieldNotEmpty(this.name.current.value);
    form.email.empty = !isFieldNotEmpty(this.email.current.value);
    form.phone.notPhoneNumber = !!this.phone.current.value ? !isPhoneNumber(this.phone.current.value) : false;
    form.email.notEmail = !!this.email.current.value ? !isEmailCorrect(this.email.current.value) : false;
    form.linkedin.notURL = !!this.linkedin.current.value ? !isURL(this.linkedin.current.value) : false;
    form.file.empty = this.file.current.files.length === 0;

    const { isVisible, isValid } = this.state.reCaptcha;
    this.setState({
      ...this.state,
      form,
      reCaptcha: {
        isVisible,
        isValid,
        isEmpty: isVisible && !isValid
      }
    });
  };

  resetForm = () => {
    this.name.current.value = "";
    this.email.current.value = "";
    this.phone.current.value = "";
    this.linkedin.current.value = "";
    this.message.current.value = "";
    if (this.file.current) {
      this.file.current.value = "";
      this.setState({ fileName: "" });
    }
  };

  updateForm = () => {
    const form = this.state.form;

    form.name.value = this.name.current.value;
    form.phone.value = this.phone.current.value;
    form.email.value = this.email.current.value;
    form.linkedin.value = this.linkedin.current.value;
    form.message.value = this.message.current.value;

    this.setState({
      ...this.state,
      form: { ...this.state.form, ...form },
      fileValue: this.file.current ? this.file.current.files[0] : "",
    });
  };

  onChangeHandler = () => {
    this.updateForm();
    const isFormValid = this.checkFields();

    this.setState(state => ({
      ...this.state,
      reCaptcha: {
        isEmpty: false,
        isValid: state.reCaptcha.isValid,
        isVisible: isFormValid
      }
    }));
  };

  onConfirmReCaptcha = () =>
    this.setState(state => ({
      ...this.state,
      reCaptcha: {
        isEmpty: false,
        isValid: true,
        isVisible: state.reCaptcha.isVisible
      }
    }));

  onSubmitHandler = () => {
    if (this.checkFields() && this.state.reCaptcha.isValid) {
      this.submitForm();
    } else {
      this.indicateFields();
    }
  };

  onFocusHandler = element => {
    let form = { ...this.state.form };
    if (element === "email") form[element].notEmail = false;
    if (element === "phone") form[element].notPhoneNumber = false;
    if (element === "linkedin") form[element].notURL = false;
    form[element].empty = false;
    this.setState({ ...this.state, form });
  };

  submitForm = () => {
    fetch("/send-hr-email", {
      method: "POST",
      body: new FormData(this.form.current)
    })
    .then(res => {
      if (res.status === 200) {
        toast("Thanks, your message has been successfully sent! We'll get back to you shortly.");
      } else {
        toast("Your message cannot be sent at the moment.");
      }
    })
    .catch(e => {
      console.error(e);
      toast("Your message cannot be sent at the moment.");
    });
    formSubmitEvent(); // gtm
    this.resetForm();
  };

  getFileName = () => {
    const form = { ...this.state.form };
    form.file.empty = false;
    this.setState({ form: form });

    if (this.file.current.files[0]) {
      this.setState({ fileName: this.file.current.files[0].name });
    } else {
      this.setState({ fileName: "" });
    }
  };

  render() {
    return (
      <>
        <ToastContainer progressClassName={"fancy-progress-bar"} />

        <Spacer type='small' />

        <form onChange={this.onChangeHandler} ref={this.form} className='recruiter-form'>
          <div className='email-form'>
            <div className='email-form-half'>
              <h3 className='h3 align-center font-bold' id='contact-title'>
                Let's get in touch!
              </h3>
              <Spacer type='small' />
              <div className='fields-container'>
                <div>
                  <NameField
                    required
                    placeholder='Name*'
                    reference={this.name}
                    defaultValue={this.state.nameValue}
                    onFocus={this.onFocusHandler.bind(this, "name")}
                    errorBorder={this.state.form.name.empty}
                    errorMessage={this.state.form.name.empty}
                  />
                  <EmailField
                    required
                    placeholder='Email*'
                    reference={this.email}
                    defaultValue={this.state.emailValue}
                    onFocus={this.onFocusHandler.bind(this, "email")}
                    errorBorder={this.state.form.email.empty || this.state.form.email.notEmail}
                    errorMessage={this.state.form.email.empty}
                    errorMessage2={this.state.form.email.notEmail}
                  />
                  <PhoneField
                    placeholder='Phone'
                    reference={this.phone}
                    defaultValue={this.state.phoneValue}
                    onFocus={this.onFocusHandler.bind(this, "phone")}
                    errorBorder={this.state.form.phone.notPhoneNumber}
                    errorMessage2={this.state.form.phone.notPhoneNumber}
                  />
                  <LinkField
                    placeholder='LinkedIn profile'
                    reference={this.linkedin}
                    defaultValue={this.state.linkedin}
                    onFocus={this.onFocusHandler.bind(this, "linkedin")}
                    errorBorder={this.state.form.linkedin.notURL}
                    errorMessage={this.state.form.linkedin.notURL}
                  />
                  <div className='file-input-wrapper'>
                    <FileField
                      onChange={this.getFileName}
                      reference={this.file}
                      fileName={this.state.fileName}
                      errorMessage={this.state.form.file.empty}
                      label={'Attach your CV'}
                    />
                  </div>
                </div>
                <div className='text-area-wrapper'>
                  <h4 className='align-left font-bold'>Want to tell few words about yourself?</h4>
                  <Textarea
                    reference={this.message}
                    defaultValue={this.state.messageValue}
                    onFocus={this.onFocusHandler.bind(this, "message")}
                    errorBorder={this.state.form.message.empty}
                    errorMessage={this.state.form.message.empty}
                  />
                </div>
              </div>

              <div className='form-row-submit'>
                <input
                  type='button'
                  className='btn btn-outline-main submit'
                  value='Send Message'
                  onClick={this.onSubmitHandler}
                />
              </div>
            </div>
          </div>

          {/*loading reCaptcha lazily*/}
          {this.state.reCaptcha.isVisible && (
            <LazyReCaptcha onChange={this.onConfirmReCaptcha} errorMessage={this.state.reCaptcha.isEmpty} />
          )}
          <Spacer type='mini' />
        </form>
        <Spacer type='medium' />
      </>
    );
  }
}

export { ContactRecruitingForm };
