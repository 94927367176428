import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {
  About,
  Footer,
  Home,
  Expertise,
  Contact,
  HeaderWithRouter,
  ErrorPage404,
  Portfolio,
  PrivacyPolicy,
  TermsConditions,
  Kallik,
  Peerwith,
  Imagex,
  OASwitchboard,
  PostCms,
  Exterity,
  Salsify,
  Innroad,
  Watstock,
  Zippia,
  Felix,
  Career,
  DedicatedTeam,
  Qa,
  Jsd,
  FrontEndDevelopment,
  WebDevelopmentService,
  UiDevelopment,
  CustomSoftwareDevelopment,
  STM,
  Smartrr,
  ReactTech,
  AngularTech,
  NodeTech,
  PythonTech,
  DotNetTech,
  DevOps, Ai
} from '../index';
import {ModelMaster} from "../ModelMaster/ModelMaster";
import {BreadcrumbsWithRouter} from "../../components";
import QaWorkshops from "../QaWorkshops/QaWorkshops";

class Page extends React.Component {

  render() {

    return (
      <React.Fragment>
        <div id="page-loader" className="text-light show-logo">
          <span className="loader-icon bullets-jump">
            <span/> <span/> <span/>
          </span>
        </div>
        <div id='page-content'>
          <HeaderWithRouter/>
          <BreadcrumbsWithRouter/>
          <div>
            <Switch>
              <Route exact path='/' component={Home}/>
              <Route path='/web-app-development' component={Expertise}/>
              <Route path ='/front-end-development' component={FrontEndDevelopment}/>
              <Route path='/javascript-development' component={Jsd}/>
              <Route path='/react-technology' component={ReactTech}/>
              <Route path='/angular-technology' component={AngularTech}/>
              <Route path='/node-js-technology' component={NodeTech}/>
              <Route path='/python-technology' component={PythonTech}/>
              <Route path='/dotnet-technology' component={DotNetTech}/>
              <Route path='/devops' component={DevOps}/>
              <Route path='/artificial-intelligence' component={Ai}/>
              <Route exact path='/software-qa-testing-services' component={Qa}/>
              <Route path='/software-qa-testing-services/qa-workshops' component={QaWorkshops}/>
              <Route path='/dedicated-software-development-team' component={DedicatedTeam}/>
              <Route path='/web-development-service' component={WebDevelopmentService}/>
              <Redirect from='/node-js-angular-react-developers' to='/web-development-service'/>
              <Route path='/ui-development' component={UiDevelopment}/>
              <Route path='/custom-software-development' component={CustomSoftwareDevelopment}/>
              <Route path='/it-outsourcing-company-ukraine' component={About}/>
              <Route path='/career' component={Career}/>
              <Route path='/contact' component={Contact}/>
              <Route exact path='/case-studies' component={Portfolio}/>
              <Route path='/case-studies/kallik' component={Kallik}/>
              <Route path='/case-studies/peerwith' component={Peerwith}/>
              <Route path='/case-studies/imagex' component={Imagex}/>
              <Route path='/case-studies/oa-switchboard' component={OASwitchboard} />
              <Route path='/case-studies/postcms' component={PostCms}/>
              <Route path='/case-studies/exterity' component={Exterity}/>
              <Route path='/case-studies/salsify' component={Salsify}/>
              <Route path='/case-studies/zippia' component={Zippia}/>
              <Route path='/case-studies/watstock' component={Watstock}/>
              <Route path='/case-studies/stm' component={STM}/>
              <Route path='/case-studies/model-master' component={ModelMaster}/>
              <Route path='/case-studies/property-management-and-hotel-reservation-system' component={Innroad}/>
              <Route path='/case-studies/felix' component={Felix}/>
              <Route path='/case-studies/smartrr' component={Smartrr}/>
              <Route path='/privacy-policy' component={PrivacyPolicy}/>
              <Route path='/terms-and-conditions' component={TermsConditions}/>
              <Route component={ErrorPage404}/>
            </Switch>
          </div>
          <Footer/>
        </div>
      </React.Fragment>
    );
  }
}

export {Page};
