import React from 'react';
import './InstagramFeed.css';

export const InstagramFeed = () => (
  <div className="insta-container">
    <div className="insta-title">
      <h5>Instagram feed</h5>
    </div>
    <div className="insta-images">
      <div className="elfsight-app-67ed304d-d034-4f1f-97eb-ff37714f09a7"></div>
    </div>
  </div>
);
