import React, {Fragment, useEffect} from 'react';
import './DevOps.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData, devopsFaqStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    LogosList,
    PageBody,
    QaWhatWeDo,
    ScrollDownBtn,
    Spacer, WdsSlider,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {
    devopsMainCaptionText,
    devopsMainCaptionTitle,
    devopsOfferCards,
    devopsOfferText,
    devopsOfferTitle,
    testimonialItems,
    homeClientsSliderHeader,
    devopsProcessTitle,
    devopsProcessContent,
    devopsWhyChooseTextOne,
    devopsWhyChooseTextTwo,
    devopsWhyChooseTextThree,
    devopsBenefitsContent,
    devopsBenefitsTitle,
    devopsProjectText,
    devopsToolsLogos,
    devopsToolsTitle,
    devopsFaqList,
} from "../../mocks";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import BlueLine from "../../components/BlueLine/BlueLine";
import WhatDoWeOffer from "../../components/WhatDoWeOffer/WhatDoWeOffer";
import ProcessStages from "../../components/ProcessStages/ProcessStages";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import {portfolioItem} from "../../utils";
import Faq from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";

export const DevOps = () => {

    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <Fragment>
            <HelmetSetter
                title="DevOps Services and Solutions | ELITEX"
                description="Revolutionize your workflow with ELITEX, a trusted DevOps company. Discover how our DevOps development services can make a difference for your business."
                ogImg="/devops/devops-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "DevOps",
                    item2: "https://elitex.systems/devops"
                })}
                faqStructuredData={devopsFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img devops">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={devopsMainCaptionTitle} text={devopsMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-devops">
                <div id="testimonial-slider">
                    <SliderMaker clientsSliderHeader={homeClientsSliderHeader} dark sliderItems={testimonialItems}
                                 SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{devopsOfferTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{devopsOfferText}</p>
                    <Spacer type="small"/>
                    <WhatDoWeOffer content={devopsOfferCards}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{devopsProcessTitle}</h2>
                    <Spacer type="small"/>
                    <ProcessStages content={devopsProcessContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{devopsBenefitsTitle}</h2>
                    <Spacer type="small"/>
                    <QaWhatWeDo list={devopsBenefitsContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title"><strong>Why Choose ELITEX</strong> as a DevOps Development Company?</h2>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{devopsWhyChooseTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{devopsWhyChooseTextTwo}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{devopsWhyChooseTextThree}</p>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation text={devopsProjectText}/>
                <Spacer type="big"/>
                <h2 className="main-title" id="portfolio-grid">Check Out Our Case Studies</h2>
                <Spacer type="small"/>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('postCMS', true),
                    portfolioItem('imagex', false),
                    portfolioItem('peerwith', true),
                ]}/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{devopsToolsTitle}</h2>
                    <Spacer type="small"/>
                    <LogosList list={devopsToolsLogos} tech/>
                    <Spacer type="big"/>
                </Wrapper>
                <h2 className="main-title">Our Other Services</h2>
                <WdsSlider/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title"><strong>FAQs</strong></h2>
                    <Faq content={devopsFaqList}/>
                </Wrapper>
                <Spacer type="big"/>
                <div className="case-study-grey-2">
                    <ContactForm/>
                </div>
            </PageBody>
        </Fragment>
    );
};
