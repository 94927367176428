import React, {useEffect, useState} from 'react';
import './FAQ.css';
import {checkCardsStatus, transformFaqListToObject} from "../../utils/faq.util";

const Faq = ({content, title}) => {
    const initialState = transformFaqListToObject(content);

    const [openCard, setOpenCard] = useState(initialState);
    const [isExpanded, setIsExpanded] = useState(false);

    const checkIsOpenCards = checkCardsStatus(openCard, true);
    const checkIsClosedCards = checkCardsStatus(openCard, false);

    useEffect(() => {
        if(checkIsOpenCards){
            setIsExpanded(true)
            return
        }
        if(checkIsClosedCards && isExpanded){
            setIsExpanded(false);
        }
   }, [openCard]);

    const toggleOpenCard = (id) => {
        setOpenCard((prevState) => (
            {
                ...prevState,
                [id]: !prevState[id]
            }
        ))
    };

    const toggleOpenAllCards = () => {
        setIsExpanded((prevState) => !prevState);
            setOpenCard((prevState) => {
                const updatedObject = { ...prevState };
                Object.keys(updatedObject).forEach( key => {
                    updatedObject[key] = !isExpanded;
                });
                return updatedObject;
            })
    };

    const btnLabel = isExpanded ? "Collapse" : "Expand";
    return (
        <div className="faq-container">
            {title && <h2 className="main-title">{title.main}<strong> {title.strong}</strong></h2>}
            <button className="faq-expand-btn" onClick={toggleOpenAllCards}>{btnLabel} All</button>
            {
                content.map(({id,name,text,listItems}) => (
                    <div className="faq-cards-box" key={id}>
                        <div className="faq-card-question">
                            <span className="faq-card-question-number">{id}</span>
                            <h3 className="faq-card-text question">{name}</h3>
                            <button
                                className={`faq-card-question-btn ${openCard[id] ? '' : 'plus'}`}
                                onClick={() => toggleOpenCard(id)}
                                aria-label="Open card"
                            />
                        </div>
                        {
                            openCard[id] && (
                                <div className="faq-card-content">
                                    <p className="faq-card-text content-text">{text}</p>
                                    {listItems && (
                                        <ul>
                                            {
                                                listItems.map(({id, text}) => (
                                                    <li key={id} className="faq-card-text content-text list">{text}</li>
                                                ))
                                            }
                                        </ul>
                                    )}
                                </div>
                            )
                        }
                    </div>
                ))
            }
        </div>
    );
};

export default Faq;