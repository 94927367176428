export const careerWhoWeAre = [{
  id: 1,
  header: 'We are professionals',
  text: 'That’s why our engineers have up to 30 years of experience and 50% of them are seniors.',
  img: '/img/career/whoweare/resized/we_are_1_large.webp',
  srcSetPath: '/img/career/whoweare/resized/we_are_1_small.webp 600w, /img/career/whoweare/resized/we_are_1_large.webp 1200w',
  right: false
}, {
  id: 2,
  header: 'We provide the best work environment',
  text: 'You will enjoy a stylish 470 sq.m office in the Lviv city centre with a panoramic view of the Opera House.',
  img: '/img/career/whoweare/resized/we_are_2_large.webp',
  srcSetPath: '/img/career/whoweare/resized/we_are_2_small.webp 600w, /img/career/whoweare/resized/we_are_2_large.webp 1200w',
  right: true
}, {
  id: 3,
  header: 'We help our people to grow',
  text: 'Elitex provides regular TechTalks, mentorship program, and compensation for professional learning.',
  img: '/img/career/whoweare/resized/we_are_3_large.webp',
  srcSetPath: '/img/career/whoweare/resized/we_are_3_small.webp 600w, /img/career/whoweare/resized/we_are_3_large.webp 1200w',
  right: false
}, {
  id: 4,
  header: 'We hear your opinion',
  text: 'You will have regular one-to-one meetings with your manager and HR. And if you need — CEO’s door is always open.',
  img: '/img/career/whoweare/resized/we_are_4_large.webp',
  srcSetPath: '/img/career/whoweare/resized/we_are_4_small.webp 600w, /img/career/whoweare/resized/we_are_4_large.webp 1200w',
  right: true
}, {
  id: 5,
  header: 'We do care about our team',
  text: 'That’s why people on average work with us for 2+ years and are satisfied with the company by 90%.',
  img: '/img/career/whoweare/resized/we_are_5_large.webp',
  srcSetPath: '/img/career/whoweare/resized/we_are_5_small.webp 600w, /img/career/whoweare/resized/we_are_5_large.webp 1200w',
  right: false
}
];
