export const wdsWhatWeCan = [
  {
    id: 1,
    title: 'Prototype & Design',
    text: 'Since the user experience on the website is one of the most important thing, we always establish and simplify UX first, making even complex, multifunctional projects easy to use.',
    img: '/img/wds/whatWeCanDo/resized/what-we-can-do_1.webp',
    tagName: 'h2'
  },
  {
    id: 2,
    title: 'Front-end development',
    text: 'We use the JavaScript technologies to provide users with smooth performance, reliability and great experience. We can also implement your own design, add new features, and improve the existing front-end functionality.',
    img: '/img/wds/whatWeCanDo/resized/what-we-can-do_2.webp',
    tagName: 'h2'
  },
  {
    id: 3,
    title: 'Back-end development',
    text: 'Back-end development includes data storage, update and retrieval, logic and security, integration with APIs and servers. We know how to create needed functionality with minimum resources.',
    img: '/img/wds/whatWeCanDo/resized/what-we-can-do_3.webp',
    tagName: 'h2'
  },
  {
    id: 4,
    title: 'Database development',
    text: 'Designing efficient database is the key for satisfying customers, especially, if your project includes a whole lot of diverse data. We take into account the scale and workload to develop the best-fit for the project.',
    img: '/img/wds/whatWeCanDo/resized/what-we-can-do_4.webp',
    tagName: 'h2'
  }
];
