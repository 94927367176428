import React from 'react';
import './Feedback.css';

export const Feedback = ({info}) => {
    const {name, position, message, avatar} = info;
    return (
        <div className={`feedback-block spaced-none addcustompadding`}>
            <div className="column">
                <div className="inner">
                        <p className="align-center">
                            <img
                                src={avatar || '/img/placeholders/resized/avatar-placeholder.webp'}
                                width="70"
                                height="70"
                                loading="lazy"
                                alt="client avatar"
                            />
                        </p>
                    <blockquote>
                        <p>{message}</p>
                    </blockquote>
                    {name &&
                        <p className='h5'>
                            <strong>{name}</strong>
                        </p>
                    }
                    <small className="title-alt"> {position}</small>
                </div>
            </div>
        </div>
    )
};
