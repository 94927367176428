import React from 'react';
import './CaseStudiesGrid.css';
import { Link } from "react-router-dom";

export const CaseStudiesGrid = ({ list }) => (
    <div className='case-study-container'>
        {list.map((item) => (
            <div className={`case-study-lazy ${item.fullWidth ? '--full' : ''}`} key={item.id}>
                <div className={`portfolio-item ${item.fullWidth ? '--full' : ''}`}
                    style={{ backgroundImage: `url('${item.backPath}')` }}
                >
                    <Link to={`/case-studies/${item.link}`}>
                        <div className={`portfolio-item-link ${item.invert ? 'inverted' : ''}`}>
                            <div className="portfolio-item-text">
                                <h3 className="portfolio-item-link-name">{item.name}</h3>
                                <p>{item.subName}</p>
                            </div>
                            <div className="portfolio-item-img">
                                <img
                                    src={item.photoPath}
                                    srcSet={item.srcSetPath}
                                    width="100%"
                                    height="100%"
                                    alt="notebook"
                                />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        ))}
    </div>);
