// MainCaption
const nodeMainCaptionTitle = "Node.js Development Services";
const nodeMainCaptionText = "ELITEX is an advanced Node.js development company that provides custom web solutions. Explore the benefits of Node.js development services with ELITEX.";

// TechAwards
const nodeTechAwardsContent = [
    {
        id: 1,
        text: "ELITEX produces high-performance, future-proved, and flexible web and mobile apps. We ensure the highest standard of Node.js custom development to fulfill your business needs. With our up-to-date expertise and the advanced skills of senior developers on board, you will receive lightweight and highly efficient products. It ensures the creation of lightweight software with high scalability and load, data processing, and excellent performance."
    }
];

// WHAT DO WE OFFER

const nodeWhatDoOfferTitle = {
    main: "Node.js Development Services:",
    strong: "What Do We Offer"
};
const nodeWhatDoOfferSubtitle = "Our highly skilled developers utilize Node.js to build scalable software for various industries delivered quickly and proficiently. Experienced Node.js programmers can produce custom applications of all sizes and levels of complexity. ELITEX, as a Node.js web development company, develops various backends for real-time online applications and high-load applications using modern technologies and approaches, like microservices and event-driven architectures."
const nodeWhatDoOfferTextOne = "In case you just need some devs to augment your current team and requirements (regardless of your project’s complexity), we also provide";
const nodeWhatDoOfferTextTwo = "We can extend your team with our Node.js development company team members: engineers and a project manager to fill your needs and technical skills. You can hire senior Node.js developers within less than a month to scale your team and empower fast software development. ELITEX offers various engagement plans for software development projects tailored to clients' needs.";
const nodeWhatDoOfferLink = "dedicated development team services.";
const nodeWhatDoWeOfferContent = [
    {
        id: 1,
        name: "Custom Node.js development",
        text: "Node.js is an ideal ecosystem for full-stack development, which entails front and back-end development. We design industry-tailored, high-performing, scalable, secure, and UI-rich applications to meet your company’s needs.",
        img: "/img/node/whatDoWeOffer/node-offer_1.webp"
    },
    {
        id: 2,
        name: "Node.JS API development",
        text: "We use Node.js to create a secure and ready-to-use API from scratch in a short period. Our Node.js programmers are experts in building scalable and reliable API-based business applications.",
        img: "/img/node/whatDoWeOffer/node-offer_2.webp"
    },
    {
        id: 3,
        name: "Real-time apps development",
        text: "Everything focuses on speed in the real-time app development process. Node.js leverages Chrome’s V8 engine to handle asynchronous queries, which provides the best highly performant tool to build real-time applications. Node.js is a perfect choice for developing sustainable and scalable real-time apps.",
        img: "/img/node/whatDoWeOffer/node-offer_3.webp"
    },
    {
        id: 4,
        name: "Node.js consulting services",
        text: "In the case of an existing app, we can assist you with code review, scalability, detecting performance issues, and deploying the best Node.js development practices. Node JS consulting services guarantee in-depth advice from professional engineers on creating a comprehensive picture of the project: we identify areas where we can enhance your applications and infrastructure and provide solutions that will improve performance and efficiency.",
        img: "/img/node/whatDoWeOffer/node-offer_4.webp"
    },
];

// Process Stages

const nodeProcessTitle = "How Does Node.js Development Process Look Like?";
const nodeProcessTextOne = "Overall, the Node.js application development projects are complex tasks that follow an iterative and collaborative process that prioritizes efficient development, continuous testing, and quality assurance.";
const nodeProcessTextTwo = "The node.js development circle typically involves:";
const nodeProcessContent = [
    {
        id: 1,
        header: "Project requirements gathering and planning",
        text: "The first stage of the app development project is to understand the specific business requirements and create a detailed plan.",
        arrow: true
    },
    {
        id: 2,
        header: "Development",
        text: "After the first stage, developers use various tools and frameworks to build scalable, high-performance apps. Usually, development is considered the most significant part of the whole project.",
        arrow: false
    },
    {
        id: 3,
        header: "Testing and deployment",
        text: "Throughout the development process, rigorous testing and quality assurance measures ensure the application’s reliability and security. The application is deployed and available for users as soon as the development is completed.",
        arrow: true
    },
    {
        id: 4,
        header: "Maintenance",
        text: "Ongoing maintenance and support help the application function efficiently over time. The maintenance process involves the identification, resolution, and prevention of any issues that may arise in the app after the release.",
        arrow: false
    },
];

// What Makes Elitex

const nodeWhatMakesTitle = {
    main: "the Ideal Node.js Development Company for You?",
    strong: "What Makes ELITEX"
};
const nodeWhatMakesTextOne = "With years of experience building high-performance and scalable applications, ELITEX knows what it takes to create software that delivers excellent results. Our team puts quality, collaboration, and innovation at the forefront of everything we do. Communication and teamwork throughout the development process, ensuring that the feedback is incorporated at every stage, is a crucial aspect of our approach to development.";
const nodeWhatMakesTextTwo = "ELITEX is your end-to-end solution for all needs of your business. In addition to the development and maintenance cycle, we offer a comprehensive range of other services, including outstaff and outsource Node js development services. The smooth collaboration and communication, built on the principles of honesty, integrity, and transparency, is complemented by a vast knowledge and general expertise in various industries. The ELITEX’s experience covers FinTech, News & Media, Content Management, E-learning, Property Management, Telecommunications, and E-Commerce.";
const nodeWhatMakesTextThree = "Top-notch and creative custom solutions from qualified IT specialists distinguish ELITEX from a mediocre development services provider. Choose ELITEX as your development partner, and experience the difference our expertise and quality commitment can make for your business.";

// Benefits of Using

const nodeBenefitsText = "Our engineers define the primary benefits of selecting Node.js web development services for your project based on the requirements. We consider this technology fast, scalable, cost-effective, and versatile. Using modern system design principles, NodeJS can be used to create distributed systems with a scalable microservice approach and data intensiveness.";
const nodeBenefitsCards = [
    {
        id: 1,
        title: "One language for frontend and backend",
        textList: [
            {
                id: 1,
                text: "Both back and front-end development can be produced using the same language, JavaScript. Finding a full-stack developer using NodeJS rather than other programming languages is easier. It reduces the development time, improves the app’s maintenance, and saves the budget."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_1.png"
    },
    {
        id: 2,
        title: "Scalable and flexible",
        textList: [
            {
                id: 1,
                text: "The horizontal scaling ability of Node is a great asset. Node-based apps have the potential to expand by adding new capability nodes or resources without changing the existing codebase. The flexibility of NodeJS makes it appealing to both startups and well-established companies."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_2.png"
    },
    {
        id: 3,
        title: "Fast and lightweight",
        textList: [
            {
                id: 1,
                text: "Node is one of the fastest application technologies since Google’s V8 JS engine powers it. The use of an event-driven architecture allows NodeJS to perform non-blocking. It runs code at the fastest possible speed. It makes the app lighter, faster, and more pleasant to use."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_3.png"
    },
    {
        id: 4,
        title: "Best for microservices",
        textList: [
            {
                id: 1,
                text: "Microservice architecture is based on independent services, similar to some sort of distributed computing. Each service is responsible for a particular functionality and could be developed by a separate team with a different technology stack. Our dedicated Node.js coders split up the development process, thus delivering top quality and high reliability."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_4.png"
    },
    {
        id: 5,
        title: "The rich ecosystem of tools and libraries",
        textList: [
            {
                id: 1,
                text: "Node is rich in libraries. Being well-developed, they make NodeJS highly popular and boost the development process. Node is also a great solution when the app has to communicate with the platform API and database. Hence, large companies have adopted Node.js for their backend development and server-side programming."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_5.png"
    },
    {
        id: 6,
        title: "Cost-effective for enterprise solutions",
        textList: [
            {
                id: 1,
                text: "Node has the ability to handle a large number of connection requests efficiently and enables us to guarantee cost-effectiveness without affecting solution quality. Furthermore, Node’s microservices architecture is ideal for constant growth despite a limited budget."
            }
        ],
        icon: "/img/techWhatDoOffer/tech-what-offer_6.png"
    },
];

// Project Consultation

const nodeProjectText = "Get Started on Your Project - Schedule a Consultation Today";

// Our Experience

const nodeExperienceTitle = {
    main: "Our Experience as a",
    strong: "Node.js Development Company"
};
const nodeExperienceTextOne = "ELITEX delivers world-class Node.JS development services to companies in the US, UK, and EU. To achieve business objectives, we combine our technical knowledge and Node.js platform capabilities.";
const nodeExperienceTextTwo = "We have built Node.js app projects for a wide range of industries. With our bespoke Node.js development services, you will receive a server-side web application that can accomplish a multitude of functions.";
const nodeExperienceTextThree = "Being a top-notch Node.js software development company, we create robust and productive apps to power companies with new business opportunities and save time for software testing. Node is a powerful environment for developing apps that can handle millions of daily visitors and a vast volume of data.";

// FAQ

const nodeFaqTitle = {
    main: "Node.js Development Services",
    strong: "FAQs"
};
const nodeFaqList = [
    {
        id: 1,
        name: "What is Node.js?",
        text: "Node is a cross-platform runtime JavaScript environment for developing real-time applications. It is an open-source framework for executing JavaScript code and maintaining fast request processing: the technology creates real-time web applications that must process many requests."
    },
    {
        id: 2,
        name: "How is Node.js used in software development?",
        text: "NodeJS is used to develop a back-end with JavaScript, allowing it to create a good match with the front-end part of the app. Developers use Node.js to build apps of all demands and sizes, ensuring high scalability. Node.js is a promising technology for mobile applications back-ends, server-side apps, real-time applications, chat servers, and gaming apps."
    },
    {
        id: 3,
        name: "Why is Node a perfect choice for your business?",
        text: "You should choose Node due to its ability to handle large amounts of data and connections efficiently. It’s easy and scalable and can work seamlessly with other web technologies, creating a high demand for any Node.js development services company."
    },
    {
        id: 4,
        name: "In what ways can Node.js benefit your business?",
        text: "The library is used to create robust and scalable solutions on thousands of websites. This technology features a lightweight and excellent software structure for building web apps. Since Node.js is a JS runtime environment based on Google Chrome’s open-source JS engine, it helps speed up processing."
    },
    {
        id: 5,
        name: "What makes Node.js so popular?",
        text: "Node.js has been empowering enterprises for over a decade. Major corporations like Google, Yahoo, Microsoft, and PayPal have adopted this technology. With its ability to handle a concurrent request, Node.js allows for fast and scalable software development."
    },
    {
        id: 6,
        name: "How much do Node.js app development services cost?",
        text: "The cost of development services can vary depending on the project's complexity and the client's specific requirements. There is no one-size-fits-all answer to this question; it will vary depending on the particular Node.js app development company."
    },
];

export {
    nodeMainCaptionTitle,
    nodeMainCaptionText,
    nodeTechAwardsContent,
    nodeWhatDoWeOfferContent,
    nodeWhatDoOfferTitle,
    nodeWhatDoOfferTextOne,
    nodeWhatDoOfferTextTwo,
    nodeWhatDoOfferLink,
    nodeWhatDoOfferSubtitle,
    nodeProcessTitle,
    nodeProcessTextOne,
    nodeProcessTextTwo,
    nodeProcessContent,
    nodeWhatMakesTitle,
    nodeWhatMakesTextOne,
    nodeWhatMakesTextTwo,
    nodeWhatMakesTextThree,
    nodeBenefitsText,
    nodeBenefitsCards,
    nodeProjectText,
    nodeExperienceTitle,
    nodeExperienceTextOne,
    nodeExperienceTextTwo,
    nodeExperienceTextThree,
    nodeFaqTitle,
    nodeFaqList
}