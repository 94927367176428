export const customersLogos = [{
    id: 1,
    alt: 'Isole24Ore logo',
    src: 'img/customers/logos/resized/24ore.webp',
    link: 'https://www.ilsole24ore.com/',
    explicitHeight: '100%',
    explicitWidth: '100%',
}, {
    id: 2,
    alt: 'Kallik logo',
    src: 'img/customers/logos/resized/kallik.webp',
    link: 'https://www.kallik.com/',
    explicitHeight: '100%',
    explicitWidth: '100%',
}, {
    id: 3,
    alt: 'Exterity logo',
    src: 'img/customers/logos/resized/exterity.webp',
    link: 'https://www.exterity.com/',
    explicitHeight: '100%',
    explicitWidth: '100%',
}, {
    id: 4,
    alt: 'Peerwith logo',
    src: 'img/customers/logos/resized/peerwith.webp',
    explicitHeight: '100%',
    explicitWidth: '100%',
}, {
    id: 6,
    alt: 'Felix Security logo',
    src: 'img/customers/logos/resized/felix.webp',
    explicitHeight: '100%',
    explicitWidth: '100%',
}, {
    id: 7,
    alt: 'Zippia logo',
    src: 'img/customers/logos/resized/zippia.webp',
    link: 'https://www.zippia.com/',
    explicitHeight: '100%',
    explicitWidth: '100%',
}, {
    id: 8,
    alt: 'Trace logo',
    src: 'img/customers/logos/resized/trace.webp',
    link: 'https://tracedata.co.uk/',
    explicitHeight: '100%',
    explicitWidth: '100%',
}];