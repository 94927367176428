import React from 'react';

export const MainSliderItem = ({ image, srcSet }) => (
  <div className="image-item">
      <div className="image-item-inner">
              <img
                  src={image}
                  alt="office"
                  width="100%"
                  height="100%"
                  srcSet={srcSet}
              />
      </div>
  </div>
);
