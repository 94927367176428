export const zippiaSliders = [
  {
    id: 2,
    img: '/img/portfolio/zippia/resized/slider/zippia_1_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_1_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_1_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_1_large.webp 1400w'
  },
  {
    id: 3,
    img: '/img/portfolio/zippia/resized/slider/zippia_2_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_2_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_2_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_2_large.webp 1400w'
  },
  {
    id: 4,
    img: '/img/portfolio/zippia/resized/slider/zippia_3_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_3_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_3_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_3_large.webp 1400w'
  },
  {
    id: 5,
    img: '/img/portfolio/zippia/resized/slider/zippia_4_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_4_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_4_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_4_large.webp 1400w'
  },
  {
    id: 6,
    img: '/img/portfolio/zippia/resized/slider/zippia_5_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_4_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_5_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_5_large.webp 1400w'
  },
  {
    id: 7,
    img: '/img/portfolio/zippia/resized/slider/zippia_6_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_6_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_6_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_6_large.webp 1400w'
  },
  {
    id: 8,
    img: '/img/portfolio/zippia/resized/slider/zippia_7_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_7_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_7_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_7_large.webp 1400w'
  },
  {
    id: 9,
    img: '/img/portfolio/zippia/resized/slider/zippia_8_large.webp',
    srcSet: '/img/portfolio/zippia/resized/slider/zippia_8_small.webp 600w, /img/portfolio/zippia/resized/slider/zippia_8_medium.webp 1000w,/img/portfolio/zippia/resized/slider/zippia_8_large.webp 1400w'
  },
];
