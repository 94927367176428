import React from 'react';
import {navigationSubMenu} from "../../mocks";
import {NavLink} from "react-router-dom";
import './CompanyMobDrawer.css';

export const CompanyMobDrawer = ({show, onLinkClick, onBackClick, categories}) => (
  <div className={`elx-company-drawer ${show ? 'elx-expertise-drawer--show' : ''}`}>
    <div className='elx-expertise-drawer-header'>
      <p onClick={onBackClick}>
        <span>
          <svg viewBox="0 0 256 256">
            <path d="M207.093 30.187L176.907 0l-128 128 128 128 30.186-30.187L109.28 128z"/>
          </svg>Back
        </span>
      </p>
    </div>
    {navigationSubMenu?.[categories]?.map(item => (
      <div className='elx-mob-link align-center' key={item.id}>
         {item.link.includes('https') ?
          (<a className="elx-menu-dropdown-link" href={item.link}> {item.name}</a>) :
          (<NavLink
            to={item.link}
            onClick={onLinkClick}
            exact activeClassName="active-nav">{item.name}
          </NavLink>)
        }
      </div>
    ))}
  </div>
);
