import React from 'react';
import './WdsWorkflow.css';

export const WdsWorkflow = () => (
  <div className='wds-workflow-container'>
    <div className='wds-workflow-item'>
      <img src="/img/wds/workflow/resized/space.webp" alt="space" loading="lazy" width='30' height='30'/>
      <h6>PLANNING</h6>
    </div>
    <div className='grey-line grey-line-1'></div>
    <div className='wds-workflow-item'>
      <img src="/img/wds/workflow/resized/dashboard.webp" alt="space" loading="lazy" width='30' height='30'/>
      <h6>PROTOTYPING</h6>
    </div>
    <div className='grey-line grey-line-2'></div>
    <div className='wds-workflow-item'>
      <img src="/img/wds/workflow/resized/source.webp" alt="space" loading="lazy" width='30' height='30'/>
      <h6>DEVELOPMENT</h6>
    </div>
    <div className='grey-line grey-line-3'></div>
    <div className='wds-workflow-item'>
      <img src="/img/wds/workflow/resized/qa.webp" alt="space" loading="lazy" width='30' height='30'/>
      <h6>TESTING (QA)</h6>
    </div>
    <div className='grey-line grey-line-4'></div>
    <div className='wds-workflow-item'>
      <img src="/img/wds/workflow/resized/startup.webp" alt="space" loading="lazy" width='30' height='30'/>
      <h6>LAUNCHING</h6>
    </div>
    <div className='grey-line grey-line-5'></div>
    <div className='wds-workflow-item'>
      <img src="/img/wds/workflow/resized/customer.webp" alt="space" loading="lazy" width='30' height='30'/>
      <h6>CONTINUOUS SUPPORT</h6>
    </div>
  </div>
);
