import React from 'react';
import './QaTools.css';
import {qaToolsContent} from "../../mocks";

const QaTools = () => {
    return (
        <div className="qa-tools-container">
            {
                qaToolsContent.map(({id, name, images}) => (
                    <div className="qa-tools-item" key={id}>
                            <p className="qa-tools-name">{name}</p>
                            <div className="qa-tools-img-wrapper">
                                {images.map((image, index) => (
                                    <img src={image} alt="qa tool" key={index}/>
                                ))}
                            </div>
                    </div>
                ))
            }
        </div>
    );
};

export default QaTools;