import React, { useEffect } from 'react';
import { Hero, PageBody, Spacer, Wrapper, CaseStudiesGrid } from '../../components';
import { ContactForm } from '../';
import { portfolioItems } from '../../mocks'
import './Portfolio.css';
import { delay, hideLoader, showLoader } from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import { breadcrumbStructuredData } from "../../utils/structuredData";

function Portfolio() {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  return (
    <React.Fragment>
      <HelmetSetter
        title='Case Studies in Custom Software Development and JS | ELITEX'
        description="Finding the custom approach in software development. Learn more about ELITEX's portfolio and how we’ve launched solutions for top companies from Fortune 500."
        ogImg="portfolio/case-studies.jpg"
        structuredData={breadcrumbStructuredData({
          name1: "Home",
          item1: "https://elitex.systems",
          name2: "Case Studies",
          item2: "https://elitex.systems/case-studies"
        })}
      />
      <Hero className="hero-big banner-with-img prt">
        <Wrapper id="page-title">
          <h1 className="h2 white-text">We Have a Great Experience</h1>
          <p className="white-text h4">We've helped our customer to launch a solution for one of the TOP 3</p>
          <p className="white-text h4">companies in Fortune 500 list</p>
        </Wrapper>
      </Hero>
      <PageBody>
        <CaseStudiesGrid list={portfolioItems} />
      </PageBody>
      <Spacer type='big' />
      <ContactForm />
    </React.Fragment>
  );
}

export { Portfolio };
