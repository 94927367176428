export const formSubmitEvent = () => {
  const dataLayer = window['dataLayer'];
  dataLayer.push({'event': 'contact_us_submit'});
  dataLayer.push({'event': 'foto_form_submit'});
};

export const clickEmailEvent = () => {
  const dataLayer = window['dataLayer'];
  dataLayer.push({'event': 'footer_email_click'});
};

export const clickSkypeEvent = () => {
  const dataLayer = window['dataLayer'];
  dataLayer.push({'event': 'footer_skype_click'});
};
