import React, {useEffect, useState} from 'react';
import './Breadcrumbs.css';
import {Link, withRouter} from "react-router-dom";
import {breadcrumbsList, breadcrumbsRoutes} from "../../mocks";
import {convertPaths, filterRoutes} from "../../utils";

function Breadcrumbs(props) {
    const pathName = props.location.pathname;

    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const pathsList = convertPaths(pathName)

    const updateBreadcrumbs = () => {
        const breadcrumbsPath = pathsList.length >= 2 ?
            filterRoutes(breadcrumbsList, ['/', ...pathsList]) :
            breadcrumbsRoutes.includes(pathName) ? filterRoutes(breadcrumbsList, ['/', ...pathsList]) : [];
        setBreadcrumbs(breadcrumbsPath);
    }

    useEffect(() => {
        updateBreadcrumbs();
    }, [pathName]);

    return (
        <nav className='breadcrumbs-container'>
            <ul className="breadcrumbs-list">
                {
                    breadcrumbs.map((crumb, index) => (

                        crumb.link === '/services' || crumb.link === '/technologies' ?
                            (<li key={crumb.id}>
                                <p className="active-link">{crumb.name}</p>
                            </li>)
                            : (
                                <li key={crumb.id}>
                                    <Link
                                        className={index === breadcrumbs.length - 1 ? 'disabled-link' : 'active-link'}
                                        to={crumb.link}>
                                        {crumb.name}
                                    </Link>
                                </li>
                            )
                    ))
                }
            </ul>
        </nav>
    );
}

export const BreadcrumbsWithRouter = withRouter(Breadcrumbs);