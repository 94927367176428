import React, {Fragment, useEffect} from 'react';
import './DotNetTech.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData, dotNetTechFaqStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    LogosList,
    PageBody,
    QaWhatWeDo,
    ScrollDownBtn,
    Spacer, WdsSlider,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {
    dotNetBenefitsCards,
    dotNetBenefitsTextOne,
    dotNetBenefitsTextTwo,
    dotNetBenefitsTitle,
    dotNetFaqList,
    dotNetMainCaptionText,
    dotNetMainCaptionTitle,
    dotNetMethodologyTextOne,
    dotNetMethodologyTextTwo,
    dotNetMethodologyTitle,
    dotNetProcessCards,
    dotNetProcessText,
    dotNetProjectText,
    dotNetWhatOfferCards,
    dotNetWhatOfferText,
    dotNetWhatOfferTitle,
    homeClientsSliderHeader,
    pythonTechnologiesLogos,
    testimonialItems,
} from "../../mocks";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import WhatDoWeOffer from "../../components/WhatDoWeOffer/WhatDoWeOffer";
import BlueLine from "../../components/BlueLine/BlueLine";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import {portfolioItem} from "../../utils";
import DedicatedProcess from "../../components/DedicatedProcess/DedicatedProcess";
import Faq from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";

export const DotNetTech = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <Fragment>
            <HelmetSetter
                title=".NET Development Services | ELITEX"
                description="Unlock the full power of .NET software development services together with ELITEX. Discover how a custom .NET development company can supercharge your product."
                ogImg="dotNet/dotnet-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: ".Net Development",
                    item2: "https://elitex.systems/dotnet-technology"
                })}
                faqStructuredData={dotNetTechFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img dot-net">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={dotNetMainCaptionTitle} text={dotNetMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-dot-net">
                <div id="testimonial-slider">
                    <SliderMaker dark
                                 clientsSliderHeader={homeClientsSliderHeader}
                                 sliderItems={testimonialItems}
                                 SliderItem={Feedback}
                                 speed={15000}
                                 dots
                    />
                </div>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{dotNetWhatOfferTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{dotNetWhatOfferText}</p>
                    <Spacer type="small"/>
                    <WhatDoWeOffer content={dotNetWhatOfferCards}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{dotNetBenefitsTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{dotNetBenefitsTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{dotNetBenefitsTextTwo}</p>
                    <Spacer type="big"/>
                    <QaWhatWeDo list={dotNetBenefitsCards}/>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation text={dotNetProjectText}/>
                <Spacer type="big"/>
                <h2 className="main-title" id="portfolio-grid">Check Out Our Case Studies</h2>
                <Spacer type="small"/>
                <CaseStudiesGrid list={[
                    portfolioItem('stm', false),
                    portfolioItem('kallik', true),
                    portfolioItem('postCMS', false),
                    portfolioItem('watstock', true),
                ]}/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">Why Choose ELITEX as a Custom <strong>.NET Development</strong> Company?
                    </h2>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{dotNetProcessText}</p>
                    <Spacer type="medium"/>
                    <DedicatedProcess content={dotNetProcessCards}/>
                    <Spacer type="big"/>
                    <h2 className="main-title left">{dotNetMethodologyTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle left tech">{dotNetMethodologyTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle left tech">{dotNetMethodologyTextTwo}</p>
                    <Spacer type="big"/>
                    <h2 className="main-title">Other Technologies We Provide</h2>
                    <Spacer type="medium"/>
                    <LogosList list={pythonTechnologiesLogos} tech/>
                </Wrapper>
                <Spacer type="big"/>
                <h2 className="main-title">Our Other Services</h2>
                <Spacer type="small"/>
                <WdsSlider/>
                <Spacer type="big"/>
                <Wrapper type="medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">FAQ About<strong> .NET Software Development</strong> Services</h2>
                    <Faq content={dotNetFaqList}/>
                </Wrapper>
                <Spacer type="big"/>
                <div className="case-study-grey-2">
                    <ContactForm/>
                </div>
            </PageBody>
        </Fragment>
    );
};
