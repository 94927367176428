import React, {useEffect, Fragment} from 'react';
import './Smartrr.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {portfolioItem} from "../../utils";
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData} from "../../utils/structuredData";
import {CaseStudyFeedback, Hero, Spacer, Wrapper} from "../../components";
import {ContactForm} from "../ContactForm/ContactForm";

const Smartrr = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    const item = portfolioItem('smartrr');

    return (
        <Fragment>
            <HelmetSetter
                title="Case Study - Smartrr: Subscription platform | ELITEX"
                description="Collaboration with the premium subscription services tool for DTC Shopify brands from the very early stages of the development. Designed with the end customer in mind."
                ogImg="portfolio/smartrr/smartrr-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: "Case Studies",
                    item2: "https://elitex.systems/case-studies",
                    name3: "Smartrr",
                    item3: "https://elitex.systems/case-studies/smartrr"
                })}
            />
            <Hero className="hero-big banner-with-img smartrr">
                <Wrapper id="page-title">
                    <div className="case-study-hero-divider-container">
                        <div className="case-study-hero-divider-txt">
                            <h1 className="white-text h2">{item.name}</h1>
                            <p className="white-text h5">{item.subName}</p>
                        </div>
                        <div className="case-study-hero-divider-img">
                            <img
                                src="/img/portfolio/smartrr/smartrr-notebook-2-large.webp"
                                srcSet="/img/portfolio/smartrr/smartrr-notebook-2-small.webp 600w,
                                       /img/portfolio/smartrr/smartrr-notebook-2-medium.webp 1100w,
                                       /img/portfolio/smartrr/smartrr-notebook-2-large.webp 1400w"
                                alt="notebook"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Wrapper>
            </Hero>
            <Spacer type="medium"/>
            <div className="case-study-page">
                <Wrapper>
                    <div className="case-study-column column-mb">
                        <img
                            src="/img/portfolio/smartrr/smartrr-logo.webp"
                            alt="logo"
                            width="170"
                            height="29"
                        />
                        <Spacer type="mini"/>
                        <h3 className="h4"><strong>Industry</strong></h3>
                        <p>E-commerce</p>
                        <Spacer type="mini"/>
                        <h3 className="h4"><strong>Technology</strong></h3>
                        <p>JavaScript (React.js), Node.js (Express.js), TypeScript, PostgreSQL, GraphQL (Apollo), Kubernetes, TypeORM, AWS, Google Cloud Platform</p>
                        <Spacer type="mini"/>
                        <h3 className="h4"><strong>Services</strong></h3>
                        <ul className="nolist">
                            <li>Dedicated development team</li>
                            <li>Quality assurance</li>
                            <li>DevOps services</li>
                            <li>Front-end development</li>
                            <li>Back-end development</li>
                        </ul>
                        <Spacer type="mini"/>
                        <h3 className="h4"><strong>Customer’s Location</strong></h3>
                        <p className="case-study-location">
                            <img
                                src="/img/portfolio/flags/usa_flag.webp"
                                alt="flag"
                                width="40"
                                height="40"
                            />
                            NY, United States
                        </p>
                        <Spacer type="small"/>
                        <a
                            href="https://smartrr.com/"
                            className="btn btn-default btn-sm"
                            target="_blank"
                            rel="noopener noreferrer">
                            View Site
                        </a>
                    </div>
                    <div className="case-study-60">
                        <h2 className="h4">About the <strong>project</strong></h2>
                        <p>Smartrr is a premium subscription services tool for DTC Shopify brands designed with the end customer in mind. It is fully integrated into Shopify’s platform and consists of three integral parts: the customer’s as well as vendor’s interfaces, auxiliary integration, and analytics tools. The platform provides a comprehensive membership experience that allows customers to focus on exclusive offers and additional discounts while businesses can focus on their growth journey without getting bogged down by spreadsheets leveraging the power of real-time analytics and reporting.</p>
                        <p>The tool also offers a wide range of native loyalty features for customers, including rewards and referrals, as well as tools for seamless integration of commerce and community involving influencers and creators into the process. Smartrr’s approach is based on the end customer and brand connection provided by smart retention, mobile-first design, email & SMS for subscription management combined with customer support service and ongoing onboarding.</p>
                        <Spacer type="medium"/>
                        <h2 className="h4">The <strong>challenge</strong></h2>
                        <p>ELITEX started collaborating with Smartrr at the very early stages of the development when the startup was around 6 months old. At this phase, Smartrr was a small but fast-growing and promising team. Smartrr was looking for QA specialists. At this stage, the goal was to create a quality assurance team and establish testing processes from scratch.</p>
                        <p>Furthermore, as the robust QA team was built, the business also experienced high growth, and it became evident that they needed to rapidly grow their team of developers.</p>
                    </div>
                </Wrapper>
                <Spacer type="medium"/>
                <img
                    src="/img/portfolio/smartrr/smartrr-back-2-large.webp"
                    srcSet="/img/portfolio/smartrr/smartrr-back-2-small.webp 600w,
                            /img/portfolio/smartrr/smartrr-back-2-large.webp 1440w"
                    alt="background"
                    loading="lazy"
                    width="100%"
                    height="100%"
                />
                <div className="case-study-relative">
                    <div className="case-study-flex-order">
                        <Wrapper>
                            <Spacer type="medium"/>
                            <div className="case-study-60">
                                <h2 className="h4">The <strong>solution</strong></h2>
                                <p>The partnership between Smartrr and ELITEX started with QA specialists. Initially, the small team of QA engineers was later expanded to include a DevOps engineer, back-end and front-end developers, additional QA specialists, a project manager, and tech support: ultimately, a fully integrated into the in-house team immersed into the project.</p>
                                <p>Right after the initial preparations and manual testing, the QA team proceeded with new features testing cases, regression/feature analysis, as well as improvement of the existing user experience, and planning of automation of QA processes. The collaboration between Smartrr and ELITEX was based on the principles of honesty and transparency, so the partnership flourished in clear communication and collaboration.</p>
                                <p>Regarding QA automation, the collaboration continues, and our team leverages the power of Typescript (Playwright framework) to create automated QA testing to streamline and expedite the overall testing process. Currently, the QA team is working on autotests, reducing the regression time and freeing up the time for feature testing.</p>
                            </div>
                            <Spacer type="medium"/>
                        </Wrapper>
                        <div className="case-study-grey feedback">
                            <Wrapper>
                                <div className="case-study-60">
                                    <CaseStudyFeedback
                                        cssClass="feedback-quotes-color"
                                        text="ELITEX has an excellent screening process for the new positions available we might have. They always do a very extensive technical screening so that, when we get on the phone with the candidates, there’s already a high level of confidence that they have the skillsets that we need. This process helps us shorten the time it takes to onboard new resources because our initial conversations with the candidates revolve around finding out if they’re the right fit for us instead of focusing on their experience and skills."
                                        author="- Smartrr"
                                    />
                                </div>
                            </Wrapper>
                        </div>
                        <Spacer type="medium"/>
                        <Wrapper>
                            <div className="case-study-60">
                            <p>The very essence of our partnership lies in building strong links between Smartrr and ELITEX team, which is working on the project. Our team is completely integrated with the Smartrr team, attending all standups, company huddles, and town halls - they are truly a part of the Smartrr team. This has brought a holistic approach to development, significantly improving the final product. A shared vision of the teams empowered the core house crew to focus on strategic initiatives and business objectives, propelling the company toward its long-term goals.</p>
                            </div>
                        </Wrapper>
                        <div className="case-study-order-3">
                            <Wrapper>
                                <div className="case-study-60">
                                    <Spacer type="medium"/>
                                    <h2 className="h4">The <strong>result</strong></h2>
                                    <p>Although Smartrr is a relatively young company,  the success the team has achieved in a few years is astounding. Being one of the most promising subscription e-commerce apps on the market recently,<a href="https://www.linkedin.com/posts/gabriella-tegen_ipo-nasdaq-leading100-activity-7064712120755466240-b-1m/?utm_source=share&utm_medium=member_desktop"> Smartrr has already gained deserved recognition.</a> And ELITEX is proud to be a part of the project's overall success.</p>
                                </div>
                            </Wrapper>
                            <Spacer type="medium"/>
                            <div className="case-study-grey feedback">
                                <Wrapper>
                                    <div className="case-study-60">
                                        <CaseStudyFeedback
                                            cssClass="feedback-quotes-color"
                                            text="We so appreciate the partnership we hold with ELITEX. At critical times of our growth, they have helped us expand our team with excellent talent."
                                            author="- Smartrr"
                                        />
                                    </div>
                                </Wrapper>
                            </div>
                            <Spacer type="medium"/>
                            <Wrapper>
                                <div className="case-study-60">
                                    <p>As of today, the Smartrr team still has plenty of ideas to be implemented in their platform, and, for its part, the ELITEX team is ready to do everything we can to ensure the future success of the ongoing collaboration.</p>
                                </div>
                            </Wrapper>
                        </div>
                    </div>
                </div>
                <Spacer type="small"/>
            </div>
            <div className="case-study-grey-2">
                <ContactForm/>
            </div>
        </Fragment>
    );
};

export {Smartrr};