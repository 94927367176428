import React, {Fragment} from 'react';
import Slider from "react-slick";
import {MainSliderItem} from "../../components";
import "./MainSlider.css";
import {MainCaption} from "../MainCaption/MainCaption";
import '../MainCaption/MainCaption.css';

export const MainSlider = ({sliderItems, contactUsBtn, caseStudiesBtn}) => {
 const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 400,
    fade: true,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 4000,
  };
    return (
        <div className="main-slider">
          <Slider {...settings}>
            {sliderItems.map((elem) => (
                <Fragment key={elem.id}>
                  <MainSliderItem {...elem}/>
                  <MainCaption title={elem.title} text={elem.text} contactUsBtn={contactUsBtn} caseStudiesBtn={caseStudiesBtn}/>
                </Fragment>
            ))}
          </Slider>
        </div>
    );
}
