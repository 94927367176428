const jsdTechText = "As an experienced JavaScript development company, ELITEX uses all the most popular JavaScript frameworks: React.js, Node.js, Angular, Vue.js, and others. These technologies help us to create robust and cost-effective web and mobile applications. With deep expertise in web development, the latest technology stack, and best coding practices, ELITEX developers deliver practical, scalable, and secure software solutions to build solutions that exceed your expectations.";

 const jsdBenefitsText = "JavaScript Development Benefits";

 const jsdFaqTitle = {
    main: "Custom Javascript Development Services",
    strong: "FAQs"
}

export {jsdTechText, jsdBenefitsText, jsdFaqTitle}
