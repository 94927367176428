import React, {useEffect} from 'react';
import './ReactTech.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData, reactTechFaqStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    JsdLogos,
    PageBody,
    QaRelatedArticles,
    ScrollDownBtn,
    Spacer,
    WdsSlider,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {
    reactCaseStudyTitle,
    reactFaqContent,
    reactFaqTitle,
    reactJsdLogosTitle,
    reactMainCaptionText,
    reactMainCaptionTitle,
    reactProcessContent,
    reactProcessText,
    reactProcessTitle,
    reactServicesSliderTitle,
    reactTechAwardsContent,
    reactWhatOfferCards,
    reactWhatOfferText,
    reactWhatOfferTitle,
    homeClientsSliderHeader,
    testimonialItems
} from "../../mocks";
import TechAwards from "../../components/TechAwards/TechAwards";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import BlueLine from "../../components/BlueLine/BlueLine";
import ProcessStages from "../../components/ProcessStages/ProcessStages";
import ReactDevCompany from "../../components/ReactDevCompany/ReactDevCompany";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import {portfolioItem} from "../../utils";
import FAQ from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";
import TechWhatOfferCards from "../../components/TechWhatOfferCards/TechWhatOfferCards";

export const ReactTech = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <React.Fragment>
            <HelmetSetter
                title="React.js Development Services | ELITEX"
                description="Discover how React.js app development services can benefit your business. Here are some hints from ELITEX, the trusted React.js development company."
                ogImg="react/react-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "React",
                    item2: "https://elitex.systems/react-technology"
                })}
                faqStructuredData={reactTechFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img react">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={reactMainCaptionTitle} text={reactMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-react">
                <Wrapper className="wrapper-medium">
                    <TechAwards content={reactTechAwardsContent}/>
                    <Spacer type="big"/>
                </Wrapper>
                <div id="testimonial-slider">
                    <SliderMaker dark clientsSliderHeader={homeClientsSliderHeader} sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <TechWhatOfferCards title={reactWhatOfferTitle} text={reactWhatOfferText} cardsContent={reactWhatOfferCards}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{reactProcessTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{reactProcessText}</p>
                    <Spacer type="big"/>
                    <ProcessStages content={reactProcessContent}/>
                    <Spacer type="big"/>
                    <ReactDevCompany/>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation/>
                <Spacer type="big"/>
                <h2 className="main-title"><strong>{reactCaseStudyTitle.strong}</strong> {reactCaseStudyTitle.main}</h2>
                <Spacer type="small"/>
                <CaseStudiesGrid list={[
                    portfolioItem('hotelReservations', false),
                    portfolioItem('zippia', true),
                    portfolioItem('switchboard', false),
                    portfolioItem('model-master', true)
                ]}/>
                <Spacer type="big"/>
                <h2 className="main-title"><strong>{reactServicesSliderTitle.strong}</strong> {reactServicesSliderTitle.main}</h2>
                <Spacer type="small"/>
                <WdsSlider/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <FAQ title={reactFaqTitle} content={reactFaqContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{reactJsdLogosTitle.main} <strong>{reactJsdLogosTitle.strong}</strong></h2>
                    <Spacer type="medium"/>
                    <JsdLogos/>
                    <Spacer type="big"/>
                    <h2 className='main-title'><strong>Related Content</strong></h2>
                    <Spacer type="medium"/>
                    <QaRelatedArticles/>
                    <Spacer type="big"/>
                </Wrapper>
                <div className='case-study-grey-2'>
                    <ContactForm/>
                </div>
            </PageBody>
        </React.Fragment>
    );
};
