export const postCmsSliders = [
  {
    id: 1,
    img: '/img/portfolio/postcms/resized/slider/postcms_1_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_1_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_1_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_1_large.webp 1400w'
  },
  {
    id: 2,
    img: '/img/portfolio/postcms/resized/slider/postcms_2_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_2_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_2_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_2_large.webp 1400w'
  },
  {
    id: 3,
    img: '/img/portfolio/postcms/resized/slider/postcms_3_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_3_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_3_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_3_large.webp 1400w'
  },
  {
    id: 4,
    img: '/img/portfolio/postcms/resized/slider/postcms_4_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_4_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_4_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_4_large.webp 1400w'
  },
  {
    id: 5,
    img: '/img/portfolio/postcms/resized/slider/postcms_5_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_5_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_5_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_5_large.webp 1400w'
  },
  {
    id: 6,
    img: '/img/portfolio/postcms/resized/slider/postcms_6_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_6_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_6_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_6_large.webp 1400w'
  },
  {
    id: 7,
    img: '/img/portfolio/postcms/resized/slider/postcms_7_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_7_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_7_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_7_large.webp 1400w'
  },
  {
    id: 8,
    img: '/img/portfolio/postcms/resized/slider/postcms_8_large.webp',
    srcSet: '/img/portfolio/postcms/resized/slider/postcms_8_small.webp 600w, /img/portfolio/postcms/resized/slider/postcms_8_medium.webp 1000w,/img/portfolio/postcms/resized/slider/postcms_8_large.webp 1400w'
  }
];
