export const homeVariousIndustries = [
  {
    id: 1,
    title: "Fintech and e-commerce solutions",
    text: "We specialize in bringing custom solutions for online banking, mobile payments, investment management, peer-to-peer lending, as well as e-commerce development. Our team possesses extensive skills and knowledge in developing secure and decentralized financial applications. For e-commerce, we build robust end-to-end solutions, including shopping cart development, order management systems, payment gateway integration, and website and mobile application designing and developing services.",
    img: "/img/home/industries/resized/fintech.webp",
    tagName: "h4"
  },
  {
    id: 2,
    title: "Telecom & Cybersecurity",
    text: "Our team consists of professionals with up to 25 years of industry expertise. We provide telecom and cybersecurity development services that help businesses improve real-time communication systems and protect against cyber threats. Our services comprise VoIP development, mobile systems, broadband solutions, and network design and implementation. We also offer cybersecurity services, such as penetration testing, threat analysis, and security consulting, to safeguard your business's data.",
    img: "/img/home/industries/resized/shield.webp",
    tagName: "h4"
  },
  {
    id: 3,
    title: "News & Media",
    text: "We provide tech solutions for the news and media industry, offering customized content management tools, digital asset management, the transformation of legacy systems, and software solutions to suit a modern market and enhance the companies’ reach and engagement with their audiences.",
    img: "/img/home/industries/resized/journalist.webp",
    tagName: "h4"
  },
  {
    id: 5,
    title: "Information Technology",
    text: "We can augment IT development teams as an advanced software development outsourcing agency. We can help with development issues related to design, web development, security, and QA.",
    img: "/img/home/industries/resized/report.webp",
    tagName: "h4"
  },
  {
    id: 4,
    title: "Education",
    text: "We’ll help you build your custom e-learning or training platform, cloud-based management systems for students and teachers, and educational mobile apps. Our solutions in app development help educational institutions deliver engaging and interactive content to their students, manage administrative tasks efficiently, and improve student outcomes.",
    img: "/img/home/industries/resized/school.webp",
    tagName: "h4"
  },

  {
    id: 6,
    title: "Dashboards & CRM",
    text: "We offer dashboards and CRM services, including developing custom dashboards to control various data and creating customized CRM and ERP systems tailored to specific business goals. We help enterprises efficiently manage data and customer relationships, enabling them to make data-driven decisions and, as a result, improve customer satisfaction.",
    img: "/img/home/industries/resized/dashboard.webp",
    tagName: "h4"
  },
  {
    id: 7,
    title: "Real Estate",
    text: "We can help you with database software, CRM, ERP systems, and property management applications. We offer tailored solutions, understanding the specific need of the real estate business, enabling them to optimize their workflows and improve the guest experience and customer satisfaction.",
    img: "/img/home/industries/resized/code.svg",
    tagName: "h4"
  },
  {
    id: 8,
    title: "Content Management",
    text: "Fast downloading, simple UI/UX design, user management, templating system, and the ability to work with any content. We offer advanced solutions that help our clients to manage their content faster and more efficiently, enhancing the overall digital experience of customers.",
    img: "/img/home/industries/resized/management.svg",
    tagName: "h4"
  },
];
