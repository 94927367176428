export const careerRecruitersList = [
    {
        id: 1,
        img: '/img/career/recruiters/resized/iryna.webp',
        name: 'Iryna Panchuk',
        linkedin: 'https://www.linkedin.com/in/irynapanchuk/',
        mail: 'mailto:ipanchuk@elitex.systems',
        // skype: 'skype:live:panchuk.iryna.v?chat'
    },
    {
        id: 2,
        img: '/img/career/recruiters/resized/khrystyna.webp',
        name: 'Khrystyna Skochylyas',
        linkedin: 'https://www.linkedin.com/in/khrystyna-skochylyas/',
        mail: 'mailto:kskochylyas@elitex.systems',
        // skype: 'skype:live:.cid.8daec2d82d5df627?chat'
    },
    {
        id: 3,
        img: '/img/career/recruiters/resized/victoria-dzuiba.webp',
        name: 'Victoria Dzuiba',
        linkedin: 'https://www.linkedin.com/in/%F0%9F%8C%9Fvictoria-dzuiba-159a1b8b/',
        mail: 'mailto:vdzuiba@elitex.systems',
    },
    {
        id: 4,
        img: '/img/career/recruiters/resized/anastasia.webp',
        name: 'Anastasiia Dolynska',
        linkedin: 'https://www.linkedin.com/in/anastasia-dolynska-6331a8225/',
        mail: 'mailto:ndolynska@elitex.systems',
        // skype: 'skype:live:anastasiya.dolinska?chat'
    },
];
