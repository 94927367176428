import React, {Fragment} from 'react';
import './ReactDevCompany.css';
import {
    reactDevCompanyContent,
    reactDevCompanyText, reactDevCompanyTextTwo,
    reactDevCompanyTitle,
} from "../../mocks/reactTechPage.mock";
import BlueLine from "../BlueLine/BlueLine";
import {Spacer} from "../Spacer/Spacer";

const ReactDevCompany = () => {
    return (
        <Fragment>
            <h2 className="main-title"><strong>{reactDevCompanyTitle.strong}</strong> {reactDevCompanyTitle.main}</h2>
            <BlueLine/>
            <p className="main-subtitle">{reactDevCompanyText}</p>
            <Spacer type="medium"/>
            <div className="react-dev-company-cards-wrapper">
                {
                    reactDevCompanyContent.map(({id, text}) => (
                        <div key={id} className="react-dev-company-card">
                            <img
                                src="/img/react/done-circle-arrow.svg"
                                alt="done arrow"
                                width="24"
                                height="24"
                            />
                            <p className="react-dev-company-text card">{text}</p>
                        </div>
                    ))
                }
            </div>
            <Spacer type="medium"/>
            <p className="react-dev-company-text secondary">{reactDevCompanyTextTwo}</p>
        </Fragment>
    );
};

export default ReactDevCompany;