import React from 'react';

export const EmailField = ({
   reference, defaultValue, placeholder = "Email", required, onFocus, errorBorder, errorMessage, errorMessage2, ...rest
  }) => {
  let border = errorBorder ? 'invalid' : '';
  let errorMessageEmpty = errorMessage ? 'errors' : '';
  let errorMessageNotEmail = errorMessage2 ? 'errors' : '';

  return (
    <div className="form-row">
      <input
        placeholder={placeholder}
        name='email'
        className={border}
        type="text"
        ref={reference}
        onFocus={onFocus}
        defaultValue={defaultValue}
        {...rest} />
      { required && <span className={errorMessageEmpty}>Email is required</span>}
      <span className={errorMessageNotEmail}>Should be a valid email address</span>
    </div>
  )
};
