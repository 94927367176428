import React, {useEffect} from 'react';
import './Salsify.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, CaseStudyFeedback, Spacer, Wrapper} from "../../components";
import {ContactForm, CaseStudyCustomSlider} from "..";
import {salsifySliders} from "../../mocks";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const Salsify = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('salsify');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Case study - Mobile-first frontend system | ELITEX'
        description='Software development solution for North American bureau of Il Sole 24 ORE that was successfully delivered to the customer who could take it to the next level.'
        ogImg='portfolio/salsify/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'Salsify',
          item3: 'https://elitex.systems/case-studies/salsify'
        })}
      />
      <Hero className="hero-big banner-with-img salsify">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className="white-text h1">{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/salsify/webp/salsify_notebook.webp"
                  srcSet="/img/portfolio/salsify/resized/salsify_notebook_2_small.webp 600w,
                          /img/portfolio/salsify/webp/salsify_notebook.webp 1400w"
                  alt="notebook"
                  width="100%"
                  height="100%"
              />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>Media</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>AngularJS, NodeJS, Sails.js, PhantomJS, Gulp, Sass, HTML5, CSS3.</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Full-cycle web development</li>
              <li>Front-end development</li>
              <li>Back-end development</li>
              <li>Quality assurance</li>
              <li>Technical consultancy</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/usa_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />The United States / Italy
            </p>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>The client is the North American bureau of Il Sole 24 ORE, Italy’s leading financial daily newspaper, published by 24 Ore Group. In the field of services tailored to professionals and businesses, the Group enjoys a solid competitive market position thanks to its databanks, online services, and training programs.</p>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>challenge</strong></h2>
            <p>Sites belonging to the 24 Ore Group are built with old technologies and look outdated. </p>
            <p>Moreover, their owners share the same issue as our customer – as the systems are huge, have lots of dependencies, and have been developed over the years. Rewriting legacy apps from scratch or migrating them to new technologies would take a lot of time and, even more important – a solid budget.</p>
            <p>Since, from a technical perspective, the most important part of the website for readers is the customer-facing front end, the idea was to renew only this part. To develop a solution that could be
              plugged into any existing web app or CMS, use its data, but display them in its own way.</p>
            <p>In addition, the solution had to:</p>
            <ul className='case-study-ul case-study-list-bullet peerwith'>
              <li>Improve the accessibility of content on the web</li>
              <li>Provide better tools for content producers</li>
              <li>Meet the needs of both users and search engines</li>
              <li>Offer a cost-effective platform</li>
            </ul>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>solution</strong></h2>
            <p>The ELITEX team didn’t want to start with the traditional approach of building apps for the web. Instead,
              we rethought the architecture and decided to go another way. The chosen solution was more similar to that of building a mobile app.</p>
          </div>
        </Wrapper>

        <Spacer type='medium'/>
        <div className='case-study-grey-3'>
          <Wrapper>
            <CaseStudyCustomSlider slides={salsifySliders}/>
          </Wrapper>
        </div>

        {/*sticky zone*/}
        <div className="case-study-relative">
          <div className='case-study-flex-order'>
            <Wrapper>
              <Spacer type='medium'/>
              <div className='case-study-60'>
                <h4>We chose JavaScript as the primary language.</h4>
                <p>On top of JavaScript itself, it was decided to use AngularJS, Node.js, and Sails.js. From the project
                  management perspective, the scrum pattern was chosen so the team was able to show the customer the
                  interim results each sprint.</p>
                <p>The system was built with a microservice architecture. That means that the server-side app consists of two parts:</p>
                <ul className='case-study-ul case-study-list-bullet peerwith'>
                  <li>REST API that responds to users’ requests</li>
                  <li>Service that gets content from external data sources, parses, and stores it in our database.</li>
                </ul>
                <p>These services communicate with the help of queues and can be easily scaled. Our developers used a MongoDB database to handle large volumes of structured and unstructured data.</p>
                <p>The specific architecture allows most of the pages to be loaded without any delay at all, meaning the
                  application is blazingly fast. It was accomplished as a big part of the logic, as well as templates, are preloaded and cached on the front end.</p>
                <p>The user’s browser loads it only once during the first visit to the site. When the user opens any
                  data on the app, there is no need to render the page on the server and load everything from the
                  beginning.</p>
                <p>The server works as a REST API, returning only raw data. This includes the newly published content –
                  the site loads all the latest data at once. It is a powerful approach to creating modern web
                  applications.</p>
              </div>
              <Spacer type='medium'/>
            </Wrapper>

            <div className='case-study-grey'>
              <Wrapper>
                <div className='case-study-60'>
                  <CaseStudyFeedback
                    cssClass={'feedback-quotes-color'}
                    img='/img/clients/pierpaolo.jpg'
                    name='Pier Paolo Bozzano'
                    position='Head of Software Development | America24'
                    text='From concept to the final product, ELITEX has been a precious resource for our company and the mind behind our content management system. It is about more than just great knowledge in web programming; ELITEX guys take their role further. They offer their ideas and perspective and contribute to making things that were not there before, which is true.'/>
                </div>
              </Wrapper>
            </div>
            <Wrapper>
              <div className='case-study-60'>
                <Spacer type='medium'/>
                <p>Moreover, the right architecture implemented by the ELITEX team enabled a couple of other great
                  features:</p>
                <ul className='case-study-ul case-study-list-bullet peerwith'>
                  <li><strong>The app can work offline.</strong><br/>
                    It is possible as all the recent data is preloaded, and there is no need to make requests to the server. The user may not even notice that their device went offline as the app continues working
                    smoothly.
                  </li>
                  <li><strong>All the additional infrastructure needed for the platform to work is one single cheap server.</strong><br/>
                    We have chosen a small AWS instance for our needs. As the back end doesn’t play a key role anymore, one server can handle a pretty big load; therefore, the platform can save a reasonable budget on the infrastructure and its maintenance. In case a particular site has a really huge number of users, the backend can be easily scaled horizontally.
                  </li>
                </ul>
                <p>As one of the key goals was to build a system that could work on top of existing web apps, Salsify was designed to be easily adapted to the existing back-ends – e.g., various APIs, XML feeds, and CMS (WordPress, Methode, etc.)</p>
                <p>At the same time, the UI design can be easily changed to the needs of the particular site as well, considering that the templates, typography, and styles are properly separated from the logic.</p>
              </div>
            </Wrapper>

            <div className="case-study-order-3">
              <Wrapper>
                <div className='case-study-60'>
                  <Spacer type='medium'/>
                  <h2 className='h4'>The <strong>result</strong></h2>
                  <p>As a result, ELITEX has met the goal of engineering a blazingly fast, efficient, and smooth state-of-the-art system that is able to make the web faster. The platform was successfully delivered to the customer, who could take it to the next level.</p>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>

        <Spacer type='small'/>

      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>
    </React.Fragment>
  )
};
