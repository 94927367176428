import React, {useEffect} from 'react';
import './Exterity.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {portfolioItem} from "../../utils";
import LazyLoad from "react-lazyload";
import {breadcrumbStructuredData} from "../../utils/structuredData";
import {exterityVideoStructuredData} from "../../mocks/portfolioStructuredData";

export const Exterity = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    const item = portfolioItem('exterity');

    return (
        <React.Fragment>
            <HelmetSetter
                title='Digital Signage and IPTV solution for a market leader | ELITEX'
                description='Read about our approach and custom solutions in the story of our partnership with Exterity, the market leader in IP video and digital signage technology.'
                ogImg='portfolio/exterity/back.jpg'
                videoStructuredData={exterityVideoStructuredData}
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Case Studies',
                    item2: 'https://elitex.systems/case-studies',
                    name3: 'Exterity',
                    item3: 'https://elitex.systems/case-studies/exterity'
                })}
            />
            <Hero className="hero-big banner-with-img exterity">
                <Wrapper id="page-title">
                    <div className='case-study-hero-divider-container'>
                        <div className='case-study-hero-divider-txt'>
                            <h1 className="white-text h1">{item.name}</h1>
                            <p className='white-text h5'>{item.subName}</p>
                        </div>
                        <div className='case-study-hero-divider-img'>
                            <img
                                src="/img/portfolio/exterity/webp/exterity_notebook.webp"
                                srcSet="/img/portfolio/exterity/resized/exterity_notebook_2_small.webp 600w,
                  /img/portfolio/exterity/resized/exterity_notebook_2_medium.webp 1100w,
                 /img/portfolio/exterity/webp/exterity_notebook.webp 1400w"
                                alt="notebook"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Wrapper>
            </Hero>

            <Spacer type='medium'/>
            <div className='case-study-page'>
                <Wrapper>
                    <div className='case-study-column column-mb'>
                        <img
                            src="/img/portfolio/exterity/resized/exterity_logo.webp"
                            alt="logo"
                            width="170"
                            height="73"
                        />
                        <Spacer type='medium'/>
                        <h3 className='h4'><strong>Industry</strong></h3>
                        <p>Media, IPTV, Digital Signage</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Technology</strong></h3>
                        <p>NodeJS, React, AngularJS</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Services</strong></h3>
                        <ul className="nolist">
                            <li>Front-end development</li>
                            <li>Back-end development</li>
                            <li>Quality assurance</li>
                            <li>Product extension</li>
                            <li>Team augmentation</li>
                        </ul>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Customer’s Location</strong></h3>
                        <p className='case-study-location'>
                            <img
                                src="/img/portfolio/flags/united-kingdom_flag.webp"
                                alt="flag"
                                width="40"
                                height="40"
                            />
                            The United Kingdom
                        </p>
                    </div>

                    <div className='case-study-60'>
                        <h2 className='h4'>About the <strong>project</strong></h2>
                        <p>Our client is a market-leading provider of IP video and digital signage technology that helps
                            organizations to harness the power of video to engage, inform and entertain. Their
                            end-to-end IPTV and digital signage solutions enable users to capture TV and video content
                            directly from any source.</p>
                        <p>With IPTV, the user can easily distribute terrestrial and satellite television and radio,
                            videos, DVDs,
                            digital video-on-demand (VoD), digital signage, information boards, and web content
                            throughout the
                            facility.</p>
                        <p>Exterity’s customers include BBC, NHS, Thomson Reuters, Dubai Airport, University College
                            London, Stade
                            Pierre-Mauroy, Sanofi, The Open, Six Senses Zighy Bay Oman, Royal Randwick Racecourse,
                            Phoenix TV Beijing,
                            and many more.</p>
                        <Spacer type='mini'/>
                        <LazyLoad>
                            <img
                                src="/img/portfolio/exterity/resized/exterity_customers.webp"
                                alt="customers"
                                width="100%"
                                height="100%"
                            />
                        </LazyLoad>
                        <h2 className='h4'>The <strong>challenge</strong></h2>
                        <p>To keep products maintained at a high level and keep developing new solutions, our customer
                            decided to extend the capacity of their core engineering team by using an external service
                            provider.</p>
                        <p>The additional requirements for the potential partner included having <strong>strong
                            JavaScript and UI
                            experience</strong> to augment its own technical expertise.</p>
                    </div>
                </Wrapper>

                <Spacer type='medium'/>
                <div className='case-study-grey-3'>
                    <Wrapper>
                        <div className='iframe-container'>
                            <div className='iframe-video-wrapper'>
                                <iframe src="https://www.youtube.com/embed/8I-Ab8Yaqqw?controls=0&modestbranding=1&rel=0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen title='exterity'></iframe>
                            </div>
                        </div>
                    </Wrapper>
                </div>

                {/*sticky zone*/}
                <div className="case-study-relative">
                    <div className='case-study-flex-order'>
                        <Wrapper>
                            <Spacer type='medium'/>
                            <div className='case-study-60'>
                                <h2 className='h4'>The <strong>solution</strong></h2>
                                <p>Since the start of our cooperation, the team has mainly worked on two products.</p>
                                <p>The first one is an aftercare warranty solution that provides end users with software
                                    and firmware
                                    updates. It also includes:</p>
                                <ul className='case-study-ul case-study-list-bullet peerwith'>
                                    <li>access to the support team</li>
                                    <li>bug fixes</li>
                                    <li>additional user interface options.</li>
                                </ul>
                                <p>With its help, users can maximize system performance, manage devices, update them,
                                    and improve functionality.</p>
                                <p>For this product, our JavaScript engineers have successfully developed the front-end
                                    side using the
                                    AngularJS framework, which was already used in the core app.</p>
                                <p>Our quality assurance engineer monitors the development process at every stage to
                                    ensure all the functionality works properly.</p>
                                <p>As the development of some parts of the system requires testing on physical devices,
                                    e.g., certain commercial TVs or customers’ own media players, such hardware was set
                                    up in the ELITEX office so that the team could work efficiently.</p>
                                <p><strong>The second product</strong> is a great video analytics tool, offering access
                                    to various statistics, including the number and duration of views, etc.</p>
                                <p>As this product was new and had to be developed from scratch, the decision was made
                                    to build it
                                    with modern tools:</p>
                                <ul className='case-study-ul peerwith-list-bullet'>
                                    <li>React framework on the front-end side</li>
                                    <li>Node.js on the back end.</li>
                                </ul>
                            </div>
                        </Wrapper>
                        <div className="case-study-order-3">
                            <Wrapper>
                                <div className='case-study-60'>
                                    <Spacer type='medium'/>
                                    <h2 className='h4'>The <strong>result</strong></h2>
                                    <p>Currently, the team consists of the following:</p>
                                    <ul className='case-study-ul case-study-list-bullet peerwith'>
                                        <li>JavaScript developers of various levels</li>
                                        <li>QA engineer</li>
                                        <li>Project manager</li>
                                    </ul>
                                    <p>The team's main task is to extend the customer’s product functionality and
                                        support the existing apps.</p>
                                    <p>As a result, the complex of Exterity’s solutions became even more robust,
                                        allowing the company to fulfill the needs of existing customers and engage with
                                        new customers worldwide.</p>
                                </div>
                            </Wrapper>
                        </div>
                    </div>
                </div>
                <Spacer type='small'/>
            </div>
            <div className='case-study-grey-2'>
                <ContactForm/>
            </div>
        </React.Fragment>
    )
};
