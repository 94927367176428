import React, {useEffect} from 'react';
import './UiDevelopment.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {
  CaseStudiesGrid,
  Feedback,
  Hero,
  PageBody,
  Spacer,
  WdsSlider,
  Wrapper,
  LogosList,
  JsdLogos,
  QaRelatedArticles, ScrollDownBtn
} from "../../components";
import {portfolioItem} from "../../utils";
import {ContactForm, MainCaption, SliderMaker} from "..";
import {breadcrumbStructuredData, uiFaqStructuredData} from "../../utils/structuredData";
import {
  customersLogos,
  jsdTechText,
  testimonialItems,
  uiWhatOfferCards,
  uiWhatOfferText,
  uiWhatOfferTitle,
  uiFaqContent,
  uiFaqTitle,
  uiMainCaptionText,
  uiMainCaptionTitle,
  uiClientsSliderHeader, uiWhyChooseText, uiWhyChooseList, whyChooseTitle, uiWhyChooseSubtitle, uiWhyChooseTitle
} from "../../mocks";
import WhatDoWeOffer from "../../components/WhatDoWeOffer/WhatDoWeOffer";
import UiDevProcess from "../../components/UiDevProcess/UiDevProcess";
import UiWhyChooseElitex from "../../components/UiWhyChooseElitex/UiWhyChooseElitex";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import BlueLine from "../../components/BlueLine/BlueLine";
import Faq from "../../components/FAQ/FAQ";

function UiDevelopment() {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  return (
    <React.Fragment>
      <HelmetSetter
        title='UI/UX Design and Development Services | ELITEX'
        description="Change how your online business looks with UI/UX design and development services. Check out ELITEX, a trusted UX UI development company beyond expectations."
        ogImg='uid/back.jpg'
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'UI/UX Development',
          item2: 'https://elitex.systems/ui-development'
        })}
        faqStructuredData={uiFaqStructuredData}
      />
      <Hero className="hero-big banner-with-img uid">
       <Wrapper className="wrapper-medium">
         <MainCaption title={uiMainCaptionTitle} text={uiMainCaptionText} contactUsBtn/>
         <ScrollDownBtn/>
       </Wrapper>
      </Hero>
      <PageBody>
        <div id="testimonial-slider">
        <SliderMaker dark clientsSliderHeader={uiClientsSliderHeader} sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
        </div>
        <Wrapper type="medium">
          <Spacer type='big'/>
          <h2 className="main-title">{uiWhatOfferTitle}</h2>
          <BlueLine/>
          <p className="main-subtitle">{uiWhatOfferText}</p>
          <Spacer type="small"/>
          <WhatDoWeOffer content={uiWhatOfferCards}/>
          <Spacer type='big'/>
          <UiDevProcess/>
          <Spacer type='big'/>
          <h2 className='tech-title'>Technologies We Use in Our UI/UX Development Services</h2>
          <Spacer type='medium'/>
          <p className='tech-text'>{jsdTechText}</p>
          <Spacer type='medium'/>
          <JsdLogos/>
          <Spacer type='big'/>
          <h2 className="main-title">{uiWhyChooseTitle}</h2>
          <BlueLine/>
          <p className="main-subtitle ui-ux">{uiWhyChooseSubtitle}</p>
          <UiWhyChooseElitex list={uiWhyChooseList}/>
          <p className="main-subtitle">{uiWhyChooseText}</p>
        </Wrapper>
        <Spacer type='big'/>
        <ProjectConsultation/>
        <Spacer type='big'/>
        <Wrapper className="wrapper-medium">
          <h2 className='main-title'>Utilize the Expertise of Our Design Team</h2>
          <BlueLine/>
          <p className='main-subtitle'>A decade of experience in UI/UX app development services allows us to build
            efficient design strategies that bring robust results. Utilize the expertise of the ELITEX design teams to
            ensure that every aspect of your application is finely tuned for user engagement, aesthetics, functionality,
            and, ultimately, user satisfaction. Our seasoned professionals will work closely with you to craft a
            user-centered experience that sets your app apart, exceeding all your initial expectations.</p>
          <p className='main-subtitle'>As a UI/UX development company with a decade of experience, we know how to build apps that users love and find easy to use. At the same time, we know how to help you scale your existing in-house team. We can strengthen your project with our dedicated developers and designers integrated seamlessly into your workflow. With fresh skills and ideas brought by our experienced professionals, we can help you grow quickly and tackle bigger challenges with ease.</p>
          <Spacer type='big'/>
          <h2 className='main-title'>Our Clients and Partners</h2>
          <LogosList list={customersLogos} redirect/>
          <Spacer type='big'/>
        </Wrapper>
        <h2 className='main-title'>Take a Look at Our Case Studies:</h2>
        <Spacer type='small'/>
        <CaseStudiesGrid list={[
          portfolioItem('model-master', false),
          portfolioItem('switchboard', true),
          portfolioItem('exterity', false),
          portfolioItem('zippia', true)
        ]}/>
        <Spacer type='big'/>
        <h2 className='main-title'>Other Services <strong>We Provide</strong></h2>
        <WdsSlider/>
        <Wrapper className="wrapper-medium">
          <Spacer type="big"/>
          <Faq title={uiFaqTitle} content={uiFaqContent}/>
          <Spacer type="big"/>
          <h2 className="main-title"><strong>Related Content</strong></h2>
          <Spacer type="medium"/>
          <QaRelatedArticles/>
        </Wrapper>
        <Spacer type='big'/>
        <div className='case-study-grey-2'>
          <ContactForm/>
        </div>
      </PageBody>
    </React.Fragment>
  );
}

export {UiDevelopment};
