export const stmSliders = [
    {
        id: 1,
        img: '/img/portfolio/stm/resized/slider/stm_1_large.webp',
        srcSet: '/img/portfolio/stm/resized/slider/stm_1_small.webp 600w, /img/portfolio/stm/resized/slider/stm_1_medium.webp 1000w,/img/portfolio/stm/resized/slider/stm_1_large.webp 1400w'
    },
    {
        id: 2,
        img: '/img/portfolio/stm/resized/slider/stm_2_large.webp',
        srcSet: '/img/portfolio/stm/resized/slider/stm_2_small.webp 600w, /img/portfolio/stm/resized/slider/stm_2_medium.webp 1000w,/img/portfolio/stm/resized/slider/stm_2_large.webp 1400w'
    },
    {
        id: 3,
        img: '/img/portfolio/stm/webp/slider/stm3.webp',
        srcSet: '/img/portfolio/stm/resized/slider/stm_3_small.webp 600w, /img/portfolio/stm/resized/slider/stm_3_medium.webp 1000w,/img/portfolio/stm/webp/slider/stm3.webp 1400w'
    },
    {
        id: 4,
        img: '/img/portfolio/stm/resized/slider/stm_4_large.webp',
        srcSet: '/img/portfolio/stm/resized/slider/stm_4_small.webp 600w, /img/portfolio/stm/resized/slider/stm_4_medium.webp 1000w,/img/portfolio/stm/resized/slider/stm_4_large.webp 1400w'
    },
    {
        id: 5,
        img: '/img/portfolio/stm/resized/slider/stm_5_large.webp',
        srcSet: '/img/portfolio/stm/resized/slider/stm_5_small.webp 600w, /img/portfolio/stm/resized/slider/stm_5_medium.webp 1000w,/img/portfolio/stm/resized/slider/stm_5_large.webp 1400w'
    },
];
