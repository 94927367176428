export const whyList = [{
  id: 1,
  name: 'Why Elitex',
  data: [
    'Technical experts with up to 30 years of experience',
    'Strong JavaScript expertise',
    'Great working conditions for your team',
    'Honest and transparent pricing',
    'People-oriented and friendly atmosphere/culture',
    'Clear and easy communication - we speak good English',
    'We are not only growing in size but always learning and improving our services'
  ]
}, {
  id: 2,
  name: 'Why Lviv',
  data: [
    'One of the top 3 Ukrainian IT-cities',
    'More than 21,000 employees in its IT sector',
    'IT-market has tripled in five years',
    'Local IT-sphere is projected to grow 20% annually',
    'Competitive outsourcing rates paired with a large tech talent pool',
    '98% of all clients in Lviv’s IT sector are international customers',
    '2-3 hours to major European cities by air',
    'More than 4,000 IT graduates from Lviv universities each year'
  ]
}, {
  id: 3,
  name: 'Why Ukraine',
  data: [
    'One of the top IT outsourcing destinations in the world',
    '#1 for outsourcing markets in Eastern Europe, by Outsourcing Journal',
    '185.000+ IT professionals, over 1000 software companies',
    'The high quality of software developed by Ukrainian programmers',
    'Fast and easy communication, no tech barriers and high speed of Internet',
    'Cultural proximity to Western traditions',
    'Highly developed infrastructure of the country'
  ]
}];
