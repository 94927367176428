import React, {useEffect} from 'react';
import './Felix.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, CaseStudyFeedback, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

const Felix = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    const item = portfolioItem('felix');

    return (
        <React.Fragment>
            <HelmetSetter
                title='Web Solution for Singapore Cybersecurity Startup | ELITEX'
                description='Software development outsourcing partnership with Felix Security -  Next-Gen Anti-Virus to fit for all purposes'
                ogImg='portfolio/felix/back.jpg'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Case Studies',
                    item2: 'https://elitex.systems/case-studies',
                    name3: 'Felix',
                    item3: 'https://elitex.systems/case-studies/felix'
                })}
            />
            <Hero className="hero-big banner-with-img felix">
                <Wrapper id="page-title">
                    <div className='case-study-hero-divider-container'>
                        <div className='case-study-hero-divider-txt'>
                            <h1 className="white-text h1">{item.name}</h1>
                            <p className='white-text h5'>{item.subName}</p>
                        </div>
                        <div className='case-study-hero-divider-img'>
                            <img
                                src="/img/portfolio/felix/resized/felix_notebook_2_large.webp"
                                srcSet="/img/portfolio/felix/resized/felix_notebook_2_small.webp 600w,
                                   /img/portfolio/felix/resized/felix_notebook_2_medium.webp 1100w,
                                   /img/portfolio/felix/resized/felix_notebook_2_large.webp 1400w"
                                alt="notebook"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Wrapper>
            </Hero>

            <Spacer type='medium'/>
            <div className='case-study-page'>
                <Wrapper>
                    <div className='case-study-column column-mb'>
                        <img
                            src="/img/portfolio/felix/resized/felix_logo.webp"
                            alt="logo"
                            width="170"
                            height="65"
                        />
                        <Spacer type='medium'/>
                        <h3 className='h4'><strong>Industry</strong></h3>
                        <p>CyberSecurity</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Technology</strong></h3>
                        <p>C++, Python, Network, OS drivers, NodeJS, Express, MongoDB, Apache
                            Kafka, AngularJS, Angular</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Services</strong></h3>
                        <ul className="nolist">
                            <li>Full-stack web development</li>
                            <li>Back-end development</li>
                            <li>Team augmentation</li>
                            <li>Product extension</li>
                            <li>Tech consultancy</li>
                        </ul>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Customer’s Location</strong></h3>
                        <p className='case-study-location'>
                            <img
                                src="/img/portfolio/flags/singapore_flag.webp"
                                className="felix-country-flag"
                                alt="flag"
                                width="40"
                                height="40"
                            />Singapore
                        </p>
                        <p className='case-study-location'>
                            <img
                                src="/img/portfolio/flags/australia_flag.webp"
                                alt="flag"
                                width="40"
                                height="40"
                            />Australia
                        </p>
                    </div>

                    <div className='case-study-60'>
                        <h2 className='h4'>About the <strong>project</strong></h2>
                        <p>Felix is a cybersecurity startup based in Singapore, providing machine learning Next-Gen
                            Anti-Virus to fit all purposes.</p>
                        <p>For consumers, it assures the security of all applications on the computer and prevents any
                            malware or hackers from causing harm. Plus, in High-Security mode, only the applications
                            that consumers have used, malware-free, are permissible to run. For businesses and
                            enterprises, the anti-virus is flexible and easily adaptable to suit any needs whilst
                            maintaining extremely high-security assurance.</p>
                        <Spacer type='medium'/>
                        <h2 className='h4'>The <strong>challenge</strong> and the <strong>solution</strong></h2>
                        <p>Our cooperation with Felix Security started in 2016; at that time, our client needed help
                            with technology development. Felix's management team had experience hiring freelancers on
                            the services like Upwork but then decided to look for a long-term, reliable, and trustworthy
                            partner. Felix Security wanted to get the product to market in less time with the
                            possibility to scale in the future, and the best way to accomplish this was by partnering
                            with an agency.</p>
                        <p>ELITEX augmented the client’s expertise with seasoned engineers, who got involved in several
                            parts of the work across multiple projects.</p>
                        <div className='felix-feedback-offset'>
                            <div className='case-study-grey feedback-mobile'>
                                <CaseStudyFeedback
                                    cssClass={'feedback-quotes-color'}
                                    position='Director | Felix Security'
                                    text='ELITEX provides software development, working on the front and back ends. Using Python for its network solution, they also worked on cloud services and a graphical UI.'/>
                            </div>
                        </div>
                        <p>From a front-end perspective, ELITEX developers examined the different parts of the product
                            and advised on how it could be improved. It helped Felix Security to tweak the
                            company’s technology side. Another achievement of our front-end engineers was developing a
                            graphical UI from scratch as a fully custom solution.</p>
                        <p>On the backend, ELITEX engineers, in conjunction with the client’s in-house team, worked on
                            building the cloud part and connecting it with the frontend side. Felix’s chief scientist
                            was heavily involved in terms of the technologies used and provided oversight to the remote
                            dedicated development team.</p>
                    </div>
                </Wrapper>
                <div className='felix-feedback-offset'>
                    <div className='case-study-grey'>
                        <Wrapper>
                            <div className='case-study-60'>
                                <CaseStudyFeedback
                                    cssClass={'feedback-quotes-color'}
                                    position='Director | Felix Security'
                                    text='We had one main contact, who was a C++ genius. There was also a team assigned to UI Development and some people building out and maintaining the network solution using Python.'/>
                            </div>
                        </Wrapper>
                    </div>
                </div>
                <Wrapper>
                    <div className='case-study-60'>
                        <p>After the main part of the project was completed, the ELITEX C++ developer continued to work
                            on further cybersecurity platform enhancement and updates based on new OS releases. Helped
                            with the development of system program features to protect PC from malware or ransomware
                            attacks and monitored user actions to identify potentially dangerous steps.</p>

                    </div>
                </Wrapper>
                <div className='felix-feedback-offset'>
                    <div className='case-study-grey'>
                        <Wrapper>
                            <div className='case-study-60'>
                                <CaseStudyFeedback
                                    cssClass={'feedback-quotes-color'}
                                    position='Director | Felix Security'
                                    text='The ELITEX team demonstrated their excellent technical skill and expertise. They communicated well, maintaining a smooth workflow and offering fantastic value for money.'/>
                            </div>
                        </Wrapper>
                    </div>
                </div>
                <Wrapper>
                    <div className='case-study-60'>
                        <p>From a project management standpoint, while ELITEX handled the payroll of the team, Felix
                            could maintain contact with the remote employees as though they were their own. The code was
                            stored securely and could be used for further releases and changes for the customer to
                            reference for documentation later on.</p>
                    </div>
                </Wrapper>
                <div className='felix-feedback-offset'>
                    <div className='case-study-grey'>
                        <Wrapper>
                            <div className='case-study-60'>
                                <CaseStudyFeedback
                                    cssClass={'feedback-quotes-color'}
                                    position='Director | Felix Security'
                                    text='We were able to converse with their principal by either email, text, or phone calls
                                          whenever required, which made a big difference. They’re a professional outfit, not a
                                          team of freelancers working from their homes, which was what we needed.'/>
                            </div>
                        </Wrapper>
                    </div>
                </div>
                <Wrapper>
                    <div className='case-study-60'>
                        <p>During the cooperation, ELITEX experienced developers reviewed the existing code
                            and gave recommendations on how to improve it. This helped the client to fine-tune the
                            technology, enhance the product and introduce it to the market quickly.</p>
                        <Spacer type='medium'/>
                        <h2 className='h4'><strong>Screenshots</strong></h2>
                        <p>For companies that function in the security space, it is crucial to ensure privacy and meet
                            security standards and regulations. Following this, we can share the customer’s website and
                            contacts upon request only.</p>
                    </div>
                </Wrapper>
                <Spacer type='small'/>
            </div>
            <div className='case-study-grey-2'>
                <ContactForm/>
            </div>
        </React.Fragment>
    )
};

export {Felix}
