export const contactInfoList = [
    {
        id: 1,
        country: 'UK',
        street: '3 Gower Street',
        city: 'London, WC1E 6HA',
        phone: '+44 (208) 144 9984',
        email: 'sales@elitex.systems',
        secondAddress: {
            street: '39/5 Granton Crescent',
            city: 'Edinburgh, EH5 1BN',
            phone: '+44 (208) 144 9984',
        }
    },
    {
        id: 3,
        country: 'Canada',
        street: '700 2 St SW',
        city: 'Calgary, AB T2P 2W2',
        phone: '+1 416 219 47 90',
    },
    {
        id: 4,
        country: 'The Netherlands',
        street: 'Stade de Colombes 33',
        city: 'Amsterdam, 1098 VS',
        phone: '+31 (0)23 205 0048',
    },
    {
        id: 5,
        country: 'Ukraine',
        street: 'Horodotska Str. 2',
        city: 'Lviv, 79007',
        phone: '+38 (096) 585 0620',
    },
];
