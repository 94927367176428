import React, {Fragment} from 'react';
import './UiDevProcess.css';
import {uiDevProcessContent, uiDevProcessText, uiDevProcessTitle} from "../../mocks/uiPage.mock";
import {Spacer} from "../Spacer/Spacer";

const UiDevProcess = () => {
    return (
        <div>
            <h2 className="main-title">{uiDevProcessTitle}</h2>
            <Spacer type="medium"/>
            <p className="main-subtitle">{uiDevProcessText}</p>
            <div className="ui-dev-process-cards-container">
                {
                    uiDevProcessContent.map(({id, header, text, arrow}) => (
                        <Fragment key={id}>
                            <div className="ui-dev-process-card">
                                <div className="ui-dev-process-card-content">
                                    <span className="ui-dev-process-card-number">{id}</span>
                                    <p className="ui-dev-process-card-header"><strong>{header}</strong></p>
                                    <p className="ui-dev-process-card-text">{text}</p>
                                </div>
                            </div>
                                { arrow &&
                                    <img
                                    className="ui-dev-process-card-arrow"
                                    src="/img/icons/arrows/blue-right-arrow.svg"
                                    alt="arrow"
                                    width="33px"
                                    height="59px"
                                    /> }
                        </Fragment>
                    ))
                }
            </div>
        </div>
    );
};

export default UiDevProcess;