import React, {Fragment} from 'react';
import './ContactAddresses.css';
import {ContactInfoItem} from "../ContactInfoItem/ContactInfoItem";

export const ContactAddresses = ({list}) => (
  <Fragment>
    <h5 className="title-alt">Our locations</h5>
    <div className="addresses-row">
      {list.map((item) => <ContactInfoItem key={item.id} {...item}/>)}
    </div>
  </Fragment>
);
