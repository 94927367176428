import React, {useEffect} from 'react';
import './Peerwith.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, CaseStudyFeedback, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {peerwithSliders} from "../../mocks";
import {CaseStudyCustomSlider} from "../index";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";

export const Peerwith = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    const item = portfolioItem('peerwith');

    return (
        <React.Fragment>
            <HelmetSetter
                title='Software Development of Marketplace - Peerwith Case Study'
                description='Software development outsourcing partnership with Peerwith. We provided software development services for marketplace expansion and the launch of new features.'
                ogImg='portfolio/peerwith/back.jpg'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Case Studies',
                    item2: 'https://elitex.systems/case-studies',
                    name3: 'Peerwith',
                    item3: 'https://elitex.systems/case-studies/peerwith'
                })}
            />
            <Hero className="hero-big banner-with-img peerwith">
                <Wrapper id="page-title">
                    <div className='case-study-hero-divider-container'>
                        <div className='case-study-hero-divider-txt'>
                            <h1 className="white-text h1">{item.name}</h1>
                            <p className='white-text h5'>{item.subName}</p>
                        </div>
                        <div className='case-study-hero-divider-img'>
                            <img
                                src="/img/portfolio/peerwith/resized/peerwith_notebook_2_large.webp"
                                srcSet='/img/portfolio/peerwith/resized/peerwith_notebook_2_small.webp 600w,
                          /img/portfolio/peerwith/resized/peerwith_notebook_2_medium.webp 1100w,
                          /img/portfolio/peerwith/resized/peerwith_notebook_2_large.webp 1400w'
                                alt="notebook"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Wrapper>
            </Hero>

            <Spacer type='medium'/>
            <div className='case-study-page'>
                <Wrapper>
                    <div className='case-study-column column-mb'>
                        <img
                            src="/img/portfolio/peerwith/resized/peerwith_logo.webp"
                            alt="logo"
                            width="170"
                            height="29"
                        />
                        <Spacer type='medium'/>
                        <h3 className='h4'><strong>Industry</strong></h3>
                        <p>Publishing, science</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Technology</strong></h3>
                        <p>NodeJS, Express, MongoDB, Elasticsearch, Angular, AngularJS, Polymer, HTML/CSS</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Services</strong></h3>
                        <ul className="nolist">
                            <li>Dedicated development team</li>
                            <li>Full-stack web development</li>
                            <li>Front-end development</li>
                            <li>Back-end development</li>
                            <li>Product extension</li>
                        </ul>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Customer’s Location</strong></h3>
                        <p className='case-study-location'>
                            <img
                                src="/img/portfolio/flags/netherlands_flag.webp"
                                alt="flag"
                                width="40"
                                height="37"
                            />
                            Amsterdam, the Netherlands
                        </p>
                    </div>
                    <div className='case-study-60'>
                        <h2 className='h4'>About the <strong>project</strong></h2>
                        <p>Peerwith is a platform for expert-led researcher services. It helps academics seeking to
                            raise the
                            quality of their scientific work to connect with peers who are experts in researcher
                            services.</p>
                        <p>Peerwith partners with the best publishers, societies, and institutes to help bring author
                            services to a
                            wide academic audience. This way, researchers can improve the quality of their academic work
                            while building lasting relationships with peers from their academic discipline.</p>
                        <Spacer type='big'/>
                        <div className='case-study-grey feedback-mobile'>
                            <CaseStudyFeedback
                                cssClass={'feedback-quotes-color'}
                                name='Ivo Verbeek'
                                img='/img/clients/ivoverbeek.jpg'
                                position='Director @Peerwith'
                                text='ELITEX can quickly scale the team up or down to meet our needs. They’re very
                                  flexible in that regard. If we tell them we need specific resources for a project next month, they
                                  recruit the people that we need, and it only takes a few days to get them up to speed. ELITEX prepares
                                  and mentors all of their employees well.'/>
                        </div>
                        <Spacer type='medium'/>
                        <h2 className='h4'>The <strong>challenge</strong></h2>
                        <p>The platform lacked the capacity to deal with a growing number of partners and users. When
                            the customer
                            contacted us for the first time, the startup was in need of software development services
                            for marketplace
                            expansion and the development of new features.</p>
                        <p>Peerwith also needed the flexibility to scale the team up and down according to the
                            development capacity
                            required at any particular phase of the project.</p>
                    </div>
                </Wrapper>
                <Spacer type='medium'/>

                <div className='case-study-grey-3'>
                    <Wrapper>
                        <CaseStudyCustomSlider slides={peerwithSliders}/>
                    </Wrapper>
                </div>

                {/*sticky zone*/}
                <div className="case-study-relative">
                    <div className='case-study-flex-order'>
                        <Wrapper>
                            <Spacer type='medium'/>
                            <div className='case-study-60'>
                                <h2 className='h4'>The <strong>solution</strong></h2>
                                <p>ELITEX and Peerwith started cooperating in 2017. Before that, our client tried the
                                    services of
                                    multiple freelancers and other software development companies. ELITEX offered a more
                                    consistent approach to the work, and the client decided to stay with us.</p>
                                <p>Over the years, ELITEX was flexible to the needs of the customer. The development
                                    team consisted of developers of various levels, and its size was changing based on
                                    the workload. ELITEX has also proven a strong ability to prepare and mentor
                                    newcomers to the project.</p>
                                <p>While ELITEX was managing and motivating the team members from its side, from the
                                    customer’s perspective, remote engineers were managed by Peerwith’s CTO, who
                                    communicated with the team on a regular basis, setting priorities and managing the
                                    tasks.</p>
                                <p>We used the following technologies:</p>
                                <p><strong>Backend</strong></p>
                                <ul className='case-study-ul case-study-list-bullet peerwith'>
                                    <li>Node.js</li>
                                    <li>Express</li>
                                    <li>MongoDB</li>
                                    <li>Elasticsearch</li>
                                </ul>
                                <p><strong>Frontend</strong></p>
                                <ul className='case-study-ul case-study-list-bullet peerwith'>
                                    <li>AngularJS</li>
                                    <li>Angular 2+</li>
                                    <li>Polymer</li>
                                    <li>HTML/CSS</li>
                                </ul>
                                <p>Web apps were deployed with <strong>AWS (Amazon Web Services)</strong>, which allowed
                                    easy and quick infrastructure scalability at a reasonable cost.</p>
                            </div>
                        </Wrapper>

                    </div>
                </div>
                <Spacer type='medium'/>
                <img
                    src="/img/portfolio/peerwith/webp/peerwith_overview.webp"
                    alt="overview"
                    loading="lazy"
                    width="100%"
                    height="100%"
                />
                <div className='case-study-order-3'>
                    <Wrapper>
                        <div className='case-study-60'>
                            <Spacer type='medium'/>
                            <h2 className='h4'>The <strong>result</strong></h2>
                            <p>Our software engineers worked on various parts of the project:</p>
                            <ul className='case-study-ul peerwith-list-bullet'>
                                <li>Submission portal</li>
                                <li>Invoicing</li>
                                <li>Rankings</li>
                                <li>Experts section</li>
                                <li>Administrative sections</li>
                                <li>Partners' webpages</li>
                            </ul>
                            <p>During our cooperation, Peerwith also launched a Software-as-a-Service (SaaS) solution -
                                Cockpit, a
                                management console for administrative users at the partner level.</p>
                            <p>We helped Cockpit in the development of different features:</p>
                            <ul className='case-study-ul peerwith-list-bullet'>
                                <li>monitoring usage of the platform at a partner level;</li>
                                <li>access and controlling features related to a partner / institutional account;</li>
                                <li>administrative features (budgeting or billing);</li>
                                <li>reporting.</li>
                            </ul>
                            <p>While the core work for Peerwith has already been done, ELITEX continues to support the
                                startup, developing new parts of the system and tuning existing ones.</p>
                        </div>
                    </Wrapper>
                </div>

                <div className='case-study-order-4'>
                    <Spacer type='medium'/>
                    <div className='case-study-grey'>
                        <Wrapper>
                            <div className='case-study-60'>
                                <CaseStudyFeedback
                                    cssClass={'feedback-quotes-color'}
                                    img='/img/clients/ivoverbeek.jpg'
                                    name='Ivo Verbeek'
                                    position='Director @Peerwith'
                                    text='As an outsourced team, ELITEX serves as a cost-effective development option. They have proven to be a reliable vendor that is capable of finding, training, and implementing the appropriate resources to meet project requirements. Their experience with an array of technology is a definite strength.'/>
                            </div>
                        </Wrapper>
                    </div>
                </div>
                <Spacer type='big'/>
            </div>
            <div className='case-study-grey-2'>
                <ContactForm/>
            </div>
        </React.Fragment>
    )
};
