export const jsdFaqContent = [
    {
        id: 1,
        name: "What is JavaScript development used for?",
        text: "JavaScript is a dynamic programming language that is compact, multi-paradigm, interpreted, and rapidly compiled. It is used chiefly for client-side development, including web services and applications. Nevertheless, JS also has a Node.js framework, a proper tool for server-side development.",
        state: "one"
    },
    {
        id: 2,
        name: "Is JavaScript a front-end or back-end language?",
        text: "Well, actually, it is a full-stack language, as we can code both the front-end and back-end using different frameworks and libraries. However, JS is primarily known as a main front-end development language."
    },
    {
        id: 3,
        name: "What are the primary benefits of outsourcing JavaScript services?",
        text: "It’s hard to name all the benefits as soon as they largely depend on your project specificity. Still, some of them are cost savings, access to a larger pool of talents, increased flexibility and scalability of your project, reduced development time, and the ability to focus on core business functions."
    },
    {
        id: 4,
        name: "What are the most popular frameworks of JavaScript?",
        text: "Some of the most popular JS frameworks include React (especially popular in mobile development), Angular (web development), Vue (web interfaces and SPAs), Node (back-end), and Express (SPAs, MPAs, and hybrid applications). These frameworks are quite versatile and can be replaced by each other (excluding back-end frameworks), and the choice of the framework may depend on several factors."
    },
    {
        id: 5,
        name: "How much does it cost to build a basic JavaScript application?",
        text: "The cost of building an application varies depending on multiple factors, such as the app’s complexity, JavaScript application development company rates, specific features of the development project, its timeline, etc. It’s hard to predict the exact numbers; however, let’s say the basic options cost anywhere from a few thousand dollars."
    },
    {
        id: 6,
        name: "Why is JavaScript so popular?",
        text: "It is one of the IT industry's speediest and most dynamic languages. In fact, it is an indispensable front-end development tool, and the vast majority of websites and web applications are JS-based today."
    },
];