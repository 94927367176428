import React from 'react';
import './LogosList.css';
import classNames from "classnames";

export const LogosList = ({list, redirect, tech}) => {

  const styleClass = classNames( 'logo-list', {'tech-logo': tech})

  return (
      <div className={styleClass}>
        {list.map(el => (
            el.link ?
                <a
                    href={el.link}
                    key={el.id}
                    className="logo-item"
                    rel="noopener noreferrer nofollow"
                    target={redirect ? "_blank" : "_self"}
                    aria-label="Technology"
                >
                  <img src={el.src} alt={el.alt} style={{maxHeight: el?.height}}
                       width={el.explicitWidth}
                       height={el.explicitHeight}
                  />
                </a>
                :
                <div className='logo-item' key={el.id}>
                  <img style={{maxHeight: el.height}} src={el.src} alt={el.alt}
                       width={el.explicitWidth}
                       height={el.explicitHeight}
                  />
                </div>
        ))}
      </div>
  )
}