import React, {useEffect} from 'react';
import {
  LogosList,
  Spacer,
  Wrapper,
  ScrollDownBtn,
  Hero,
  PageBody,
  QaWhatWeDo,
  TrustedLinks,
  CaseStudiesGrid, QaRelatedArticles
} from '../../components';
import {
  customersLogos,
  technologiesLogos,
  homeLogosWorkWith,
  homeVariousIndustries,
  trustedLinks,
  homeSliderItems,
  homeFaqList,
  testimonialItems,
  homeClientsSliderHeader, homeFaqTitle
} from '../../mocks';
import {ContactForm, MainSlider, SliderMaker} from '../';
import HelmetSetter from '../../components/Helmet/Helmet';
import './Home.css';
import {hideLoader, showLoader, delay} from "../../utils/loaderHandler.util";
import {portfolioItem} from "../../utils";
import {Feedback} from '../../components';
import HomeWhatDoWeOffer from "../../components/HomeWhatDoWeOffer/HomeWhatDoWeOffer";
import HomeWhyChooseElitex from "../../components/HomeWhyChooseElitex/HomeWhyChooseElitex";
import BlueLine from "../../components/BlueLine/BlueLine";
import FAQ from "../../components/FAQ/FAQ";
import {homeFaqStructuredData} from "../../utils/structuredData";


function Home() {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

return (
        <React.Fragment>
          <HelmetSetter
              title='ELITEX: Software Development Company'
              description='Looking for a trusted outsourcing software development company? Feel the difference ELITEX, a trusted custom software development company, can make.'
              ogImg='office/office_2.jpg'
              faqStructuredData={homeFaqStructuredData}
          />
          <Hero>
            <MainSlider sliderItems={homeSliderItems} contactUsBtn caseStudiesBtn/>
            <ScrollDownBtn />
          </Hero>
          <PageBody>
            <Spacer type="big"/>
            <Wrapper className="wrapper-medium">
              <HomeWhatDoWeOffer/>
              <Spacer type="big"/>
              <HomeWhyChooseElitex/>
              <Spacer type="big"/>
              <h2 className='h4 align-center'>Our <strong>clients and partners</strong></h2>
              <LogosList list={customersLogos} redirect/>
              <Spacer type='medium'/>
              <h2 className='h4 align-center'>Our <strong>certifications and awards</strong></h2>
              <TrustedLinks trustedList={trustedLinks}/>
              <Spacer type="big"/>
              <h2 className='h4 align-center' id='portfolio-grid'>Take a Look at Our Case Studies </h2>
              <Spacer type='small'/>
            </Wrapper>
            <CaseStudiesGrid list={[
              portfolioItem('kallik', false),
              portfolioItem('postCMS', true),
              portfolioItem('imagex', false),
              portfolioItem('peerwith', true),
            ]}/>
            <Wrapper className="wrapper-medium">
              <Spacer type="big"/>
              <h2 className='h4 align-center'>We work with various industries</h2>
              <BlueLine/>
              <p className="home-industries-text">As an experienced software development outsourcing company, we have worked with clients from diverse industries. Our team of software developers has a deep understanding and structured knowledge of the unique challenges and opportunities each industry presents.</p>
              <Spacer type='medium'/>
              <QaWhatWeDo list={homeVariousIndustries}/>
              <Spacer type="big"/>
              <h2 className='h4 align-center' id='portfolio-grid'>Technologies We Use in Our Development Services</h2>
              <Spacer type='medium'/>
              <div className='align-center'>
                <LogosList list={technologiesLogos}/>
              </div>
              <Spacer type='small'/>
              <h2 className='h4 align-center'>We also <strong>work with</strong></h2>
              <div className='align-center'>
                <LogosList list={homeLogosWorkWith}/>
              </div>
            </Wrapper>
            <Spacer type='medium'/>
            <div id="testimonial-slider">
              <SliderMaker dark clientsSliderHeader={homeClientsSliderHeader} sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
            </div>
            <Wrapper className="wrapper-medium">
              <Spacer type="big"/>
              <FAQ title={homeFaqTitle} content={homeFaqList}/>
            </Wrapper>
            <Spacer type='medium'/>
            <div className='case-study-grey-2'>
              <ContactForm/>
            </div>
            <Spacer type="big"/>
            <Wrapper className="wrapper-medium">
              <h2 className='h4 align-center'><strong>Related Content</strong></h2>
              <Spacer type="medium"/>
              <QaRelatedArticles/>
              <Spacer type="medium"/>
            </Wrapper>
          </PageBody>
        </React.Fragment>
  );
}

export  { Home };
