export const salsifySliders = [
  {
    id: 1,
    img: '/img/portfolio/salsify/webp/slider/salsify_1.webp',
    srcSet: '/img/portfolio/salsify/resized/slider/salsify_1_small.webp 600w, /img/portfolio/salsify/webp/slider/salsify_1.webp 1200w'
  },
  {
    id: 2,
    img: '/img/portfolio/salsify/webp/slider/salsify_2.webp',
    srcSet: '/img/portfolio/salsify/resized/slider/salsify_2_small.webp 600w, /img/portfolio/salsify/webp/slider/salsify_2.webp 1200w'
  },
  {
    id: 3,
    img: '/img/portfolio/salsify/webp/slider/salsify_3.webp',
    srcSet: '/img/portfolio/salsify/resized/slider/salsify_3_small.webp 600w, /img/portfolio/salsify/webp/slider/salsify_3.webp 1200w'
  },
  {
    id: 4,
    img: '/img/portfolio/salsify/webp/slider/salsify_4.webp',
    srcSet: '/img/portfolio/salsify/resized/slider/salsify_4_small.webp 600w, /img/portfolio/salsify/webp/slider/salsify_4.webp 1200w'
  },
];
