import React from 'react';
import { withRouter } from 'react-router-dom';
import { Logo } from '../../components';
import './Header.css';
import { NavTopLinks, CompanyMobDrawer, CookieWarning, BannerSupport } from '../../components/index';
import { checkCurrentAddress } from '../../utils/linkChecker.util';
class Header extends React.Component {

  state = {
    showMobLinksDrawer: false,
    showCompanyDrawer: false,
    invert: false,
    invertCookieWarning: false,
    invertBanner: false,
    cookieAccepted: true,
    categoriesDrawer: null
  };
  mobileMenuRef = React.createRef();

  componentDidMount = () => {
    this.isCookieAccepted();
    if (checkCurrentAddress(this.props.location.pathname)) {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('scroll', this.handleScrollForCookieWarning);
    } else this.setState({ invert: true, invertBanner: true, invertCookieWarning: true });
    document.addEventListener('mousedown', this.handleOutsideClick);
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && this.props.location.hash === '') {
      window.scrollTo(0, 0);
      if (checkCurrentAddress(this.props.location.pathname)) {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.handleScrollForCookieWarning);
        this.setState({ invert: false, invertBanner: false, invertCookieWarning: false })
      } else {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('scroll', this.handleScrollForCookieWarning);
        this.setState({ invert: true, invertBanner: true, invertCookieWarning: true })
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleScroll = () => this.setState({
    invert: window.pageYOffset > 200,
    invertBanner: window.pageYOffset > 20
  });

  handleScrollForCookieWarning = () => this.setState({ invertCookieWarning: window.pageYOffset > 80 });

  isCookieAccepted = () => {
    if (document.cookie.indexOf('cookiePolicyAccepted=true') === -1) {
      this.setState({ cookieAccepted: false })
    }
  };

  cookieSetter = () => {
    document.cookie = "cookiePolicyAccepted=true; path=/; max-age=31536000";
    this.setState({ cookieAccepted: true })
  };
  handleOutsideClick = (event) => {
    if (!this.mobileMenuRef.current.contains(event.target)) {
      this.setState({ showMobLinksDrawer: false, showCompanyDrawer: false });
    }
  };
  toggleMobDrawer = () => {
    if(!this.state.showMobLinksDrawer && this.state.showCompanyDrawer){
      this.setState({ showMobLinksDrawer: false, showCompanyDrawer: false  });
      return
    }
    this.setState({showMobLinksDrawer: !this.state.showMobLinksDrawer});
  };

  hideMobLinksDrawer = () => this.setState({ showMobLinksDrawer: false });

  triggerHandler = (e) => {
    this.setState({
      showCompanyDrawer: true,
      categoriesDrawer: e?.toLowerCase(),
      showMobLinksDrawer: false
    });
  };

  hideCompanyExpertiseDrawer = () => this.setState({ showCompanyDrawer: false });

  companyBackHandler = () => this.setState({
    showMobLinksDrawer: true,
    showCompanyDrawer: false
  });

  render() {
    const {
      showMobLinksDrawer, invert, invertCookieWarning, invertBanner, cookieAccepted, showCompanyDrawer
    } = this.state;
    return (
      <header>
        <CookieWarning
          invert={invertCookieWarning}
          cookieAccepted={cookieAccepted}
          cookieAcceptHandler={this.cookieSetter} />
        {/*Mob menu*/}
        <BannerSupport invert={invertBanner} />
        <div ref={this.mobileMenuRef} className="elx-mob-menu">
          <div className="elx-mob-logo-and-ham">
            <Logo onClose={() => {
              this.hideMobLinksDrawer();
            }} />
            <div
              className={`nav-ham ${showMobLinksDrawer || showCompanyDrawer ? 'change' : ''}`}
              onClick={this.toggleMobDrawer}>
              <div className="nav-ham-bar1"></div>
              <div className="nav-ham-bar2"></div>
              <div className="nav-ham-bar3"></div>
            </div>
          </div>
          <div className={`elx-mob-drawer ${showMobLinksDrawer ? 'elx-mob-drawer--show' : ''}`}>
            <NavTopLinks
              mobile
              showMobLinksDrawer={this.state.showMobLinksDrawer}
              onDropDownTriggerClick={this.triggerHandler}
              onClose={this.hideMobLinksDrawer} />
          </div>
          <CompanyMobDrawer
            categories={this.state.categoriesDrawer}
            show={this.state.showCompanyDrawer}
            onLinkClick={this.hideCompanyExpertiseDrawer}
            onBackClick={this.companyBackHandler} />
        </div>

        {/*menu*/}
        <div className={`elx-menu ${invert ? 'elx-menu--inverted' : ''}`}>
          <Logo onClose={this.hideMobLinksDrawer} />
          <NavTopLinks showDarkLinks={invert} />
        </div>
      </header>
    );
  }
}

export const HeaderWithRouter = withRouter(Header);
