import React from 'react';
import { Heading } from '../Heading/heading';
import './QaWhatWeDo.css';

export const QaWhatWeDo = ({list, shortHeader}) => (
  <div className='what-we-do-container'>
    {list.map(item => (
      <div className='what-we-do-item' key={item.id}>
        <div className='what-we-do-item-top'>
          <img
              src={item.img}
              alt="what-we-do"
              loading="lazy"
              width="50"
              height="50"
          />
          <div>
            <Heading tag={item.tagName} className={`h5 what-we-do-text${shortHeader ? ' short-header' : ''}`}>
              <strong>{item.title}</strong>
            </Heading>
          </div>
        </div>
        {item.text && <p>{item.text}</p>}
      </div>
    ))}
  </div>
);
