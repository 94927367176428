import React from 'react';
import {NavLink} from 'react-router-dom';
import {navigationList} from "../../mocks";
import {MenuDropdown, MobileDropDownTrigger} from "..";

export const NavTopLinks = ({mobile, onClose, showDarkLinks, onDropDownTriggerClick}) => {
    const links = mobile
        ?
        <React.Fragment>
            {navigationList.map(link => {
                if (link?.link === '/blog') {
                    return (
                        <div className="elx-mob-link" key={link?.id}>
                            <a href="https://elitex.systems/blog/">Blog</a>
                        </div>
                    )
                }
                return (
                    <div key={link.id} className="elx-mob-link">
                        {link?.link ? 
                            <NavLink
                                to={link.link}
                                onClick={onClose}
                                activeClassName="active-nav"
                                exact
                            >
                                {link.name}
                            </NavLink> :
                          <p>{link?.name}</p>
                        }
                        {link?.relative ?
                            <MobileDropDownTrigger triggerClass={'trigger1'} clicked={onDropDownTriggerClick}
                                                   link={link.name}/> : null}
                    </div>
                )
            })}
        </React.Fragment>
        :
        <div className="elx-menu-links">
            {navigationList.map(link => {
                if (link?.link === '/blog') {
                    return (
                        <div className={`elx-menu-link ${showDarkLinks ? 'elx-menu-link--inverted' : ''}`}
                             key={link.id}>
                            <a href="https://elitex.systems/blog/">Blog</a>
                        </div>
                    )
                }
                return (
                    <div
                        className={`elx-menu-link
                        ${showDarkLinks ? 'elx-menu-link--inverted' : ''}
                        ${link?.relative ? 'elx-relative-link' : ''}`}
                        key={link.id}>
                        {link?.link ? 
                            <NavLink
                                to={link?.link}
                                activeClassName="active-nav"
                                exact>
                            {link?.name}</NavLink> : 
                            <p>{link?.name}</p>
                        }
                        {link.relative && 
                            <MenuDropdown change={showDarkLinks} category={link.category}
                        />}
                    </div>
                )
            })}
        </div>;

    return links;
};









