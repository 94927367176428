export const kallikSliders = [
  {
    id: 1,
    img: '/img/portfolio/kallik/resized/slider/kallik_1_large.webp',
    srcSet: '/img/portfolio/kallik/resized/slider/kallik_1_small.webp 600w, /img/portfolio/kallik/resized/slider/kallik_1_medium.webp 900w, /img/portfolio/kallik/resized/slider/kallik_1_large.webp 1400w'
  },
  {
    id: 2,
    img: '/img/portfolio/kallik/resized/slider/kallik_2_large.webp',
    srcSet: '/img/portfolio/kallik/resized/slider/kallik_2_small.webp 600w, /img/portfolio/kallik/resized/slider/kallik_2_medium.webp 900w, /img/portfolio/kallik/resized/slider/kallik_2_large.webp 1400w'
  },
  {
    id: 3,
    img: '/img/portfolio/kallik/resized/slider/kallik_3_large.webp',
    srcSet: '/img/portfolio/kallik/resized/slider/kallik_3_small.webp 600w, /img/portfolio/kallik/resized/slider/kallik_3_medium.webp 900w, /img/portfolio/kallik/resized/slider/kallik_3_large.webp 1400w'
  },
  {
    id: 4,
    img: '/img/portfolio/kallik/resized/slider/kallik_4_large.webp',
    srcSet: '/img/portfolio/kallik/resized/slider/kallik_4_small.webp 600w, /img/portfolio/kallik/resized/slider/kallik_4_medium.webp 900w, /img/portfolio/kallik/resized/slider/kallik_4_large.webp 1400w'
  },
  {
    id: 5,
    img: '/img/portfolio/kallik/resized/slider/kallik_5_large.webp',
    srcSet: '/img/portfolio/kallik/resized/slider/kallik_5_small.webp 600w, /img/portfolio/kallik/resized/slider/kallik_5_medium.webp 900w, /img/portfolio/kallik/resized/slider/kallik_5_large.webp 1400w'
  },
  {
    id: 6,
    img: '/img/portfolio/kallik/resized/slider/kallik_6_large.webp',
    srcSet: '/img/portfolio/kallik/resized/slider/kallik_6_small.webp 600w, /img/portfolio/kallik/resized/slider/kallik_6_medium.webp 900w, /img/portfolio/kallik/resized/slider/kallik_6_large.webp 1400w'
  }
];
