export const modelMasterSliders1 = [
    {
        id: 1,
        img: '/img/portfolio/model-master/slider1/model-master_1.png'
    },
    {
        id: 2,
        img: '/img/portfolio/model-master/slider1/model-master_2.png'
    },
    {
        id: 3,
        img: '/img/portfolio/model-master/slider1/model-master_3.png'
    },
    {
        id: 4,
        img: '/img/portfolio/model-master/slider1/model-master_4.png'
    },
    {
        id: 5,
        img: '/img/portfolio/model-master/slider1/model-master_5.png'
    },
    {
        id: 6,
        img: '/img/portfolio/model-master/slider1/model-master_6.png'
    },
];
export const modelMasterSliders2 = [
    {
        id: 1,
        img: '/img/portfolio/model-master/resized/slider2/model-master_1_large.webp',
        srcSet: '/img/portfolio/model-master/resized/slider2/model-master_1_small.webp 600w, /img/portfolio/model-master/resized/slider2/model-master_1_medium.webp 1000w,/img/portfolio/model-master/resized/slider2/model-master_1_large.webp 1400w'
    },
    {
        id: 2,
        img: '/img/portfolio/model-master/resized/slider2/model-master_2_large.webp',
        srcSet: '/img/portfolio/model-master/resized/slider2/model-master_2_small.webp 600w, /img/portfolio/model-master/resized/slider2/model-master_2_medium.webp 1000w,/img/portfolio/model-master/resized/slider2/model-master_2_large.webp 1400w'
    },
    {
        id: 3,
        img: '/img/portfolio/model-master/resized/slider2/model-master_3_large.webp',
        srcSet: '/img/portfolio/model-master/resized/slider2/model-master_3_small.webp 600w, /img/portfolio/model-master/resized/slider2/model-master_3_medium.webp 1000w,/img/portfolio/model-master/resized/slider2/model-master_3_large.webp 1400w'
    },
    {
        id: 4,
        img: '/img/portfolio/model-master/resized/slider2/model-master_4_large.webp',
        srcSet: '/img/portfolio/model-master/resized/slider2/model-master_4_small.webp 600w, /img/portfolio/model-master/resized/slider2/model-master_4_medium.webp 1000w,/img/portfolio/model-master/resized/slider2/model-master_4_large.webp 1400w'
    },
    {
        id: 5,
        img: '/img/portfolio/model-master/resized/slider2/model-master_5_large.webp',
        srcSet: '/img/portfolio/model-master/resized/slider2/model-master_5_small.webp 600w, /img/portfolio/model-master/resized/slider2/model-master_5_medium.webp 1000w,/img/portfolio/model-master/resized/slider2/model-master_5_large.webp 1400w'
    },
    {
        id: 6,
        img: '/img/portfolio/model-master/resized/slider2/model-master_6_large.webp',
        srcSet: '/img/portfolio/model-master/resized/slider2/model-master_6_small.webp 600w, /img/portfolio/model-master/resized/slider2/model-master_6_medium.webp 1000w,/img/portfolio/model-master/resized/slider2/model-master_6_large.webp 1400w'
    },
];
