export const breadcrumbsList = [
    {
        name: 'Home',
        id: 1,
        link: '/'
    },
    {
        name: 'Services',
        id: 2,
        link: '/services'
    },
    {
        name: 'Technologies',
        id: 3,
        link: '/technologies'
    },
    {
        name: 'Case Studies',
        id: 4,
        link: '/case-studies'
    },
    {
        name: 'Kallik',
        id: 5,
        link: '/kallik'
    },
    {
        name: 'Peerwith',
        id: 6,
        link: '/peerwith'
    },
    {
        name: 'Property Management and Hotel Reservation System',
        id: 7,
        link: '/property-management-and-hotel-reservation-system'
    },
    {
        name: 'Exterity',
        id: 8,
        link: '/exterity'
    },
    {
        name: 'Imagex',
        id: 9,
        link: '/imagex'
    },
    {
        name: 'PostCMS',
        id: 10,
        link: '/postcms'
    },
    {
        name: 'Salsify',
        id: 11,
        link: '/salsify'
    },
    {
        name: 'Watstock',
        id: 12,
        link: '/watstock'
    },
    {
        name: 'Zippia',
        id: 13,
        link: '/zippia'
    },
    {
        name: 'Felix',
        id: 14,
        link: '/felix'
    },
    {
        name: 'The OA Switchboard',
        id: 15,
        link: '/oa-switchboard'
    },
    {
        name: 'STM Integrity Hub',
        id: 16,
        link: '/stm'
    },
    {
        name: 'ModelMaster',
        id: 17,
        link: '/model-master'
    },
    {
        name: 'Web Application Development',
        id: 18,
        link: '/web-app-development'
    },
    {
        name: 'JavaScript Development',
        id: 19,
        link: '/javascript-development'
    },
    {
        name: 'Quality Assurance',
        id: 20,
        link: '/software-qa-testing-services'
    },
    {
        name: 'Dedicated Development Teams',
        id: 21,
        link: '/dedicated-software-development-team'
    },
    {
        name: 'Custom Web Development',
        id: 22,
        link: '/web-development-service'
    },
    {
        name: 'UI/UX Development',
        id: 23,
        link: '/ui-development'
    },
    {
        name: 'Custom Software Development',
        id: 24,
        link: '/custom-software-development'
    },
    {
        name: 'Front-End Development',
        id: 25,
        link: '/front-end-development'
    },
    {
        name: 'Smartrr',
        id: 26,
        link: '/smartrr'
    },
    {
        name: 'React',
        id: 27,
        link: '/react-technology'
    },
    {
        name: 'Angular',
        id: 28,
        link: '/angular-technology'
    },
    {
        name: 'Node.js',
        id: 29,
        link: '/node-js-technology'
    },
    {
        name: 'Python',
        id: 30,
        link: '/python-technology'
    },
    {
        name: '.Net Development',
        id: 31,
        link: '/dotnet-technology'
    },
    {
        name: 'Qa Workshops',
        id: 32,
        link: '/qa-workshops'
    },
    {
        name: 'DevOps',
        id: 33,
        link: '/devops'
    },
    {
        name: 'Artificial Intelligence',
        id: 34,
        link: '/artificial-intelligence'
    },
];

export const technologiesSubRoutes = ['/javascript-development', '/react-technology', '/angular-technology', '/node-js-technology', '/python-technology', '/dotnet-technology'];
export const servicesSubRoutes = ['/web-app-development', '/front-end-development', '/software-qa-testing-services', '/dedicated-software-development-team',
                                           '/web-development-service', '/ui-development', '/custom-software-development', '/devops', '/artificial-intelligence'];

export const breadcrumbsRoutes = [
    '/javascript-development',
    '/react-technology',
    '/angular-technology',
    '/node-js-technology',
    '/python-technology',
    '/dotnet-technology',
    '/web-app-development',
    '/front-end-development',
    '/devops',
    '/artificial-intelligence',
    '/software-qa-testing-services',
    '/dedicated-software-development-team',
    '/web-development-service',
    '/ui-development',
    '/custom-software-development',
    '/case-studies'
];