
const homeFaqTitle = {
    main: "Frequently Asked",
    strong: "Questions"
}
const homeFaqList = [
    {
        id:1,
        name: "If I have a request for a custom project, is that something ELITEX can help with?",
        text: "Absolutely! At ELITEX, we specialize in custom software development projects, offering various solutions across multiple industries. Our skilled team of developers, designers, analysts, and project managers will work closely with you in order to bring the vision to life."
    },
    {
        id:2,
        name: "What types of cooperation models does ELITEX provide for software development projects?",
        text: "We provide various cooperation models tailored to your project requirements, specificity, and scale. We prefer the following models:",
        listItems:[
            {
                id:1,
                text:"Software product development outsourcing. ELITEX covers an entire development process. It can offer cost savings, faster time-to-market, and flexibility in skills and technologies. Generally, it’s an ideal decision for projects with well-defined scopes."
            },
            {
                id:2,
                text:"Staff augmentation. This model involves augmenting an existing team with additional skilled specialists to help with specific tasks or project phases. It’s suitable for larger projects requiring flexibility and companies seeking to extend their in-house capabilities without incurring the costs of permanent hiring."
            },
            {
                id:3,
                text:"Dedicated teams. The model implies assembling a team of developers working exclusively on your project for an extended period. The model is suitable for long-term collaboration and projects requiring a comprehensive approach, with developers included in every development process step."
            },
        ]
    },
    {
        id:3,
        name: "What are the most popular software development strategies and techniques?",
        text: "Popular development strategies and techniques include Agile, DevOps, Waterfall, and Scrum, each with unique strengths and weaknesses, and choosing the right one depends on specific project requirements and goals."
    },
    {
        id:4,
        name: "What steps does ELITEX take to ensure the budget and timeline predictability of the project?",
        text: "We take a proactive approach to ensure budget and timeline predictability. Industry-standard tools and methodologies, thorough planning and estimation, and communication with the client every step of the way help us stick to the schedule."
    },
    {
        id:5,
        name: "What is the minimum number of engineers I can hire with ELITEX?",
        text: "At ELITEX, we don’t have a minimum number of specialists for hire when working with us. Regardless of the project scale, whether you need one developer or an entire team, we can make both options work."
    },
    {
        id:6,
        name: "Is app development outsourcing a good idea for small businesses?",
        text: "Yes, it can be a good idea for small businesses from various industries. It can reduce software development costs and give access to a big tech talent pool and flexible resourcing. Nevertheless, it is essential for small businesses to carefully evaluate potential outsourcing partners and establish clear communication and expectations in order to ensure a successful experience."
    },
    {
        id:7,
        name: "How do I know my team/developer works exclusively on my project?",
        text: "We assign a dedicated team of developers, designers, and project managers to your project, ensuring they will work exclusively on it until completion. Our project managers provide regular progress updates, communicate with you regularly, and address any concerns or questions you may have to ensure that the team is focused on the project. Moreover, you can track progress in such tools as Jira, having complete control of the process."
    },
    {
        id:8,
        name: "How do you control the quality of your software?",
        text: "We control the software quality that we provide through a comprehensive process, including code review, automated and manual testing, acceptance control, and continuous monitoring at each development step."
    },
    {
        id:9,
        name: "What measures does ELITEX take to protect my intellectual property and keep it confidential during the development process?",
        text: "Security and intellectual property are among our top priorities, and we focus on keeping them confidential during development. We have strict confidentiality agreements in place, follow the principle of least privilege, use secure servers and communication channels, and limit access to your project for developers."
    },
];

export {
    homeFaqList,
    homeFaqTitle
}