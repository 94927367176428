import React, {Fragment, useEffect} from 'react';
import './PythonTech.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData, pythonTechFaqStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero, LogosList,
    PageBody,
    QaWhatWeDo,
    ScrollDownBtn,
    Spacer,
    WdsSlider,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {
    pythonBenefitsCards,
    pythonBenefitsText,
    pythonBenefitsTitle,
    pythonFaqList,
    pythonFaqTitle,
    pythonMainCaptionText,
    pythonMainCaptionTitle,
    pythonOutsourceTextOne,
    pythonOutsourceTextTwo,
    pythonOutsourceTitle,
    pythonTechnologiesLogos,
    pythonWhyChooseTextOne,
    pythonWhyChooseTextThree,
    pythonWhyChooseTextTwo,
    pythonWhyChooseTitle,
    homeClientsSliderHeader,
    nodeProjectText,
    testimonialItems,
    pythonWhatOfferCards,
    pythonWhatOfferTitle,
    pythonWhatOfferTextOne,
    pythonWhatOfferTextTwo
} from "../../mocks";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import BlueLine from "../../components/BlueLine/BlueLine";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import {portfolioItem} from "../../utils";
import FAQ from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";
import WhatDoWeOffer from "../../components/WhatDoWeOffer/WhatDoWeOffer";


export const PythonTech = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <Fragment>
            <HelmetSetter
                title="Python Development Services | ELITEX"
                description="Unlock the power of Python development services with ELITEX, a trusted Python development company. Leverage smart solutions with the best industry experts."
                ogImg="python/python-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "Python",
                    item2: "https://elitex.systems/python-technology"
                })}
                faqStructuredData={pythonTechFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img python">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={pythonMainCaptionTitle} text={pythonMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-python">
                <div id="testimonial-slider">
                    <SliderMaker dark
                                 clientsSliderHeader={homeClientsSliderHeader}
                                 sliderItems={testimonialItems}
                                 SliderItem={Feedback} speed={15000}
                                 dots
                    />
                </div>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{pythonWhatOfferTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{pythonWhatOfferTextOne}</p>
                    <p className="main-subtitle">{pythonWhatOfferTextTwo}</p>
                    <Spacer type="small"/>
                    <WhatDoWeOffer content={pythonWhatOfferCards} twoInRow/>
                    <Spacer type="big"/>
                    <h2 className="main-title">{pythonBenefitsTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{pythonBenefitsText}</p>
                    <Spacer type="big"/>
                    <QaWhatWeDo list={pythonBenefitsCards}/>
                    <Spacer type="big"/>
                    <h2 className="main-title"><strong>{pythonWhyChooseTitle.strong}</strong> {pythonWhyChooseTitle.main}</h2>
                    <Spacer type="medium"/>
                    <p className="main-subtitle">{pythonWhyChooseTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{pythonWhyChooseTextTwo}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{pythonWhyChooseTextThree}</p>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation text={nodeProjectText}/>
                <Spacer type="big"/>
                <h2 className="main-title" id="portfolio-grid">Check Out Our Case Studies</h2>
                <Spacer type="small"/>
                <CaseStudiesGrid list={[
                    portfolioItem('zippia', false),
                    portfolioItem('felix', true),
                    portfolioItem('kallik', false),
                    portfolioItem('salsify', true),
                ]}/>
                <Spacer type="big"/>
                <h2 className="main-title">Our Other Services</h2>
                <Spacer type="small"/>
                <WdsSlider/>
                <Spacer type="big"/>
                <Wrapper type="medium">
                    <h2 className="main-title left">{pythonOutsourceTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle left tech">{pythonOutsourceTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle left tech">{pythonOutsourceTextTwo}</p>
                    <Spacer type="big"/>
                    <FAQ title={pythonFaqTitle} content={pythonFaqList}/>
                    <Spacer type="big"/>
                    <h2 className="main-title">Other Technologies We Provide</h2>
                    <Spacer type="medium"/>
                    <LogosList list={pythonTechnologiesLogos} tech/>
                    <Spacer type="big"/>
                </Wrapper>
                <div className="case-study-grey-2">
                    <ContactForm/>
                </div>
            </PageBody>
        </Fragment>
    );
};