import React from 'react';
import './WhatDoWeOffer.css';
import classNames from "classnames";

const WhatDoWeOffer = ({content, twoInRow}) => {

    const cardsContainerClass = classNames("we-offer-cards-container", {
        "two-in-row": twoInRow
    })
    return (
            <div className={cardsContainerClass}>
                {
                   content.map(({id, title, text, image}) => (
                        <div className="we-offer-card" key={id} >
                            <div className="we-offer-card-content">
                                <h3 className="we-offer-card-title">{title}</h3>
                                <span className="we-offer-card-text">{text}</span>
                            </div>
                                <img
                                    src={image}
                                    alt="team"
                                    width="100%"
                                    height="100%"
                                    className="we-offer-card-img"
                                />
                        </div>
                    ))
                }
            </div>
    );
};

export default WhatDoWeOffer;