// MAIN CAPTION

export const feMainCaptionTitle = "Front-End Development Services";
export const feMainCaptionText = "Enhance your digital landscape with ELITEX, your premier front-end development services partner. Discover industry trends and our proven development process.";

// FRONT-END INFO
export const infoText = 'A solid online presence is essential for basically anyone looking to succeed in the modern digital world. Your website or application serves as the face of your company, and it’s often the first point of contact on your way to gaining customer loyalty. At ELITEX, we understand the significance of having a web app that both looks great and performs flawlessly. Our front-end development team has extensive experience creating tailored front-end solutions for any business goals. Our approach, which involves modern technology and adhering to industry best practices, ensures the end product we build is a well-tuned, high-quality digital solution with exceptional visual appeal.';


// WHAT DO WE OFFER

export const feWhatOfferTitle = {
    main: "Front-End Development Services:",
    strong: "What Do We Offer"
}
export const feWhatDoWeOfferContent = [
    { 
        id: 1,
        name: "Custom front-end development",
        text: "Our software developers create bespoke responsive websites and applications tailored to your specific business objectives. Whether it’s a small business website, an intricate e-commerce platform, or a comprehensive enterprise solution, we offer our technical skills to build web applications optimized for speed and performance from scratch.",
        img: "/img/frontEnd/offer_1.webp"
    },
    {
        id: 2,
        name: "Web design services",
        text: "We also offer comprehensive web design services that encompass every aspect of building dynamic web applications. At ELITEX, we build all kinds of custom solutions you need for sustainable business growth, from basic UI/UX to responsive design solutions that can be scaled together with your company.",
        img: "/img/frontEnd/offer_2.webp"
    },
    {
        id: 3,
        name: "UI modernization and optimization",
        text: "ELITEX’s experts provide valuable guidance and insights on any development project. Our front-end modernization and optimization options help your web application stay updated with the latest technology and coding standards. Our experienced team of frontend developers identifies areas that could benefit from modernization, such as code to clean or loading times to reduce, and optimizes them.",
        img: "/img/frontEnd/offer_3.webp"
    },
    {
        id: 4,
        name: "Consulting services, maintenance, and support",
        text: "Ongoing services ensure the smooth post-launch operation of the website/application or its components. Maintenance and support refer to regular updates, bug fixes, and other complex actions to prevent costly downtime or errors. By building reliable long-term partner relationships for ongoing support with ELITEX, you can ensure the proper functioning and seamless user experience of your product.",
        img: "/img/frontEnd/offer_4.webp"
    },
];

export const whatDoWeOfferTextOne= "Being an experienced front end development software company, we deliver a wide range of services tailored to different scenarios and business requirements. Whether you need an overhaul of your existing product or a custom application developed from scratch, we have something to offer you.";
export const whatDoWeOfferTextTwo = "In addition to a range of custom front-end development services, consulting, web/app design, and ongoing support, we offer backend development, third-party API integration, cross-browser compatibility testing, web accessibility compliance auditing, building complex e-commerce solutions, effective data management, database design, and other solutions required for the proper functioning of your digital product.";
export const whatDoWeOfferTextThree = "If you need to expand your existing workflows with skilled professionals who work exclusively on your project, our dedicated development team can help. A flexible hiring model, such as a dedicated team, ensures transparent communication while you maintain complete control over development processes. Whatever your project requirements may be, ELITEX is here to help you succeed. ";


// FE DEVELOPMENT PROCESS

export const devProcessTextOne = "Front-end development is a crucial part of web development, involving several steps to build user-facing components. Collaborating with designers, back-end developers, and project managers, front-end developers build a product that faces business challenges successfully while remaining fully functional and visually appealing. By combining technologies like HTML, CSS, and JavaScript, our experienced engineers can create responsive user interface designs for various devices and platforms. In this vein, a well-structured process ensures efficiency and high quality of the products. Usually, these stages involve:";
export const devProcessTextTwo = "However, the application development lifecycle is broad and also involves specific web technologies such as HTML, CSS, and JavaScript. These technologies primarily focus on creating the user interface design of the website or application. Front-end developers work to create an accessible and responsive design that works across multiple devices and platforms.";

export const devProcessTextThree = "Overall, it’s vital to follow a structured process to ensure the efficiency and high quality of the product. Let’s see how it works in practice. Usually, the full range of frontend development consists of the following stages:";

export const devProcessContent = [
    {
        id: 1,
        header:"Planning and research",
        text: "At this stage, we need to define the strategic goals of the entire project and the target audience (research user preferences, industry trends, market analysis, etc).",
        arrow: true,
    },
    {
        id: 2,
        header:"Prototyping and wireframing",
        text: "Then, our team creates a visual representation of the layout and user interface. This helps you understand any potential issues better.",
        arrow: true,
    },
    {
        id: 3,
        header:"Design",
        text: "The next stage involves creating design elements (graphic parts, such as color schemes, fonts, logos, and other visual elements) to create consistent user experience."
    },
    {
        id: 4,
        header:"Development",
        text: "This stage is about coding itself. We build the website or app using different programming languages and front-end frameworks.",
        arrow: true,
    },
    {
        id: 5,
        header:"Testing and deployment",
        text: "We conduct thorough testing to identify any bugs or issues. We do tests across various platforms and devices to ensure compatibility. When the product is ready for lunch, we deploy it to the web server and configure the server settings.",
        arrow: true,
    },
    {
        id: 6,
        header:"Maintenance and updates",
        text: "After the deployment, it’s crucial to make ongoing maintenance and updating for the product to keep it secure, fix any issues, and keep up with the latest trends and technology."
    },
]

// WHY CHOOSE ELITEX

export const whyChooseTitle = "Why Choose ELITEX as a Front-End Development Company";

export const whyChooseTextOne = "As an experienced front end web development company, ELITEX has vast expertise in creating scalable and innovative solutions. We work closely to understand our client's vision and appreciate feedback, ensuring it’s incorporated at every stage, delivering a digital product that meets needs and exceeds expectations. Both client and user satisfaction are our top priorities, and we strive to ensure that every project we undertake succeeds in every way.";
export const whyChooseTextTwo = "We understand the importance of following industry-standard coding practices, continuous skill development, and using the latest technologies to ensure the application is efficient, responsive, and accessible. That’s why our team brings together exceptionally qualified, hand-picked engineers who share the ELITEX values to stay up-to-date. The logic is quite straightforward: we need the best people to be the best front-end development company, and that’s why we approach the hiring process very carefully. That’s the main postulate of our work.";

export const whyChooseTextThree = "Our approach combines several principles: zero bureaucracy, ongoing self-improving, transparency in communication, and flexibility. Together it allows us to reduce additional development costs significantly: we offer modest hourly rates, much lower than in Western Europe or Northern America. At the same time, all our team members are fluent in English.";

export const whyChooseTextFour = "We value a reliable long-term partnership founded on confidence and mutual respect. Our track record shows that, on average, collaboration with our clients lasts for more than two years.";

export const whyChooseTextFive = "Working with ELITEX always means stability and fostering trustworthy and respectful cooperation. Book a call today and discover why ELITEX is rightfully considered the top front-end development company.";

export const takeFirstStepText = "Take the First Step: Schedule a Project Consultation Today";

// CASE STUDIES

export const caseStudiesTitle = {
    main: "Discover Real-Life Examples of Web Development in Action Through",
    strong: "Our Case Studies"
};

// TECHNOLOGIES WE USE

export const technologiesUseTitle = "Technologies We Use in Our Front-End Development Services";

export const technologiesUseText = "We deliver top-quality front-end web development services using cutting-edge technologies, advanced tools, and the most efficient front-end frameworks. We continuously improve our coding practices and embrace novelties to ensure our innovative solutions position us at the forefront of the software development industry.";

// FREQUENTLY ASKED QUESTIONS
export const feFaqContent = [
    {
        id: 1,
        name: "What is front-end?",
        text: "Front-end refers to the part of the website or web application visible to users. Front–end development usually includes designing and developing the client side of the website (how it looks and where you can click). Programming languages like HTML, CSS, and JavaScript, together with additional tools, allow the implementation of these development and design solutions.",
        state: "one"
    },
    {
        id: 2,
        name: "What is the best front-end programming language?",
        text: "There is no single “best” answer since the optimal choice of front-end technology depends on the project’s unique requirements and the particular approach of the front-end development agency you are dealing with. HTML, CSS, and JavaScript (for JS, it’s generally about modern frameworks and libraries such as Vue, Angular, React, and TypeScript) are typically considered the major front-end development languages."
    },
    {
        id: 3,
        name: "What is the difference between front-end and back-end development?",
        text: "Front-end development services handle the user interface and user experience of an app/website. It’s mostly about the part of the web app users see and directly interact with. In contrast, back-end development usually refers to designing and implementing server-side logic and database operations (what’s hidden from the user). In contrast to CSS, HTML, and JS, back-end developers typically use languages like Node.js, PHP, Python, or Ruby."
    },
    {
        id: 4,
        name: "What is the typical amount of time required to develop a robust front-end website or application?",
        text: "The time required to develop a robust website or app depends on several factors, primarily the scope of work and the front-end web development company’s experience. The actual front-end development services cycle can take anywhere from several weeks to months to complete a development project, while ongoing maintenance and support of successful projects should be provided for years after the project's launch."
    },
    {
        id: 5,
        name: "Why are front-end development services important?",
        text: "Today, a business's digital presence may significantly contribute to its successful branding. Usually, a front-end development company builds user-friendly websites and applications, connecting the back end with responsive web design in a way that creates consistent user experiences. "
    },
];

// OTHER SERVICES SLIDER

export const servicesSliderTitle = {
    main: "You May Find Interesting",
    strong: "Other Services"
};


// ARTICLES

export const articlesTitle = "Related Content"