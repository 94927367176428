const transformFaqListToObject = (faqList) => {
    const transformedObject = {};

    faqList.forEach((faqItem) => {
        const { id } = faqItem;
        transformedObject[id] = false;
    });

    return transformedObject;
};

const checkCardsStatus = (cards, status) => Object.values(cards).every(card => card === status);

export {
    transformFaqListToObject,
    checkCardsStatus
}