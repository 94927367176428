import React, {useEffect} from 'react';
import './Qa.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {
    Hero,
    Wrapper,
    Spacer,
    PageBody,
    Feedback,
    QaCertified,
    CaseStudiesGrid,
    ScrollDownBtn,
    WdsSlider
} from "../../components";
import {ContactForm, MainCaption, SliderMaker} from "..";
import {
    homeClientsSliderHeader,
    qaBenefitsContent,
    qaFaqList,
    qaFaqTitle,
    qaMainCaptionText,
    qaMainCaptionTitle,
    qaOurText,
    qaProcessContent,
    qaTestingCompanyTextOne,
    qaTestingCompanyTextTwo,
    qaWeOfferContentFour,
    qaWeOfferContentOne,
    qaWeOfferContentThree,
    qaWeOfferContentTwo,
    qaWeOfferTitleFour,
    qaWeOfferTitleOne,
    qaWeOfferTitleThree,
    qaWeOfferTitleTwo,
    qaWhoWeAreTextOne,
    qaWhoWeAreTextTwo,
    qaWhoWeAreTitle,
    testimonialItems,
} from "../../mocks";
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData, qaFaqStructuredData} from "../../utils/structuredData";
import QaWhatOfferCards from "../../components/QaWhatOfferCards/QaWhatOfferCards";
import TechWhatOfferPhoto from "../../components/TechWhatOfferPhoto/TechWhatOfferPhoto";
import {portfolioItem, smoothScroll} from "../../utils";
import BlueLine from "../../components/BlueLine/BlueLine";
import ProcessStages from "../../components/ProcessStages/ProcessStages";
import Faq from "../../components/FAQ/FAQ";
import QaTools from "../../components/QaTools/QaTools";
import {Link} from "react-router-dom";

function Qa() {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <React.Fragment>
            <HelmetSetter
                title='Software Testing and Quality Assurance Services Company | ELITEX'
                description="At ELITEX, a trusted QA services company, we know everything about ensuring your product’s quality. Check how our QA and testing services can help your success."
                ogImg='qa/qaa.jpg'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Quality Assurance',
                    item2: 'https://elitex.systems/software-qa-testing-services'
                })}
                faqStructuredData={qaFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img qa">
                <Wrapper className="wrapper-medium">
                    <MainCaption
                        title={qaMainCaptionTitle}
                        text={qaMainCaptionText}
                        contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-qa">
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">{qaWhoWeAreTitle}</h2>
                    <Spacer type="medium"/>
                    <h4 className="main-subtitle qa-text">{qaWhoWeAreTextOne}</h4>
                    <Spacer type="small"/>
                    <h4 className="main-subtitle qa-text">{qaWhoWeAreTextTwo}</h4>
                    <Spacer type="big"/>
                </Wrapper>
                <div className="qa-cta">
                    <Wrapper className="wrapper-medium">
                        <div>
                            <p className="main-title">
                                Do you want to feel the difference high-quality QA services can make for your business?
                            </p>
                            <p className="main-title">
                                Join ELITEX QA workshops and check how seasoned experts can improve your project in just
                                a few hours!
                            </p>
                        </div>
                        <Link to="/software-qa-testing-services/qa-workshops">
                            <button className="btn-outline-blue">
                                Learn more
                            </button>
                        </Link>
                    </Wrapper>
                </div>
                <div className="case-study-grey-3">
                    <Spacer type="medium"/>
                    <Wrapper className="wrapper-medium">
                        <h2 className="main-title">Our <strong>QA</strong> and Software Testing Services</h2>
                        <Spacer type="medium"/>
                        <p className="main-subtitle tech">{qaOurText}</p>
                    </Wrapper>
                    <Spacer type="medium"/>
                </div>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <QaWhatOfferCards cards={qaWeOfferContentOne} title={qaWeOfferTitleOne}/>
                    <Spacer type="big"/>
                    <QaWhatOfferCards cards={qaWeOfferContentTwo} title={qaWeOfferTitleTwo}/>
                    <Spacer type="big"/>
                    <QaWhatOfferCards cards={qaWeOfferContentThree} title={qaWeOfferTitleThree}/>
                    <Spacer type="big"/>
                    <QaWhatOfferCards cards={qaWeOfferContentFour} title={qaWeOfferTitleFour}/>
                </Wrapper>
                <Spacer type="big"/>
                <div className="case-study-grey-3">
                    <Wrapper className="wrapper-medium">
                        <QaCertified/>
                    </Wrapper>
                </div>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title">How Businesses Benefit From <strong>Testing</strong> At Elitex</h2>
                    <Spacer type="medium"/>
                    <TechWhatOfferPhoto content={qaBenefitsContent}/>
                </Wrapper>
                <Spacer type="big"/>
                <h2 className="main-title">Our<strong> QA</strong> and Testing Case Studies</h2>
                <Spacer type="medium"/>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('hotelReservations', true),
                    portfolioItem('smartrr', false),
                    portfolioItem('exterity', true)
                ]}/>
                <Spacer type="big"/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="main-title left">ELITEX: Software<strong> Testing Services Company</strong></h2>
                    <BlueLine/>
                    <p className="main-subtitle left tech">{qaTestingCompanyTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle left tech">{qaTestingCompanyTextTwo}</p>
                    <Spacer type="big"/>
                    <h2 className="main-title">Tools We Use During Testing</h2>
                    <Spacer type="medium"/>
                    <QaTools/>
                    <Spacer type="big"/>
                    <h2 className="main-title">Testing process</h2>
                    <Spacer type="medium"/>
                    <ProcessStages content={qaProcessContent}/>
                    <Spacer type="big"/>
                </Wrapper>
                <div id="testimonial-slider">
                    <SliderMaker dark
                                 clientsSliderHeader={homeClientsSliderHeader}
                                 sliderItems={testimonialItems}
                                 SliderItem={Feedback}
                                 speed={15000}
                                 dots
                    />
                </div>
                <Spacer type="big"/>
                <h2 className="main-title">Our Other Services</h2>
                <Spacer type="small"/>
                <WdsSlider/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <Faq content={qaFaqList} title={qaFaqTitle}/>
                    <Spacer type="big"/>
                </Wrapper>
                <div className="case-study-grey-2">
                    <ContactForm/>
                </div>
            </PageBody>
        </React.Fragment>
    );
}

export {
    Qa
};
