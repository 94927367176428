import React, {useEffect} from 'react';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {
    Hero,
    Wrapper,
    Spacer,
    PageBody,
    Feedback,
    QaWhatWeDo,
    JsdLogos,
    CaseStudiesGrid,
    WdsSlider,
    ScrollDownBtn,
    QaRelatedArticles
} from "../../components";
import {ContactForm, MainSlider, SliderMaker} from "..";
import {
    testimonialItems,
    jsdWhyWeWork,
    jsdSliderItems,
    jsdTechText,
    jsdFaqTitle,
    jsdFaqContent,
    homeClientsSliderHeader,
    jsdWhatOfferCards,
    jsdWhatOfferTitle,
    jsdWhatOfferText
} from "../../mocks";
import './Jsd.css';
import HelmetSetter from "../../components/Helmet/Helmet";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData, jsdFaqStructuredData} from "../../utils/structuredData";
import JsdWhyChooseElitex from "../../components/JsdWhyChooseElitex/JsdWhyChooseElitex";
import FAQ from "../../components/FAQ/FAQ";
import WhatDoWeOffer from "../../components/WhatDoWeOffer/WhatDoWeOffer";
import BlueLine from "../../components/BlueLine/BlueLine";

function Jsd() {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    return (
        <React.Fragment>
            <HelmetSetter
                title='JavaScript Development Services | JS Development Company | ELITEX'
                description='Explore the potential of JavaScript development services with ELITEX. As a trusted JavaScript development company, we provide results succeeding expectations.'
                ogImg='jsd/jsd.jpg'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'JavaScript Development',
                    item2: 'https://elitex.systems/javascript-development'
                })}
                faqStructuredData={jsdFaqStructuredData}
            />
                <Hero className="jsd-hero" >
                    <MainSlider sliderItems={jsdSliderItems} contactUsBtn/>
                    <ScrollDownBtn />
                </Hero>
            <PageBody>
                <Wrapper className="wrapper-medium">
                    <Spacer type='medium'/>
                    <h2 className='main-title'>JavaScript Development Benefits</h2>
                    <Spacer type='medium'/>
                    <QaWhatWeDo list={jsdWhyWeWork}/>
                    <Spacer type='big'/>
                    <h2 className='main-title'>Technologies We Use in Our JavaScript Development Services</h2>
                    <Spacer type='medium'/>
                    <p className="align-center" style={{fontSize: "20px"}}>{jsdTechText}</p>
                    <Spacer type='medium'/>
                    <JsdLogos/>
                </Wrapper>
                <Spacer type='big'/>
                <h2 className='main-title'>JavaScript-Based Solutions We Build</h2>
                <Spacer type='small'/>
                <CaseStudiesGrid list={[
                    portfolioItem('kallik', false),
                    portfolioItem('exterity', true),
                    portfolioItem('zippia', false),
                    portfolioItem('smartrr', true)
                ]}/>
                <Wrapper className="wrapper-medium">
                    <Spacer type='big'/>
                    <h2 className='main-title'>{jsdWhatOfferTitle}</h2>
                    <BlueLine/>
                    <p className='main-subtitle'>{jsdWhatOfferText}</p>
                    <Spacer type='small'/>
                    <WhatDoWeOffer content={jsdWhatOfferCards}/>
                    <Spacer type='big'/>
                    <JsdWhyChooseElitex/>
                    <Spacer type='big'/>
                </Wrapper>
                <div id="testimonial-slider">
                <SliderMaker dark clientsSliderHeader={homeClientsSliderHeader} sliderItems={testimonialItems} SliderItem={Feedback} speed={15000} dots/>
                </div>
                <Spacer type='big'/>
                <Wrapper className="wrapper-medium">
                    <FAQ content={jsdFaqContent} title={jsdFaqTitle}/>
                </Wrapper>
                <Spacer type='big'/>
                <h2 className='main-title'>Other services<strong> We Provide</strong></h2>
                <Spacer type='small'/>
                <WdsSlider/>
                <Spacer type='big'/>
                <div className='case-study-grey-2'>
                    <ContactForm/>
                </div>
                <Spacer type="big"/>
                <Wrapper className="wrapper-medium">
                    <h2 className='main-title'><strong>Related Content</strong></h2>
                    <Spacer type="medium"/>
                    <QaRelatedArticles/>
                    <Spacer type="medium"/>
                </Wrapper>
            </PageBody>
        </React.Fragment>
    );
}

export {Jsd};
