import React from 'react';
import {smoothScroll} from '../../utils';
import './MainCaption.css';

export const MainCaption = ({title, text, contactUsBtn, caseStudiesBtn}) => (
    <div className="main-caption">
        <h1 className="el-main-label">{title}</h1>
        <p className="el-main-text">{text}</p>
        <div className="main-caption-btns">
            {contactUsBtn &&
                <button
                    className="btn btn-primary btn-primary-inverted"
                    onClick={smoothScroll.bind(this, "contact-title")}>
                    Contact us
                </button>}
            {caseStudiesBtn &&
                <button
                    className="btn btn-primary"
                    onClick={smoothScroll.bind(this, "portfolio-grid")}>
                    Case studies
                </button>}
        </div>
    </div>
);
