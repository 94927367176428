import React from 'react';
import './BannerSupport.css';

export const BannerSupport = ({ invert }) => (
  <aside className={`support-banner${invert ? ' support-banner-inverted' : ''}`}>
    <div className='support-banner-text-container support-banner-text'>Elitex stands with Ukraine
      <img
          className='support-banner-flag'
          src="/img/home/icons/ua-flag.png"
          alt="ukraine flag"
          width="20"
          height="16"
      />
      <a target="_blank" className='support-banner-text support-banner-link' href="https://savelife.in.ua/en/donate-en/" rel="nofollow noopener noreferrer" tabIndex="0">
        Support charity fund
      </a>
    </div>
  </aside>
);
