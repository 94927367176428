import React, {Fragment, useEffect} from 'react';
import './NodeTech.css';
import HelmetSetter from "../../components/Helmet/Helmet";
import {breadcrumbStructuredData, nodeTechFaqStructuredData} from "../../utils/structuredData";
import {
    CaseStudiesGrid,
    Feedback,
    Hero,
    LogosList,
    PageBody, QaRelatedArticles,
    ScrollDownBtn,
    Spacer,
    WdsSlider,
    Wrapper
} from "../../components";
import {MainCaption} from "../MainCaption/MainCaption";
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {
    customersLogos,
    nodeBenefitsCards,
    nodeBenefitsText,
    nodeExperienceTextOne,
    nodeExperienceTextThree,
    nodeExperienceTextTwo,
    nodeExperienceTitle,
    nodeFaqList,
    nodeFaqTitle,
    nodeMainCaptionText,
    nodeMainCaptionTitle,
    nodeProcessContent,
    nodeProcessTextOne,
    nodeProcessTextTwo,
    nodeProcessTitle,
    nodeProjectText,
    nodeTechAwardsContent,
    nodeWhatDoOfferLink,
    nodeWhatDoOfferSubtitle,
    nodeWhatDoOfferTextOne,
    nodeWhatDoOfferTextTwo,
    nodeWhatDoOfferTitle,
    nodeWhatDoWeOfferContent,
    nodeWhatMakesTextOne,
    nodeWhatMakesTextThree,
    nodeWhatMakesTextTwo,
    nodeWhatMakesTitle,
    testimonialItems,
    homeClientsSliderHeader
} from "../../mocks";
import TechAwards from "../../components/TechAwards/TechAwards";
import {SliderMaker} from "../SliderMaker/SliderMaker";
import BlueLine from "../../components/BlueLine/BlueLine";
import TechWhatOfferPhoto from "../../components/TechWhatOfferPhoto/TechWhatOfferPhoto";
import {Link} from "react-router-dom";
import ProcessStages from "../../components/ProcessStages/ProcessStages";
import TechWhatOfferCards from "../../components/TechWhatOfferCards/TechWhatOfferCards";
import ProjectConsultation from "../../components/ProjectConsultation/ProjectConsultation";
import {portfolioItem} from "../../utils";
import FAQ from "../../components/FAQ/FAQ";
import {ContactForm} from "../ContactForm/ContactForm";

export const NodeTech = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <Fragment>
            <HelmetSetter
                title="Node.js Development Services | ELITEX"
                description="ELITEX is an advanced Node.js development company that provides custom web solutions. Explore the benefits of Node.js development services with ELITEX."
                ogImg="node/node-back.webp"
                structuredData={breadcrumbStructuredData({
                    name1: "Home",
                    item1: "https://elitex.systems",
                    name2: "Node.js",
                    item2: "https://elitex.systems/node-js-technology"
                })}
                faqStructuredData={nodeTechFaqStructuredData}
            />
            <Hero className="hero-big banner-with-img node">
                <Wrapper className="wrapper-medium">
                    <MainCaption title={nodeMainCaptionTitle} text={nodeMainCaptionText} contactUsBtn/>
                    <ScrollDownBtn/>
                </Wrapper>
            </Hero>
            <PageBody className="page-body-node">
                <Wrapper className="wrapper-medium">
                    <TechAwards content={nodeTechAwardsContent}/>
                    <Spacer type="big"/>
                </Wrapper>
                <div id="testimonial-slider">
                    <SliderMaker dark
                                 clientsSliderHeader={homeClientsSliderHeader}
                                 sliderItems={testimonialItems}
                                 SliderItem={Feedback} speed={15000}
                                 dots
                    />
                </div>
                <Spacer type="big"/>
                <Wrapper className="wrapper-medium">
                    <h2 className="main-title">{nodeWhatDoOfferTitle.main}<strong> {nodeWhatDoOfferTitle.strong}</strong>
                    </h2>
                    <BlueLine/>
                    <p className="main-subtitle">{nodeWhatDoOfferSubtitle}</p>
                    <Spacer type="big"/>
                    <TechWhatOfferPhoto content={nodeWhatDoWeOfferContent}/>
                    <Spacer type="big"/>
                    <p className="main-subtitle node-offer">
                        {nodeWhatDoOfferTextOne} <Link to="/dedicated-software-development-team"
                                                       className="node-what-offer-link">{nodeWhatDoOfferLink}</Link>
                    </p>
                    <Spacer type="medium"/>
                    <p className="main-subtitle">{nodeWhatDoOfferTextTwo}</p>
                    <Spacer type="big"/>
                    <h2 className="main-title">{nodeProcessTitle}</h2>
                    <BlueLine/>
                    <p className="main-subtitle">{nodeProcessTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{nodeProcessTextTwo}</p>
                    <Spacer type="big"/>
                    <ProcessStages content={nodeProcessContent}/>
                    <Spacer type="big"/>
                    <h2 className="main-title"><strong>{nodeWhatMakesTitle.strong}</strong> {nodeWhatMakesTitle.main}
                    </h2>
                    <BlueLine/>
                    <p className="main-subtitle">{nodeWhatMakesTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{nodeWhatMakesTextTwo}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle">{nodeWhatMakesTextThree}</p>
                    <Spacer type="big"/>
                    <h2 className="main-title">Benefits of Using <strong>Node.js</strong> as an Application Development
                        Language</h2>
                    <TechWhatOfferCards text={nodeBenefitsText} cardsContent={nodeBenefitsCards}/>
                    <Spacer type="big"/>
                </Wrapper>
                <ProjectConsultation text={nodeProjectText}/>
                <Wrapper className="wrapper-medium">
                    <Spacer type="big"/>
                    <h2 className="h4 align-center"><strong>Our clients and partners</strong></h2>
                    <LogosList list={customersLogos} redirect/>
                    <Spacer type="big"/>
                    <h2 className="main-title left">{nodeExperienceTitle.main}<strong> {nodeExperienceTitle.strong}</strong>
                    </h2>
                    <BlueLine/>
                    <p className="main-subtitle left tech">{nodeExperienceTextOne}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle left tech">{nodeExperienceTextTwo}</p>
                    <Spacer type="small"/>
                    <p className="main-subtitle left tech">{nodeExperienceTextThree}</p>
                    <Spacer type="big"/>
                    <h2 className="main-title" id="portfolio-grid">Check the Real-World Examples of <strong>Our Node.js
                        Case Studies</strong></h2>
                    <Spacer type="small"/>
                </Wrapper>
                <CaseStudiesGrid list={[
                    portfolioItem('peerwith', false),
                    portfolioItem('exterity', true),
                    portfolioItem('imagex', false),
                    portfolioItem('postCMS', true),
                ]}/>
            </PageBody>
            <Wrapper className="wrapper-medium">
                <Spacer type="big"/>
                <FAQ title={nodeFaqTitle} content={nodeFaqList}/>
                <Spacer type="big"/>
                <h2 className="main-title"><strong>Other Technologies</strong> We Provide Beyond Node.js Development and
                    Maintenance</h2>
            </Wrapper>
            <Spacer type="small"/>
            <WdsSlider/>
            <Wrapper className="wrapper-medium">
                <Spacer type="big"/>
                <h2 className="main-title"><strong>Related Content</strong></h2>
                <Spacer type="medium"/>
                <QaRelatedArticles/>
                <Spacer type="big"/>
            </Wrapper>
            <div className="case-study-grey-2">
                <ContactForm/>
            </div>
        </Fragment>
    );
};
