import React from 'react';

export const ColumnItemsList = ({ list, classBlock, Component }) => (
  <React.Fragment>
    {
      list.map(elem => (
        <div key={elem.id} className={`column-section clearfix ${classBlock ? classBlock : ''}`} >
          <div>
            <h5 className="title-alt"><strong>{elem.name}</strong></h5>
          </div>
          {elem.data.map((dataItem, index) => <Component key={index} {...dataItem}/>)}
        </div>
      ))
    }
  </React.Fragment>
);
