import React from 'react';

const allowedEls = {
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

export const Heading = ({ tag, children, ...props }) => {    
  return React.createElement(
    allowedEls[tag] || allowedEls.h4, 
    props, 
    children
  );
}