import React, { Fragment } from "react";
import Slider from "react-slick";
import "./CareerPhoto.css";

const SLIDER_SETTINGS = {
    className: "office-gallery-slider",
    dots: true,
    centerMode: false,
    infinite: true,
    centerPadding: "20px",
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
};
// const MOBILE_BREAKPOINT = 1500;

export const CareerPhoto = ({photosArr}) => {
  // const windowWidth = useWindowWidth();
  // const isMobile = windowWidth < MOBILE_BREAKPOINT;
  return (
      <Fragment>
      <Slider {...SLIDER_SETTINGS}>
        {photosArr.map(photo => (
            <img
                key={photo.id}
                src={photo.path}
                srcSet={photo.srcSetPath}
                className="career-office-slider-img"
                alt="offic"
                loading="lazy"
                width="100%"
                height="100%"
            />
        ))}
      </Slider>
      {/* {isMobile ? (
        <Slider {...SLIDER_SETTINGS}>
          {Object.keys(photosArr).map((photo, index) => (
             <img key={index} src={photosArr[index].path} alt="office" loading="lazy"/>
          ))}
        </Slider>
      ) : (
        <div className="CareerPhoto">
          <img src={photosArr[0].path} alt="office" loading="lazy" />
          <div className="CareerPhoto-tab">
            <img src={photosArr[1].path} alt="office" loading="lazy"/>
            <img src={photosArr[2].path} alt="office" loading="lazy"/>
            <img src={photosArr[3].path} alt="office" loading="lazy"/>
            <img src={photosArr[4].path} alt="office" loading="lazy"/>
          </div>
        </div>
      )} */}
      </Fragment>
  );
};
