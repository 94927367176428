export const technologiesLogos = [
    {
        id: 1,
        alt: 'JavaScript',
        src: 'img/technologies/webp/js.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '78',
        link: "https://elitex.systems/javascript-development"
    },
    {
        id: 2,
        alt: 'NodeJS',
        src: 'img/technologies/webp/nodeJS.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '127.29',
        link: "https://elitex.systems/node-js-technology"
    },
    {
        id: 3,
        alt: 'Angular',
        src: 'img/technologies/webp/angular.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '160.64',
        link: "https://elitex.systems/angular-technology"
    },
    {
        id: 4,
        alt: 'React',
        src: 'img/technologies/webp/react.webp',
        height: '78px',
        explicitHeight: '78',
        explicitWidth: '151.81',
        link: "https://elitex.systems/react-technology"
    },
];
