import React, { useState } from 'react';
import "./CareerWhoWeAre.css";

import { careerWhoWeAre } from "../../mocks";
import {smoothScroll} from "../../utils";

export const CareerWhoWeAre = ({ vacancies }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const turnSlide = (slideIndex) => {
    if (typeof slideIndex === "number") {
      setActiveSlide(slideIndex);
      return;
    }

    activeSlide + 1 < careerWhoWeAre.length
      ? setActiveSlide(activeSlide + 1)
      : setActiveSlide(0);
  };

  return (
    <div>
      <div className="career-who-we-are-container-title">Who we are</div>
      <div className="career-who-we-are-container">
        <div className="career-who-we-are">
          <h4>
            Meet our company <br /> <span>&#38;</span> be a part our team
          </h4>
          <p>
            ELITEX is a trusted technology partner for startups and established
            businesses. Since 2015 we have been helping our customers deliver
            strategic projects, as well as strengthen their engineering teams.
          </p>
          <ul>
            <li>We are professionals</li>
            <li>We provide the best work environment</li>
            <li>We help our people to grow</li>
            <li>We hear your opinion</li>
            <li>We do care about our team</li>
          </ul>
          <button onClick={() => smoothScroll("career-slider")} className="btn btn-filled submit">
            Check open positions{" "}
            {vacancies.length > 0 ? `(${vacancies.length})` : ""}
          </button>
        </div>
        <div
          className="career-who-we-are-item"
          key={careerWhoWeAre[activeSlide].id}
        >
          <img
              src={careerWhoWeAre[activeSlide].img}
              srcSet={careerWhoWeAre[activeSlide].srcSetPath}
              alt="img"
              width="80%"
              height="100%"
          />
          <div className="career-who-we-are-item-content-wrapper">
            <div className="career-who-we-are-item-content">
              <h4>
                <strong>{careerWhoWeAre[activeSlide].header}</strong>
              </h4>
              <p>{careerWhoWeAre[activeSlide].text}</p>
              <div>
                {careerWhoWeAre.map((item, index) => (
                  <span
                    onClick={() => turnSlide(index)}
                    className={index === activeSlide ? "active" : ""}
                    key={index}
                  />
                ))}
              </div>
            </div>
            <div
              className="career-who-we-are-item-next-slide"
              onClick={turnSlide}
            >
              <i className="fas fa-arrow-right"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
