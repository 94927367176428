import React from 'react';
import { Route } from 'react-router-dom';

function Status({ code, children }) {
  return (
    <div className='statusContainer'>
      <Route
        render={({ staticContext }) => {
          if (staticContext) staticContext.status = code;
          return (<>
            {!staticContext && <div>Missing context!!!</div>}
            {children}
          </>);
        }}
      />
    </div>
  );
}

export { Status };