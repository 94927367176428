import React from 'react';
import './CsdWhatWeCanDo.css';
import {csdWhatWeCanDo} from "../../mocks";
import LazyLoad from "react-lazyload";

export const CsdWhatWeCanDo = () => (
  <div className='csd-what-container'>
    {csdWhatWeCanDo.map((item) => (
        <LazyLoad key={item.id}>
          <div
              className={`csd-what-item ${item.reverse ? 'csd-what-item--reversed' : ''}`}
              style={{backgroundImage: `url(${item.img})`}}
              >
            <div className='csd-what-item-inner'>
              <h2 className="h5"><strong>{item.header}</strong></h2>
              <p>{item.text}</p>
            </div>
          </div>
        </LazyLoad>
    ))}
  </div>
);
