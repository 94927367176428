const jsdWhyChooseContent = {
    title: {
        strong: "Why Choose ELITEX",
        main: "as Your JavaScript Development Company"
    },
    textList: [
        {
            id:1,
            text: "As a JavaScript development company, we can build a JavaScript project on a budget or produce a large-scale solution with thousands of simultaneous connections. We follow the best scalability and ongoing maintenance practices to ensure the web application runs smoothly and loads fast. Our JavaScript development service is first-rate whether you want to create a rich UI or a complex web application."
        },
        {
            id:2,
            text: "As a reputable and trusted JavaScript company, we have a strong record of innovative projects and high-quality assurance standards. Our vast expertise is complemented by extensive experience in building robust front-end solutions for various industries, including IT, Fintech, finance, media, education, and hospitality."
        },
        {
            id:3,
            text: "ELITEX offers top-notch and creative solutions that help businesses achieve their objectives and better appeal to their customers' preferences by utilizing the latest technologies and trends in project development. Your app visitors will enjoy a more pleasant user experience, which, as a result, will contribute to higher conversion rates and more sales."
        },
    ]
}

const jsdWhyChooseCards= [
    {
        id: 1,
        title: "Why choose ELITEX JavaScript developers for your project?",
        textList: [
            {
                id: 1,
                text: "Since our foundation, we have been a JavaScript web development company, and 94% of our developers are middle or senior-level. With the support of the latest technologies and JS frameworks, ELITEX has acquired a solid reputation in JavaScript development. Our narrow focus on JS frameworks and years of experience distinguish us from ordinary JavaScript web development company."
            },
            {
                id: 2,
                text: "Our team consists of highly-skilled front-end developers with experience in application development and a track record of completing high-complexity projects. We have deep expertise in creating high-end, efficient web applications, mobile apps, and cross-platform solutions for all purposes."
            },
        ]
    },
    {
        id: 2,
        title: "Creating your product from scratch",
        textList: [
            {
                id: 1,
                text: "Creating a web product from scratch can be a complex undertaking requiring a holistic approach. Developers have complete control over every aspect of the application, from the user interface to the functionality, using the same strategies and techniques for all product components. It allows them to tailor the app to meet the specific needs of your target audience. Also, developing apps from scratch increases efficiency due to a streamlined codebase that doesn’t contain heterogeneous elements."
            },
            {
                id: 2,
                text: "ELITEX offers bespoke JavaScript web development services to help companies design unique apps tailored to their business needs. Furthermore, development and designing are not the only stages of building a solid product. ELITEX can assist you with integrating external APIs and data sources or cloud infrastructure optimization into your JS-based application as well as other services required to build the unique custom project you are looking for."
            },
        ]
    },
    {
        id: 3,
        title: "We can build a dedicated team for you",
        textList: [
            {
                id: 1,
                text: "ELITEX uses different development techniques and strategies for the JavaScript development services we provide, for instance, agile methodology. Alternatively, we also offer outsourcing options if you prefer dedicated teams approach. Our experienced developers can expand your talent pool and complement your existing in-house team, allowing you to focus your team on the core activities."
            },
        ]
    },
];

export {jsdWhyChooseContent, jsdWhyChooseCards};