// Main

const devopsMainCaptionTitle = "DevOps Services and Solutions";
const devopsMainCaptionText = "DevOps services are fundamental to the modern software development process, reshaping how organizations create, deploy, and manage applications. Based on collaboration, automation, and continual improvement, these services enable businesses to deliver applications more swiftly and reliably. With a proven track record in providing DevOps solutions, ELITEX is ready to empower your journey towards enhanced efficiency and software excellence.";


// We Offer

const devopsOfferTitle = "DevOps Services We Offer";
const devopsOfferText = "Being a seasoned DevOps solutions and services provider, ELITEX is dedicated to assisting you in embracing the DevOps culture and implementing the latest methodologies, tools, and practices for this purpose. A comprehensive list of services offered by us includes:";

const devopsOfferCards = [
    {
        id: 1,
        title: "Security integration",
        text: "Security is paramount; it’s a non-negotiable aspect of any development process. We integrate best security practices throughout the DevOps lifecycle, encompassing threat modeling, vulnerability assessments, and secure coding practices. We offer several flexible security models suitable for various scenarios, from security integrations in the early stages of development to post-release security feature implementations.",
        image: "/img/devops/offer/devops-offer-1.webp"
    },
    {
        id: 2,
        title: "Continuous integration and continuous delivery (CI/CD)",
        text: "ELITEX offers experienced DevOps teams to design and implement CI/CD pipelines, automating the build, testing, and deployment process for faster and more reliable software delivery. Whether it’s continuous integration for code changes or continuous deployment for releasing to production, our vast CI/CD expertise has what to offer you in achieving seamless, efficient, and agile software delivery.",
        image: "/img/devops/offer/devops-offer-2.webp"

    },
    {
        id: 3,
        title: "Infrastructure as Code (IaC)",
        text: "Being one of the principal DevOps practices, IaC enables infrastructure management and automation using the code. This approach ensures scalability and consistency, allowing you to define, version, test, and deploy your infrastructure as part of your application code. As an experienced DevOps company, we have what to offer you, whether it’s setting up virtual machines, configuring network resources, or deploying application stacks.",
        image: "/img/devops/offer/devops-offer-3.webp"
    },
    {
        id: 4,
        title: "Microservices architecture implementation",
        text: "The list of our services includes microservices-based solutions, ideal for complex application development. This method boosts scalability, eases maintenance, and speeds up deployment by breaking down applications into smaller, independent services. We leverage sophisticated tools such as Docker for containerization and Kubernetes for orchestration, guaranteeing smooth management of each microservice, facilitating seamless integration and continuous delivery, while enhancing overall system resilience and efficiency.",
        image: "/img/devops/offer/devops-offer-4.webp"
    },
    {
        id: 5,
        title: "Cloud adoption and migration",
        text: "ELITEX offers assistance in the adoption of cloud services and migration of existing applications to the cloud. Cloud infrastructure provides scalability, flexibility, and cost efficiency, enabling rapid application deployment and scaling while reducing capital expenditures. Our expertise involves both working with cloud-native solutions and migrating existing applications to the cloud, allowing you to forget the burden of managing and maintaining on-premise infrastructure.",
        image: "/img/devops/offer/devops-offer-5.webp"
    },
    {
        id: 6,
        title: "Continuous monitoring and logging",
        text: "Our DevOps services and solutions include continuous monitoring and logging that offer real-time insights into the performance, security, and health of your application and infrastructure. By leveraging state-of-the-art DevOps monitoring/logging tools like ELK and practices (e.g., centralized logging, user activity logging, compliance logging, etc.), we ensure that you have immediate visibility into any issues, allowing for proactive issue detection and resolution.",
        image: "/img/devops/offer/devops-offer-6.webp"
    },
    {
        id: 7,
        title: "Containerization and orchestrations",
        text: "Using advanced tools such as Docker and Kubernetes, we provide containerization and orchestration services to ensure your application’s reliability across different environments and simplify the development and testing processes. Containerization and orchestration are key enablers of microservices architecture, enhancing resource utilization and allowing you to respond dynamically to fluctuating workloads.",
        image: "/img/devops/offer/devops-offer-7.webp"
    }, {
        id: 8,
        title: "DevOps as a service (DaaS)",
        text: "DaaS is a comprehensive solution designed to enhance your software development and delivery process. With DaaS, we provide end-to-end support for your DevOps journey, spanning from code development to deployment, maintenance, and beyond. Our DevOps team collaborates with you to design and implement CI/CD pipelines, automate testing, and integrate security at every development lifecycle stage, fostering DevOps culture, reducing manual intervention, and optimizing an entire software development process.",
        image: "/img/devops/offer/devops-offer-8.webp"
    }, {
        id: 9,
        title: "DevOps consulting services",
        text: "ELITEX offers expert guidance and tailored strategies to help you achieve your goals. We provide comprehensive support in various areas, including technology, methodology, management, particular tools, etc. The experienced members of our DevOps teams work closely with your in-house team to understand your unique challenges and opportunities, optimize your business processes, harness the latest technology, and ultimately create a roadmap to success.",
        image: "/img/devops/offer/devops-offer-9.webp"
    },
];

// Process

const devopsProcessTitle = "Our DevOps Process";

const devopsProcessContent = [
    {
        id: 1,
        header: "Planning and automation",
        text: "Initiate your DevOps transformation journey with thorough planning and the construction of automation pipelines involving both development and operations teams. At this stage, we define project objectives, select proper tools, and establish automation scripts and workflows.",
        arrow: true
    },
    {
        id: 2,
        header: "Development",
        text: "At this stage, development teams collaborate closely with DevOps specialists to write and update code. It includes version control and continuous integration practices, ensuring that code changes are consistently integrated, built, and tested in a shared environment while maintaining code quality.",
        arrow: true
    },
    {
        id: 3,
        header: "Deployment automation",
        text: "The development phase involves writing the code for At the next step, we automate the deployment process, minimizing the human interventions in order to ensure consistent and error-free application delivery. Using various scripts and tools for smooth and repeatable deployment at this stage reduces the risk of configuration drift and human errors. application using React JS. This step includes creating reusable components and management.",
        arrow: false
    },
    {
        id: 4,
        header: "Configuration management",
        text: "Leveraging advanced configuration management tools (such as Ansible, Puppet, or Chef) allows us to maintain consistency across the infrastructure. The proper tools help automate the configuration of servers, reduce manual interventions, and ensure that systems are set up exactly as intended.",
        arrow: true
    },
    {
        id: 5,
        header: "Support and maintenance:",
        text: "After finishing the main part of the development lifecycle, we implement continuous monitoring and logging solutions to keep a watchful eye on your applications and infrastructure. At this point, we collect insights into your systems' performance, security, and health, enabling us to detect and address issues proactively and maintain optimal application performance while maintaining data integrity and user trust.",
        arrow: false
    },
];

// Benefits

const devopsBenefitsTitle = "DevOps Services Benefits";

const devopsBenefitsContent = [
    {
        id: 1,
        title: "Improved software quality and reliability",
        text: "Partnering with experienced DevOps service providers offers significant benefits regarding the overall quality of software builds. With a professional DevOps team, you can identify and rectify issues in development early. It means more stable and dependable software solutions with fewer bugs and vulnerabilities. Moreover, integrating DevOps into your development lifecycle increases security and compliance.",
        img: "/img/devops/benefits/devops-quality.svg",
        tagName: "h3"
    },
    {
        id: 2,
        title: "Reduced costs",
        text: "DevOps services and solutions can lead to cost savings through the automation of processes and reduced manual interventions. With automated testing, deployment, and monitoring, companies and organizations can significantly lower overall operational costs. Moreover, the post-production costs can also be substantially reduced by improving software quality and minimizing the need for post-release bug fixes.",
        img: "/img/devops/benefits/devops-costs.svg",
        tagName: "h3"
    },
    {
        id: 3,
        title: "Faster time-to-market",
        text: "DevOps development services empower you to accelerate the software development and delivery processes. DevOps processes enable the rapid deployment of new features and updates through automation and streamlined pipelines. This agility is crucial in today’s fast-paced business and production environments, allowing companies to respond to market demands swiftly while staying competitive.",
        img: "/img/devops/benefits/devops-time.svg",
        tagName: "h3"
    },
    {
        id: 4,
        title: "Increased security and compliance",
        text: ".NET is an ideal solution for the development of interoperable apps that offer a perfect user experience across different platforms. Interoperability helps maximize code reuse and makes the app’s development process highly efficient. Each code and feature produced in the .NET framework can be reused on various platforms.",
        img: "/img/devops/benefits/devops-security.svg",
        tagName: "h3"
    },
];

// Why Choose

const devopsWhyChooseTextOne = "Choosing ELITEX as your development partner is a strategic decision that can help transform your software development and delivery processes. With almost a decade of experience in DevOps and custom software development, complemented by successful project deliveries with a proven track record, we understand how our DevOps professional services can make a difference for your project. Our team of seasoned DevOps professionals (over 90% of our developers are middle and senior developers) will work closely with you to implement the DevOps methodology, best practices, cutting-edge tools, and automation solutions that optimize your development processes, ultimately resulting in faster product release time and cost savings.";
const devopsWhyChooseTextTwo = "ELITEX recognizes that every company has its own set of challenges and business goals, and as such, we take a highly personalized approach to each project. For this purpose, we offer various flexible collaboration models suitable for different scenarios, whether you require a dedicated team of DevOps specialists, end-to-end DevOps consulting, support, or full-scale project execution. We are dedicated to tailoring our approach to your specific requirements, enabling you to choose the level of involvement that best suits your circumstances.";
const devopsWhyChooseTextThree = "We aim to provide you with a meaningful partnership that fosters innovations and achieves results that exceed your expectations. Therefore, our collaboration models are designed for lasting relationships that adapt and grow together with you. It means providing support that goes beyond DevOps software development services and extends throughout the entire customer lifecycle, which typically lasts for more than two years. Forging a meaningful partnership means not only addressing your immediate needs but also working together to secure long-term success and innovation, and that’s what ELITEX is aiming for.";

// ProjectConsultation

const devopsProjectText = "Get Started on Your Project - Schedule a Consultation Today";

// Tools

const devopsToolsTitle = "DevOps Tools and Technologies";

const devopsToolsLogos = [
    {
        id: 1,
        alt: "Aws",
        src: "img/devops/tools/devops-tools-aws.svg",
        height: "80px",
        explicitHeight: "80",
        explicitWidth: "143",
    },
    {
        id: 2,
        alt: "Google cloud",
        src: "img/devops/tools/devops-tools-google.svg",
        height: "80px",
        explicitHeight: "80",
        explicitWidth: "112",
    },
    {
        id: 3,
        alt: "Azure",
        src: "img/devops/tools/devops-tools-azure.svg",
        height: "80px",
        explicitHeight: "80",
        explicitWidth: "80",
    },
    {
        id: 4,
        alt: "Sql",
        src: "img/devops/tools/devops-tools-sql.svg",
        height: "80px",
        explicitHeight: "80",
        explicitWidth: "80",
    },
    {
        id: 5,
        alt: "NoSql",
        src: "img/devops/tools/devops-tools-nosql.svg",
        height: "80px",
        explicitHeight: "80",
        explicitWidth: "80",
    },
];

// FAQ

const devopsFaqList = [
    {
        id: 1,
        name: "How can DevOps help my business?",
        text: "DevOps can help your business by improving software delivery and deployment, as well as increasing agility, reducing operational costs, and enhancing collaboration between development and operation teams. Ultimately, it leads to faster time-to-market and higher-quality software."
    },
    {
        id: 2,
        name: "How can I outsource DevOps services?",
        text: "A successful outsourcing process usually consists of several simple steps. Begin by identifying a reputable service provider with experience in the field, then clearly define your goals and objectives, assess specific needs, and then, finally, request proposals from potential providers to choose the one that best aligns with your requirements and goals."
    },
    {
        id: 3,
        name: "How does DevOps enhance software quality?",
        text: "DevOps improves software quality through automated testing, continuous integration, fostering new collaboration approaches, and continuous monitoring. Together, these practices result in more reliable and stable software by identifying and addressing issues earlier in development."
    },
    {
        id: 4,
        name: "How can I measure the success of a DevOps project?",
        text: "In the case of DevOps projects, success can be measured by factors such as faster delivery times, reduced defect rates, increased collaboration, cost savings, and improved user satisfaction. By choosing an experienced DevOps services company, such as ELITEX, you can feel the improvements in different spheres of your development lifecycle."
    },
    {
        id: 5,
        name: "What industries can benefit from the DevOps model in software development?",
        text: "Virtually all industries can benefit from this philosophy, including finance, healthcare, e-commerce, and many more. Any organization that relies on software for its operations can improve efficiency, quality, and innovation through DevOps practices."
    },
];

export {
    devopsMainCaptionTitle,
    devopsMainCaptionText,
    devopsOfferTitle,
    devopsOfferText,
    devopsOfferCards,
    devopsProcessTitle,
    devopsProcessContent,
    devopsBenefitsContent,
    devopsBenefitsTitle,
    devopsWhyChooseTextOne,
    devopsWhyChooseTextTwo,
    devopsWhyChooseTextThree,
    devopsProjectText,
    devopsToolsTitle,
    devopsToolsLogos,
    devopsFaqList
}