import React, { useEffect } from 'react';
import './QaWorkshops.css';
import { delay, hideLoader, showLoader } from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import { breadcrumbStructuredData } from "../../utils/structuredData";
import { Hero, Wrapper } from "../../components";
import { Link } from "react-router-dom";
import { workshopsMockData } from "../../mocks/qaWorkshops.mock";

const renderTextWithBold = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    let lastIndex = 0;
    const parts = [];

    text.replace(boldRegex, (match, content, index) => {
        if (index !== lastIndex) {
            parts.push(<span key={lastIndex}>{text.substring(lastIndex, index)}</span>);
        }
        parts.push(<strong key={index}>{content}</strong>);
        lastIndex = index + match.length;
    });

    if (lastIndex < text.length) {
        parts.push(<span key={lastIndex}>{text.substring(lastIndex)}</span>);
    }

    return parts;
};

const QaWorkshops = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);
    return (
        <>
            <HelmetSetter
                title='QA Workshops with ELITEX'
                description='ELITEX offer FREE QA consulting sessions to help startups & SMBs'
                ogImg='qaWorkshops/workshop-services.png'
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Quality Assurance',
                    item2: 'https://elitex.systems/software-qa-testing-services',
                    name3: 'Qa Workshops',
                    item3: 'https://elitex.systems/software-qa-testing-services/qa-workshops'
                })}
            />
            <Hero className="hero-big banner-with-img">
                <div className="workshop-main">
                    <Wrapper className="wrapper-medium">
                        <h1 className="workshop-main-title">QA Workshops with ELITEX</h1>
                        <div className="workshop-main-btns">
                            <a href="https://calendly.com/d/cp5g-m32-ryv/qa-workshops-with-elitex">
                                <button
                                    className="btn btn-primary btn-primary-inverted">
                                    Book a call
                                </button>
                            </a>
                            <Link to="/case-studies">
                                <button className="btn btn-primary">
                                    Case studies
                                </button>
                            </Link>
                        </div>
                    </Wrapper>
                </div>
            </Hero>
            <Wrapper className="wrapper-medium">
                <div className="workshop-company">
                    <h2 className="workshop-company-title">We have tested software used by</h2>
                    <div className="workshop-company-logos">
                        {
                            workshopsMockData.companyLogos.map(({ id, logo }) => (
                                <div className="">
                                    <img src={logo} alt="company logo" />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Wrapper>
            <div className="workshop-engineer">
                <div className="workshop-engineer-bg" />
                <Wrapper className="wrapper-medium">
                    <div className="workshop-engineer-content">
                        <div className="workshop-engineer-wrapper">
                            {
                                workshopsMockData.engineers.map((item) => (
                                    <div key={item.id} className="workshop-engineer-container">
                                        <div className="workshop-engineer-text">
                                            {item.title && <h2 className="workshop-engineer-title">{item?.title}</h2>}
                                            <p className="workshop-engineer-name">{item.name}</p>
                                            <p className="workshop-engineer-summary">{item.summary}</p>
                                        </div>
                                        <div className="workshop-engineer-photo">
                                            <img src={item.photo} alt="photo" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Wrapper>
            </div>
            <Wrapper className="wrapper-medium">
                <div className="workshop-consulting">
                    <h2 className="workshop-consulting-title">
                        ELITEX offer FREE QA consulting sessions to help startups & SMBs:
                    </h2>
                    <div className="workshop-consulting-content">
                        {
                            workshopsMockData.consulting.map((item) => (
                                <div key={item.id}
                                    className="workshop-consulting-item">
                                    <img
                                        className="workshop-consulting-icon"
                                        src={item.icon}
                                        alt="icon" />
                                    <div className="workshop-consulting-text">
                                        {renderTextWithBold(item.text)}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="workshop-process">
                    <h2 className="workshop-process-title">
                        How does it work?
                    </h2>
                    <div className="workshop-process-steps">
                        {workshopsMockData.process.map((item, index) => (
                            <div key={index} className="workshop-process-content">
                                <div className="workshop-process-step">
                                    {index + 1}
                                </div>
                                <p className="workshop-process-text">
                                    {item.text}
                                </p>
                            </div>
                        ))}
                        <div className="workshop-process-line" />
                    </div>
                </div>
            </Wrapper>
            <div className="workshop-info">
                <Wrapper className="wrapper-medium">
                    <p className="workshop-info-text">
                        QA Workshops by ELITEX are completely free with no commitments & hidden costs involved
                    </p>
                </Wrapper>
            </div>
            <Wrapper className="wrapper-medium">
                <div className="workshop-motivation">
                    <div className="workshop-motivation-icon">
                        <img src="/img/qaWorkshops/workshop-motivation.svg" alt="image" />
                    </div>
                    <div className="workshop-motivation-content">
                        <p className="workshop-motivation-title">What is our motivation?</p>
                        <p className="workshop-motivation-text">
                            A large number of seasoned Ukraine-based QA teams cannot find projects worth their
                            experience. We want to help great QA professionals network with companies that need
                            their expertise.
                        </p>
                    </div>
                </div>

                <div className="workshop-cta-container">
                    <div className="workshop-cta">
                        <div className="workshop-cta-logo">
                            <img src="/elitex-logo-white.svg" alt="logo" />
                        </div>
                        <div className="workshop-cta-content">
                            <p className="workshop-cta-title">
                                Book a QA Workshop with ELITEX
                            </p>
                            <a
                                className="btn btn-primary btn-primary-inverted"
                                href="https://calendly.com/d/cp5g-m32-ryv/qa-workshops-with-elitex">
                                Book Your Time
                            </a>
                        </div>
                    </div>
                </div>
                <div className="workshop-services-container">
                    <h2 className="workshop-services-title">
                        ELITEX have 10 years of manual and automation testing experience, covering the full spectrum
                        of QA services:
                    </h2>
                    <div className="workshop-services">
                        <div className="workshop-services-content">
                            {
                                workshopsMockData.services.map((service, index) => (
                                    <div key={service.id} className="workshop-services-wrapper">
                                        <div className="workshop-services-number">
                                            {index + 1}
                                        </div>
                                        <p className="workshop-services-text">{service.text}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="workshop-services-img">
                            <img src="/img/qaWorkshops/workshop-services.png" alt="image" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default QaWorkshops;