import React from 'react';
import './WdsSlider.css';
import '../../containers/MainSlider/MainSlider.css';
import Slider from "react-slick";
import {wdsSlider} from "../../mocks";
import {Link} from "react-router-dom";

export const WdsSlider = () => {
  let dragging;

  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    swipeToSlide: true,
    dots: true,
    beforeChange: () => dragging = true,
    afterChange: () => dragging = false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="wds-slider-container">
      <Slider {...settings}>
        {wdsSlider.map((item) => (
              <div aria-hidden="false" className="wds-slider-link" style={{backgroundImage: `url(${item.img})`}} key={item.id}>
                <Link tabIndex="-1" to={item.link} onClick={(e) => dragging && e.preventDefault()}>
                  <p className="wds-slider-title" style={{backgroundImage: `url(${item.img})`}}>{item.name}</p>
                </Link>
              </div>
        ))}
      </Slider>
    </div>
  )
};
