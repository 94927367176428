export const wdsSlider = [
  {
    id:1,
    name: 'JavaScript Development',
    link: '/javascript-development',
    img: 'img/jsd/webp/jsd.webp'
  },
  {
    id:2,
    name: 'Quality Assurance',
    link: '/software-qa-testing-services',
    img: 'img/qa/webp/qa.webp'
  },
  {
    id:3,
    name: 'Dedicated Team',
    link: '/dedicated-software-development-team',
    img: 'img/dedicatedTeam/webp/dedicated_team_back.webp'
  },
  {
    id:4,
    name: 'Web Development Service',
    link: '/web-development-service',
    img: 'img/wds/webp/wds_back.webp'
  },
  {
    id:5,
    name: 'UI Development',
    link: '/ui-development',
    img: 'img/uid/webp/uid_back.webp'
  },
  {
    id:6,
    name: 'Custom Software Development',
    link: '/custom-software-development',
    img: 'img/csd/webp/csd_back.webp'
  }
];
