import React, {Fragment} from 'react';
import './UiWhyChooseElitex.css';

const UiWhyChooseElitex = ({list}) => {
    return (
        <Fragment>
            <ul className="ui-why-choose-list">
                {
                    list.map(({id, text}) => (
                        <li key={id} className="main-subtitle">{text}</li>
                    ))
                }
            </ul>
        </Fragment>
    );
};

export default UiWhyChooseElitex;