import React, {Fragment} from 'react';
import './JsdWhyChooseElitex.css';
import BlueLine from "../BlueLine/BlueLine";
import {jsdWhyChooseCards, jsdWhyChooseContent} from "../../mocks";
import {Spacer} from "../Spacer/Spacer";

const JsdWhyChooseElitex = () => {
    const {title, textList} = jsdWhyChooseContent;
    return (
        <Fragment>
            <h2 className='main-title'><strong>{title.strong}</strong> {title.main}</h2>
             <BlueLine/>
            {
                textList.map(({id, text}) => (
                    <p key={id} className="jsd-why-choose-text">{text}</p>
                ))
            }
            <Spacer type="big"/>
            <div className="jsd-why-choose-cards-wrapper">
                {
                    jsdWhyChooseCards.map(({id, title, textList}) => (
                        <div key={id} className="jsd-why-choose-card-container">
                            <h3 className="jsd-why-choose-card-title">{title}</h3>
                            {
                                textList.map(({id,text}) => (
                                    <p key={id} className="jsd-why-choose-card-text">{text}</p>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </Fragment>
    );
};

export default JsdWhyChooseElitex;