import React, {useEffect} from 'react';
import './STM.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import HelmetSetter from "../../components/Helmet/Helmet";
import {Hero, Spacer, Wrapper} from "../../components";
import {ContactForm} from "..";
import {stmSliders} from "../../mocks";
import {CaseStudyCustomSlider} from "../index";
import {portfolioItem} from "../../utils";
import LazyLoad from "react-lazyload";
import {breadcrumbStructuredData} from "../../utils/structuredData";
import {stmVideoStructuredData} from "../../mocks/portfolioStructuredData";

export const STM = () => {
    useEffect(() => {
        setTimeout(hideLoader.bind(this), delay);
        return showLoader();
    }, []);

    const item = portfolioItem('stm');

    return (
        <React.Fragment>
            <HelmetSetter
                title='STM Integrity Hub'
                description='A modular platform to safeguard research integrity'
                ogImg='portfolio/stm/back.jpg'
                videoStructuredData={stmVideoStructuredData}
                structuredData={breadcrumbStructuredData({
                    name1: 'Home',
                    item1: 'https://elitex.systems',
                    name2: 'Case Studies',
                    item2: 'https://elitex.systems/case-studies',
                    name3: 'STM Integrity Hub',
                    item3: 'https://elitex.systems/case-studies/stm'
                })}
            />
            <Hero className="hero-big banner-with-img stm">
                <Wrapper id="page-title">
                    <div className='case-study-hero-divider-container case-study-mt'>
                        <div className='case-study-hero-divider-txt'>
                            <h1 className="white-text h1">{item.name}</h1>
                            <p className='white-text'>{item.subName}</p>
                        </div>
                        <div className='case-study-hero-divider-img'>
                            <img
                                src="/img/portfolio/stm/webp/stm_notebook.webp"
                                srcSet="/img/portfolio/stm/resized/stm_notebook_2_small.webp 600w,
                                        /img/portfolio/stm/resized/stm_notebook_2_medium.webp 1100w,
                                        /img/portfolio/stm/webp/stm_notebook.webp 1400w"
                                alt="notebook"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </Wrapper>
            </Hero>

            <Spacer type='medium'/>
            <div className='case-study-page'>
                <Wrapper>
                    <div className='case-study-column column-mb'>
                        <img
                            className="case-study-logo stm-logo"
                            src="/img/portfolio/stm/resized/stm_logo.webp"
                            alt="logo"
                            width="170"
                            height="33"
                        />
                        <Spacer type='medium'/>
                        <h3 className='h4'><strong>Industry</strong></h3>
                        <p>Publishing, science</p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Technology</strong></h3>
                        <p><strong>AWS:</strong></p>
                        <p>Lambdas, DynamoDB, OpenSearch, Fargate, S3, SSM, CloudFormation</p>
                        <p>JavaScript (Node.js 14.x)</p>
                        <p>Serverless Framework </p>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Services</strong></h3>
                        <ul className="nolist">
                            <li>Dedicated nearshore team</li>
                            <li>System integration service</li>
                            <li>UI development</li>
                            <li>Back-end development</li>
                        </ul>
                        <Spacer type='mini'/>
                        <h3 className='h4'><strong>Customer’s Location</strong></h3>
                        <p className='case-study-location'>
                            <img
                                src="/img/portfolio/flags/netherlands_flag.webp"
                                alt="flag"
                                width="40"
                                height="40"
                            />The Netherlands
                        </p>
                        <Spacer type='small'/>
                        <a
                            href='https://www.stm-assoc.org'
                            className="btn btn-default btn-sm"
                            target="_blank"
                            rel="noopener noreferrer">View Site</a>
                    </div>

                    <div className='case-study-60'>
                        <h2 className='h4'>About the <strong>project</strong></h2>
                        <p>
                            STM is the leading global trade association for academic and professional publishers, with
                            145 members in 21 countries. The STM Integrity Hub was founded by STM members committed to
                            research integrity.
                            Its architecture is designed to evolve and grow as new data, standards, and resources,
                            consistent with applicable laws, data privacy, and competition law, are added.
                        </p>
                        <Spacer type='medium'/>
                        <p><strong>As the enabling infrastructure, the Hub covers:</strong></p>
                        <ul className='case-study-ul case-study-list-bullet peerwith'>
                            <li>Technology and screening infrastructure.</li>
                            <li>The secure, protected environment that protects data privacy and is consistent with
                                competition laws.
                            </li>
                            <li>Policies + frameworks.</li>
                            <li>A modular platform to safeguard research integrity for all connected STM members.</li>
                        </ul>
                        <Spacer type='medium'/>
                        <Wrapper className="stm-wrapper">
                            <div className='iframe-container'>
                                <div className='iframe-video-wrapper'>
                                    <iframe
                                        src="https://www.youtube.com/embed/6yA3phww3m8?controls=0&modestbranding=1&rel=0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title='The OA Switchboard'
                                    ></iframe>
                                </div>
                            </div>
                        </Wrapper>
                        <Spacer type='medium'/>
                        <h2 className='h4'>The <strong>challenge</strong></h2>
                        <p>
                            The STM Integrity Hub aims to provide a cloud-based environment for publishers to check
                            submitted articles for research integrity issues, consistent with applicable laws and
                            industry best practices and fully respecting the laws and ethics of data privacy and
                            competition/anti-trust laws.
                        </p>
                        <p>
                            When the customer contacted us for the first time, the startup was in need of software
                            development expertise and flexibility to scale the team up and down according to the
                            project’s needs.
                        </p>
                    </div>
                </Wrapper>
                <Spacer type='medium'/>

                <Wrapper>
                    <LazyLoad>
                        <img
                            src="/img/portfolio/stm/resized/stm_architecture_large.webp"
                            srcSet="/img/portfolio/stm/resized/stm_architecture_small.webp 600w,
                                        /img/portfolio/stm/resized/stm_architecture_medium.webp 1100w,
                                        /img/portfolio/stm/resized/stm_architecture_large.webp 1400w"
                            alt="architecture"
                            width="100%"
                            height="100%"
                        />
                    </LazyLoad>
                </Wrapper>

                {/*sticky zone*/}
                <div className="case-study-relative">
                    <div className='case-study-flex-order'>
                        <Wrapper>
                            <Spacer type='medium'/>
                            <div className='case-study-60'>
                                <h2 className='h4'>The <strong>solution</strong></h2>
                                <p>STM Solutions decided to hire a dedicated, nearshored team of developers at ELITEX.
                                    The team is responsible for enabling infrastructure, integration with publishers,
                                    and the actual screening tools provided by third parties.</p>
                                <p>The enabling infrastructure is an API-first set of AWS (micro)services glued together
                                    by Lambda functions. It means our developers write and maintain those Lambda
                                    functions. DevOps engineers are responsible for the various services' configuration
                                    and deployments, such as API Gateway, Cognito, DynamoDB, SQS, and others. This setup
                                    is serverless and, therefore, highly scalable.</p>
                                <p>The ultimate goal is for publishers to integrate their back-end systems with the Hubs
                                    API themselves. However, ELITEX is also responsible for the intermediate solution
                                    where connectors sit between publishers' systems, such as editorial/peer review
                                    systems, a publisher configuration environment, then connect to the Hub API. That
                                    means our developers work with publishers' IT staff to integrate all systems and
                                    bring the required data feeds in place. At the other end, participating members
                                    and/or third parties bring the actual screening tools or Applications into the mix,
                                    which also need to be integrated into the Hubs workflow.</p>
                            </div>
                        </Wrapper>
                    </div>
                </div>

                <div className='case-study-order-3'>
                    <Wrapper>
                        <div className='case-study-60'>
                            <Spacer type='medium'/>
                            <h2 className='h4'><strong>Functional tools</strong></h2>
                            <p>Although the Integrity Hub is primarily API-driven, in practice, a User Interface is
                                needed as well. So part of our delivery is also the UI for publishers, for STM to manage
                                the Hub, and a dedicated UI for Papermill detection, which combines several different
                                screening tools but acts as a single Application.</p>
                            <p>The complexity of the Hubs dataflows is exciting, especially if we combine legal and data
                                privacy-related policies. A significant part of the hours spent by our team is on this
                                sensitive topic.</p>
                        </div>
                    </Wrapper>
                    <Spacer type='medium'/>
                </div>

                <div className='case-study-grey-3'>
                    <Wrapper>
                        <CaseStudyCustomSlider slides={stmSliders}/>
                    </Wrapper>
                </div>

                <div className='case-study-order-4'>
                    <Wrapper>
                        <div className='case-study-60'>
                            <Spacer type='medium'/>
                            <h2 className='h4'>The <strong>result</strong></h2>
                            <p>
                                The STM Integrity Hub is more a System Integration project than Custom Software
                                Development, although some development is needed as well. We are proud as the “spider in
                                the web” to connect all stakeholders together.
                            </p>
                            <p>
                                ELITEX team is excited to be able to act as a tech partner for STM in this project and
                                collaborate with so many different publishers to solve their integration challenges.
                            </p>
                        </div>
                    </Wrapper>
                </div>
                <Spacer type="big"/>
            </div>
            <div className='case-study-grey-2'>
                <ContactForm
                    hideCalendly
                    linkedinLink="https://www.linkedin.com/in/ivoverbeek/"
                    linkedinPhoto="/img/contactForm/ivo.jpeg"
                    linkedinPosition="Director ELITEX NL."
                    linkedinName="Ivo Verbeek"
                />
            </div>
        </React.Fragment>
    )
};
