import React from 'react';
import './ContactInfoItem.css';

export const ContactInfoItem = ({country, street, city, phone, email, secondAddress}) => (
    <div className="address-card">
        <h4>{country}</h4>
        <div>
            <p>{street}</p>
            <p>{city}</p>
            <p>{phone}</p>
            {secondAddress && (
                <div className="address-card-second">
                    <p>{secondAddress.street}</p>
                    <p>{secondAddress.city}</p>
                    <p>{secondAddress.phone}</p>
                </div>
            )}
            {email && <p id="address-card-email"><a href={`mailto:${email}`}>{email}</a></p>}
        </div>
    </div>
);
