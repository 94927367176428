import React, {useEffect} from 'react';
import './Kallik.css';
import {delay, hideLoader, showLoader} from "../../utils/loaderHandler.util";
import {Hero, Spacer, Wrapper, CaseStudyFeedback} from "../../components";
import {kallikSliders} from "../../mocks";
import {ContactForm} from "..";
import HelmetSetter from "../../components/Helmet/Helmet";
import {CaseStudyCustomSlider} from "../index";
import {portfolioItem} from "../../utils";
import {breadcrumbStructuredData} from "../../utils/structuredData";
import {kallikVideoStructuredData} from "../../mocks/portfolioStructuredData";

export const Kallik = () => {
  useEffect(() => {
    setTimeout(hideLoader.bind(this), delay);
    return showLoader();
  }, []);

  const item = portfolioItem('kallik');

  return (
    <React.Fragment>
      <HelmetSetter
        title='Case Study - Kallik - Labeling content management | ELITEX'
        description='A long time of successful cooperation with cross-functional teams from both sides (the customer’s team in the UK and ELITEX’s team in Ukraine).'
        ogImg='portfolio/kallik/back.jpg'
        videoStructuredData={kallikVideoStructuredData}
        structuredData={breadcrumbStructuredData({
          name1: 'Home',
          item1: 'https://elitex.systems',
          name2: 'Case Studies',
          item2: 'https://elitex.systems/case-studies',
          name3: 'Kallik',
          item3: 'https://elitex.systems/case-studies/kallik'
        })}
      />
      <Hero className="hero-big banner-with-img kallik">
        <Wrapper id="page-title">
          <div className='case-study-hero-divider-container'>
            <div className='case-study-hero-divider-txt'>
              <h1 className="white-text h1">{item.name}</h1>
              <p className='white-text h5'>{item.subName}</p>
            </div>
            <div className='case-study-hero-divider-img'>
              <img
                  src="/img/portfolio/kallik/resized/kallik_notebook_2_large.webp"
                  srcSet="/img/portfolio/kallik/resized/kallik_notebook_2_small.webp 600w,
                  /img/portfolio/kallik/resized/kallik_notebook_2_medium.webp 1100w,
                  /img/portfolio/kallik/resized/kallik_notebook_2_large.webp 1400w"
                  alt="notebook"
                  width="100%"
                  height="100%"
              />
            </div>
          </div>
        </Wrapper>
      </Hero>

      <Spacer type='medium'/>
      <div className='case-study-page'>
        <Wrapper>
          <div className='case-study-column column-mb'>
            <img
                src="/img/portfolio/kallik/resized/kallik_logo.webp"
                alt="logo"
                width="170"
                height="32"
            />
            <Spacer type='medium'/>
            <h3 className='h4'><strong>Industry</strong></h3>
            <p>Labeling content management</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Technology</strong></h3>
            <p>JavaScript, Angular, HTML/CSS, Java, Oracle DB</p>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Services</strong></h3>
            <ul className="nolist">
              <li>Dedicated development team</li>
              <li>Front-end development</li>
              <li>Back-end development</li>
              <li>Quality assurance</li>
              <li>Product extension</li>
            </ul>
            <Spacer type='mini'/>
            <h3 className='h4'><strong>Customer’s Location</strong></h3>
            <p className='case-study-location'>
              <img
                  src="/img/portfolio/flags/united-kingdom_flag.webp"
                  alt="flag"
                  width="40"
                  height="40"
              />
              Birmingham, United Kingdom
            </p>
            <Spacer type='small'/>
            <a
              href='https://www.kallik.com/'
              className="btn btn-default btn-sm"
              target="_blank"
              rel="noopener noreferrer">View Site</a>
          </div>

          <div className='case-study-60'>
            <h2 className='h4'>About the <strong>project</strong></h2>
            <p>Kallik is a global provider of labeling and artwork management solutions for regulated markets such as the pharmaceutical, medical devices, cosmetics, and chemical industries.</p>
            <p>Kallik’s labeling and artwork management solution enables large worldwide organizations to produce accurate, print-ready artwork for their packaging and labeling in no time. The integrated cloud solution centralizes messaging content, stores all its types, e.g., text, symbols, and logos, as structured data, and manages it in a smooth way.</p>
            <p>Global organizations rely on Kallik to ensure compliance, guarantee product and brand consistency, and reduce the risk of the product recall. A list of Kallik’s customers includes famous companies and brands from the US, UK, and other countries.</p>
            <Spacer type='medium'/>
            <h2 className='h4'>The <strong>challenge</strong></h2>
            <p>The existing version of the labeling solution was based on Java and had a powerful functionality. However, its UI was outdated and, in many cases, inconvenient to use. Kallik needed to:</p>
            <ul className='case-study-ul case-study-list-bullet kallik'>
              <li>Update and modernize their core web application</li>
              <li>Extend the application's features</li>
              <li>Get further ongoing development</li>
              <li>Get regular maintenance</li>
            </ul>
            <p>As the client’s in-house development team mainly consisted of back-end developers, they lacked UI/front-end expertise to accelerate the system modernization process. Our customer therefore decided
              to augment their expertise with a remote JavaScript development team.</p>
          </div>
        </Wrapper>
        <Spacer type='medium'/>

        <div className='case-study-grey-3'>
          <Wrapper>
            <CaseStudyCustomSlider slides={kallikSliders}/>
          </Wrapper>
        </div>

        {/*sticky zone*/}
        <div className="case-study-relative">
          <div className='case-study-flex-order'>
            <Spacer type='medium'/>
            <Wrapper>
              <div className='case-study-60'>
                <h2 className='h4'>The <strong>solution</strong></h2>
                <p>We began by involving our JavaScript/UI experts, who closely collaborated with the customer’s API engineers and QAs.</p>
                <p>Since the initial cooperation was successful and ELITEX had proven its trustworthy and strong
                  technical expertise, Kallik has started sharing increased responsibilities with our team.</p>
                <p>As a result, the remote team has grown, adding more UI/front-end developers, Java developers, and
                  QA engineers. ELITEX also started providing back-end/API development and testing services.</p>
              </div>
            </Wrapper>
            <Spacer type='medium'/>
            <div className='case-study-grey'>
              <Wrapper>
                <div className='case-study-60'>
                  <CaseStudyFeedback
                    cssClass={'kallik-quotes-color'}
                    img='/img/clients/resized/clients_rob.webp'
                    name='Rob Woodall'
                    position='VP Technology at Kallik'
                    text='The collaboration with ELITEX was successful. Their team developed several unique software solutions, including a rule engine editor. They were responsive to inquiries from the client and consistently met deadlines. Customers can expect strong project management skills and creativity.'/>
                </div>
              </Wrapper>
              <Spacer type='medium'/>
              <Wrapper>
                <div className='iframe-container'>
                  <div className='iframe-video-wrapper'>
                    <iframe
                        src="https://www.youtube.com/embed/RVRne623cAs?si=zPsuu-uTMsoQ3X9y&controls=0&modestbranding=1&rel=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                  </div>
                </div>
              </Wrapper>
            </div>

            <div className='case-study-order-2'>
              <Spacer type='medium'/>
              <Wrapper>
                <div className='case-study-60'>
                  <h2 className='h4'>The <strong>result</strong></h2>
                  <p>Currently, after a long time of successful cooperation, cross-functional teams from both sides (the
                    customer’s team in the UK and ELITEX’s team in Ukraine) are highly integrated and successfully work
                    together to deliver in time and meet Kallik’s and its customers' needs.</p>
                  <p>Our dedicated development team that works for Kallik includes the following:</p>
                  <ul className='case-study-ul case-study-list-bullet kallik'>
                    <li>Three front-end engineers (JavaScript)</li>
                    <li>Three backend engineers (Java)</li>
                    <li>Three quality assurance (QA) engineers</li>
                    <li>Project manager</li>
                    <li>Team lead</li>
                  </ul>
                  <p>The project management process is based on a scrum methodology.  Every two weeks, the team handles demo meetings and planners with the customer to keep up with Kallik’s expectations and set the following tasks. Additionally, people from both sides have business trips to meet each other personally.</p>
                </div>
              </Wrapper>
            </div>
          </div>
        </div>

        <Spacer type='small'/>
      </div>
      <div className='case-study-grey-2'>
        <ContactForm/>
      </div>

    </React.Fragment>
  )
};
