import React from 'react';
import './MenuDropdown.css';
import {NavLink} from "react-router-dom";
import {navigationSubMenu} from "../../mocks";
import classNames from "classnames";

export const MenuDropdown = ({change, category, narrow}) => {
   const subMenuClass = classNames({
        "elx-menu-dropdown": true,
        "elx-menu-dropdown-narrow" : narrow,
        "elx-menu-dropdown--inverted": change,
    })

  return (
    <div className={subMenuClass}>
                {navigationSubMenu?.[category].map(item => {
                     return item.link.includes('https') ?
                    (<a key={item.id} className="elx-menu-dropdown-link" href={item.link}> {item.name}</a>) :
                    (
                        <NavLink
                            key={item.id}
                            to={item.link}
                            className="elx-menu-dropdown-link"
                            activeClassName="active-nav"
                            exact>
                            {item.name}
                        </NavLink>
                    )
                })}
    </div>
  )
};
